export class CheckListsFilter {
  category?: number;
  agentId?: number;
  state: number;
  agentActivityId?: number;

  constructor(state: number, category?: number, agentId?: number, agentActivityId?: number) {
    this.state = state;

    if (undefined !== category && category !== -1) {
      this.category = category;
    }
    if (undefined !== agentId && agentId !== -1) {
      this.agentId = agentId;
    }
    if (undefined !== agentActivityId) {
      this.agentActivityId = agentActivityId;
    }
  }
}
