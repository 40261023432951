<template>
  <div class="no-scrollbar flex h-[calc(100dvh)] flex-col justify-center overflow-x-auto">
    <div class="m-auto">
      <div class="box">
        <div class="flex flex-col items-center justify-center bg-white p-96">
          <div class="scale-90"><LogoA2G /></div>
          <div class="mt-24 w-256">
            <AgButton variant="secondary" @click="login" block> {{ t('loginMicrosoft') }} </AgButton>
          </div>
        </div>
        <div class="flex flex-col justify-center bg-primary p-48 text-white">
          <div class="font-bold pb-8">Agent2Go</div>
          <div class="font-light">{{ t('loginText') }}</div>
        </div>
      </div>
      <div class="imprinting">
        <span class="font-bold">agent2go by KONVERTO&nbsp;</span>
        <span class="opacity-90">
          Buozzistraße, 8 - 39100, Bozen, info@konverto.eu, Tel. 0471 064 500, MwSt. Nr. 02254110212
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import AgButton from '@/components/library/button/AgButton.vue';
  import LogoA2G from '@/components/logo/LogoA2G.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { i18n } from '@/plugins/i18n';
  import { watch } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;

  const { login, isAuthenticated } = useAuthentication();
  const router = useRouter();

  watch(
    isAuthenticated,
    (value) => {
      if (value) {
        router.push('/dashboard');
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .box {
    @apply grid max-w-[700px] grid-cols-2 max-md:max-w-[400px] max-md:grid-cols-1;
  }
  .imprinting {
    @apply max-w-[533px] break-words text-[10px] text-white max-md:max-w-[333px] pt-8;
  }

  .imprintingFooter {
    @apply w-fit break-words text-[10px] text-white;
  }
</style>
