<template>
  <div class="m-24 border border-neutral-550">
    <div class="p-12">
      <div v-if="items.length === 0">
        <div>{{ t('customers.noDataFound') }}</div>
      </div>
      <div v-else>
        <div class="max-h-512 py-12">
          <div class="tableHeader">
            <div class="headerColumn">{{ t('customers.invoiceNumber') }}</div>
            <div class="headerColumn">{{ t('customers.invoiceDate') }}</div>
            <div class="headerColumn">{{ t('customers.invoiceDueDate') }}</div>
            <div class="headerColumn right">{{ t('customers.openAmount') }}</div>
          </div>

          <div v-for="item in props.items" :key="item.invoiceCode">
            <div class="table">
              <div class="tableColumn">{{ item.invoiceCode }}</div>
              <div class="tableColumn">{{ formatDate(item.invoiceDate) }}</div>
              <div class="tableColumn">{{ formatDate(item.dueDate) }}</div>
              <div class="tableColumn right" :class="{ 'text-infoRed': item.openAmount < 0 }">
                {{ formatNumberToEuro(item.openAmount) }}
              </div>
            </div>
          </div>

          <div class="mt-8 flex justify-end border-t border-neutral-550 pt-8">
            <strong :class="{ 'text-infoRed': totalInvoicesAmount < 0 }">
              {{ formatNumberToEuro(totalInvoicesAmount) }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    items: Array<OutstandingDebtDto>;
  }

  const props = defineProps<Props>();

  const totalInvoicesAmount = computed(() => {
    let amount = 0;
    props.items.forEach((item) => {
      amount = amount + item.openAmount;
    });
    return amount;
  });
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-flow-col grid-cols-4 gap-4 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid grid-flow-col grid-cols-4 gap-4 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }
</style>
