<template>
  <AgModal v-if="internalValue" :has-header="false" fit-height fit-width>
    <template #content>
      <div class="md:min-h-[120px]">
        <div class="text-xl font-bold pb-16">
          {{ t('orders.confirmCloseOrder') }}
        </div>
        <div class="mx-auto py-24">
          <div v-if="isLoading">
            <IcLoading />
          </div>
          <div v-if="isError" class="text-danger">
            {{ t('orders.errorCloseOrder') }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click.stop="closeModal" :disabled="isLoading">
        {{ t('orders.cancel') }}
      </AgButton>
      <AgButton @click="confirmCloseOrder" :disabled="isLoading">
        {{ t('orders.closeOrder') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useOrderData } from '@/composables/data/useOrderData';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  const { syncOrders } = useOrderData();

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  interface Props {
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const confirmCloseOrder = function () {
    if (!order.value || !order.value.gid) {
      isLoading.value = false;
      isError.value = true;

      return;
    }
    isLoading.value = true;
    isError.value = false;

    order.value.orderDate = currentTimestamp();
    order.value.status = OrderStatus.COMPLETED;

    setTimeout(() => closeOrder(), 3 * 1000);
  };

  const closeOrder = async function () {
    await syncOrders().then(() => {
      reloadOrder();
    });
  };

  const reloadOrder = function () {
    const gid = order.value ? order.value.gid : '';
    orderStore.loadOrderByGid(gid).then(() => {
      isLoading.value = false;
      isError.value = false;
      closeModal();
    });
  };
</script>
