import { useAuthentication } from '@/composables/auth/useAuthentication';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export default function authRequired(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated.value) {
    return next('/login');
  }
  return next();
}
