import { useApi } from '@/composables/api/useApi';
import type { AuthUserDto } from '@/domain/admin/AuthUserDto';

export function useDashboard() {
  const api = useApi();

  const getUsers = async function (): Promise<Array<AuthUserDto>> {
    const users: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/users/1';

      const container = await api.fetch<Array<AuthUserDto>>(requestUri);
      if (container) {
        users.push(...container);
      }
    } catch (error) {
      console.log('Error', error);
    }
    return users;
  };

  return {
    getUsers,
  };
}
