<!-- eslint-disable max-len -->
<template>
  <svg id="ic_mail_close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Pfad_2213" data-name="Pfad 2213" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Pfad_2214"
      data-name="Pfad 2214"
      d="M18.33,6.718c0-.588-.279-1.389-1.626-1.389H3.634A1.5,1.5,0,0,0,2,6.857v8.03a1.639,1.639,0,0,0,1.634,1.634H16.7a1.639,1.639,0,0,0,1.634-1.634Zm-1.8.139L10.169,10.8,3.859,6.857Zm-12.9,8.03V8.63l6.535,4.1L16.7,8.654l.008,6.233Z"
      transform="translate(0 -1.329)"
      fill="currentColor"
    />
  </svg>
</template>
