<template>
  <button class="text-s" :class="buttonClasses" :disabled="props.disabled">
    <div class="group min-h-[40px] flex items-center justify-center gap-8 uppercase font-bold">
      <div v-if="hasIcon && iconBeforeText === true"><slot name="icon"></slot></div>
      <div v-if="hasContent" :class="contentClasses"><slot></slot></div>
      <div v-if="hasIcon && iconBeforeText === false"><slot name="icon"></slot></div>
    </div>
  </button>
</template>

<script setup lang="ts">
  import { computed, useSlots } from 'vue';

  interface Props {
    effect?: 'hover';
    block?: boolean;
    disabled?: boolean;
    rounded?: boolean;
    variant?: 'primary' | 'secondary' | 'ghost';
    iconBeforeText?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    block: false,
    disabled: false,
    rounded: false,
    variant: 'primary',
    iconBeforeText: false,
  });

  const contentClasses = computed(() => ({
    'hidden group-hover:flex group-hover:px-12': props.effect && props.effect === 'hover',
  }));

  const buttonClasses = computed(() => ({
    rounded: props.rounded,
    'block w-full': props.block,
    'bg-primary text-white active:bg-primary-dark': !props.disabled && props.variant === 'primary',
    'bg-secondary border active:text-primary-dark active:border-primary-dark':
      !props.disabled && props.variant === 'secondary',
    'bg-neutral-250 text-white': props.disabled && props.variant === 'primary',
    'bg-secondary border border-neutral-200 text-neutral-200': props.disabled && props.variant === 'secondary',
    'text-neutral-200': props.disabled && props.variant === 'ghost',
    'active:text-primary': !props.disabled && props.variant === 'ghost',
    'px-32': hasContent.value && !hasIcon.value,
  }));

  const hasContent = computed<boolean>(() => {
    return !!useSlots().default;
  });

  const hasIcon = computed<boolean>(() => {
    return !!useSlots().icon;
  });
</script>
