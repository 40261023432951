<template>
  <div>
    <!-- TABLE COLLAPSIBLE -->
    <AgCollapsible show-icon class="min-w-fit border-t border-neutral-500" :disabledAutoCollapse="disabledAutoCollapse">
      <template #header>
        <!-- TABLE CONTENT -->
        <div class="table">
          <!-- COLUM AGENT CODE -->
          <div class="tableColumn">
            {{ props.openItem.agentCode }}
          </div>
          <!-- COLUM AGENT NAME -->
          <div class="tableColumn">
            {{ props.openItem.agentName }}
          </div>
          <!-- COLUM INVOICE COUNT -->
          <div class="tableColumn right">
            {{ props.openItem.invoiceCount }}
          </div>
          <!-- COLUM OPEN AMOUNT SUM -->
          <div class="tableColumn right" :class="{ 'text-[#dc3545]': props.openItem.openAmountSum < 0 }">
            {{ formatNumberToEuro(props.openItem.openAmountSum) }}
          </div>
          <!-- COLUM ICON SEND EMAIL TO AGENT -->
          <div class="tableColumn right">
            <span @click.stop="sendEmail(props.openItem.agentEmail)">
              <IcMailClose class="cursor-pointer" />
            </span>
          </div>
        </div>
      </template>
      <template #default>
        <AgentInvoicesTable :agentId="props.openItem.agentId" v-model="disabledAutoCollapse" class="cursor-pointer" />
      </template>
    </AgCollapsible>

    <!-- SEND EMAIL POPUP -->
    <OpenItemsPopupEmail
      v-model:modal="showPopupEmail"
      v-model:email="emailTo"
      :which-email="WhichEmail.AGENT_OUTSTANDING_DEBTS"
      :sorting="props.sorting"
      :agent-id="props.openItem.agentId"
    />
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtAgentDto } from '@/domain/OutstandingDebtAgentDto';

  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import OpenItemsPopupEmail from '@/modules/open-items/components/OpenItemsPopupEmail.vue';
  import AgentInvoicesTable from '@/modules/open-items/components/by-agent/AgentInvoicesTable.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { Sorting } from '@/util/Sorting';
  import { ref } from 'vue';

  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    openItem: OutstandingDebtAgentDto;
    sorting: Sorting;
  }
  const props = defineProps<Props>();

  const disabledAutoCollapse = ref<boolean>(false);

  const emailTo = ref<string>('');
  const showPopupEmail = ref<boolean>(false);

  const sendEmail = (email: string) => {
    emailTo.value = email;
    showPopupEmail.value = true;
  };
</script>

<style scoped lang="scss">
  .table {
    @apply grid grid-cols-[150px_minmax(133px,_1fr)_170px_200px_30px] min-h-48;
    @apply items-center;
  }

  .table:hover {
    @apply cursor-pointer bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }
</style>
