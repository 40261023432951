<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <CustomerFilter v-model="selectedCustomerId" />
    </TheSubheader>

    <div class="overflow-auto" v-if="selectedCustomerId > 0">
      <div class="mt-12 mb-32 md:mx-[80px] max-md:mx-16 min-w-fit">
        <AgAlert v-if="customer?.blocked" variant="danger">
          {{ t('customers.userBlocked') }}
          <template #icon><IcAlert /></template>
        </AgAlert>

        <!-- OPEN ITEMS INFO -->
        <div class="flex py-12 gap-4 text-s-13" v-if="invoiceNumber">
          <div class="flex gap-4 items-center justify-center bg-neutral-300 rounded-full p-4 shrink">
            <!-- OPEN ITEMS NUMBER -->
            <div class="flex items-center justify-center rounded-full bg-infoBlue text-white w-24 h-24">
              {{ invoiceNumber.totalNumberOpenItems }}
            </div>
            {{ t('customers.openPost') }}
          </div>
          <div class="flex gap-4 items-center justify-center bg-neutral-300 rounded-full p-4 shrink">
            <!-- TODAY < X < 1 -->
            <div class="flex items-center justify-center rounded-full bg-infoYellow text-white w-24 h-24">
              {{ invoiceNumber.totalLeastOneMonth }}
            </div>
            {{ t('customers.openItemstotalLeast1Month') }}
          </div>
          <!-- 1 < X < 6  -->
          <div class="flex gap-4 items-center justify-center bg-neutral-300 rounded-full p-4 shrink">
            <div class="flex items-center justify-center rounded-full bg-infoOrange text-white w-24 h-24">
              {{ invoiceNumber.totalOneMonth }}
            </div>
            {{ t('customers.openItems1M') }}
          </div>
          <!-- X > 6  -->
          <div class="flex gap-4 items-center justify-center bg-neutral-300 rounded-full p-4 shrink">
            <div class="flex items-center justify-center rounded-full bg-infoRed text-white w-24 h-24">
              {{ invoiceNumber.totalSixMonth }}
            </div>
            {{ t('customers.openItems6M') }}
          </div>
        </div>

        <!-- CUSTOMER ADDRESS INFO -->
        <CustomerAddressInfo :customer="customer" />

        <!-- CUSTOMER INFO TAB -->
        <div>
          <!-- CUSTOMER TAB TITLE -->
          <AgTabSwitch class="w-full min-w-fit border-b-neutral-500">
            <AgTab v-model="selectedTab" v-if="config.company.modules.ORDER" value="orders" dynamic-width>
              <template #title>{{ t('customers.orders') }}</template>
            </AgTab>
            <AgTab v-model="selectedTab" v-if="config.company.modules.OFFER" value="offers" dynamic-width>
              <template #title>{{ t('customers.offers') }}</template>
            </AgTab>
            <AgTab v-model="selectedTab" v-if="config.company.modules.OPEN_ITEMS" value="open-items" dynamic-width>
              <template #title>{{ t('customers.openPost') }}</template>
            </AgTab>
            <AgTab v-model="selectedTab" v-if="hasOrderOrOfferActive()" value="trade-agreements" dynamic-width>
              <template #title>{{ t('customers.tradeAgreements') }}</template>
            </AgTab>
            <AgTab v-model="selectedTab" value="customer-notes" dynamic-width>
              <template #title>{{ t('customers.notes') }}</template>
            </AgTab>
            <AgTab
              v-model="selectedTab"
              v-if="config.company.modules.CHECKLISTS"
              value="customer-activities"
              dynamic-width
            >
              <template #title>{{ t('customers.checklists') }}</template>
            </AgTab>
          </AgTabSwitch>
          <!-- CUSTOMER ORDERS TAB -->
          <div v-if="selectedTab === Tabs.Orders">
            <CustomerOrders :customer="customer" />
          </div>
          <!-- CUSTOMER OFFERS TAB -->
          <div v-if="selectedTab === Tabs.Offers">
            <CustomerOffers :customer="customer" />
          </div>
          <!-- CUSTOMER OPEN ITEMS TAB -->
          <div v-if="selectedTab === Tabs.OpenItems">
            <CustomerOpenItems :customer-id="selectedCustomerId" />
          </div>
          <!-- CUSTOMER TRADE AGREEMENT TAB -->
          <div v-if="selectedTab === Tabs.TradeAgreements" class="min-h-384">
            <CustomerTradeAgreement :customer="customer" />
          </div>
          <!-- CUSTOMER NOTES TAB -->
          <div v-if="selectedTab === Tabs.CustomerNotes">
            <CustomerNotes :customer-id="selectedCustomerId" />
          </div>
          <!-- CUSTOMER ACTIVITIES TAB -->
          <div v-if="selectedTab === Tabs.CustomerActivities">
            <CustomerActivities :customerId="selectedCustomerId" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12 mb-32 md:mx-[80px] max-md:mx-16" v-if="selectedCustomerId == -1">
      <AgAlert variant="info">{{ t('customers.selectCustomer') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { InvoiceNumberDto } from '@/domain/InvoiceNumberDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAlert from '@/components/icons/IcAlert.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgTabSwitch from '@/components/library/tab-switch/AgTabSwitch.vue';
  import AgTab from '@/components/library/tab-switch/tab/AgTab.vue';
  import CustomerAddressInfo from '@/modules/customers/components/CustomerAddressInfo.vue';
  import CustomerFilter from '@/modules/customers/components/CustomerFilter.vue';
  import CustomerActivities from '@/modules/customers/components/checklists/CustomerActivities.vue';
  import CustomerNotes from '@/modules/customers/components/customer-notes/CustomerNote.vue';
  import CustomerOffers from '@/modules/customers/components/offers/CustomerOffers.vue';
  import CustomerOpenItems from '@/modules/customers/components/open-items/CustomerOpenItems.vue';
  import CustomerOrders from '@/modules/customers/components/orders/CustomerOrders.vue';
  import CustomerTradeAgreement from '@/modules/customers/components/tradeAgreement/CustomerTradeAgreement.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useCommon } from '@/composables/useCommon';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;
  const { tables } = useMasterData();

  const { getInvoiceNumber } = useCommon();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('customers.overview');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  enum Tabs {
    Orders = 'orders',
    Offers = 'offers',
    OpenItems = 'open-items',
    TradeAgreements = 'trade-agreements',
    CustomerNotes = 'customer-notes',
    CustomerActivities = 'customer-activities',
  }

  const selectedTab = ref<string>('');
  const selectedCustomerId = ref<number>(-1);
  const customer = ref<CustomerDto | null>(null);
  const invoiceNumber = ref<InvoiceNumberDto | null>(null);

  const getSelectedTabDefault = function () {
    if (config.company.modules.ORDER) {
      return Tabs.Orders;
    } else if (config.company.modules.OFFER) {
      return Tabs.Offers;
    } else if (config.company.modules.OPEN_ITEMS) {
      return Tabs.OpenItems;
    }
    return Tabs.CustomerNotes;
  };

  const loadCustomer = async function () {
    const foundCustomer = await tables.customers.filter((c) => c.id === selectedCustomerId.value).first();

    if (foundCustomer) {
      customer.value = foundCustomer;

      if (config.company.modules.OPEN_ITEMS) {
        invoiceNumber.value = await getInvoiceNumber(foundCustomer.id);
      }
    } else {
      customer.value = null;
      const error = new Error();
      error.name = 'CUSTOMER_NOT_FOUND';
      error.message = 'Customer not found in list of customers.';
      throw error;
    }
  };

  const hasOrderOrOfferActive = function () {
    return config.company.modules.ORDER || config.company.modules.OFFER;
  };

  watch(
    selectedCustomerId,
    async () => {
      if (selectedCustomerId.value > 0) {
        selectedTab.value = getSelectedTabDefault();

        await loadCustomer();
      }
    },
    { immediate: true }
  );
</script>
