<template>
  <div>
    <TheSubheader>
      <StatisticsGlobalFilter
        v-model:agentId="agentId"
        v-model:fromDate="fromDate"
        v-model:toDate="toDate"
        v-model:filter="search"
        @newSearch="newSearch()"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 max-md:mx-16 md:px-[80px]">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>

      <div v-else-if="globalStatistics.length > 0">
        <AgTabSwitch class="w-full min-w-fit border-b-neutral-500">
          <AgTab v-model="selectedTab" value="global" dynamic-width>
            <template #title>{{ t('statistics.global') }}</template>
          </AgTab>
          <AgTab v-model="selectedTab" value="chart" dynamic-width>
            <template #title>{{ t('statistics.chart') }}</template>
          </AgTab>
        </AgTabSwitch>

        <!-- CUSTOMER ORDERS TAB -->
        <div v-if="selectedTab === Tabs.Global">
          <GlobalTable :globalStatistics="statistics" />
        </div>
        <!-- CUSTOMER OFFERS TAB -->
        <div v-if="selectedTab === Tabs.Chart">
          <Chart :globalStatistics="statistics" />
        </div>
      </div>
      <div v-else>
        <AgAlert variant="info" class="mt-24">{{ t('statistics.noData') }}</AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticGlobalDto } from '@/domain/StatisticGlobalDto';
  import type { StatisticEntry } from '@/domain/internal/StatisticEntry';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgTabSwitch from '@/components/library/tab-switch/AgTabSwitch.vue';
  import AgTab from '@/components/library/tab-switch/tab/AgTab.vue';
  import StatisticsGlobalFilter from '@/modules/statistics/components/filters/StatisticsGlobalFilter.vue';
  import Chart from '@/modules/statistics/components/global/Chart.vue';
  import GlobalTable from '@/modules/statistics/components/global/GlobalTable.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';

  import { useHierarchy } from '@/composables/useHierarchy';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getAllHierarchy } = useHierarchy();

  const store = useStatisticStore();
  const { getStatistics } = store;
  const { globalStatistics } = storeToRefs(store);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('statistics.statistics', 'statistics.global');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  enum Tabs {
    Global = 'global',
    Chart = 'chart',
  }

  interface groupType {
    [key: number]: { [key: string]: { [key: number]: StatisticEntry } };
  }
  const selectedTab = ref<string>(Tabs.Global);

  const isLoading = ref<boolean>(true);
  const isHierarchy = ref<boolean>(false);

  const fDate = ref<string>('');
  const tDate = ref<string>('');
  const search = ref<string>('');

  const agentId = ref<number>(-1);

  const globalStatisticsGrouped = ref<groupType>({});
  const statistics = ref<{ [key: string]: { [key: number]: StatisticEntry } }>({});

  const fromDate = computed({
    get: () => {
      if (fDate.value && fDate.value !== '') {
        return fDate.value;
      } else {
        const today = new Date();
        today.setMonth(0);
        today.setDate(1);

        const currentYear = today.getFullYear();
        const oneYearAgo = today.setFullYear(currentYear - 1);
        const formDate = new Date(oneYearAgo).toISOString().split('T')[0];

        return formDate;
      }
    },
    set: (newDate) => {
      if (newDate) {
        fDate.value = newDate;
      }
    },
  });

  const toDate = computed({
    get: () => {
      if (tDate.value && tDate.value !== '') {
        return tDate.value;
      } else {
        const toDate = new Date().toISOString().split('T')[0];
        return toDate;
      }
    },
    set: (newDate) => {
      if (newDate) {
        tDate.value = newDate;
      }
    },
  });

  const groupStatistics = function (): groupType {
    const allAgentsGroup: { [key: string]: { [key: number]: StatisticEntry } } = {};

    const internalCustomerList: { [key: number]: { [key: string]: { [key: number]: Array<string> } } } = {};
    const internalCustomerListAllAgents: { [key: string]: { [key: number]: Array<string> } } = {};

    const groupStas = globalStatistics.value.reduce(
      (groups: groupType, statistic: StatisticGlobalDto) => {
        const agentId = statistic.agentId;
        const year = new Date(statistic.date * 1000).getFullYear();
        const month = new Date(statistic.date * 1000).getMonth() + 1;

        if (!groups[agentId]) {
          groups[agentId] = {};
          internalCustomerList[agentId] = {};
        }
        if (!groups[agentId][year]) {
          groups[agentId][year] = {};
          internalCustomerList[agentId][year] = {};
        }
        if (!groups[agentId][year][month]) {
          groups[agentId][year][month] = {
            totalAmount: 0,
            totalQuantity: 0,
            totalCustomers: 0,
          };
          internalCustomerList[agentId][year][month] = [];
        }
        if (!internalCustomerList[agentId][year][month].includes(statistic.customerCode)) {
          internalCustomerList[agentId][year][month].push(statistic.customerCode);
          groups[agentId][year][month].totalCustomers += 1;
        }
        groups[agentId][year][month].totalAmount += statistic.amount;
        groups[agentId][year][month].totalQuantity += statistic.quantity;

        if (isHierarchy.value) {
          if (!allAgentsGroup[year]) {
            allAgentsGroup[year] = {};
            internalCustomerListAllAgents[year] = {};
          }
          if (!allAgentsGroup[year][month]) {
            allAgentsGroup[year][month] = {
              totalAmount: 0,
              totalQuantity: 0,
              totalCustomers: 0,
            };
            internalCustomerListAllAgents[year][month] = [];
          }
          allAgentsGroup[year][month].totalAmount += statistic.amount;
          allAgentsGroup[year][month].totalQuantity += statistic.quantity;
          if (!internalCustomerListAllAgents[year][month].includes(statistic.customerCode)) {
            internalCustomerListAllAgents[year][month].push(statistic.customerCode);
            allAgentsGroup[year][month].totalCustomers += 1;
          }
        }
        return groups;
      },
      { [-1]: allAgentsGroup }
    );
    return groupStas;
  };

  const newSearch = function () {
    const from = new Date(fromDate.value).getTime();
    const to = new Date(toDate.value).getTime();
    const filter = new StatisticFilter(from, to, undefined, undefined, undefined, undefined, undefined, search.value);
    getStatistics(filter)
      .then(() => {
        globalStatisticsGrouped.value = groupStatistics();
        statistics.value = globalStatisticsGrouped.value[agentId.value] ?? {};
        isLoading.value = false;
      })
      .catch((error) => console.log(error));
  };

  watch(
    search,
    () => {
      newSearch();
    },
    { immediate: false }
  );

  watch(
    agentId,
    (value) => {
      statistics.value = globalStatisticsGrouped.value[value] ?? {};
    },
    { immediate: false }
  );

  onMounted(async () => {
    const hierarchyList = await getAllHierarchy();
    agentId.value = hierarchyList.length > 0 ? hierarchyList[0].authUser.id : -1;
    if (hierarchyList.length > 0 && hierarchyList[0].children.length > 0) {
      isHierarchy.value = true;
    }
    newSearch();
  });
</script>
