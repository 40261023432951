import type { CountryDto } from '@/domain/masterData/CountryDto';
import type { OrderAddressDto } from '@/domain/orderData/OrderAddressDto';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function required(value: string) {
  const ret = {
    state: false,
    msg: '',
  };
  if (!value) {
    ret.state = false;
    ret.msg = '';
  }
  return true;
}

export interface CustomerErrorObject {
  name: { state: boolean; msg: string };
  fiscalCode: { state: boolean; msg: string };
  vatCode: { state: boolean; msg: string };
  sdiCode: { state: boolean; msg: string };
  priceListCode: { state: boolean; msg: string };
}

export function initCustomerErrors(): CustomerErrorObject {
  return {
    name: { state: true, msg: '' },
    fiscalCode: { state: true, msg: '' },
    vatCode: { state: true, msg: '' },
    sdiCode: { state: true, msg: '' },
    priceListCode: { state: true, msg: '' },
  };
}

export interface AddressErrorObject {
  name: { state: boolean; msg: string };
  street: { state: boolean; msg: string };
  city: { state: boolean; msg: string };
  nation: { state: boolean; msg: string };
  zip: { state: boolean; msg: string };
  region: { state: boolean; msg: string };
  phone: { state: boolean; msg: string };
  deliveryPhone: { state: boolean; msg: string };
  email: { state: boolean; msg: string };
  pec: { state: boolean; msg: string };
  addressType: { state: boolean; msg: string };
}

export function initAddressErrors(): AddressErrorObject {
  return {
    name: { state: true, msg: '' },
    street: { state: true, msg: '' },
    city: { state: true, msg: '' },
    nation: { state: true, msg: '' },
    zip: { state: true, msg: '' },
    region: { state: true, msg: '' },
    phone: { state: true, msg: '' },
    deliveryPhone: { state: true, msg: '' },
    email: { state: true, msg: '' },
    pec: { state: true, msg: '' },
    addressType: { state: true, msg: '' },
  };
}

export function isModifiedAddressValid(
  data: OrderAddressDto | null | undefined,
  countries: Array<CountryDto>,
  deliveryEqualsToBilling?: boolean
) {
  if (!data) {
    return false;
  }
  const errorsAddress: AddressErrorObject = validateModifiedAddressForm(data, countries, deliveryEqualsToBilling);
  let ret = true;
  Object.values(errorsAddress).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function isReviewAddressValid(data: OrderAddressDto | null | undefined, countries: Array<CountryDto>) {
  if (!data) {
    return false;
  }
  const errorsAddress: AddressErrorObject = validateReviewAddressForm(data, countries);
  let ret = true;
  Object.values(errorsAddress).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function validateModifiedAddressForm(
  data: OrderAddressDto | null | undefined,
  countries: Array<CountryDto>,
  deliveryEqualsToBilling?: boolean
): AddressErrorObject {
  const errors: AddressErrorObject = initAddressErrors();
  const phoneRegexp = new RegExp(
    /^\+?\d{1,4}?[-.\s]?[/.\s]?\(?\d{1,3}?\)?[-.\s]?[/.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
  );

  const zipRegexp = new RegExp(/^[0-9]+$/);

  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (!data) {
    return errors;
  }
  // STREET VERIFICATION
  if (!data.name) {
    errors.name.state = false;
    errors.name.msg = t('orders.nameRequired');
  }
  // STREET VERIFICATION
  if (!data.street) {
    errors.street.state = false;
    errors.street.msg = t('orders.streetRequired');
  }
  // CITY VERIFICATION
  if (!data.city) {
    errors.city.state = false;
    errors.city.msg = t('orders.cityRequired');
  }
  // ZIP VERIFICATION
  if (!data.zip) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipRequired');
  } else if (!zipRegexp.test(data.zip)) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipNotValid');
  }
  // REGION VERIFICATION
  if (!data.region) {
    errors.region.state = false;
    errors.region.msg = t('orders.regionRequired');
  }
  // EMAIL VERIFICATION
  if (!data.email && deliveryEqualsToBilling) {
    errors.email.state = false;
    errors.email.msg = t('offers.emailRequired');
  }
  if (data.email && !emailRegexp.test(data.email)) {
    errors.email.state = false;
    errors.email.msg = t('orders.emailNotValid');
  }
  // PEC VERIFICATION
  if (data.pec && !emailRegexp.test(data.pec)) {
    errors.pec.state = false;
    errors.pec.msg = t('orders.pecNotValid');
  }
  // PHONE NUMBER VERIFICATION
  if (!data?.phoneNumber) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneRequired');
  } else if (!phoneRegexp.test(data.phoneNumber)) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneNotValid');
  }
  // DELIVERY PHONE NUMBER VERIFICATION
  if (data?.deliveryPhoneNotification) {
    if (!data?.deliveryPhoneNumber) {
      errors.deliveryPhone.state = false;
      errors.deliveryPhone.msg = t('orders.deliveryPhoneRequired');
    } else if (!phoneRegexp.test(data.deliveryPhoneNumber ?? '')) {
      errors.deliveryPhone.state = false;
      errors.deliveryPhone.msg = t('orders.deliveryPhoneNotValid');
    }
  }
  // NATION VERIFICATION
  if (!data.nation || (countries && countries.findIndex((c) => c.code === data.nation) === -1)) {
    errors.nation.state = false;
    errors.nation.msg = t('orders.nationRequired');
  }
  return errors;
}

export function validateReviewAddressForm(
  data: OrderAddressDto | null | undefined,
  countries: Array<CountryDto>
): AddressErrorObject {
  const errors: AddressErrorObject = initAddressErrors();
  const phoneRegexp = new RegExp(
    /^\+?\d{1,4}?[-.\s]?[/.\s]?\(?\d{1,3}?\)?[-.\s]?[/.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
  );
  const zipRegexp = new RegExp(/^[0-9]+$/);

  if (!data) {
    return errors;
  }
  // STREET VERIFICATION
  if (!data.name) {
    errors.name.state = false;
    errors.name.msg = t('orders.nameRequired');
  }
  // STREET VERIFICATION
  if (!data.street) {
    errors.street.state = false;
    errors.street.msg = t('orders.streetRequired');
  }
  // CITY VERIFICATION
  if (!data.city) {
    errors.city.state = false;
    errors.city.msg = t('orders.cityRequired');
  }
  // ZIP VERIFICATION
  if (!data.zip) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipRequired');
  } else if (!zipRegexp.test(data.zip)) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipNotValid');
  }
  // REGION VERIFICATION
  if (!data.region) {
    errors.region.state = false;
    errors.region.msg = t('orders.regionRequired');
  }
  // PHONE NUMBER VERIFICATION
  if (!data?.phoneNumber) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneRequired');
  } else if (!phoneRegexp.test(data.phoneNumber)) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneNotValid');
  }
  // DELIVERY PHONE NUMBER VERIFICATION
  if (data?.deliveryPhoneNotification) {
    if (!data?.deliveryPhoneNumber) {
      errors.deliveryPhone.state = false;
      errors.deliveryPhone.msg = t('orders.deliveryPhoneRequired');
    } else if (!phoneRegexp.test(data.deliveryPhoneNumber ?? '')) {
      errors.deliveryPhone.state = false;
      errors.deliveryPhone.msg = t('orders.deliveryPhoneNotValid');
    }
  }
  // NATION VERIFICATION
  if (!data.nation || (countries && countries.findIndex((c) => c.code === data.nation) === -1)) {
    errors.nation.state = false;
    errors.nation.msg = t('orders.nationRequired');
  }
  return errors;
}
