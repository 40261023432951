import type { AuthUserDto } from '@/domain/masterData/AuthUserDto';
import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
import { useUserData } from './data/useUserData';

export const useHierarchy = () => {
  const userData = useUserData();

  const getAllHierarchy = async function () {
    return (await userData.tables.hierarchy.toArray()) || [];
  };

  const resolveHierarchyUsers = async function (): Promise<AuthUserDto[]> {
    const userHierarchy = await getAllHierarchy();
    const authUsers: AuthUserDto[] = [];
    traverseHierarchy(userHierarchy, authUsers);

    return authUsers;
  };

  function traverseHierarchy(hierarchy: UserHierarchyDto[], authUsers: AuthUserDto[]) {
    for (const user of hierarchy) {
      if (user) {
        authUsers.push(user.authUser);
        if (user.children) {
          traverseHierarchy(user.children, authUsers);
        }
      }
    }
  }

  return {
    getAllHierarchy,
    resolveHierarchyUsers,
  };
};
