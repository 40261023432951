<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold">{{ t('orders.cartDiscount') }}</div>
    </template>
    <template #content>
      <div class="flex flex-col md:min-w-[333px]">
        <div class="overflow-y-auto">
          <!-- ITEM DISCOUNT -->
          <AgFormGroup label="">
            <span class="font-bold">{{ t('orders.discount') }}</span>
            <div class="flex flex-row items-center gap-4">
              <AgFormInput type="number" class="w-full" v-model="internalDiscount" />
              <span class="font-bold">%</span>
            </div>
          </AgFormGroup>
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="closeModal"> {{ t('orders.cancel') }} </AgButton>
      <AgButton @click="saveDiscount" :disabled="isButtonDisabled">
        {{ t('orders.apply') }}
      </AgButton>
    </template>
  </AgModal>
</template>
<script setup lang="ts">
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import { i18n } from '@/plugins/i18n';

  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    modelValue?: boolean;
    discount: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue', 'update:discount']);

  const internalDiscount = ref<number>();

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const saveDiscount = function () {
    emit('update:discount', internalDiscount.value);
    internalValue.value = false;
  };

  const isButtonDisabled = computed(() => {
    return (
      internalDiscount.value !== undefined &&
      internalDiscount.value !== null &&
      (internalDiscount.value > 100 || internalDiscount.value < 0)
    );
  });

  onMounted(() => {
    internalDiscount.value = props.discount;
  });
</script>
