<template>
  <svg id="ic_clients" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_6234" data-name="Rechteck 6234" width="20" height="20" fill="none" />
    <path
      id="Pfad_13582"
      d="M11.047,15.631V14.313a2.636,2.636,0,0,0-2.636-2.636H3.139A2.636,2.636,0,0,0,.5,14.313h0v1.318"
      transform="translate(2.219 0.593)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_13583"
      d="M8.411,5.832A2.636,2.636,0,1,1,5.775,3.2h0A2.636,2.636,0,0,1,8.411,5.832Z"
      transform="translate(2.219 0.593)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_13584"
      d="M15,15.631V14.308a2.636,2.636,0,0,0-1.977-2.539"
      transform="translate(2.219 0.593)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_13585"
      d="M10.388,3.281a2.636,2.636,0,0,1,0,5.107"
      transform="translate(2.219 0.593)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>
