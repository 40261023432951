<template>
  <slot v-if="isEnabled"></slot>
</template>

<script setup lang="ts">
  import { useFeatureFlags } from '@/composables/framework/useFeatureFlags';
  import { EModules } from '@/config/EModules';
  import { computed } from 'vue';

  interface Props {
    featureId: EModules;
  }
  const props = defineProps<Props>();

  const { featureEnabled } = useFeatureFlags();

  const isEnabled = computed(() => {
    return featureEnabled(props.featureId);
  });
</script>
