import type { OfferDto } from '@/domain/offerData/OfferDto';

import { useOfferData } from '@/composables/data/useOfferData';
import { useHierarchy } from '@/composables/useHierarchy';
import { OfferStatus } from '@/domain/enumeration/OfferStatus';
import { Optional } from '@/util/Optional';
import { cloneDeep } from 'lodash';

export function useOffers() {
  const { tables } = useOfferData();
  const { getAllHierarchy } = useHierarchy();

  const saveOffer = async function (offer: OfferDto) {
    const probe = await tables.offers.get(offer.gid);

    if (probe && probe.status === OfferStatus.COMPLETED) {
      return;
    }
    await tables.offers.put(offer);
  };

  const deleteOffer = async function (offer: OfferDto) {
    if (offer.status === OfferStatus.COMPLETED) {
      return;
    }
    const deleteCopy = cloneDeep(offer);

    await Promise.all([tables.offers.delete(offer.gid), tables.deletedOffers.put(deleteCopy)]);
  };

  const getOfferByGid = async function (gid: string) {
    return Optional.of(await tables.offers.get(gid));
  };

  // Closed offers but not yet synced are also drafts
  const listOffersByStatus = async function (status: number) {
    if (status === OfferStatus.ALL) {
      return await tables.offers.orderBy('createdAt').reverse().toArray();
    } else if (status === OfferStatus.DRAFT) {
      const offers = await tables.offers.where('status').equals(OfferStatus.DRAFT).sortBy('createdAt');
      return offers
        .concat(await tables.offers.where('[status+synced]').equals([OfferStatus.COMPLETED, 0]).sortBy('createdAt'))
        .reverse();
    } else {
      const offers = await tables.offers.where('[status+synced]').equals([status, 1]).sortBy('createdAt');
      if (offers && offers.length > 0) {
        return offers.reverse();
      }
      return [];
    }
  };

  const getSizeOffersByStatus = async function (status: number) {
    const hierarchy = await getAllHierarchy();
    const agentId = hierarchy[0]?.authUser?.id;

    if (status === OfferStatus.ALL) {
      const query = tables.offers.where('status').equals(status);
      if (agentId) {
        query.filter((offer) => offer.agentId === agentId);
      }
      return await query.count();
    } else if (status === OfferStatus.DRAFT) {
      const query = tables.offers.where('status').equals(OfferStatus.DRAFT);
      if (agentId) {
        query.filter((offer) => offer.agentId === agentId);
      }
      const offersOpened = await query.count();

      const queryCompletedNotSynced = tables.offers.where('[status+synced]').equals([OfferStatus.COMPLETED, 0]);
      if (agentId) {
        queryCompletedNotSynced.filter((offer) => offer.agentId === agentId);
      }
      const offersCompletedNotSynced = await queryCompletedNotSynced.count();

      return offersOpened + offersCompletedNotSynced;
    } else if (status === OfferStatus.COMPLETED) {
      const query = tables.offers.where('[status+synced]').equals([status, 1]);
      if (agentId) {
        query.filter((order) => order.agentId === agentId);
      }
      return await query.count();
    }
    return 0;
  };

  const listOffersByCustomerVatCode = async function (vatCode: string) {
    return await tables.offers.where('customer.vatCode').equals(vatCode).toArray();
  };

  return {
    saveOffer,
    deleteOffer,
    getOfferByGid,
    listOffersByStatus,
    getSizeOffersByStatus,
    listOffersByCustomerVatCode,
  };
}
