<template>
  <div class="py-24 gap-4 flex flex-wrap">
    <CheckListActivity
      v-for="activity in props.activities"
      :key="activity.activityId"
      :activity="activity"
      class="w-full border-[2px]"
    />
  </div>
</template>

<script setup lang="ts">
  import type { ChecklistDto } from '@/domain/ChecklistDto';
  import CheckListActivity from '@/modules/checklists/components/CheckListActivity.vue';

  interface Props {
    activities: Array<ChecklistDto>;
  }
  const props = defineProps<Props>();
</script>
