<template>
  <div>
    <div class="mb-8">
      <router-link to="/open-items/by-agent" class="router-link" :title="t('openItems.overview')">
        {{ t('openPostDashboard') }}
      </router-link>
    </div>
    <DashboardWidgetOpenItemsTable :debtInfo="props.debtInfo" />
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtInfoDto } from '@/domain/OutstandingDebtInfoDto';

  import DashboardWidgetOpenItemsTable from '@/modules/open-items/components/dashboard-widget/table/DashboardWidgetOpenItemsTable.vue';

  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    debtInfo: OutstandingDebtInfoDto;
  }
  const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
  .router-link {
    @apply text-xl font-bold text-white;
  }
</style>
