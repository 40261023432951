<!-- eslint-disable max-len -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="231" height="42" viewBox="0 0 231 42">
    <g id="Logo_-_agent2go" data-name="Logo - agent2go" transform="translate(115.058 20.867)">
      <g id="Gruppe_8152" data-name="Gruppe 8152" transform="translate(-115.058 -20.867)">
        <path
          id="Pfad_13469"
          data-name="Pfad 13469"
          d="M9.49,10.7h8.868l9.332,25.04H19.344l-1.1-3.3H9.606l-1.1,3.3H.1Zm6.9,
          15.766-1.739-5.333a19.765,19.765,0,0,1-.7-3.246,25.04,25.04,0,0,1-.7,3.246l-1.739,5.333Z"
          transform="translate(-0.042 -4.498)"
          fill="#212529"
        />
        <path
          id="Pfad_13470"
          data-name="Pfad 13470"
          d="M60.553,9.7a22.026,22.026,0,0,1,8.115,1.507v7.361a13.332,13.332,0,0,
          0-6.608-1.681c-3.652,0-6.9,1.623-6.9,5.912A5.507,5.507,0,0,0,60.5,
          28.538h.985V21.35h7.767V34.218A23.475,23.475,0,0,1,60.727,35.9C53.482,
          35.9,46.7,31.726,46.7,22.8S53.54,9.7,60.553,9.7"
          transform="translate(-19.631 -4.077)"
          fill="#212529"
        />
        <path
          id="Pfad_13471"
          data-name="Pfad 13471"
          d="M110.354,17.192H99.631v2.9h9.448v6.086H99.631V29.19h10.955v6.55H91.4V10.7h18.954Z"
          transform="translate(-38.421 -4.498)"
          fill="#212529"
        />
        <path
          id="Pfad_13472"
          data-name="Pfad 13472"
          d="M129.8,10.7h6.956l8.463,11.767a35.989,35.989,0,0,
          1-.232-4.289V10.7h8.289V35.74H146.32l-8.463-11.709a35.015,
          35.015,0,0,1,.174,4.231V35.8H129.8Z"
          transform="translate(-54.563 -4.498)"
          fill="#212529"
        />
        <path
          id="Pfad_13473"
          data-name="Pfad 13473"
          d="M199.151,17.945h-9.8V35.74h-8.231V17.945H174.4V10.7h21.678Z"
          transform="translate(-73.311 -4.498)"
          fill="#212529"
        />
        <path
          id="Pfad_13474"
          data-name="Pfad 13474"
          d="M279.412,11c5.043,0,9.622,2.2,9.622,7.883,0,3.536-2.2,
          5.449-4.521,7.072s-3.594,2.261-4.347,3.246h8.926v6.666H269.5V32.5c.058-5.275,
          3.71-7.361,6.9-9.448,2.2-1.449,4.115-2.087,4.115-3.536s-.869-1.565-2.319-1.565a13.9,
          13.9,0,0,0-7.941,3.072V13.608A17.273,17.273,0,0,1,279.412,11"
          transform="translate(-113.288 -4.624)"
          fill="#008232"
        />
        <path
          id="Pfad_13475"
          data-name="Pfad 13475"
          d="M321.011,9.7a22.026,22.026,0,0,1,8.115,1.507v7.361a13.331,13.331,0,0,0-6.608-1.681c-3.652,
          0-6.9,1.623-6.9,5.912a5.507,5.507,0,0,0,5.333,5.738h.985V21.35h7.709V34.218a23.475,23.475,0,0,
          1-8.521,1.681c-7.245,0-14.027-4.173-14.027-13.1S314,9.7,321.011,9.7"
          transform="translate(-129.093 -4.077)"
          fill="#008232"
        />
        <path
          id="Pfad_13476"
          data-name="Pfad 13476"
          d="M363.664,9.7c9.042,0,13.564,5.912,13.564,13.1s-4.521,13.1-13.564,13.1S350.1,29.987,
          350.1,22.8s4.521-13.1,13.564-13.1m0,7.477c-3.246,0-5.1,2.2-5.1,5.622s1.855,5.622,5.1,5.622,
          5.1-2.2,5.1-5.622-1.855-5.622-5.1-5.622"
          transform="translate(-147.169 -4.078)"
          fill="#008232"
        />
        <rect
          id="Rechteck_6067"
          data-name="Rechteck 6067"
          width="8.017"
          height="7.459"
          transform="translate(135.084 4.06) rotate(-24.775)"
          fill="#005a32"
        />
        <rect
          id="Rechteck_6068"
          data-name="Rechteck 6068"
          width="8.017"
          height="7.459"
          transform="translate(139.008 12.513) rotate(-24.775)"
          fill="#876d40"
        />
        <rect
          id="Rechteck_6069"
          data-name="Rechteck 6069"
          width="7.999"
          height="7.443"
          transform="translate(142.629 21.184) rotate(-24.7)"
          fill="#86939f"
        />
        <rect
          id="Rechteck_6070"
          data-name="Rechteck 6070"
          width="8.017"
          height="7.459"
          transform="translate(126.233 8.191) rotate(-24.775)"
          fill="#008232"
        />
        <rect
          id="Rechteck_6071"
          data-name="Rechteck 6071"
          width="7.999"
          height="7.443"
          transform="translate(129.922 16.825) rotate(-24.7)"
          fill="#006fff"
        />
        <rect
          id="Rechteck_6072"
          data-name="Rechteck 6072"
          width="7.999"
          height="7.443"
          transform="translate(133.799 25.325) rotate(-24.7)"
          fill="#3d708d"
        />
        <path
          id="Pfad_13477"
          data-name="Pfad 13477"
          d="M213.911,58.778a1.333,1.333,0,1,1-1.333,1.333,1.333,1.333,0,0,1,1.333-1.333m0-3.478a4.811,
          4.811,0,1,0,4.811,4.811,4.811,4.811,0,0,0-4.811-4.811"
          transform="translate(-87.898 -23.246)"
          fill="#212529"
        />
        <path
          id="Pfad_13478"
          data-name="Pfad 13478"
          d="M210.1,14.3l12.23,26.084a2.666,2.666,0,0,0,3.478,1.275l10.375-4.811"
          transform="translate(-88.318 -6.011)"
          fill="none"
          stroke="#212529"
          stroke-linecap="round"
          stroke-width="3.478"
        />
      </g>
    </g>
  </svg>
</template>
