import type { OfferCustomerDto } from '@/domain/offerData/OfferCustomerDto';
import type { OfferDeliveryMethodDto } from '@/domain/offerData/OfferDeliveryMethodDto';
import type { OfferDeliveryTermDto } from '@/domain/offerData/OfferDeliveryTermDto';
import type { OfferDto } from '@/domain/offerData/OfferDto';
import type { OfferPaymentMethodDto } from '@/domain/offerData/OfferPaymentMethodDto';
import type { OfferPaymentTermDto } from '@/domain/offerData/OfferPaymentTermDto';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function required(value: string) {
  const ret = {
    state: false,
    msg: '',
  };
  if (!value) {
    ret.state = false;
    ret.msg = '';
  }
  return true;
}

export interface PaymentDeliveryErrorObject {
  deliveryTerm: { state: boolean; msg: string };
  deliveryMethod: { state: boolean; msg: string };
  paymentTerm: { state: boolean; msg: string };
  paymentMethod: { state: boolean; msg: string };
}

export interface CustomerErrorObject {
  iban: { state: boolean; msg: string };
  dueDate: { state: boolean; msg: string };
}

export function initErrors(): CustomerErrorObject {
  return {
    iban: { state: true, msg: '' },
    dueDate: { state: true, msg: '' },
  };
}

export function initPaymentDeliveryErrors(): PaymentDeliveryErrorObject {
  return {
    deliveryTerm: { state: true, msg: '' },
    deliveryMethod: { state: true, msg: '' },
    paymentTerm: { state: true, msg: '' },
    paymentMethod: { state: true, msg: '' },
  };
}

export function validateForm(data: OfferCustomerDto | null | undefined): CustomerErrorObject {
  const errors: CustomerErrorObject = initErrors();

  if (!data?.iban) {
    errors.iban.state = false;
    errors.iban.msg = t('offers.ibanRequired');
  }
  return errors;
}

export function isIbanValid(data: OfferCustomerDto | null | undefined) {
  const errors: CustomerErrorObject = validateForm(data);
  let ret = true;
  Object.values(errors).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function validateDueDate(dueDate: number | null | undefined): CustomerErrorObject {
  const errors: CustomerErrorObject = initErrors();

  if (dueDate) {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);

    const ddDay = new Date(dueDate * 1000);
    ddDay.setUTCHours(0, 0, 0, 0);

    if (ddDay < today) {
      errors.dueDate.state = false;
      errors.dueDate.msg = t('offers.dateRequired');
    }
  } else {
    errors.dueDate.state = false;
    errors.dueDate.msg = t('offers.dateNotValid');
  }
  return errors;
}

export function isDueDateValid(dueDate: number | null | undefined) {
  const errors: CustomerErrorObject = validateDueDate(dueDate);
  let ret = true;
  Object.values(errors).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function validatePaymentDelivery(
  data: OfferDto | null | undefined,
  deliveryTerms: Array<OfferDeliveryTermDto>,
  deliveryMethods: Array<OfferDeliveryMethodDto>,
  paymentTerms: Array<OfferPaymentTermDto>,
  paymentmethods: Array<OfferPaymentMethodDto>
): PaymentDeliveryErrorObject {
  const errors: PaymentDeliveryErrorObject = initPaymentDeliveryErrors();
  if (
    !data?.paymentMethod?.id ||
    (paymentmethods && paymentmethods.findIndex((pm) => pm.id === data?.paymentMethod?.id) === -1)
  ) {
    errors.paymentMethod.state = false;
    errors.paymentMethod.msg = t('offers.paymentMetodRequired');
  }
  if (
    !data?.paymentTerm?.id ||
    (paymentTerms && paymentTerms.findIndex((pt) => pt.id === data?.paymentTerm?.id) === -1)
  ) {
    errors.paymentTerm.state = false;
    errors.paymentTerm.msg = t('offers.paymentTermRequired');
  }
  if (
    !data?.deliveryMethod?.id ||
    (deliveryMethods && deliveryMethods.findIndex((dt) => dt.id === data?.deliveryMethod?.id) === -1)
  ) {
    errors.deliveryMethod.state = false;
    errors.deliveryMethod.msg = t('offers.deliveryMetodRequired');
  }
  if (
    !data?.deliveryTerm?.id ||
    (deliveryTerms && deliveryTerms.findIndex((dt) => dt.id === data?.deliveryTerm?.id) === -1)
  ) {
    errors.deliveryTerm.state = false;
    errors.deliveryTerm.msg = t('offers.deliveryTermRequired');
  }
  return errors;
}

export function isPaymentDeliveryValid(
  data: OfferDto | null | undefined,
  deliveryTerms: Array<OfferDeliveryTermDto>,
  deliveryMethods: Array<OfferDeliveryMethodDto>,
  paymentTerms: Array<OfferPaymentTermDto>,
  paymentmethods: Array<OfferPaymentMethodDto>
) {
  const errors: PaymentDeliveryErrorObject = validatePaymentDelivery(
    data,
    deliveryTerms,
    deliveryMethods,
    paymentTerms,
    paymentmethods
  );
  let ret = true;
  Object.values(errors).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}
