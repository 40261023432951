<template>
  <div v-if="order" class="md:mx-[80px] max-md:mx-16 mt-12 mb-32 min-w-fit">
    <OrderInfo />

    <div class="text-l pt-8 font-bold">{{ t('orders.deliveryDetails') }}</div>

    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('orders.deliveryTerm')" class="basis-1/2" required>
        <AgSearchSelect
          :disabled="disabledDeliveryTerm"
          :options="deliveryTermsOptions"
          v-model="newDeliveryTerm"
          :validation-state="validationPaymentDelivery.deliveryTerm.state"
          :errorMessage="validationPaymentDelivery.deliveryTerm.msg"
        />
      </AgFormGroup>
      <AgFormGroup :label="t('orders.deliveryMethod')" class="basis-1/2" required>
        <AgSearchSelect
          :options="deliveryMethodOptions"
          v-model="newDeliveryMethod"
          :validation-state="validationPaymentDelivery.deliveryMethod.state"
          :errorMessage="validationPaymentDelivery.deliveryMethod.msg"
        />
      </AgFormGroup>
    </div>
    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('orders.shippingRequestDate')" class="basis-full md:basis-1/2 md:pr-8">
        <AgFormInput type="date" v-model="shippingDateRequest" :min="getMinShippingDate()" />
      </AgFormGroup>
      <AgFormGroup :label="t('orders.deliveryRequestDate')" class="basis-full md:basis-1/2 md:pr-8" required>
        <AgFormInput
          :disabled="!selectedDeliveryDate"
          type="date"
          v-model="deliveryDateRequest"
          :validation-state="validationPaymentDelivery.deliveryRequestDate.state"
          :errorMessage="validationPaymentDelivery.deliveryRequestDate.msg"
          :min="getMinDeliveryDate()"
        />
      </AgFormGroup>
    </div>
    <div v-if="depotOptions && depotOptions.length > 0" class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('orders.depot')" class="basis-1/2">
        <AgSearchSelect
          :options="depotOptions"
          v-model="newDepot"
          :validation-state="validationPaymentDelivery.paymentTerm.state"
          :errorMessage="validationPaymentDelivery.paymentTerm.msg"
        />
      </AgFormGroup>
    </div>

    <div class="text-l pt-24 font-bold border-t-neutral-200 border-t-2">{{ t('orders.paymentDetails') }}</div>

    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('orders.paymentTerm')" class="basis-1/2" required>
        <AgSearchSelect
          :options="paymentTermsOptions"
          v-model="newPaymentTerm"
          :validation-state="validationPaymentDelivery.paymentTerm.state"
          :errorMessage="validationPaymentDelivery.paymentTerm.msg"
        />
      </AgFormGroup>
      <AgFormGroup :label="t('orders.paymenthMethod')" class="basis-1/2" required>
        <AgSearchSelect
          :options="paymentMethodOptions"
          v-model="newPaymentMethod"
          :validation-state="validationPaymentDelivery.paymentMethod.state"
          :errorMessage="validationPaymentDelivery.paymentMethod.msg"
          :disabled="config.company.disabledPaymentMethod"
        />
      </AgFormGroup>
    </div>

    <div v-show="order.paymentMethod?.ibanRequired" class="flex py-8">
      <AgFormGroup label="IBAN" class="basis-full md:basis-1/2 md:pr-8" required>
        <AgFormInput
          v-model="order.customer.iban"
          :validation-state="validation.iban.state"
          :errorMessage="validation.iban.msg"
        />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { OrderDeliveryMethodDto } from '@/domain/orderData/OrderDeliveryMethodDto';
  import type { OrderDeliveryTermDto } from '@/domain/orderData/OrderDeliveryTermDto';
  import type { OrderPaymentMethodDto } from '@/domain/orderData/OrderPaymentMethodDto';
  import type { OrderPaymentTermDto } from '@/domain/orderData/OrderPaymentTermDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import OrderInfo from '@/modules/orders/components/order-wizard/steps/OrderInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import type { DepotDto } from '@/domain/masterData/DepotDto';
  import {
    isIbanValid,
    isPaymentDeliveryValid,
    validateForm,
    validatePaymentDelivery,
  } from '@/modules/orders/components/order-wizard/steps/order-data/OrderDataValidation';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeUpdate, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const { tables } = useMasterData();

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.order', 'orders.orderDetails');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const deliveryTerms = ref<Array<OrderDeliveryTermDto>>([]);
  const deliveryMethods = ref<Array<OrderDeliveryMethodDto>>([]);
  const paymentTerms = ref<Array<OrderPaymentTermDto>>([]);
  const paymentMethods = ref<Array<OrderPaymentMethodDto>>([]);
  const depots = ref<Array<DepotDto>>([]);

  const emit = defineEmits(['update:modelValue']);

  const showPopper = ref<boolean>(false);

  const loadOrderData = async function () {
    deliveryTerms.value = await tables.deliveryTerms.toArray();
    deliveryMethods.value = await tables.deliveryMethods.toArray();
    paymentTerms.value = await tables.paymentTerms.toArray();
    paymentMethods.value = await tables.paymentMethods.toArray();
    depots.value = await tables.depots.toArray();

    await calculateDefaultValues();
  };

  const calculateDefaultValues = async function () {
    if (order.value) {
      const customerId = order.value.customer.id;
      if (customerId) {
        const customer = await tables.customers.where('id').equals(customerId).first();
        if (customer) {
          const defualtPaymentTermId = customer.paymentTermId ?? -1;
          const defualtPaymentMethodId = customer.paymentMethodId ?? -1;

          if (defualtPaymentTermId !== -1) {
            order.value.paymentTerm =
              paymentTerms.value.find((pt) => pt.id === defualtPaymentTermId) ?? order.value.paymentTerm;
          }
          if (defualtPaymentMethodId !== -1) {
            order.value.paymentMethod =
              paymentMethods.value.find((pm) => pm.id === defualtPaymentMethodId) ?? order.value.paymentMethod;
          }
        }
        let deliveryAddressId = -1;
        if (order.value.deliveryAddressEqualToBilling) {
          deliveryAddressId = order.value.invoiceAddress.id ?? -1;
        } else if (order.value.deliveryAddress) {
          deliveryAddressId = order.value.deliveryAddress.id ?? -1;
        }
        if (deliveryAddressId !== -1) {
          const deliveryAddress = customer?.addresses.find((a) => a.id === deliveryAddressId);

          const defualtDeliveryTermId = deliveryAddress?.deliveryTermId ?? -1;
          const defualtDeliveryMethodId = deliveryAddress?.deliveryMethodId ?? -1;

          if (defualtDeliveryTermId !== -1) {
            order.value.deliveryTerm =
              deliveryTerms.value.find((dt) => dt.id === defualtDeliveryTermId) ?? order.value.deliveryTerm;
            //if can find delivery term, set the default delivery method, else maintain the first default
          }
          if (defualtDeliveryMethodId !== -1) {
            order.value.deliveryMethod =
              deliveryMethods.value.find((dm) => dm.id === defualtDeliveryMethodId) ?? order.value.deliveryMethod;
          }
        }
      }
    }
  };

  const getMinShippingDate = function () {
    const today = new Date();

    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const minDate = tomorrow.toISOString().split('T')[0];
    return minDate;
  };

  const getMinDeliveryDate = function () {
    const minDate = new Date().toISOString().split('T')[0];
    return minDate;
  };

  const shippingDateRequest = computed({
    get: () => {
      if (order.value && order.value.shippingDateRequest) {
        const date = new Date(order.value.shippingDateRequest * 1000);
        return date.toISOString().split('T')[0];
      }
      return null;
    },
    set: (newDate) => {
      if (newDate && order.value) {
        const date = new Date(newDate);
        order.value.shippingDateRequest = Math.floor(date.getTime() / 1000);
      }
    },
  });

  const deliveryDateRequest = computed({
    get: () => {
      if (order.value && order.value.deliveryDateRequest) {
        const date = new Date(order.value.deliveryDateRequest * 1000);
        return date.toISOString().split('T')[0];
      }
      return null;
    },
    set: (newDate) => {
      if (order.value) {
        const date = new Date(newDate || '');
        order.value.deliveryDateRequest = Math.floor(date.getTime() / 1000);
      }
    },
  });

  const selectedDeliveryDate = computed(() => {
    if (!order.value) {
      return false;
    }
    return order.value.deliveryMethod?.requestedReceiptDateRequired;
  });

  const deliveryTermsOptions = computed((): Array<AgSearchSelectOption> => {
    return deliveryTerms.value.map((dt) => {
      return {
        value: dt.id,
        label: getTranslatedText(dt.title),
        searchableString: getTranslatedText(dt.title),
      };
    });
  });

  const disabledDeliveryTerm = computed(() => {
    if (order.value && order.value.deliveryTerm && config.company.disabledDeliveryTerm) {
      return true;
    }
    return false;
  });

  const newDeliveryTerm = computed({
    get: () => {
      if (order.value && order.value.deliveryTerm) {
        return order.value.deliveryTerm.id;
      }
      return null;
    },
    set: (deliveryTermId) => {
      if (deliveryTermId && order.value) {
        const deliveryTerm = deliveryTerms.value.find((dt) => {
          return dt.id === deliveryTermId;
        });
        if (deliveryTerm) {
          order.value.deliveryTerm = deliveryTerm;
        }
      }
    },
  });

  const deliveryMethodOptions = computed((): Array<AgSearchSelectOption> => {
    return deliveryMethods.value.map((dt) => {
      return {
        value: dt.id,
        label: getTranslatedText(dt.title),
        searchableString: getTranslatedText(dt.title),
      };
    });
  });

  const depotOptions = computed((): Array<AgSearchSelectOption> => {
    return depots.value.map((dt) => {
      return {
        value: dt.id,
        label: getTranslatedText(dt.title),
        searchableString: getTranslatedText(dt.title),
      };
    });
  });

  const newDeliveryMethod = computed({
    get: () => {
      if (order.value && order.value.deliveryMethod) {
        return order.value.deliveryMethod.id;
      }
      return null;
    },
    set: (deliveryMethodId) => {
      if (deliveryMethodId && order.value) {
        const deliveryMethod = deliveryMethods.value.find((dm) => {
          return dm.id === deliveryMethodId;
        });
        if (deliveryMethod) {
          order.value.deliveryMethod = deliveryMethod;
        }
      }
    },
  });

  const paymentTermsOptions = computed((): Array<AgSearchSelectOption> => {
    return paymentTerms.value.map((pt) => {
      return {
        value: pt.id,
        label: getTranslatedText(pt.title),
        searchableString: getTranslatedText(pt.title),
      };
    });
  });

  const newPaymentTerm = computed({
    get: () => {
      if (order.value && order.value.paymentTerm) {
        return order.value.paymentTerm.id;
      }
      return null;
    },
    set: (paymentTermId) => {
      if (paymentTermId && order.value) {
        const paymentTerm = paymentTerms.value.find((pt) => {
          return pt.id === paymentTermId;
        });
        if (paymentTerm) {
          order.value.paymentTerm = paymentTerm;
        }
      }
    },
  });

  const paymentMethodOptions = computed((): Array<AgSearchSelectOption> => {
    return paymentMethods.value.map((pm) => {
      return {
        value: pm.id,
        label: getTranslatedText(pm.title),
        searchableString: getTranslatedText(pm.title),
      };
    });
  });

  const newPaymentMethod = computed({
    get: () => {
      if (order.value && order.value.paymentMethod) {
        return order.value.paymentMethod.id;
      }
      return null;
    },
    set: (paymentMethodId) => {
      if (paymentMethodId && order.value) {
        const paymentMethod = paymentMethods.value.find((pm) => {
          return pm.id === paymentMethodId;
        });
        if (paymentMethod) {
          order.value.paymentMethod = paymentMethod;
        }
      }
    },
  });

  const newDepot = computed({
    get: () => {
      if (order.value) {
        if (order.value.depot) {
          return order.value.depot.id;
        } else if (depots.value.some((d) => d.default)) {
          return depots.value.find((d) => d.default)?.id;
        } else if (depots.value.length > 0) {
          return depots.value[0].id;
        }
      }
      return null;
    },
    set: (depotId) => {
      if (depotId && order.value) {
        const depot = depots.value.find((d) => d.id === depotId);
        if (depot) {
          order.value.depot = depot;
        }
      }
    },
  });

  const validation = computed(() => {
    validateAll();
    return validateForm(order.value?.customer);
  });

  const validationPaymentDelivery = computed(() => {
    validateAll();
    return validatePaymentDelivery(
      order.value,
      deliveryTerms.value,
      deliveryMethods.value,
      paymentTerms.value,
      paymentMethods.value
    );
  });

  const validateAll = function () {
    if (
      !order.value ||
      !isPaymentDeliveryValid(
        order.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      )
    ) {
      emit('update:modelValue', {
        id: 3,
        error: false,
      });
    } else if (order.value.paymentMethod?.ibanRequired) {
      emit('update:modelValue', {
        id: 3,
        error: isIbanValid(order.value?.customer),
      });
    } else {
      emit('update:modelValue', {
        id: 3,
        error: true,
      });
    }
  };

  onMounted(() => {
    loadOrderData();
    validateAll();
  });

  onBeforeUpdate(() => {
    if (
      !isPaymentDeliveryValid(
        order.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      ) ||
      isIbanValid(order.value?.customer)
    ) {
      showPopper.value = true;
    }
  });
</script>
