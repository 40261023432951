<template>
  <div class="px-32 flex text-s font-light">
    <div class="flex flex-col justify-start w-full">
      <div>{{ t('loggedAsSidebar') }}:</div>
      <div class="truncate font-bold">{{ props.username }}</div>
    </div>
    <div class="flex items-center">
      <AgButton variant="ghost" @click="logout">
        <template #icon><IcLogout /></template>
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcLogout from '@/components/icons/IcLogout.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    username: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    username: '',
  });

  const emit = defineEmits(['logout']);

  const logout = () => emit('logout');
</script>
