<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="152.1" height="159.21" viewBox="0 0 152.1 159.207">
    <rect class="st0" width="152.1" height="159.207" />
    <path
      id="Pfad_13484"
      :class="{ st8: props.variant !== 'gray', gray: props.variant === 'gray' }"
      d="M5.19,20.317c-3.1505,0.2933-5.4668,3.085-5.1735,6.2355s3.085,5.4668,6.2355,5.1735l0,0h19.46
	l45.961,101.257l0.12,0.36h0.12c1.4796,3.6307,4.2581,6.5804,7.794,8.274c4.1954,2.0169,9.0617,2.1046,13.327,0.24h0.12
	l40.907-18.364c2.993-0.9596,4.6414-4.1638,3.6818-7.1567c-0.9596-2.993-4.1637-4.6414-7.1567-3.6819
	c-0.0466,0.0149-0.0929,0.0305-0.1391,0.0466c-0.3697,0.1228-0.7256,0.2836-1.062,0.48l-41.047,18.363
	c-1.149,0.5436-2.4898,0.4989-3.6-0.12c-1.0134-0.5536-1.8464-1.3867-2.4-2.4l-0.24-0.36L34.586,23.675
	c-0.915-2.0305-2.9289-3.3421-5.156-3.358H5.19"
    />
    <path
      id="Pfad_13485"
      :class="{ st8: props.variant !== 'gray', gray: props.variant === 'gray' }"
      d="M48.308,125.188c-9.3928,0.1732-16.8667,7.9279-16.6936,17.3206
	s7.9278,16.8668,17.3206,16.6936s16.8667-7.9279,16.6936-17.3206c-0.0421-2.2835-0.5436-4.5351-1.4746-6.6206
	C61.3715,129.0281,55.1326,125.0622,48.308,125.188 M48.548,135.62c2.6275-0.0771,5.0421,1.4377,6.116,3.837
	c1.5233,3.3115,0.0736,7.2309-3.2379,8.7542s-7.2309,0.0736-8.7542-3.2379s-0.0737-7.2309,3.2379-8.7542
	c0.0001-0.0001,0.0001-0.0001,0.0002-0.0001c0.8324-0.3682,1.7282-0.572,2.638-0.6"
    />
    <rect
      id="Rechteck_6075"
      x="85.0062"
      y="5.2204"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 0.8832 43.9693)"
      :class="{ st2: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
    <rect
      id="Rechteck_6076"
      x="100.3682"
      y="38.6406"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 -11.6773 53.4472)"
      :class="{ st3: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
    <rect
      id="Rechteck_6077"
      x="115.7142"
      y="72.0679"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 -24.2423 62.9191)"
      :class="{ st4: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
    <rect
      id="Rechteck_6078"
      x="49.813"
      y="21.3985"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 -9.098 30.7426)"
      :class="{ st5: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
    <rect
      id="Rechteck_6079"
      x="65.159"
      y="54.8257"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 -21.6629 40.2144)"
      :class="{ st6: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
    <rect
      id="Rechteck_6080"
      x="80.514"
      y="88.2309"
      transform="matrix(0.9085 -0.4179 0.4179 0.9085 -34.2177 49.688)"
      :class="{ st7: props.variant !== 'gray', gray: props.variant === 'gray' }"
      width="31.6742"
      height="29.4952"
    />
  </svg>
</template>

<script setup lang="ts">
  interface Props {
    variant?: 'gray';
  }

  const props = defineProps<Props>();
</script>

<style scoped lang="css">
  .st0 {
    fill: none;
  }
  .st1 {
    fill: #212529;
  }
  .st2 {
    fill: #005a32;
  }
  .st3 {
    fill: #876d40;
  }
  .st4 {
    fill: #86939f;
  }
  .st5 {
    fill: #008232;
  }
  .st6 {
    fill: #006fff;
  }
  .st7 {
    fill: #3d708d;
  }
  .st8 {
    fill: currentColor;
  }
  .gray {
    fill: #e2e2e2;
  }
</style>
