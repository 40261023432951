import { config } from '@/config/config';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function required(value: string) {
  const ret = {
    state: false,
    msg: '',
  };
  if (!value) {
    ret.state = false;
    ret.msg = '';
  }
  return true;
}

export interface DiscountsErrorObject {
  discount1: { state: boolean; msg: string };
  discount2: { state: boolean; msg: string };
  discount3: { state: boolean; msg: string };
  discount4: { state: boolean; msg: string };
  discount5: { state: boolean; msg: string };
  discount6: { state: boolean; msg: string };
}

export function initDiscountErrors(): DiscountsErrorObject {
  return {
    discount1: { state: true, msg: '' },
    discount2: { state: true, msg: '' },
    discount3: { state: true, msg: '' },
    discount4: { state: true, msg: '' },
    discount5: { state: true, msg: '' },
    discount6: { state: true, msg: '' },
  };
}

export function areModifiedDiscountsValid(
  netPrices: Array<number> | null | undefined,
  discounts: Array<number> | null | undefined,
  minPrice: number | undefined
) {
  if (!netPrices) {
    return false;
  }
  const errorsDiscount: DiscountsErrorObject = validateAllDiscounts(netPrices, discounts, minPrice);
  let ret = true;
  Object.values(errorsDiscount).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function validateAllDiscounts(
  netPrices: Array<number> | null | undefined,
  discounts: Array<number> | null | undefined,
  minPrice: number | undefined
) {
  const errors: DiscountsErrorObject = initDiscountErrors();
  if (minPrice !== null && minPrice !== undefined && minPrice < 0) {
    errors.discount1.state = false;
    errors.discount1.msg = t('orders.minPriceNotValid');
    errors.discount2.state = false;
    errors.discount2.msg = t('orders.minPriceNotValid');
    errors.discount3.state = false;
    errors.discount3.msg = t('orders.minPriceNotValid');
    errors.discount4.state = false;
    errors.discount4.msg = t('orders.minPriceNotValid');
    errors.discount5.state = false;
    errors.discount5.msg = t('orders.minPriceNotValid');
    errors.discount6.state = false;
    errors.discount6.msg = t('orders.minPriceNotValid');
  }
  if (!netPrices) {
    errors.discount1.state = false;
    errors.discount1.msg = t('errors.required');
  }
  netPrices?.forEach((_element, index) => {
    type ObjectKey = keyof typeof errors;
    const key = `discount${index + 1}` as ObjectKey;
    if (discounts && (discounts[index] < 0 || discounts[index] > 100)) {
      errors[key].state = false;
      errors[key].msg = t('orders.discountOutOfRange');
    } else if (
      minPrice &&
      netPrices[index] < minPrice &&
      discounts &&
      discounts[index] > 0 &&
      config.company.minPrice
    ) {
      errors[key].state = false;
      errors[key].msg = t('orders.discountOverMinPrice');
    }
  });
  return errors;
}
