<template>
  <svg id="ic_order" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4580" width="20" height="20" fill="none" />
    <path
      id="Pfad_14627"
      d="M17.2,12.009H12.855l-1.447,2.24H8.516L7.07,12.009H2.731"
      transform="translate(0 -2.008)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14628"
      d="M2.731,12.009v4.675a1.506,1.506,0,0,0,1.447,1.559h11.57a1.506,1.506,0,0,0,1.447-1.559h0V12.009"
      transform="translate(0 -2.008)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <line
      id="Linie_353"
      data-name="Linie 353"
      x2="12.515"
      transform="translate(3.754 7.225)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <line
      id="Linie_354"
      data-name="Linie 354"
      x2="8.522"
      transform="translate(5.75 4.229)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
    />
  </svg>
</template>
