<template>
  <div class="py-[40px] overflow-x-auto">
    <div class="mx-auto w-fit">
      <!-- TABLE HEADER PERIODS-->
      <StatisticByCustomerPeriodsHeader
        :periodOne="props.periodOne"
        :periodTwo="props.periodTwo"
        :startMonth="props.startMonth"
        :endMonth="props.endMonth"
      />

      <!-- TABLE HEADER -->
      <StatisticTableByCustomerHeader @update:sorting="updateSorting($event)" />

      <!-- ROWS -->
      <div v-if="props.statistics && props.statistics.length > 0">
        <div v-for="statistic in statistics" :key="statistic.index">
          <StatisticByCustomerTableRow
            :statistic="statistic"
            :startMonth="props.startMonth"
            :endMonth="props.endMonth"
            :periodOne="props.periodOne"
            :periodTwo="props.periodTwo"
            :agentid="props.agentId"
          />
        </div>
        <StatisticByCustomerTableRow :statistic="sumStatistic" :isSumRow="true" class="bg-neutral-100 font-bold" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByCustomerDto } from '@/domain/StatisticByCustomerDto';
  import type { Sorting } from '@/util/Sorting';

  import StatisticByCustomerPeriodsHeader from '@/modules/statistics/components/by-customer/StatisticByCustomerPeriodsHeader.vue';
  import StatisticByCustomerTableRow from '@/modules/statistics/components/by-customer/StatisticByCustomerTableRow.vue';
  import StatisticTableByCustomerHeader from '@/modules/statistics/components/by-customer/StatisticTableByCustomerHeader.vue';

  import { computed } from 'vue';

  interface Props {
    statistics: Array<StatisticByCustomerDto>;
    periodOne: number;
    periodTwo: number;
    startMonth: number;
    endMonth: number;
    agentId?: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:sorting']);

  const sumStatistic = computed(() => {
    let st: StatisticByCustomerDto = {
      totalAmountPeriodOne: 0,
      totalQuantityPeriodOne: 0,
      totalAmountPeriodTwo: 0,
      totalQuantityPeriodTwo: 0,
      customerCode: '',
      customerName: '',
      index: 0,
    };
    props.statistics.forEach((stat: StatisticByCustomerDto) => {
      st.totalQuantityPeriodOne += stat.totalQuantityPeriodOne;
      st.totalAmountPeriodOne += stat.totalAmountPeriodOne;
      st.totalQuantityPeriodTwo += stat.totalQuantityPeriodTwo;
      st.totalAmountPeriodTwo += stat.totalAmountPeriodTwo;
    });
    return st;
  });

  const updateSorting = (sorting: Sorting) => {
    emit('update:sorting', sorting);
  };
</script>
