<template>
  <AgModal v-if="internalValue" fit-height fit-width :has-header="false">
    <template #content>
      <div class="md:min-h-[120px]">
        <div class="text-xl font-bold">{{ t('offers.confirmCloseOffer') }}</div>
        <div class="mx-auto py-24">
          <div v-if="isLoading">
            <IcLoading />
          </div>
          <div v-if="isError" class="text-danger">
            {{ t('offers.errorCloseOffer') }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <AgButton variant="secondary" class="basis-1/2" @click.stop="closeModal" :disabled="isLoading">
        {{ t('offers.cancel') }}
      </AgButton>
      <AgButton class="basis-1/2" @click="confirmCloseOffer" :disabled="isLoading">
        {{ t('offers.closeOffer') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useOfferData } from '@/composables/data/useOfferData';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  const { syncOffers } = useOfferData();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  interface Props {
    modelValue?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const confirmCloseOffer = function () {
    if (!offer.value || !offer.value.gid) {
      isLoading.value = false;
      isError.value = true;

      return;
    }
    isLoading.value = true;

    offer.value.offerDate = currentTimestamp();
    offer.value.status = OfferStatus.COMPLETED;

    setTimeout(() => closeOffer(), 3 * 1000);
  };

  const closeOffer = async function () {
    await syncOffers().then(() => reloadOffer());
  };

  const reloadOffer = async function () {
    const gid = offer.value ? offer.value.gid : '';
    await offerStore.loadOfferByGid(gid).then(() => {
      isLoading.value = false;
      isError.value = false;
      closeModal();
    });
  };
</script>
