export function Utilities() {
  const escapedRegExp = function (str: string) {
    return new RegExp(str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
  };

  const escapedRegExpTwo = function (pattern: string) {
    const pt = pattern.trim();
    const words = pt.split(/\|\|/).filter((word) => word);

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    return new RegExp(words.join('|'), 'gi');
  };

  return {
    escapedRegExp,
    escapedRegExpTwo,
  };
}
