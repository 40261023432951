<template>
  <div ref="subMenuCollapsible" id="subMenu" class="cursor-pointer">
    <div class="flex place-items-center justify-between py-12" :class="menuItemClasses" @click.stop="onOpenClose">
      <div class="flex gap-12">
        <div v-if="hasIcon" id="subMenuIcon">
          <slot name="icon"></slot>
        </div>
        <div id="subMenuLabel" class="truncate text-l" :class="menuItemLinkClasses">
          {{ props.label }}
        </div>
      </div>
      <div>
        <IcChevronUp v-if="!close" id="subMenuIconClose" />
        <IcChevronDown v-if="close" id="subMenuIconOpen" />
      </div>
    </div>
    <div v-show="!close" class="submenu border-t border-neutral-200 pl-32">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';

  import { computed, ref, useSlots, watchEffect } from 'vue';
  import { useRoute } from 'vue-router';

  interface Props {
    label: string;
    to: string;
  }
  const props = defineProps<Props>();

  const route = useRoute();

  const subMenuCollapsible = ref();
  const close = ref<boolean>(false);

  const menuItemClasses = computed(() => ({
    'text-primary': !close.value,
  }));

  const menuItemLinkClasses = computed(() => ({
    'font-bold': !close.value,
    'font-light': close.value,
  }));

  const onOpenClose = () => {
    close.value = !close.value;
  };

  const hasIcon = computed<boolean>(() => {
    return !!useSlots().icon;
  });

  watchEffect(() => {
    const isPathValid = route.path.startsWith(props.to);
    if (!isPathValid) {
      close.value = true;
    }
    const handleOutsideClick = (event: Event) => {
      if (event && subMenuCollapsible.value) {
        const notContains = !subMenuCollapsible.value?.contains(event.target);
        if (isPathValid && notContains) {
          close.value = false;
        } else if (notContains && !close.value) {
          close.value = true;
        }
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });
</script>

<style lang="scss">
  .submenu > a {
    &:not(:last-child) {
      @apply border-b border-neutral-200;
    }
  }
</style>
