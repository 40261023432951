<template>
  <div v-if="isLoading">
    <IcLoading />
  </div>
  <div v-else-if="agentsByMonth.length > 0">
    <!-- TABLE HEADER -->
    <div class="table border-b border-neutral-300 text-neutral-550">
      <div class="tableRowEntry">&nbsp;</div>
      <div class="tableRowEntry">{{ props.yearTwo }}</div>
      <div class="tableRowEntry">{{ props.yearOne }}</div>
      <div class="py-16 px-12">{{ t('statistics.difference') }}&nbsp;({{ props.yearTwo }}-{{ props.yearOne }})</div>
    </div>

    <div class="table border-b border-neutral-300 text-neutral-550 cursor-pointer">
      <div class="flex items-center border-r border-neutral-300" @click.stop.prevent="updateSorting(ColumnName.AGENT)">
        <span class="px-4">{{ t('statistics.agent') }}</span>
        <span v-if="sorting.col === ColumnName.AGENT">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="tableEntry border-r border-neutral-300">
        <!-- QUANTITY YEAR TWO -->
        <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_TWO)">
          <span class="px-4">{{ t('statistics.quantity') }}</span>
          <span v-if="sorting.col === ColumnName.QUANTITY_TWO">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <!-- AMOUNT YEAR TWO -->
        <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_TWO)">
          <span class="px-4">{{ t('statistics.amount') }}</span>
          <span v-if="sorting.col === ColumnName.AMOUNT_TWO">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- CUSTOMERS YEAR TWO -->
        <div class="right" @click.stop="updateSorting(ColumnName.CUSTOMERS_TWO)">
          <span class="px-4">{{ t('statistics.customers') }}</span>
          <span v-if="sorting.col === ColumnName.CUSTOMERS_TWO">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
      </div>

      <div class="tableEntry border-r border-neutral-300">
        <!-- QUANTITY YEAR ONE -->
        <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_ONE)">
          <span class="px-4">{{ t('statistics.quantity') }}</span>
          <span v-if="sorting.col === ColumnName.QUANTITY_ONE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- AMOUNT YEAR ONE -->
        <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_ONE)">
          <span class="px-4">{{ t('statistics.amount') }}</span>
          <span v-if="sorting.col === ColumnName.AMOUNT_ONE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- CUSTOMERS YEAR ONE -->
        <div class="right" @click.stop="updateSorting(ColumnName.CUSTOMERS_ONE)">
          <span class="px-4">{{ t('statistics.customers') }} </span>
          <span v-if="sorting.col === ColumnName.CUSTOMERS_ONE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
      </div>

      <div class="tableEntry">
        <!-- QUANTITY DIFFERENCE -->
        <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_DIFFERENCE)">
          <span class="px-4">{{ t('statistics.quantity') }}</span>
          <span v-if="sorting.col === ColumnName.QUANTITY_DIFFERENCE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- AMOUNT DIFFERENCE -->
        <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_DIFFERENCE)">
          <span class="px-4">{{ t('statistics.amount') }}</span>
          <span v-if="sorting.col === ColumnName.AMOUNT_DIFFERENCE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- REVENUE -->
        <div class="right" @click.stop="updateSorting(ColumnName.PERCENTAGE)">
          <span class="px-4">{{ t('statistics.revenue') }}&nbsp;%</span>
          <span v-if="sorting.col === ColumnName.PERCENTAGE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- CUSTOMERS DIFFERENCE -->
        <div class="right" @click.stop="updateSorting(ColumnName.CUSTOMERS_DIFFERENCE)">
          <span class="px-4">{{ t('statistics.customers') }}</span>
          <span v-if="sorting.col === ColumnName.CUSTOMERS_DIFFERENCE">
            <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
            <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
      </div>
    </div>

    <!-- TABLE CONTENT -->
    <div v-for="agentSt in agentsByMonth" :key="agentSt.agentId">
      <StatisticByMonthSubRow :statistic="agentSt" />
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('statistics.noData') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useHierarchy } from '@/composables/useHierarchy';
  import StatisticByMonthSubRow from '@/modules/statistics/components/by-month/sub-table/StatisticByMonthSubRow.vue';

  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const store = useStatisticStore();
  const { agentsByMonth } = storeToRefs(store);
  const { getStatisticsAgentsByMonth } = store;

  const { resolveHierarchyUsers } = useHierarchy();

  interface Props {
    yearOne: number | undefined;
    yearTwo: number | undefined;
    startMonth: number;
    endMonth: number | undefined;
    inputSearch: string | undefined;
    agentId: number | undefined;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    CUSTOMERS_ONE = 'customers1',
    QUANTITY_ONE = 'quantity1',
    AMOUNT_ONE = 'amount1',

    CUSTOMERS_TWO = 'customers2',
    QUANTITY_TWO = 'quantity2',
    AMOUNT_TWO = 'amount2',

    AGENT = 'agent',
    QUANTITY_DIFFERENCE = 'quantity_difference',
    AMOUNT_DIFFERENCE = 'amount_difference',
    CUSTOMERS_DIFFERENCE = 'customers_difference',
    PERCENTAGE = 'revenue',
  }
  const sorting = ref<Sorting>(new Sorting(ColumnName.AGENT, Order.ASC));

  const isLoading = ref<boolean>(true);

  const agentNames = ref<{ [key: number]: string }>({});

  const updateSorting = (colum: ColumnName) => {
    if (colum === ColumnName.AGENT) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => {
          if (a.agentId && b.agentId) {
            return agentNames.value[a.agentId].localeCompare(agentNames.value[b.agentId]);
          }
          return 0;
        });
      } else {
        agentsByMonth.value.sort((a, b) => {
          if (a.agentId && b.agentId) {
            return agentNames.value[b.agentId].localeCompare(agentNames.value[a.agentId]);
          }
          return 0;
        });
      }
    } else if (colum === ColumnName.QUANTITY_ONE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalQuantityPeriodOne - b.totalQuantityPeriodOne);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalQuantityPeriodOne - a.totalQuantityPeriodOne);
      }
    } else if (colum === ColumnName.QUANTITY_TWO) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalQuantityPeriodTwo - b.totalQuantityPeriodTwo);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalQuantityPeriodTwo - a.totalQuantityPeriodTwo);
      }
    } else if (colum === ColumnName.QUANTITY_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort(
          (a, b) =>
            a.totalQuantityPeriodTwo - a.totalQuantityPeriodOne - (b.totalQuantityPeriodTwo - b.totalQuantityPeriodOne)
        );
      } else {
        agentsByMonth.value.sort(
          (a, b) =>
            b.totalQuantityPeriodTwo - b.totalQuantityPeriodOne - (a.totalQuantityPeriodTwo - a.totalQuantityPeriodOne)
        );
      }
    } else if (colum === ColumnName.AMOUNT_ONE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalAmountPeriodOne - b.totalAmountPeriodOne);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalAmountPeriodOne - a.totalAmountPeriodOne);
      }
    } else if (colum === ColumnName.AMOUNT_TWO) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalAmountPeriodTwo - b.totalAmountPeriodTwo);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalAmountPeriodTwo - a.totalAmountPeriodTwo);
      }
    } else if (colum === ColumnName.AMOUNT_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort(
          (a, b) => a.totalAmountPeriodTwo - a.totalAmountPeriodOne - (b.totalAmountPeriodTwo - b.totalAmountPeriodOne)
        );
      } else {
        agentsByMonth.value.sort(
          (a, b) => b.totalAmountPeriodTwo - b.totalAmountPeriodOne - (a.totalAmountPeriodTwo - a.totalAmountPeriodOne)
        );
      }
    } else if (colum === ColumnName.CUSTOMERS_ONE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalCustomersPeriodOne - b.totalCustomersPeriodOne);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalCustomersPeriodOne - a.totalCustomersPeriodOne);
      }
    } else if (colum === ColumnName.CUSTOMERS_TWO) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => a.totalCustomersPeriodTwo - b.totalCustomersPeriodTwo);
      } else {
        agentsByMonth.value.sort((a, b) => b.totalCustomersPeriodTwo - a.totalCustomersPeriodTwo);
      }
    } else if (colum === ColumnName.CUSTOMERS_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort(
          (a, b) =>
            a.totalCustomersPeriodTwo -
            a.totalCustomersPeriodOne -
            (b.totalCustomersPeriodTwo - b.totalCustomersPeriodOne)
        );
      } else {
        agentsByMonth.value.sort(
          (a, b) =>
            b.totalCustomersPeriodTwo -
            b.totalCustomersPeriodOne -
            (a.totalCustomersPeriodTwo - a.totalCustomersPeriodOne)
        );
      }
    } else if (colum === ColumnName.PERCENTAGE) {
      if (sorting.value.order === Order.ASC) {
        agentsByMonth.value.sort((a, b) => {
          if (a.totalAmountPeriodOne === 0) {
            return 1;
          }
          if (b.totalAmountPeriodOne === 0) {
            return -1;
          }
          const revenueA = (a.totalAmountPeriodTwo * 100) / a.totalAmountPeriodOne;
          const revenueB = (b.totalAmountPeriodTwo * 100) / b.totalAmountPeriodOne;

          return revenueA - revenueB;
        });
      } else {
        agentsByMonth.value.sort((a, b) => {
          if (a.totalAmountPeriodOne === 0) {
            return -1;
          }
          if (b.totalAmountPeriodOne === 0) {
            return 1;
          }
          const revenueA = (a.totalAmountPeriodTwo * 100) / a.totalAmountPeriodOne;
          const revenueB = (b.totalAmountPeriodTwo * 100) / b.totalAmountPeriodOne;

          return revenueB - revenueA;
        });
      }
    }
    sorting.value.col = colum;
    sorting.value.order = sorting.value.order === Order.DESC ? Order.ASC : Order.DESC;
  };

  const newSearch = function () {
    const today = props.endMonth == 13 ? true : false;
    const eMonth = props.startMonth;
    const filter = new StatisticFilter(
      undefined,
      undefined,
      props.startMonth,
      eMonth,
      props.yearOne,
      props.yearTwo,
      undefined,
      props.inputSearch,
      props.agentId,
      undefined,
      today
    );
    getStatisticsAgentsByMonth(filter).then(() => (isLoading.value = false));
  };

  onMounted(() => {
    resolveHierarchyUsers().then((users) => {
      users.forEach((user) => {
        agentNames.value[user.id] = user.name;
      });
    });
    newSearch();
  });
</script>

<style scoped lang="scss">
  .table {
    @apply grid grid-cols-[163px_293px_293px_393px] min-h-32;
  }

  .tableEntry {
    @apply flex justify-between items-center gap-4;
  }

  .tableRowEntry {
    @apply border-r border-neutral-300 py-16 px-12;
  }

  .right {
    @apply flex justify-end items-center px-4;
  }
</style>
