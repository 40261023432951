<template>
  <div class="flex flex-row items-center justify-between md:mx-[80px] max-md:mx-16 gap-64">
    <AgFormGroup :label="t('contingents.filter')" class="w-1/2">
      <AgSearchSelect
        :options="filterOptions"
        v-model="internalFilter"
        @update:model-value="() => emit('update:customerId')"
      />
    </AgFormGroup>

    <AgFormGroup :label="t('contingents.customer')" class="w-1/2">
      <AgSearchSelect
        :options="customers"
        v-model="internalCustomer"
        @update:model-value="() => emit('update:filter')"
      />
    </AgFormGroup>

    <AgFormGroup v-if="agentOptions.length > 2" :label="t('contingents.agent')" class="min-w-[112px]">
      <AgFormSelect :options="agentOptions" v-model="internalAgent" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { resolveHierarchyUsers } = useHierarchy();
  const { tables } = useMasterData();

  interface Props {
    customerId: number;
    filter: string;
    agentId: number | undefined;
    customerIds: Array<number>;
  }
  const props = withDefaults(defineProps<Props>(), {
    customerId: -1,
    filter: 'All',
  });

  const emit = defineEmits(['update:agentId', 'update:filter', 'update:customerId']);

  const contingentCustomers = ref<Array<CustomerDto>>([]);
  const filterOptions = ref<Array<AgSearchSelectOption>>([]);
  const customers = ref<Array<AgSearchSelectOption>>([]);
  const agentOptions = ref<Array<AgFormSelectOption>>([]);

  const internalAgent = computed({
    get: () => {
      if (!props.agentId) {
        if (agentOptions.value.length > 0) {
          return agentOptions.value[0].key;
        }
      }
      return props.agentId;
    },
    set: (value) => {
      emit('update:agentId', value);
    },
  });

  const internalCustomer = computed({
    get: () => {
      return props.customerId;
    },
    set: (value) => {
      emit('update:customerId', value);
    },
  });

  const internalFilter = computed({
    get: () => {
      return props.filter;
    },
    set: (value) => {
      if (value) {
        emit('update:filter', value);
      }
    },
  });

  const loadFilterCustomers = async function () {
    for (const id of props.customerIds) {
      const customer = await tables.customers.where('id').equals(id).first();
      if (customer) {
        contingentCustomers.value.push(customer);
      }
    }
    customers.value.push({ value: -1, label: 'contingents.allItems', searchableString: 'All' });
    contingentCustomers.value.forEach((customer: CustomerDto) => {
      customers.value.push({
        value: customer.id,
        label: `${customer.code} / ${customer.name} `,
        searchableString: `${customer.code} / ${customer.name}`,
      });
    });
  };

  const loadFilterContingent = async function () {
    const contingents = await tables.contingents.toArray();
    const contingentsWithFilter = contingents.filter((c) => c.filter);
    const filters = new Set(contingentsWithFilter.map((c) => c.filter ?? ''));

    filterOptions.value.push({ value: 'All', label: 'contingents.allItems', searchableString: 'contingents.allItems' });
    filters.forEach((f) =>
      filterOptions.value.push({
        value: f,
        label: f + ' ',
        searchableString: f,
      })
    );
  };

  onMounted(async () => {
    const hierarchyUserList = await resolveHierarchyUsers();

    if (hierarchyUserList.length > 1) {
      agentOptions.value.push({ label: t('contingents.allAgents'), key: -1 });

      hierarchyUserList.forEach((currentUser) => {
        agentOptions.value.push({ label: currentUser.name, key: currentUser.id });
      });
    }
    loadFilterCustomers();
    loadFilterContingent();
  });
</script>
