<template>
  <div v-if="data" class="chart-container">
    <Line :data="data" :options="options" class="chart-class" />
  </div>
</template>

<script setup lang="ts">
  import type { StatisticEntry } from '@/domain/internal/StatisticEntry';
  import { i18n } from '@/plugins/i18n';
  import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
  } from 'chart.js';
  import { computed } from 'vue';
  import { Line } from 'vue-chartjs';

  const { t } = i18n.global;

  interface Props {
    globalStatistics: { [key: string]: { [key: number]: StatisticEntry } };
  }
  const props = defineProps<Props>();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
  };

  const data = computed(() => {
    const statistics = props.globalStatistics;
    return {
      labels: [
        t('statistics.month1'),
        t('statistics.month2'),
        t('statistics.month3'),
        t('statistics.month4'),
        t('statistics.month5'),
        t('statistics.month6'),
        t('statistics.month7'),
        t('statistics.month8'),
        t('statistics.month9'),
        t('statistics.month10'),
        t('statistics.month11'),
        t('statistics.month12'),
      ],
      datasets: Object.entries(statistics).map(([year, monthData]) => {
        const colors = ['#FC0303', '#0313FC', '#03FC03', '#0F0F0F', '#F5E507', '#F00582'];
        let currentColor: number = 0;
        try {
          currentColor = parseInt(year);
        } catch (e) {
          console.error(e);
        }
        const yearColor = colors[currentColor % colors.length];
        return {
          label: year,
          backgroundColor: yearColor,
          borderColor: yearColor,
          borderWidth: 1,
          data: Object.entries(monthData).map(([_month, statistic]) => statistic.totalAmount),
        };
      }),
    };
  });
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
</script>

<style scoped lang="scss">
  .chart-container {
    @apply lg:max-w-[calc(100vw-29em)];
    height: 40vh;
    width: 90vw;
  }
  .chart-class {
    width: 100vw;
    height: 100vh;
  }
</style>
