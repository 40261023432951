<template>
  <div>
    <!-- TABLE COLLAPSIBLE -->
    <AgCollapsible show-icon class="border-t border-neutral-500">
      <template #header>
        <!-- TABLE CONTENT -->
        <div class="table">
          <!-- COLUM CUSTOMER CODE -->
          <div class="tableColumn">
            <span>{{ props.openItem.customerCode }}</span>
          </div>
          <!-- COLUM CUSTOMER NAME -->
          <div class="tableColumn">
            {{ props.openItem.customerName }}
          </div>
          <!-- COLUM INVOICE COUNT -->
          <div class="tableColumn right">
            {{ props.openItem.invoiceCount }}
          </div>
          <!-- COLUM OPEN AMOUNT SUM -->
          <div class="tableColumn right" :class="{ 'text-infoRed': props.openItem.openAmountSum < 0 }">
            {{ formatNumberToEuro(props.openItem.openAmountSum) }}
          </div>
          <!-- COLUM ICON SEND EMAIL TO CUSTOMER -->
          <div class="tableColumn right">
            <span @click.stop="sendEmail(props.openItem.customerEmail)">
              <IcMailClose class="cursor-pointer" />
            </span>
          </div>
        </div>
      </template>
      <template #default>
        <CustomerInvoicesTable
          :isSelectionActive="props.isSelectionActive"
          :customerId="props.openItem.customerId"
          :customerCode="props.openItem.customerCode"
          :customerName="props.openItem.customerName"
        />
      </template>
    </AgCollapsible>

    <!-- SEND EMAIL POPUP -->
    <OpenItemsPopupEmail
      v-model:modal="showPopupEmail"
      v-model:email="emailTo"
      :which-email="WhichEmail.CUSTOMER_OUTSTANDING_DEBTS"
      :sorting="sorting"
      :customer-id="props.openItem.customerId"
    />
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtCustomerDto } from '@/domain/OutstandingDebtCustomerDto';

  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import OpenItemsPopupEmail from '@/modules/open-items/components/OpenItemsPopupEmail.vue';
  import CustomerInvoicesTable from '@/modules/open-items/components/by-customer/CustomerInvoicesTable.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { ref } from 'vue';

  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    openItem: OutstandingDebtCustomerDto;
    isSelectionActive: boolean;
  }
  const props = defineProps<Props>();

  const emailTo = ref<string>('');
  const showPopupEmail = ref<boolean>(false);
  const sorting = ref<Sorting>(new Sorting('invoiceDate', Order.ASC));

  const sendEmail = (email: string) => {
    emailTo.value = email ?? '';
    showPopupEmail.value = true;
  };
</script>

<style scoped lang="scss">
  .table {
    @apply grid grid-cols-[160px_minmax(200px,1fr)_170px_200px_30px] min-h-48;
    @apply items-center;
  }

  .table:hover {
    @apply cursor-pointer bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }
</style>
