<template>
  <AgBox>
    <router-link to="/orders" class="router-link">
      <span>{{ t('draftOrdersDashboard') }}:</span><span>{{ sizeDraftOrders }}</span>
    </router-link>
    <router-link to="/orders/completed" class="router-link">
      <span>{{ t('completedOrdersDashboard') }}:</span><span>{{ sizeCompletedOrders }}</span>
    </router-link>
    <div class="flex items-center justify-between">
      <span>{{ t('totalOrdersDashboard') }}:</span><span>{{ totalOrders }}</span>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import AgBox from '@/components/library/box/AgBox.vue';

  import { useOrdersStore } from '@/modules/orders/stores/useOrdersStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { onMounted } from 'vue';

  const { t } = i18n.global;

  const orderStore = useOrdersStore();
  const { sizeCompletedOrders, sizeDraftOrders } = storeToRefs(orderStore);
  const { totalOrders } = storeToRefs(orderStore);

  onMounted(async () => await orderStore.refreshOrdersData());
</script>

<style lang="scss" scoped>
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }

  .router-link:hover {
    @apply underline;
  }
</style>
