import type { OrderCustomerDto } from '@/domain/orderData/OrderCustomerDto';
import type { OrderDeliveryMethodDto } from '@/domain/orderData/OrderDeliveryMethodDto';
import type { OrderDeliveryTermDto } from '@/domain/orderData/OrderDeliveryTermDto';
import type { OrderDto } from '@/domain/orderData/OrderDto';
import type { OrderPaymentMethodDto } from '@/domain/orderData/OrderPaymentMethodDto';
import type { OrderPaymentTermDto } from '@/domain/orderData/OrderPaymentTermDto';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function required(value: string) {
  const ret = {
    state: false,
    msg: '',
  };
  if (!value) {
    ret.state = false;
    ret.msg = '';
  }
  return true;
}

export interface CustomerErrorObject {
  iban: { state: boolean; msg: string };
}

export interface PaymentDeliveryErrorObject {
  deliveryTerm: { state: boolean; msg: string };
  deliveryMethod: { state: boolean; msg: string };
  paymentTerm: { state: boolean; msg: string };
  paymentMethod: { state: boolean; msg: string };
  deliveryRequestDate: { state: boolean; msg: string };
}

export function initErrors(): CustomerErrorObject {
  return {
    iban: { state: true, msg: '' },
  };
}

export function initPaymentDeliveryErrors(): PaymentDeliveryErrorObject {
  return {
    deliveryTerm: { state: true, msg: '' },
    deliveryMethod: { state: true, msg: '' },
    paymentTerm: { state: true, msg: '' },
    paymentMethod: { state: true, msg: '' },
    deliveryRequestDate: { state: true, msg: '' },
  };
}

export function validateForm(data: OrderCustomerDto | null | undefined): CustomerErrorObject {
  const errors: CustomerErrorObject = initErrors();

  if (!data?.iban) {
    errors.iban.state = false;
    errors.iban.msg = t('orders.ibanRequired');
  }
  return errors;
}

export function isIbanValid(data: OrderCustomerDto | null | undefined) {
  const errors: CustomerErrorObject = validateForm(data);
  let ret = true;
  Object.values(errors).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function validatePaymentDelivery(
  data: OrderDto | null | undefined,
  deliveryTerms: Array<OrderDeliveryTermDto>,
  deliveryMethods: Array<OrderDeliveryMethodDto>,
  paymentTerms: Array<OrderPaymentTermDto>,
  paymentmethods: Array<OrderPaymentMethodDto>
): PaymentDeliveryErrorObject {
  const errors: PaymentDeliveryErrorObject = initPaymentDeliveryErrors();
  if (
    !data?.paymentMethod?.id ||
    (paymentmethods && paymentmethods.findIndex((pm) => pm.id === data?.paymentMethod?.id) === -1)
  ) {
    errors.paymentMethod.state = false;
    errors.paymentMethod.msg = t('orders.paymentMetodRequired');
  }
  if (
    !data?.paymentTerm?.id ||
    (paymentTerms && paymentTerms.findIndex((pt) => pt.id === data?.paymentTerm?.id) === -1)
  ) {
    errors.paymentTerm.state = false;
    errors.paymentTerm.msg = t('orders.paymentTermRequired');
  }
  if (
    !data?.deliveryMethod?.id ||
    (deliveryMethods && deliveryMethods.findIndex((dt) => dt.id === data?.deliveryMethod?.id) === -1)
  ) {
    errors.deliveryMethod.state = false;
    errors.deliveryMethod.msg = t('orders.deliveryMetodRequired');
  }
  if (
    !data?.deliveryTerm?.id ||
    (deliveryTerms && deliveryTerms.findIndex((dt) => dt.id === data?.deliveryTerm?.id) === -1)
  ) {
    errors.deliveryTerm.state = false;
    errors.deliveryTerm.msg = t('orders.deliveryTermRequired');
  }
  if (data?.deliveryMethod?.requestedReceiptDateRequired && !data?.deliveryDateRequest) {
    errors.deliveryRequestDate.state = false;
    errors.deliveryRequestDate.msg = t('orders.dateRequired');
  } else if (data?.deliveryMethod?.requestedReceiptDateRequired && data?.deliveryDateRequest) {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);

    const ddDay = new Date(data.deliveryDateRequest * 1000);
    ddDay.setUTCHours(0, 0, 0, 0);

    if (ddDay < today) {
      errors.deliveryRequestDate.state = false;
      errors.deliveryRequestDate.msg = t('orders.dateNotValid');
    }
  }
  return errors;
}

export function isPaymentDeliveryValid(
  data: OrderDto | null | undefined,
  deliveryTerms: Array<OrderDeliveryTermDto>,
  deliveryMethods: Array<OrderDeliveryMethodDto>,
  paymentTerms: Array<OrderPaymentTermDto>,
  paymentmethods: Array<OrderPaymentMethodDto>
) {
  const errors: PaymentDeliveryErrorObject = validatePaymentDelivery(
    data,
    deliveryTerms,
    deliveryMethods,
    paymentTerms,
    paymentmethods
  );
  let ret = true;
  Object.values(errors).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}
