import authRequired from '@/middleware/authRequired';
import ArticlesOverviewIndexPageVue from '@/modules/articles/pages/ArticlesOverviewIndexPage.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: ArticlesOverviewIndexPageVue,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
