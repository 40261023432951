// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import murmur from 'murmurhash-js';

export function useMurmurHash() {
  const murmur3 = function (key: string, seed = 0) {
    return murmur.murmur3(key, seed);
  };

  return {
    murmur3,
  };
}
