import { MasterData } from '@/composables/data/local-database/tables/masterData';
import { UserDatabase } from '@/composables/data/local-database/tables/userData';

const db = {
  masterData: new MasterData(),
  userData: new UserDatabase(),
};

export function useLocalDatabase() {
  const clearUserData = function () {
    db.userData
      .delete()
      .then(() => console.log('Database userData successfully deleted!!!'))
      .catch((err) => console.warn('Could not delete database userData', err));

    db.userData
      .open()
      .then(() => console.log('Database userData successfully opened!!!'))
      .catch((err) => console.warn('Could not open database userData', err));
  };

  const clearMasterData = function () {
    db.masterData
      .delete()
      .then(() => console.log('Database masterData successfully deleted!!!'))
      .catch((err) => console.warn('Could not delete database masterData', err));

    db.masterData
      .open()
      .then(() => console.log('Database masterData successfully opened!!!'))
      .catch((err) => console.warn('Could not open database masterData', err));
  };

  const clearAll = function () {
    db.userData
      .delete()
      .then(() => console.log('Database userData successfully deleted!!!'))
      .catch((err) => console.warn('Could not delete database userData', err));

    db.masterData
      .delete()
      .then(() => console.log('Database masterData successfully deleted!!!'))
      .catch((err) => console.warn('Could not delete database masterData', err));
  };

  return {
    clearUserData,
    clearMasterData,
    clearAll,
    masterData: db.masterData,
    userData: db.userData,
  };
}
