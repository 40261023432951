import type { OrderDto } from '@/domain/orderData/OrderDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { useOrders } from '@/modules/orders/composables/useOrders';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

export const useOrderWizardStore = defineStore('order-wizard', () => {
  const theOrder = ref<OrderDto | null>(null);
  const isCustomerBlocked = ref<boolean>(false);
  const validationStates = ref<Array<boolean>>([true, true, true, true, true, true]);
  const masterData = useMasterData();
  const orders = useOrders();
  const wizzardAddressOrrigin = ref<{ [key: string]: string }>({});

  watch(
    theOrder,
    async (newValue, oldValue) => {
      if (newValue && oldValue) {
        const copy = cloneDeep(newValue);
        copy.synced = 0;

        await orders.saveOrder(copy);

        isCustomerBlocked.value = await isBlocked();
      }
    },
    { immediate: true, deep: true }
  );

  const reset = function () {
    theOrder.value = null;
    isCustomerBlocked.value = false;
    validationStates;
  };

  const loadOrderByGid = async function (gid: string) {
    const maybeOrder = await orders.getOrderByGid(gid);
    if (maybeOrder.isPresent()) {
      theOrder.value = maybeOrder.get();
      isCustomerBlocked.value = await isBlocked();
    } else {
      theOrder.value = null;
      isCustomerBlocked.value = false;
    }
  };

  const isLoaded = computed(() => {
    return theOrder.value !== null;
  });

  const isBlocked = async function () {
    if (!theOrder.value || !theOrder.value.customer.id) {
      return false;
    }
    const id = theOrder.value.customer.id;
    const customer = await masterData.tables.customers.where('id').equals(id).first();
    if (!customer) {
      return true;
    }
    return customer.blocked;
  };

  return {
    reset,
    validationStates: validationStates,
    order: theOrder,
    isLoaded,
    isCustomerBlocked,
    loadOrderByGid,
    wizzardAddressOrrigin,
  };
});
