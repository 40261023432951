<!-- eslint-disable max-len -->
<template>
  <svg id="ic_close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4630" data-name="Rechteck 4630" width="20" height="20" fill="none" />
    <path
      id="Pfad_2464"
      data-name="Pfad 2464"
      d="M17.141,6.223,15.918,5,11.07,9.848,6.223,5,5,6.223,9.848,11.07,5,15.918l1.223,1.223,
      4.848-4.848,4.848,4.848,1.223-1.223L12.293,11.07Z"
      fill="currentColor"
    />
  </svg>
</template>
