<template>
  <teleport to="body">
    <div class="ag-modal" data-modal="x">
      <div class="modal" :class="classes">
        <!--HEADER-->
        <div class="modalHeader" v-if="hasHeader">
          <slot name="header"></slot>
        </div>
        <!--CONTENT-->
        <div class="modalContent" :class="contentClasses">
          <slot name="content"></slot>
        </div>
        <!--FOOTER-->
        <div class="modalFooter" v-if="hasFooter">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted } from 'vue';

  const element = document.getElementsByTagName('body')[0];

  interface Props {
    hasHeader?: boolean;
    hasFooter?: boolean;
    fitHeight?: boolean;
    fitWidth?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    hasHeader: true,
    hasFooter: true,
    fitHeight: false,
    fitWidth: false,
  });

  //Hook to disable overflow of body
  onMounted(() => {
    element.classList.add('ag-modal-open');
  });

  //Hook to enable overflow of body when all modals are closed
  onUnmounted(() => {
    const modals = document.querySelectorAll('[data-modal="x"]');
    if (modals.length < 1) {
      element.classList.remove('ag-modal-open');
    }
  });

  const classes = computed(() => ({
    'w-fit lg:max-w-[calc(100%-21em)] md:max-w-[calc(100vh-10em)]': props.fitWidth,
    modalWidth: !props.fitWidth,
  }));

  const contentClasses = computed(() => ({
    'h-fit max-h-[calc(100vh-20em)] overflow-y-auto': props.fitHeight,
    modalHeight: !props.fitHeight,
  }));
</script>

<style scoped lang="scss">
  .ag-modal {
    z-index: 9001;
    @apply fixed flex left-0 top-0 bg-[#111111] h-full bg-opacity-50 max-lg:w-full lg:w-[calc(100%-20.8125em)];
  }

  .modal {
    @apply m-auto border-[1px] shadow-lg rounded-lg;
  }

  .modalHeader {
    @apply bg-neutral-100 border-b border-neutral-300 px-[40px] py-24;
  }

  .modalContent {
    @apply bg-white w-full overflow-auto px-[40px] py-[20px];
  }

  .modalFooter {
    @apply flex justify-between max-sm:flex-wrap max-sm:gap-y-4 gap-x-8 bg-neutral-100 border-t border-neutral-300 py-24 px-[40px];
  }

  .modalWidth {
    @apply md:max-w-[calc(100vh-10em)] sm:md:w-[100vh] lg:max-w-[calc(100%-21em)] lg:w-[100vh];
  }

  .modalHeight {
    @apply max-md:h-[calc(100vh-25em)] h-[calc(100vh-20em)];
  }
</style>
