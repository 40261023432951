<template>
  <div>
    <!-- TABLE COLLAPSIBLE-->
    <AgCollapsible show-icon collapse class="border-b border-neutral-500">
      <template #header>
        <!-- TABLE CONTENT -->
        <div class="table px-16" v-if="props.sumContingentRow">
          <div class="grid grid-cols-[143px_minmax(233px,333px)]">
            <!-- COLUMN CUSTOMER CODE -->
            <div class="tableColumn">
              {{ props.sumContingentRow.customerCode }}
            </div>
            <!-- COLUMN CUSTOMER NAME -->
            <div class="tableColumn">
              {{ props.sumContingentRow.customerName }}
            </div>
          </div>

          <div class="grid grid-cols-[minmax(80px,180px)_minmax(80px,180px)_minmax(80px,180px)_minmax(80px,180px)]">
            <!-- COLUMN TOTAL QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.totalQuantity }}
            </div>
            <!-- COLUMN DELIVERED QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.deliveredQuantity }}
            </div>
            <!-- COLUMN ORDERED QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.orderedQuantity }}
            </div>
            <!-- COLUMN AVAILABLE QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.availableQuantity }}
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <AgBox variant="accent" :showBorder="false" class="border-t border-x border-neutral-500">
          <ContGrArticleContingentsSubTable
            :contingents="props.contingents"
            :contingentGroupArticles="props.contingentGroupArticles"
            :articleDimensionValueConfigs="props.articleDimensionValueConfigs"
          />
        </AgBox>
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import type { SumContingentDto } from '@/domain/internal/SumContingent';
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ContGrArticleContingentsSubTable from '@/modules/contingents/components/by-customer/ContGrArticleContingentsSubTable.vue';

  interface Props {
    customerId: number;
    sumContingentRow: SumContingentDto | undefined;
    contingents: Array<ContingentDto>;
    contingentGroupArticles: Array<ContingentGroupArticleDto>;
    articleDimensionValueConfigs: Array<ArticleDimensionValueConfigsDto>;
  }
  const props = defineProps<Props>();
</script>

<style scoped lang="scss">
  .table {
    @apply flex justify-between items-center min-h-48;
  }

  .tableColumn {
    @apply flex items-center min-h-48;
  }

  .right {
    @apply flex justify-end;
  }
</style>
