import authRequired from '@/middleware/authRequired';
import MessagesIndexPage from '@/modules/messages/pages/MessagesIndexPage.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:id?',
    component: MessagesIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
