export class CustomerNotesFilter {
  search: string;
  category: string;
  date: string;

  typeDateFilter?: number;
  constructor(search = '', category = '', date = '', typeDateFilter?: number) {
    this.search = search;
    this.category = category;
    this.date = date;

    if (undefined !== typeDateFilter) {
      this.typeDateFilter = typeDateFilter;
    }
  }
}
