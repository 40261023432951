import { useApi } from '@/composables/api/useApi';
import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import { config } from '@/config/config';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { useDataTimestampStore } from '@/store/useDataTimestampStore';
import { useError } from '@/util/useError';
import { ref } from 'vue';

const TIMESTAMP_OFFERS = 'offers';
const MODIFY_TIMESTAMP_OFFERS = 'offersModify';

const isLoading = ref(false);

let intervalOffers: number | null = null;

export function useOfferData() {
  const api = useApi();

  const { userData } = useLocalDatabase();
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const { refreshOffersDataTimestamp } = useDataTimestampStore();

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const getOffersModifyTimestamp = async function () {
    const timestamp = await userData.timestamps.get(MODIFY_TIMESTAMP_OFFERS);

    if (!timestamp) {
      return 0;
    }
    return timestamp.value || 0;
  };

  const getNotSyncedOffers = async function () {
    return userData.offers.where('synced').equals(0).toArray();
  };

  const getDeletedOffers = async function () {
    return userData.deletedOffers.toArray();
  };

  const syncOffers = async function () {
    if (isLoading.value || !config.company.modules.OFFER) {
      return;
    }
    console.log('*** SYNC OFFERS DATA ***', new Date().toISOString());

    isLoading.value = true;

    const timestamp = await getOffersModifyTimestamp();

    const offersToSync = await getNotSyncedOffers();
    const deletedOffers = await getDeletedOffers();

    try {
      const containerResult = await api.fetch<ContainerDto>('/offersync?timestamp=' + timestamp, 'POST', {
        data: {
          offers: {
            upsert: offersToSync,
            delete: deletedOffers.map((offer) => offer.gid),
          },
        },
      } as ContainerDto);
      if (containerResult.errors && containerResult.errors.length > 0) {
        handleCatchServerError(containerResult.errors);
        return;
      }
      if (!containerResult.data || Object.keys(containerResult.data).length === 0 || !containerResult.data.offers) {
        isLoading.value = false;
        return;
      }
      await userData.offers.bulkPut(containerResult.data.offers.upsert);
      await userData.offers.bulkDelete(containerResult.data.offers.delete);

      await userData.deletedOffers.clear();

      const current_timestamp = currentTimestamp();

      userData.timestamps.bulkPut([
        {
          id: MODIFY_TIMESTAMP_OFFERS,
          value: containerResult.modifyTimestamp,
        },
        {
          id: TIMESTAMP_OFFERS,
          value: current_timestamp,
        },
      ]);
      refreshOffersDataTimestamp(Date.now());

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      handleCatchLocalError(error);
    }
  };

  const setIntervalSyncOffers = function () {
    if (intervalOffers === null && config.company.modules.OFFER) {
      intervalOffers = window.setInterval(async () => await syncOffers(), 2 * 60 * 1000); // Every 2 minutes
    }
  };

  const clearSyncOffers = function () {
    if (intervalOffers !== null && config.company.modules.OFFER) {
      clearInterval(intervalOffers);
      intervalOffers = null;
    }
  };

  return {
    setIntervalSyncOffers,
    clearSyncOffers,
    syncOffers,
    isLoading,
    tables: { offers: userData.offers, deletedOffers: userData.deletedOffers },
  };
}
