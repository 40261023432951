<template>
  <svg id="ic_kontingent" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_3157" data-name="Rechteck 3157" width="20" height="20" fill="none" />
    <g id="Gruppe_5129" data-name="Gruppe 5129" transform="translate(2 2)">
      <g
        id="Rechteck_3158"
        data-name="Rechteck 3158"
        transform="translate(1 9)"
        fill="none"
        stroke="#212529"
        stroke-width="1.5"
      >
        <rect width="6" height="6" stroke="none" />
        <rect x="0.75" y="0.75" width="4.5" height="4.5" fill="none" />
      </g>
      <g
        id="Rechteck_3161"
        data-name="Rechteck 3161"
        transform="translate(1 1)"
        fill="none"
        stroke="#212529"
        stroke-width="1.5"
      >
        <rect width="6" height="6" rx="3" stroke="none" />
        <rect x="0.75" y="0.75" width="4.5" height="4.5" rx="2.25" fill="none" />
      </g>
      <g
        id="Rechteck_3159"
        data-name="Rechteck 3159"
        transform="translate(9 9)"
        fill="none"
        stroke="#212529"
        stroke-width="1.5"
      >
        <rect width="6" height="6" stroke="none" />
        <rect x="0.75" y="0.75" width="4.5" height="4.5" fill="none" />
      </g>
      <g
        id="Rechteck_3160"
        data-name="Rechteck 3160"
        transform="translate(9 1)"
        fill="none"
        stroke="#212529"
        stroke-width="1.5"
      >
        <rect width="6" height="6" stroke="none" />
        <rect x="0.75" y="0.75" width="4.5" height="4.5" fill="none" />
      </g>
    </g>
  </svg>
</template>
