import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import NewOrderPage from '@/modules/orders/pages/NewOrderPage.vue';
import OrdersIndexPage from '@/modules/orders/pages/OrdersIndexPage.vue';
import OrderWizardPage from '@/modules/orders/pages/OrderWizardPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:status?',
    component: OrdersIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/new',
    component: NewOrderPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/:gid/:step(customer|address|shopping-cart|order-data|notes|review)',
    name: 'order-wizard',
    component: OrderWizardPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
