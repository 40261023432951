import { useMsal } from '@/composables/auth/useMsal';
import { AuthError } from '@azure/msal-browser';
import { computed, ref, watch } from 'vue';

export function useAuthentication() {
  const { account, acquireToken, signIn, signOut } = useMsal();

  const isAuthenticated = ref<boolean>(false);
  const role = ref<string>('User');

  const accountName = computed(() => {
    if (!account.value) {
      return '';
    }
    return account.value.username;
  });

  const userName = computed(() => {
    if (!account.value) {
      return '';
    }
    return account.value.name;
  });

  const login = async function () {
    await signIn();
  };

  const logout = async function () {
    await signOut();
  };

  const getToken = async function () {
    try {
      const token = await acquireToken();
      return token;
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.errorCode === 'app_offline') {
          throw error;
        }
      }
      await logout();
    }
  };

  watch(
    account,
    () => {
      isAuthenticated.value = !!account.value;
      if (account.value && isAuthenticated.value) {
        role.value = 'User';
        if (account.value.idTokenClaims && account.value.idTokenClaims['extension_Role']) {
          const extensionRole = account.value.idTokenClaims['extension_Role'] as string;
          if (extensionRole) {
            role.value = extensionRole;
          }
        }
      }
    },
    { immediate: true }
  );

  return {
    isAuthenticated,
    role,
    login,
    logout,
    getToken,
    accountName,
    userName,
  };
}
