<template>
  <svg id="ic_checklist" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_8212" data-name="Rechteck 8212" width="20" height="20" fill="none" />
    <path
      id="Pfad_14393"
      data-name="Pfad 14393"
      d="M-22780.777-9756.327l1.98,1.789,4.342-4.911"
      transform="translate(22787.777 9768.613)"
      fill="none"
      stroke="#212529"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <g id="Icon_feather-clipboard" data-name="Icon feather-clipboard" transform="translate(-1.549 -0.001)">
      <path
        id="Pfad_17026"
        data-name="Pfad 17026"
        d="M14.322,6h1.387A1.387,1.387,0,0,1,17.1,7.387V17.1a1.387,1.387,0,0,1-1.387,1.387H7.387A1.387,1.387,0,0,1,6,17.1V7.387A1.387,1.387,0,0,1,7.387,6H8.774"
        transform="translate(0 -1.613)"
        fill="none"
        stroke="#212529"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Pfad_17027"
        data-name="Pfad 17027"
        d="M12.694,3h4.161a.694.694,0,0,1,.694.694V5.081a.694.694,0,0,1-.694.694H12.694A.694.694,0,0,1,12,5.081V3.694A.694.694,0,0,1,12.694,3Z"
        transform="translate(-3.226)"
        fill="none"
        stroke="#212529"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
