<template>
  <svg id="ic_download" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4580" width="20" height="20" fill="none" />
    <path
      id="Pfad_11570"
      d="M5.843,9,10,13.158,14.157,9"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14628"
      d="M2.782,16.017v1.419a1.506,1.506,0,0,0,1.447,1.559H15.8a1.506,1.506,0,0,0,1.447-1.559h0V16.017"
      transform="translate(0 -2.008)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <line
      id="Linie_355"
      data-name="Linie 355"
      y2="9.786"
      transform="translate(10 3)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
    />
  </svg>
</template>
