<template>
  <svg id="ic_deliverydetails" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <circle id="Ellipse_224" data-name="Ellipse 224" cx="25" cy="25" r="25" fill="none" />
    <g id="Pfad_7111" data-name="Pfad 7111" transform="translate(11.235 7.947)" fill="none">
      <path d="M25.656,15.087,15.522,21.109,9.5,11.122,19.781,5.1Z" stroke="none" />
      <path
        d="M 16.20855331420898 18.37435531616211 L 22.92277908325195 14.38489437103271 L 19.06953811645508 7.834634780883789 L 12.2587308883667 11.8236665725708 L 16.20855331420898 18.37435531616211 M 15.52187538146973 21.10877418518066 L 9.500004768371582 11.12164306640625 L 19.7812557220459 5.100003242492676 L 25.6562557220459 15.08712387084961 L 15.52187538146973 21.10877418518066 Z"
        stroke="none"
        fill="#212529"
      />
    </g>
    <line
      id="Linie_209"
      data-name="Linie 209"
      x2="11.086"
      y2="18.897"
      transform="translate(12.414 11.603)"
      fill="none"
      stroke="#86939f"
      stroke-width="2"
    />
    <path
      id="Pfad_11895"
      data-name="Pfad 11895"
      d="M0,6.648,9.691.746"
      transform="translate(28.813 25.139)"
      fill="none"
      stroke="#86939f"
      stroke-width="2"
    />
    <line
      id="Linie_210"
      data-name="Linie 210"
      x1="6.951"
      y1="1.137"
      transform="translate(9.549 17.363)"
      fill="none"
      stroke="#86939f"
      stroke-width="2"
    />
    <g
      id="Ellipse_276"
      data-name="Ellipse 276"
      transform="translate(21.041 29.199)"
      fill="none"
      stroke="#86939f"
      stroke-width="2"
    >
      <circle cx="5" cy="5" r="5" stroke="none" />
      <circle cx="5" cy="5" r="4" fill="none" />
    </g>
  </svg>
</template>
