<template>
  <div>
    <input class="hidden" v-model="modelValue" type="radio" data-id="input" :value="props.value" :id="id" />
    <label class="label" :class="classes" :for="id" data-id="content">
      <span v-if="hasTitle" class="px-4"><slot name="title"></slot></span>
      <span v-if="hasIcon" class="px-4"><slot name="icon"></slot></span>
    </label>
  </div>
</template>

<script setup lang="ts">
  import { computed, getCurrentInstance, useSlots } from 'vue';

  const emit = defineEmits(['update:modelValue']);

  const id = `tab-${getCurrentInstance()?.uid}`;

  interface Props {
    value: unknown;
    modelValue?: unknown;
    icon?: boolean;
    title?: string;
    dynamicWidth?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    icon: false,
    dynamicWidth: false,
  });

  const classes = computed(() => ({
    'w-[36px]': props.icon && !props.dynamicWidth,
    'w-[140px]': !props.icon && !props.dynamicWidth,
    'px-12': props.dynamicWidth,
    'text-link h-[50px] border-b-2 border-b-link': props.modelValue === props.value,
    'text-black h-[50px]': props.modelValue !== props.value,
  }));

  const modelValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
  });

  const hasTitle = computed<boolean>(() => {
    return !!useSlots().title;
  });

  const hasIcon = computed<boolean>(() => {
    return !!useSlots().icon;
  });
</script>

<style scoped lang="scss">
  .label {
    @apply flex cursor-pointer items-center justify-center;
  }
</style>
