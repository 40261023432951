<template>
  <div class="md:mx-[80px] max-md:mx-16">
    <div class="min-w-fit">
      <div class="py-12 text-xl text-primary font-bold">{{ t(`orders.existingCustomer`) }}</div>
      <div class="font-bold">{{ t('orders.customer') }}</div>
      <div class="flex items-center gap-32 max-md:flex-col">
        <AgSearchSelect
          :options="customerOptions"
          :placeholder="t('orders.tapToSearch')"
          v-model="selectedCustomerId"
        />
        <AgButton
          @click="createOrderForExistingCustomer"
          :disabled="isDisabledCustomerButton"
          class="basis-1/5"
          variant="secondary"
        >
          {{ t('orders.newOrder') }}
        </AgButton>
      </div>
    </div>
    <div class="min-w-fit" v-if="config.company.code !== 'NORDWAL'">
      <div class="py-12 text-xl text-primary font-bold">{{ t('orders.newCustomer') }}</div>
      <div class="font-bold">{{ t(`orders.priceList`) }}</div>
      <div class="flex items-center gap-32 max-md:flex-col">
        <AgSearchSelect
          :options="priceListOptions"
          v-model="selectedPriceListId"
          :placeholder="t('orders.tapToSearch')"
        />
        <AgButton
          @click="createOrderForNewCustomerUsingPriceList"
          :disabled="isDisabledPriceListsButton"
          class="basis-1/5"
          variant="secondary"
        >
          {{ t('orders.newOrder') }}
        </AgButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { PriceListDto } from '@/domain/masterData/PriceListDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrders } from '@/modules/orders/composables/useOrders';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const { tables, isFullReloading, isLoading } = useMasterData();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.order', 'orders.newOrder');

  const { loadOrderByGid } = useOrderWizardStore();

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const customers = ref<Array<CustomerDto>>([]);
  const priceLists = ref<Array<PriceListDto>>([]);

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  const loadPriceLists = async function () {
    priceLists.value = await tables.priceLists.toArray();
  };

  const customerOptions = computed((): Array<AgSearchSelectOption> => {
    return customers.value.map((c) => {
      const label: string[] = [c.code];
      if (c.searchName) {
        label.push(c.searchName);
      }
      label.push(c.name);

      let address: string = '';
      if (c.addresses && c.addresses.length > 0) {
        c.addresses
          .filter((a) => a.type.includes(CustomerAddressType.BILLING))
          .map((a) => {
            address = a.street + a.city;
            label.push(a.street);
            label.push(a.city);
            if (a.district) {
              address += a.district;
              label.push(a.district);
            }
          });
      }
      return {
        value: c.id,
        label: label.join(' / '),
        searchableString: c.name + c.code + c.fiscalCode + c.vatCode + c.searchName + address,
        disabled: c.blocked || !c.vatCode,
      };
    });
  });

  const priceListOptions = computed((): Array<AgSearchSelectOption> => {
    return priceLists.value.map((c) => {
      return {
        value: c.id,
        label: getTranslatedText(c.title),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const orderFactory = useOrderFactory();
  const orders = useOrders();
  const router = useRouter();

  const selectedCustomerId = ref<number>();

  const isDisabledCustomerButton = computed(() => {
    return !selectedCustomerId.value;
  });

  const selectedPriceListId = ref<number>();

  const isDisabledPriceListsButton = computed(() => {
    return !selectedPriceListId.value;
  });

  const createOrderForExistingCustomer = async function () {
    if (!selectedCustomerId.value) {
      return;
    }
    const customer = customers.value.find((customer) => {
      return customer.id === selectedCustomerId.value;
    });
    if (!customer) {
      return;
    }
    const order = await orderFactory.createOrderForCustomer(customer.code);
    await orders.saveOrder(order);
    await loadOrderByGid(order.gid);
    await router.push({
      name: 'order-wizard',
      params: { gid: order.gid, step: 'customer' },
    });
  };

  const createOrderForNewCustomerUsingPriceList = async function () {
    if (!selectedPriceListId.value) {
      return;
    }
    const customer = orderFactory.createNewEmptyOrderCustomer();
    customer.priceListId = selectedPriceListId.value;

    const order = await orderFactory.createOrderForOrderCustomer(customer);
    await orders.saveOrder(order);

    await router.push({
      name: 'order-wizard',
      params: { gid: order.gid, step: 'customer' },
    });
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
        await loadPriceLists();
      }
    },
    { immediate: true }
  );
</script>
