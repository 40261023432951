<template>
  <svg id="alert_filled_24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Rechteck_6930" data-name="Rechteck 6930" width="24" height="24" fill="none" />
    <circle
      id="Ellipse_407"
      data-name="Ellipse 407"
      cx="9"
      cy="9"
      r="9"
      transform="translate(21 21) rotate(180)"
      fill="#e74668"
    />
    <line
      id="Linie_332"
      data-name="Linie 332"
      y2="6"
      transform="translate(12.027 12.5) rotate(180)"
      fill="none"
      stroke="#fff"
      stroke-width="2"
    />
    <line
      id="Linie_334"
      data-name="Linie 334"
      y2="2"
      transform="translate(12.027 16.5) rotate(180)"
      fill="none"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>
</template>
