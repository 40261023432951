export enum EModules {
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  CUSTOMER_OVERVIEW = 'CUSTOMER_OVERVIEW',
  ARTICLES_OVERVIEW = 'ARTICLES_OVERVIEW',
  PROCESSED_DATA = 'PROCESSED_DATA',
  OPEN_ITEMS = 'OPEN_ITEMS',
  MESSAGES = 'MESSAGES',
  DOWNLOADS = 'DOWNLOADS',
  STATISTICS = 'STATISTICS',
  CONTINGENTS = 'CONTINGENTS',
  CHECKLISTS = 'CHECKLISTS',
}
