<template>
  <svg id="save_disc" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="save" transform="translate(12 12)">
      <g id="Gruppe_3103" data-name="Gruppe 3103" transform="translate(-12 -12)">
        <g id="Bounding_Boxes" data-name="Bounding Boxes">
          <path id="Pfad_7124" data-name="Pfad 7124" d="M0,0H24V24H0Z" fill="none" />
        </g>
        <g id="Outline">
          <g id="Gruppe_3102" data-name="Gruppe 3102">
            <path
              id="Pfad_7125"
              data-name="Pfad 7125"
              d="M16.5,2H4.5a2,2,0,0,0-2,2V20a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2V7Zm3,18H4.5V4H15.7l3.8,3.8Z"
              fill="currentColor"
            />
            <path
              id="Pfad_7126"
              data-name="Pfad 7126"
              d="M11.5,11a3,3,0,1,0,3,3,2.9,2.9,0,0,0-3-3Z"
              fill="none"
              stroke="currentColor"
              stroke-width="1.6"
            />
            <rect
              id="Rechteck_2215"
              data-name="Rechteck 2215"
              width="9"
              height="2"
              transform="translate(5.5 5.5)"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
