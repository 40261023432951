<template>
  <AgBox>
    <div class="flex flex-row gap-16 max-md:flex-col">
      <div class="basis-1/2 flex flex-col gap-4">
        <div class="flex justify-between">
          <span>
            {{ formatUnixTimestamp(fromLastYear.getTime()) }} - {{ formatUnixTimestamp(lastYearToday.getTime()) }}
          </span>
          <span>{{ formatNumberToEuro(props.infoStatistics.totalAmountPeriodOne) }}</span>
        </div>
        <div class="flex justify-between">
          <span>
            {{ formatUnixTimestamp(fromCurrentYear.getTime()) }} - {{ formatUnixTimestamp(today.getTime()) }}
          </span>
          <span>{{ formatNumberToEuro(props.infoStatistics.totalAmountPeriodTwo) }}</span>
        </div>
      </div>

      <div class="w-[0.1em] flex items-center bg-neutral-100 opacity-20 mx-96 max-lg:mx-16 max-lg:hidden">&nbsp;</div>

      <div class="basis-1/2 flex flex-col gap-4">
        <div class="flex justify-between">
          <span>{{ t('revenueGrowthDashboard') }}:</span>
          <span>{{
            formatNumberToEuro(props.infoStatistics.totalAmountPeriodTwo - props.infoStatistics.totalAmountPeriodOne)
          }}</span>
        </div>
        <div class="flex justify-between">
          <span>{{ t('revenueGrowthPercentageDashboard') }}:</span>
          <div class="flex items-center gap-4">
            <div v-if="isNaN(revenueGrowthPercentage)">--</div>
            <div v-else>{{ formatNumberToPercentage(revenueGrowthPercentage) }}</div>
            <div v-if="revenueGrowthPercentage < 0"><IcArrowDown class="text-infoRed w-[15px] h-[15px]" /></div>
            <div v-else-if="revenueGrowthPercentage > 0"><IcArrowUp class="text-success w-[15px] h-[15px]" /></div>
            <div v-else-if="revenueGrowthPercentage == 0">
              <IcArrowEqual class="text-infoYellow w-[15px] h-[15px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import type { StatisticsInfoDto } from '@/domain/StatisticsInfoDto';

  import IcArrowDown from '@/components/icons/IcArrowDown.vue';
  import IcArrowEqual from '@/components/icons/IcArrowEqual.vue';
  import IcArrowUp from '@/components/icons/IcArrowUp.vue';
  import AgBox from '@/components/library/box/AgBox.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    infoStatistics: StatisticsInfoDto;
  }
  const props = defineProps<Props>();

  const { formatNumberToEuro } = useNumberFormatting();
  const { formatNumberToPercentage } = useNumberFormatting();
  const { formatUnixTimestamp } = useDateFormatting();

  const revenueGrowthPercentage = computed(() => {
    if (props.infoStatistics.totalAmountPeriodOne === 0) {
      return NaN;
    } else {
      return (
        Math.round(
          (((props.infoStatistics.totalAmountPeriodTwo - props.infoStatistics.totalAmountPeriodOne) * 100) /
            props.infoStatistics.totalAmountPeriodOne +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
  });

  const fromCurrentYear = new Date(new Date().getFullYear(), 0, 1);
  const today = new Date();
  const lastYear = new Date().getFullYear() - 1;
  const fromLastYear = new Date(lastYear, 0, 1);
  const lastYearToday = new Date(lastYear, today.getMonth(), today.getDate());
</script>

<style lang="scss" scoped>
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }
</style>
