<template>
  <select
    aria-label="AgFormSelect"
    class="focus:ring-blue-500 focus:border-blue-500 w-full rounded border p-8 text-s-20 h-[40px] focus:border-primary focus:outline"
    v-model="internalValue"
    :id="id"
    :class="selectClasses"
    :disabled="props.disabled"
  >
    <option v-if="placeholder" disabled value>
      {{ placeholder }}
    </option>
    <option v-for="option in options" :value="option.key" :key="option.key">
      <span v-if="te(option.label)">{{ t(option.label) }}</span>
      <span v-else>{{ option.label }}</span>
    </option>
  </select>
  <div class="text-s-13 pt-4 italic text-danger" v-if="validationState === false">{{ props.errorMessage }}</div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import { i18n } from '@/plugins/i18n';
  import { computed, getCurrentInstance } from 'vue';

  const { t, te } = i18n.global;

  const id = `form-select-${getCurrentInstance()?.uid}`;

  interface Props {
    options: AgFormSelectOption[];
    placeholder?: string;
    modelValue?: string | number | null;
    variant?: string | null;
    errorMessage?: undefined | null | string;
    validationState?: undefined | null | boolean;
    disabled?: undefined | boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    placeholder: '',
    validationState: null,
    disabled: false,
    variant: undefined,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => {
      return props.modelValue;
    },
    set: (newValue) => {
      emit('update:modelValue', newValue);
    },
  });

  const selectClasses = computed(() => ({
    'border-danger': props.validationState === false,
    'border-neutral-550': props.validationState !== false && props.variant !== 'shoppingCart',
    'border-neutral-300 border-[2px] focus:border-link ': props.variant === 'shoppingCart',
  }));
</script>
