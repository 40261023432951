import { useApi } from '@/composables/api/useApi';
import type { CommunicationDto } from '@/domain/CommunicationDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { Page } from '@/util/Paging';
import { PageImpl, PageRequest } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMessageStore = defineStore('message-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const messages = ref<Page<CommunicationDto>>(new PageImpl([], new PageRequest(10, 1), 0));

  //communications?app_version=2023.1.4512813617&lang=de&pagination.page=2
  async function loadData(language: string, pageRequest: PageRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        lang: `${language}`,
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/communications?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.communications) {
        messages.value = api.getResultPage(container, container.data.communications);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    messages,
    loadData,
  };
});
