import type { RouteRecordRaw } from 'vue-router';

import { config } from '@/config/config';
import { routes as articlesModuleRoutes } from '@/modules/articles/routes';
import { routes as checklistsModuleRoutes } from '@/modules/checklists/routes';
import { routes as contingentsModuleRoutes } from '@/modules/contingents/routes';
import { routes as customersModuleRoutes } from '@/modules/customers/routes';
import { routes as downloadsModuleRoutes } from '@/modules/downloads/routes';
import { routes as messagesModuleRoutes } from '@/modules/messages/routes';
import { routes as offersModuleRoutes } from '@/modules/offers/routes';
import { routes as openItemsModuleRoutes } from '@/modules/open-items/routes';
import { routes as ordersModuleRoutes } from '@/modules/orders/routes';
import { routes as processedDataModuleRoutes } from '@/modules/processed-data/routes';
import { routes as statisticsModuleRoutes } from '@/modules/statistics/routes';
import { routes } from '@/routes';
import { createRouter, createWebHashHistory } from 'vue-router';

function addRoutes(newRoutes: Array<RouteRecordRaw>, path: string) {
  newRoutes.forEach((r) => {
    r.path = path + r.path;
    routes.push(r);
  });
}
if (config.company.modules.ARTICLES_OVERVIEW) {
  addRoutes(articlesModuleRoutes, '/articles');
}
if (config.company.modules.CUSTOMER_OVERVIEW) {
  addRoutes(customersModuleRoutes, '/customers');
}
if (config.company.modules.DOWNLOADS) {
  addRoutes(downloadsModuleRoutes, '/downloads');
}
if (config.company.modules.MESSAGES) {
  addRoutes(messagesModuleRoutes, '/messages');
}
if (config.company.modules.OFFER) {
  addRoutes(offersModuleRoutes, '/offers');
}
if (config.company.modules.ORDER) {
  addRoutes(ordersModuleRoutes, '/orders');
}
if (config.company.modules.OPEN_ITEMS) {
  addRoutes(openItemsModuleRoutes, '/open-items');
}
if (config.company.modules.PROCESSED_DATA) {
  addRoutes(processedDataModuleRoutes, '/processed-data');
}
if (config.company.modules.STATISTICS) {
  addRoutes(statisticsModuleRoutes, '/statistics');
}
if (config.company.modules.CONTINGENTS) {
  addRoutes(contingentsModuleRoutes, '/contingents');
}
if (config.company.modules.CHECKLISTS) {
  addRoutes(checklistsModuleRoutes, '/checklists');
}

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
