<template>
  <div v-if="props.openItems && props.openItems.length > 0">
    <!-- TABLE HEADER -->
    <div class="tableHeader">
      <!-- COLUMN YEAR -->
      <div class="tableColumn" @click.stop="sortYear">
        <span class="px-4">{{ t('openItems.year') }}</span>
        <span v-if="orderYear === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderYear === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN INVOICE NUMBER -->
      <div class="tableColumn" @click.stop="sortInvoiceCode">
        <span class="px-4">{{ t('openItems.invoiceNumber') }}</span>
        <span v-if="orderInvoiceCode === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderInvoiceCode === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN INVOICE DATE -->
      <div class="tableColumn" @click.stop="sortInvoiceDate">
        <span class="px-4">{{ t('openItems.invoiceDate') }}</span>
        <span v-if="orderInvoiceDate === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderInvoiceDate === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN CUSTOMER CODE -->
      <div class="tableColumn" @click.stop="sortCustomerCode">
        <span class="px-4">{{ t('openItems.customerNumber') }}</span>
        <span v-if="orderCustomerCode === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderCustomerCode === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN CUSTOMER NAME -->
      <div class="tableColumn" @click.stop="sortCustomerName">
        <span class="px-4">{{ t('openItems.customerName') }}</span>
        <span v-if="orderCustomerName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderCustomerName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN INVOICE AMOUNT -->
      <div class="tableColumn justify-end" @click.stop="sortInvoiceAmount">
        <span class="px-4">{{ t('openItems.invoiceAmount') }}</span>
        <span v-if="orderInvoiceAmount === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderInvoiceAmount === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN OPEN AMOUNT -->
      <div class="tableColumn justify-end" @click.stop="sortOpenAmount">
        <span class="px-4">{{ t('openItems.openAmount') }}</span>
        <span v-if="orderOpenAmount === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderOpenAmount === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN INVOICE DUE DATE -->
      <div class="tableColumn justify-end" @click.stop="sortDueDate">
        <span class="px-4">{{ t('openItems.invoiceDueDate') }}</span>
        <span v-if="orderDueDate === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderDueDate === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN NOTE -->
      <div class="tableColumn justify-end" @click.stop="sortNote">
        <span class="px-4">{{ t('openItems.note') }}</span>
        <span v-if="orderNote === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderNote === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
    </div>
    <!-- TABLE CONTENT OF OPEN ITEMS -->
    <div v-for="openItem in props.openItems" :key="openItem.id">
      <OpenItemsRow :openItem="openItem" />
    </div>
    <!-- OVERDUE INDICATORS -->
    <div class="py-12 space-y-8">
      <div>
        <span class="mr-8 px-4 bg-infoYellow">&nbsp;</span>
        <span>{{ t('openItems.due') }}</span>
      </div>
      <div>
        <span class="mr-8 px-4 bg-infoOrange">&nbsp;</span>
        <span>{{ t('openItems.oneMonthdue') }}</span>
      </div>
      <div>
        <span class="mr-8 px-4 bg-infoRed">&nbsp;</span>
        <span>{{ t('openItems.sixMonthDue') }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('openItems.notFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import OpenItemsRow from '@/modules/open-items/components/OpenItemsRow.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    openItems: Array<OutstandingDebtDto>;
    modelValue: Sorting;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const orderYear = ref<Order | null>(null);
  const sortYear = () => emit('update:modelValue', getSorting(ColumnName.YEAR));

  const orderInvoiceCode = ref<Order | null>(null);
  const sortInvoiceCode = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_CODE));

  const orderInvoiceDate = ref<Order | null>(null);
  const sortInvoiceDate = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_DATE));

  const orderCustomerCode = ref<Order | null>(null);
  const sortCustomerCode = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_CODE));

  const orderCustomerName = ref<Order | null>(null);
  const sortCustomerName = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_NAME));

  const orderInvoiceAmount = ref<Order | null>(null);
  const sortInvoiceAmount = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_AMOUNT));

  const orderOpenAmount = ref<Order | null>(null);
  const sortOpenAmount = () => emit('update:modelValue', getSorting(ColumnName.OPEN_AMOUNT));

  const orderDueDate = ref<Order | null>(null);
  const sortDueDate = () => emit('update:modelValue', getSorting(ColumnName.DUE_DATE));

  const orderNote = ref<Order | null>(null);
  const sortNote = () => emit('update:modelValue', getSorting(ColumnName.NOTE));

  enum ColumnName {
    YEAR = 'year',
    INVOICE_CODE = 'invoiceCode',
    INVOICE_DATE = 'invoiceDate',
    CUSTOMER_CODE = 'customerCode',
    CUSTOMER_NAME = 'customerName',
    INVOICE_AMOUNT = 'invoiceAmount',
    OPEN_AMOUNT = 'openAmount',
    DUE_DATE = 'dueDate',
    NOTE = 'note',
  }

  const getSorting = (column: ColumnName): Sorting => {
    if (column === ColumnName.YEAR) {
      if (orderYear.value === Order.ASC) {
        orderYear.value = Order.DESC;
      } else {
        orderYear.value = Order.ASC;
      }
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.YEAR, orderYear.value);
    } else if (column === ColumnName.INVOICE_CODE) {
      if (orderInvoiceCode.value === Order.ASC) {
        orderInvoiceCode.value = Order.DESC;
      } else {
        orderInvoiceCode.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_CODE, orderInvoiceCode.value);
    } else if (column === ColumnName.INVOICE_DATE) {
      if (orderInvoiceDate.value === Order.ASC) {
        orderInvoiceDate.value = Order.DESC;
      } else {
        orderInvoiceDate.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_DATE, orderInvoiceDate.value);
    } else if (column === ColumnName.CUSTOMER_CODE) {
      if (orderCustomerCode.value === Order.ASC) {
        orderCustomerCode.value = Order.DESC;
      } else {
        orderCustomerCode.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.CUSTOMER_CODE, orderCustomerCode.value);
    } else if (column === ColumnName.CUSTOMER_NAME) {
      if (orderCustomerName.value === Order.ASC) {
        orderCustomerName.value = Order.DESC;
      } else {
        orderCustomerName.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.CUSTOMER_NAME, orderCustomerName.value);
    } else if (column === ColumnName.INVOICE_AMOUNT) {
      if (orderInvoiceAmount.value === Order.ASC) {
        orderInvoiceAmount.value = Order.DESC;
      } else {
        orderInvoiceAmount.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_AMOUNT, orderInvoiceAmount.value);
    } else if (column === ColumnName.OPEN_AMOUNT) {
      if (orderOpenAmount.value === Order.ASC) {
        orderOpenAmount.value = Order.DESC;
      } else {
        orderOpenAmount.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.OPEN_AMOUNT, orderOpenAmount.value);
    } else if (column === ColumnName.DUE_DATE) {
      if (orderDueDate.value === Order.ASC) {
        orderDueDate.value = Order.DESC;
      } else {
        orderDueDate.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.DUE_DATE, orderDueDate.value);
    } else if (column === ColumnName.NOTE) {
      if (orderNote.value === Order.ASC) {
        orderNote.value = Order.DESC;
      } else {
        orderNote.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;

      return new Sorting(ColumnName.NOTE, orderNote.value);
    } else {
      // SORTING DEFAULT VALUE INVOICE DATE
      return new Sorting(ColumnName.INVOICE_DATE, Order.ASC);
    }
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE INVOICE DATE
    orderInvoiceDate.value = Order.ASC;

    orderYear.value = null;
    orderInvoiceCode.value = null;
    orderCustomerCode.value = null;
    orderCustomerName.value = null;
    orderInvoiceAmount.value = null;
    orderOpenAmount.value = null;
    orderDueDate.value = null;
    orderNote.value = null;
  };

  onMounted(defaultSorting);
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[80px_140px_150px_130px_minmax(200px,1fr)_165px_165px_150px_minmax(200px,1fr)] min-h-48;
    @apply items-center font-light text-neutral-550;
  }

  .tableColumn {
    @apply flex items-center gap-4 hover:cursor-pointer;
  }

  .signal {
    padding: 0 0 0 6px;
    margin: 0 0.7em 0 0;
  }
</style>
