<template>
  <div>
    <div class="w-fit mt-32 mx-auto" v-if="isLoading">
      <IcLoading />
    </div>
    <div v-else-if="actvities.length > 0">
      <TheSubheader class="bg-white"> </TheSubheader>
      <CustomerActivitiesFilter v-model:state="state" v-model:category="category" />
      <div class="tableHeader">
        <div class="headerColumn">
          <span> {{ t('customers.state') }} </span>
        </div>
        <div class="headerColumn">
          <span> {{ t('customers.generalStatus') }}* </span>
        </div>
        <div class="headerColumn" @click="sort()">
          <span> {{ t('customers.checklistsDueDate') }} </span>
          <span v-if="sorting.order === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else-if="sorting.order === Order.ASC" class="h-12 w-12">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <div class="headerColumn">
          <span> {{ t('customers.checklistsCategory') }} </span>
        </div>
        <div class="headerColumn">
          <span> {{ t('customers.checklisistDescription') }} </span>
        </div>
      </div>

      <div v-for="activity in displayedActivities" :key="activity.activityId">
        <div class="table">
          <div class="tableColumn">
            <div>
              <IcCheck v-if="activity.customerCompleted" class="h-24 w-24 fill-success" />
              <IcClose v-else class="h-24 w-24 text-infoRed" />
            </div>
          </div>
          <div class="tableColumn">
            <div>
              <IcCheck v-if="activity.completed" class="h-24 w-24 fill-success" />
              <IcClose v-else class="h-24 w-24 text-infoRed" />
            </div>
          </div>
          <div class="tableColumn" :class="{ 'text-infoRed font-bold': isExpired(activity) }">
            {{ formatDate(activity.dueDate) }}
          </div>
          <div class="tableColumn break-all">
            {{ activity.category }}
          </div>
          <div class="tableColumn break-all">
            {{ activity.description }}
          </div>
          <div class="tableColumn cursor-pointer" @click="goToActivity(activity)">
            <IcShow />
          </div>
        </div>
      </div>
      <div class="mt-24 italic font-light">*&nbsp;{{ t('customers.generalStatusReminder') }}</div>
    </div>
    <div v-else class="py-24">
      <AgAlert variant="info">{{ t('customers.noChecklists') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ChecklistDto } from '@/domain/ChecklistDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import CustomerActivitiesFilter from '@/modules/customers/components/checklists/CustomerActivitiesFilter.vue';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcShow from '@/components/icons/IcShow.vue';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useCustomerChecklistsStore } from '@/modules/customers/composables/useChecklistsStore';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const store = useCustomerChecklistsStore();
  const { getActivities } = store;
  const { actvities } = storeToRefs(store);
  const { t } = i18n.global;
  const { formatDate } = useDateFormatting();

  interface Props {
    customerId: number;
  }

  const props = defineProps<Props>();

  enum ColumnName {
    DUE_DATE = 'dueDate',
  }

  const isLoading = ref<boolean>(true);
  const state = ref<number>(-1);
  const category = ref<number>(-1);
  const sorting = ref<Sorting>(new Sorting(ColumnName.DUE_DATE, Order.DESC));
  const displayedActivities = ref<Array<ChecklistDto>>([]);

  const isExpired = function (activity: ChecklistDto) {
    return activity.dueDate * 1000 < Date.now();
  };

  const goToActivity = async (activity: ChecklistDto) => {
    if (activity.completed) {
      await router.push({
        name: 'activity/completed',
        params: { agentActivityId: activity.agentActivityId },
      });
    } else if (isExpired(activity)) {
      await router.push({
        name: 'activity/expired',
        params: { agentActivityId: activity.agentActivityId },
      });
    } else {
      await router.push({
        name: 'activity/open',
        params: { agentActivityId: activity.agentActivityId },
      });
    }
  };

  const sort = function () {
    if (sorting.value.order === Order.ASC) {
      sorting.value.order = Order.DESC;
      displayedActivities.value = displayedActivities.value.sort((a, b) => b.dueDate - a.dueDate);
    } else {
      sorting.value.order = Order.ASC;
      displayedActivities.value = displayedActivities.value.sort((a, b) => a.dueDate - b.dueDate);
    }
  };

  watch([state, category], (newValue) => {
    if (newValue[0] === -1) {
      displayedActivities.value = actvities.value;
    } else if (newValue[0] === 0) {
      displayedActivities.value = actvities.value.filter(
        (activity) => activity.customerCompleted === false && !isExpired(activity)
      );
    } else if (newValue[0] === 1) {
      displayedActivities.value = actvities.value.filter((activity) => activity.customerCompleted === true);
    } else if (newValue[0] === 2) {
      displayedActivities.value = actvities.value.filter(
        (activity) => isExpired(activity) && activity.customerCompleted === false
      );
    }
    if (newValue[1] !== -1) {
      displayedActivities.value = actvities.value.filter((activity) => activity.categoryId === newValue[1]);
    }
  });

  onMounted(() => {
    getActivities(props.customerId).then(() => {
      displayedActivities.value = actvities.value;
      isLoading.value = false;
    });
  });
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[80px_80px_minmax(133px,_233px)_minmax(133px,_233px)_minmax(133px,_1fr)_33px] gap-8 py-4 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid grid-cols-[80px_80px_minmax(133px,_233px)_minmax(133px,_233px)_minmax(133px,_1fr)_33px] gap-8 py-4 border-t border-neutral-500;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .table:hover {
    @apply bg-neutral-200;
  }
</style>
