<!-- eslint-disable max-len -->
<template>
  <svg id="ic_pin" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_3051" data-name="Rechteck 3051" width="20" height="20" fill="none" />
    <path
      id="Icon_material-location-on"
      data-name="Icon material-location-on"
      d="M11.963,3A4.46,4.46,0,0,0,7.5,7.463c0,3.347,4.463,8.288,4.463,8.288s4.463-4.941,4.463-8.288A4.46,4.46,0,0,0,11.963,3Z"
      transform="translate(-1.963 0.036)"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle
      id="Ellipse_230"
      data-name="Ellipse 230"
      cx="2"
      cy="2"
      r="2"
      transform="translate(8 5)"
      fill="currentColor"
    />
  </svg>
</template>
