import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
import type { ArticleDto } from '@/domain/masterData/ArticleDto';
import type { ArticleExternalCodeDto } from '@/domain/masterData/ArticleExternalCodeDto';
import type { ArticleGroupDto } from '@/domain/masterData/ArticleGroupDto';
import type { ArticleTypeDto } from '@/domain/masterData/ArticleTypeDto';
import type { BusinessCategoryDto } from '@/domain/masterData/BusinessCategoryDto';
import type { ContingentDto } from '@/domain/masterData/ContingentDto';
import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';
import type { CountryDto } from '@/domain/masterData/CountryDto';
import type { CustomerDto } from '@/domain/masterData/CustomerDto';
import type { DeliveryMethodDto } from '@/domain/masterData/DeliveryMethodDto';
import type { DeliveryTermDto } from '@/domain/masterData/DeliveryTermDto';
import type { DepotDto } from '@/domain/masterData/DepotDto';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { PaymentMethodDto } from '@/domain/masterData/PaymentMethodDto';
import type { PaymentTermDto } from '@/domain/masterData/PaymentTermDto';
import type { PriceGroupDto } from '@/domain/masterData/PriceGroupDto';
import type { PriceListDto } from '@/domain/masterData/PriceListDto';
import type { TaxDto } from '@/domain/masterData/TaxDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';
import Dexie from 'dexie';

export class MasterData extends Dexie {
  customers!: Dexie.Table<CustomerDto, number>;
  deliveryMethods!: Dexie.Table<DeliveryMethodDto, number>;
  deliveryTerms!: Dexie.Table<DeliveryTermDto, number>;
  paymentMethods!: Dexie.Table<PaymentMethodDto, number>;
  paymentTerms!: Dexie.Table<PaymentTermDto, number>;
  priceLists!: Dexie.Table<PriceListDto, number>;
  priceGroups!: Dexie.Table<PriceGroupDto, number>;
  tradeAgreements!: Dexie.Table<TradeAgreementDto, number>;
  articles!: Dexie.Table<ArticleDto, number>;
  discounts!: Dexie.Table<DiscountDto, number>;
  businessCategories!: Dexie.Table<BusinessCategoryDto, number>;
  countries!: Dexie.Table<CountryDto, number>;
  timestamps!: Dexie.Table<{ id: string; value?: number | null }, string>;
  articleExternalCodes!: Dexie.Table<ArticleExternalCodeDto, number>;
  articleGroups!: Dexie.Table<ArticleGroupDto, number>;
  articleTypes!: Dexie.Table<ArticleTypeDto, number>;
  articleDimensionValueConfigs!: Dexie.Table<ArticleDimensionValueConfigsDto, number>;
  contingentGroupArticles!: Dexie.Table<ContingentGroupArticleDto, number>;
  contingents!: Dexie.Table<ContingentDto, number>;
  taxes!: Dexie.Table<TaxDto, number>;
  discountLists!: Dexie.Table<PriceListDto, number>;
  depots!: Dexie.Table<DepotDto, number>;

  constructor() {
    super('a2gMasterData');

    /**
     * IMPORTANT NOTE:
     * Do not alter the stores of a version which is already in production!
     * If you do this, every user has to destroy and recreate the db to activate the new store definition.
     * If you want to alter the stores, create a new version!
     * @see http://dexie.org/docs/Version/Version.upgrade()
     */

    this.version(1).stores({
      customers: '&id,code,name,invoiceCode,hash',
      deliveryMethods: '&id,hash',
      deliveryTerms: '&id,hash',
      paymentMethods: '&id,hash',
      paymentTerms: '&id,hash',
      priceLists: '&id,hash',
      tradeAgreements: '&id,priceListCode,customerId,customerType,hash',
      articles: '&id,hash',
      discounts: '&id,type,hash',
      businessCategories: '&id,hash',
      countries: '&id,hash',
      timestamps: '&id',
    });

    this.version(2).stores({
      articleExternalCodes: '&id,hash',
      articleGroups: '&id,hash',
      articleTypes: '&id,hash',
    });

    this.version(3).stores({
      articleExternalCodes: '&id,articleId,customerId,hash',
    });

    this.version(4).stores({
      articleExternalCodes: '&id,articleId,customerId,[articleId+customerId],hash',
    });

    this.version(5).stores({
      articleDimensionValueConfigs: '&id,hash',
    });

    this.version(6).stores({
      priceGroups: '&id,hash',
    });

    this.version(7).stores({
      discounts: '&id,type,customerType,[type+customerType],hash',
    });

    this.version(8).stores({
      discounts: '&id,type,customerType,customerId,[type+customerType],[type+customerType+customerId],hash',
    });

    this.version(9).stores({
      tradeAgreements: '&id,priceListId,priceGroupId,articleId,customerId,customerType,hash',
    });

    this.version(10).stores({
      tradeAgreements: '&id,[customerType+customerId],[customerType+priceListId],hash',
    });

    this.version(11).stores({
      tradeAgreements: '&id,customerType,[customerType+customerId],[customerType+priceListId],hash',
      discounts:
        '&id,[type+customerType+articleId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],hash',
    });

    this.version(12).stores({
      tradeAgreements:
        '&id,customerType,[customerType+customerId],[customerType+priceListId],[customerType+articleId],[customerType+customerId+articleId],[customerType+priceListId+articleId],[customerType+customerId+priceGroupId],[customerType+priceListId+priceGroupId],hash',
      discounts:
        '&id,[type+customerType+articleId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],hash',
    });

    this.version(13).stores({
      tradeAgreements:
        '&id,customerType,[customerType+customerId],[customerType+priceListId],[customerType+articleId],[customerType+customerId+articleId],[customerType+priceListId+articleId],[customerType+customerId+priceGroupId],[customerType+priceListId+priceGroupId],hash',
      discounts:
        '&id,[type+customerType+articleId+fromQuantity],[type+customerType+customerId+articleId+fromQuantity],[type+customerType+priceListId+articleId+fromQuantity],[type+customerType+customerId+priceGroupId+fromQuantity],[type+customerType+priceListId+priceGroupId+fromQuantity],hash',
    });

    this.version(14).stores({
      discounts:
        '&id,fromQuantity,[type+customerType+articleId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],hash',
    });

    this.version(15).stores({
      discounts:
        '&id,fromQuantity,[type+customerType+customerId],[type+customerType+articleId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],hash',
    });

    this.version(16).stores({
      contingentGroupArticles: '&id,hash',
      contingents: '&id,hash,agentId,customerId,contingentGroupArticleId,articleDimensionValueConfigId',
    });

    this.version(17).stores({
      contingents:
        '&id,hash,agentId,customerId,articleDimensionValueConfigId,contingentGroupArticleId,[agentId+customerId+contingentGroupArticleId+articleDimensionValueConfigId],[agentId+contingentGroupArticleId+articleDimensionValueConfigId]',
    });

    this.version(18).stores({
      discounts:
        '&id,hash,fromQuantity,[type+customerType+customerId],[type+customerType+priceListId],[type+customerType+articleId],[type+customerType+priceGroupId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId]',
    });

    this.version(19).stores({
      articles: '&id,hash,contingentGroupId',
    });

    this.version(20).stores({
      taxes: '&id,hash,[customerTaxCode+articleTaxCode]',
    });

    this.version(21).stores({
      discounts:
        '&id,hash,fromQuantity,[type+customerType+customerId],[type+customerType+priceListId],[type+customerType+articleId],[type+customerType+priceGroupId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],[type+customerType+priceDiscountId+articleId],[type+customerType+priceDiscountId+priceGroupId],[type+customerType+priceDiscountId]',
    });

    this.version(22).stores({
      discountLists: '&id, hash',
    });

    this.version(23).stores({
      discounts:
        '&id,hash,fromQuantity,[type+customerType+customerId],[type+customerType+priceListId],[type+customerType+articleId],[type+customerType+priceGroupId],[type+customerType+customerId+articleId],[type+customerType+priceListId+articleId],[type+customerType+customerId+priceGroupId],[type+customerType+priceListId+priceGroupId],[type+customerType+discountListId+articleId],[type+customerType+discountListId+priceGroupId],[type+customerType+discountListId]',
    });

    this.version(24).stores({
      depots: '&id,hash,code,[code+default]',
    });
  }
}
