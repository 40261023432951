import { config } from '@/config/config';
import { useMasterData } from './useMasterData';
import { useOfferData } from './useOfferData';
import { useOrderData } from './useOrderData';
import { useUserData } from './useUserData';

let timeOut: number | null = null;

export function useSyncData() {
  const masterData = useMasterData();
  const userData = useUserData();
  const orderData = useOrderData();
  const offerData = useOfferData();

  const syncData = function () {
    userData.syncUserHierarchy();
    if (config.company.modules.ORDER) {
      orderData.syncOrders();
    }
    if (config.company.modules.OFFER) {
      offerData.syncOffers();
    }
  };

  const clearSyncData = function () {
    masterData.clearSyncMasterData();
    if (config.company.modules.ORDER) {
      orderData.clearSyncOrders();
    }
    if (config.company.modules.OFFER) {
      offerData.clearSyncOffers();
    }
  };

  const oneShotSyncAllData = function () {
    if (timeOut === null) {
      timeOut = window.setTimeout(() => {
        masterData.syncMasterData();
        syncData();
      }, 1 * 1000);
    }
  };

  const clearOneShotSyncData = function () {
    if (timeOut !== null) {
      clearInterval(timeOut);
      timeOut = null;
    }
  };

  return {
    syncData,
    oneShotSyncAllData,
    clearSyncData,
    clearOneShotSyncData,
  };
}
