<template>
  <svg id="ic_data" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4577" width="20" height="20" fill="none" />
    <line
      id="Linie_356"
      data-name="Linie 356"
      x2="8.081"
      transform="translate(5.926 11.592)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_357"
      data-name="Linie 357"
      x2="8.081"
      transform="translate(5.926 14.466)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14639"
      data-name="Pfad 14639"
      d="M14.8,17.342H5.169A1.444,1.444,0,0,1,3.725,15.9V4.156A1.444,1.444,0,0,1,5.169,2.713H14.8a1.444,1.444,0,0,1,1.444,1.444V15.9A1.444,1.444,0,0,1,14.8,17.342Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <circle
      id="Ellipse_428"
      data-name="Ellipse 428"
      cx="1.648"
      cy="1.648"
      r="1.648"
      transform="translate(6.586 5.847)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
