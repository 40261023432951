<template>
  <div>
    <div class="mb-8 flex flex-row items-center justify-between">
      <router-link to="/offers/all" class="router-link" :title="t('offers.overview')">
        {{ t('offerEntryDashboard') }}
      </router-link>
      <div class="flex flex-row items-center">
        <span class="font-normal mr-[16px] opacity-20">|</span>
        <router-link to="/offers/new" class="router-link" :title="t('offers.createNew')"><IcAdd></IcAdd></router-link>
      </div>
    </div>
    <DashboardWidgetOffersTable />
  </div>
</template>

<script setup lang="ts">
  import IcAdd from '@/components/icons/IcAdd.vue';
  import DashboardWidgetOffersTable from '@/modules/offers/components/dashbord-widget/table/DashboardWidgetOffersTable.vue';

  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;
</script>

<style scoped lang="scss">
  .router-link {
    @apply text-xl font-bold text-white;
  }
</style>
