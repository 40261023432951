<template>
  <div ref="collapsible">
    <div class="hover:cursor-pointer flex-row-reverse" :class="getClasses" @click="toggleCollapse">
      <div v-if="showIcon" class="flex items-center">
        <IcChevronDown class="upDown" :class="getIconClasses" />
      </div>
      <div :class="{ 'w-full pl-4': showIcon }">
        <slot name="header"></slot>
      </div>
    </div>
    <div v-if="!collapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';

  import { computed, ref, watch, watchEffect } from 'vue';

  interface Props {
    collapse?: boolean;
    disabledAutoCollapse?: boolean;
    showIcon?: boolean;
    showBorder?: boolean;
    colorIcon?: string | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    collapse: true,
    disabledAutoCollapse: false,
    showIcon: false,
    showBorder: false,
  });

  const collapsible = ref();
  const collapsed = ref<boolean>(true);

  const toggleCollapse = () => (collapsed.value = !collapsed.value);

  const getClasses = computed(() => ({
    'font-bold': !collapsed.value,
    'hover:bg-neutral-200': props.showIcon && !props.colorIcon,
    'flex flex-row px-4': props.showIcon,
    'border-b border-neutral-200': collapsed.value && props.showBorder,
  }));

  const getIconClasses = computed(() => ({
    rotate: !collapsed.value,
    [`text-${props.colorIcon}`]: props.colorIcon,
  }));

  defineExpose({ collapsed });

  watch(
    () => props.collapse,
    (newValue) => (collapsed.value = newValue),
    { immediate: true }
  );

  // Collapse the component when clicking outside the element
  watchEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (props.disabledAutoCollapse) {
        return;
      }

      if (event && collapsible.value) {
        const notContains = !collapsible.value.contains(event.target);
        if (!collapsed.value && notContains) {
          collapsed.value = true;
        }
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });
</script>

<style scoped lang="scss">
  .upDown {
    transition: transform 0.3s ease-in-out;
  }

  .upDown.rotate {
    transform: rotate(180deg);
  }
</style>
