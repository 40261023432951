import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDimensionStore = defineStore('dimension-store', () => {
  const dimensionValueConfigs = ref<Array<ArticleDimensionValueConfigsDto>>([]);

  const masterData = useMasterData();

  async function loadDimensionValueConfigs() {
    dimensionValueConfigs.value = await masterData.tables.articleDimensionValueConfigs.toArray();
  }

  return {
    loadDimensionValueConfigs,
    dimensionValueConfigs,
  };
});
