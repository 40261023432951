<!-- eslint-disable max-len -->
<template>
  <svg id="ic_fastback" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Pfad_11632"
      data-name="Pfad 11632"
      d="M10.59,0,6,4.58,1.41,0,0,1.41l6,6,6-6Z"
      transform="translate(10.98 4) rotate(90)"
      fill="currentColor"
    />
    <path
      id="Pfad_11633"
      data-name="Pfad 11633"
      d="M10.59,0,6,4.58,1.41,0,0,1.41l6,6,6-6Z"
      transform="translate(16.262 4) rotate(90)"
      fill="currentColor"
    />
    <path
      id="Pfad_415"
      data-name="Pfad 415"
      d="M2.286,0H17.714A2.286,2.286,0,0,1,20,2.286V17.714A2.286,2.286,0,0,1,17.714,20H2.286A2.286,2.286,0,0,1,0,17.714V2.286A2.286,2.286,0,0,1,2.286,0Z"
      fill="none"
    />
  </svg>
</template>
