import type { OrderDto } from '@/domain/orderData/OrderDto';

import { useOrderData } from '@/composables/data/useOrderData';
import { useHierarchy } from '@/composables/useHierarchy';
import { OrderStatus } from '@/domain/enumeration/OrderStatus';
import { Optional } from '@/util/Optional';
import { cloneDeep } from 'lodash';

export function useOrders() {
  const { tables } = useOrderData();
  const { getAllHierarchy } = useHierarchy();

  const saveOrder = async function (order: OrderDto) {
    const probe = await tables.orders.get(order.gid);

    if (probe && probe.status === OrderStatus.COMPLETED) {
      return;
    }
    await tables.orders.put(order);
  };

  const deleteOrder = async function (order: OrderDto) {
    if (order.status === OrderStatus.COMPLETED) {
      return;
    }
    const deleteCopy = cloneDeep(order);

    await Promise.all([tables.orders.delete(order.gid), tables.deletedOrders.put(deleteCopy)]);
  };

  const getOrderByGid = async function (gid: string) {
    return Optional.of(await tables.orders.get(gid));
  };

  const listOrdersByStatus = async function (status: number) {
    if (status === OrderStatus.ALL) {
      return await tables.orders.orderBy('createdAt').reverse().toArray();
    } else if (status === OrderStatus.DRAFT) {
      const orders = await tables.orders.where('status').equals(OrderStatus.DRAFT).sortBy('createdAt');
      return orders
        .concat(await tables.orders.where('[status+synced]').equals([OrderStatus.COMPLETED, 0]).sortBy('createdAt'))
        .reverse();
    } else {
      const orders = await tables.orders.where('[status+synced]').equals([status, 1]).sortBy('createdAt');
      if (orders && orders.length > 0) {
        return orders.reverse();
      }
      return [];
    }
  };

  const getSizeOrdersByStatus = async function (status: number) {
    const hierarchy = await getAllHierarchy();
    const agentId = hierarchy[0]?.authUser?.id;

    if (status === OrderStatus.ALL) {
      const query = tables.orders.where('status').equals(status);
      if (agentId) {
        query.filter((order) => order.agentId === agentId);
      }
      return await query.count();
    } else if (status === OrderStatus.DRAFT) {
      const query = tables.orders.where('status').equals(OrderStatus.DRAFT);
      if (agentId) {
        query.filter((order) => order.agentId === agentId);
      }
      const ordersOpened = await query.count();

      const queryCompletedNotSynced = tables.orders.where('[status+synced]').equals([OrderStatus.COMPLETED, 0]);
      if (agentId) {
        queryCompletedNotSynced.filter((order) => order.agentId === agentId);
      }
      const ordersCompletedNotSynced = await queryCompletedNotSynced.count();

      return ordersOpened + ordersCompletedNotSynced;
    } else if (status === OrderStatus.COMPLETED) {
      const query = tables.orders.where('[status+synced]').equals([status, 1]);
      if (agentId) {
        query.filter((order) => order.agentId === agentId);
      }
      return await query.count();
    }
    return 0;
  };

  const listOrdersByCustomerVatCode = async function (vatCode: string) {
    return await tables.orders.where('customer.vatCode').equals(vatCode).toArray();
  };

  return {
    saveOrder,
    deleteOrder,
    getOrderByGid,
    listOrdersByStatus,
    getSizeOrdersByStatus,
    listOrdersByCustomerVatCode,
  };
}
