<template>
  <svg id="ic_edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Rechteck_3648" data-name="Rechteck 3648" width="24" height="24" fill="none" />
    <path
      id="Icon_feather-edit-2"
      data-name="Icon feather-edit-2"
      d="M14.147,2.842a2.29,2.29,0,0,1,3.239,3.239L6.454,17.014,2,18.229l1.215-4.454Z"
      transform="translate(1.5 1.329)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>
