<template>
  <div>
    <textarea
      aria-label="AgFormTextarea"
      class="block w-full border p-12 text-s-20 focus:border-link focus:outline"
      v-model="internalValue"
      :id="id"
      :placeholder="placeholder"
      :class="textareaClasses"
      :rows="rows"
      :maxlength="maxLenght"
      :disabled="props.disabled"
    ></textarea>
  </div>
  <div class="text-s-13 pt-4 italic text-danger" v-if="validationState === false">{{ props.errorMessage }}</div>
</template>

<script setup lang="ts">
  import { computed, getCurrentInstance } from 'vue';

  const id = `form-textarea-${getCurrentInstance()?.uid}`;

  interface Props {
    modelValue?: string | number | string[];
    placeholder?: string;
    validationState?: undefined | null | boolean;
    errorMessage?: undefined | null | string;
    variant?: null | string;
    rows?: string | number;
    maxLenght?: number | undefined;
    resize?: boolean | undefined;
    disabled?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    placeholder: '',
    disabled: false,
    validationState: null,
    errorMessage: undefined,
    maxLenght: undefined,
    resize: undefined,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const textareaClasses = computed(() => ({
    'border-danger': props.validationState === false,
    'border-neutral-550': props.validationState !== false && props.variant !== 'shoppingCart',
    'resize-none': props.resize === false,
    'overflow-y-scroll': props.resize === false,
    'border-neutral-300 border-[2px] focus:border-link': props.variant === 'shoppingCart',
    'text-neutral-600 border-neutral-100 bg-neutral-100': props.disabled,
  }));
</script>
