<template>
  <div class="md:px-[80px] max-md:px-16 py-12 flex w-full items-center justify-between gap-32">
    <AgFormGroup :label="t('offers.search')" class="w-1/2">
      <AgSearchInput v-model="internalOfferFilter" />
    </AgFormGroup>
    <AgFormGroup :label="t('offers.status')" class="w-1/2">
      <AgFormSelect v-model="internalOfferStatus" :options="options" />
    </AgFormGroup>
    <AgFormGroup v-if="agents.length > 1" :label="t('offers.agent')" class="min-w-128">
      <AgFormSelect :options="agents" v-model="internalAgent" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import { useHierarchy } from '@/composables/useHierarchy';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { i18n } from '@/plugins/i18n';
  import { computed, onBeforeMount, ref } from 'vue';

  const { t } = i18n.global;

  const { resolveHierarchyUsers } = useHierarchy();

  interface Props {
    offerFilter: string;
    offerStatus: OfferStatus;
    agentId: number | undefined;
  }
  const props = withDefaults(defineProps<Props>(), {
    offerFilter: '',
    offerStatus: OfferStatus.DRAFT,
  });

  const emit = defineEmits(['update:offerFilter', 'update:offerStatus', 'update:agentId']);

  const agents = ref<Array<AgFormSelectOption>>([]);

  const options = ref<Array<AgFormSelectOption>>([
    { key: OfferStatus.DRAFT, label: 'offers.draft' },
    { key: OfferStatus.COMPLETED, label: 'offers.completed' },
    { key: OfferStatus.ALL, label: 'offers.all' },
  ]);

  const internalOfferFilter = computed({
    get: () => props.offerFilter,
    set: (value) => emit('update:offerFilter', value),
  });

  const internalOfferStatus = computed({
    get: () => props.offerStatus,
    set: (value) => emit('update:offerStatus', value),
  });

  const internalAgent = computed({
    get: () => {
      if (!props.agentId) {
        if (agents.value.length > 0) {
          return agents.value[0].key;
        }
      }
      return props.agentId;
    },
    set: (value) => {
      if (value === -1) {
        emit('update:agentId');
      } else {
        emit('update:agentId', value);
      }
    },
  });

  const loadHierarchyList = async function () {
    const hierarchyUserList = await resolveHierarchyUsers();

    if (hierarchyUserList.length > 1) {
      agents.value.push({ label: t('offers.allAgents'), key: -1 });

      hierarchyUserList.forEach((currentUser) => {
        agents.value.push({ label: currentUser.name, key: currentUser.id });
      });
    }
  };

  onBeforeMount(async () => {
    await loadHierarchyList();
  });
</script>
