<template>
  <div v-if="props.openItems && props.openItems.length > 0">
    <!-- TABLE HEADER -->
    <div class="tableHeader">
      <!-- COLUM AGENT CODE -->
      <div class="tableColumn" @click.stop="sortAgentCode">
        <span class="px-4">{{ t('openItems.agentCode') }}</span>
        <span v-if="orderAgentCode === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderAgentCode === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM AGENT NAME -->
      <div class="tableColumn" @click.stop="sortAgentName">
        <span class="px-4">{{ t('openItems.agent') }}</span>
        <span v-if="orderAgentName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderAgentName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM INVOICE COUNT -->
      <div class="tableColumn right" @click.stop="sortInvoiceCount">
        <span class="px-4">{{ t('openItems.numberOfInvoices') }}</span>
        <span v-if="orderInvoiceCount === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderInvoiceCount === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM OPEN AMOUNT SUM -->
      <div class="tableColumn right" @click.stop="sortOpenAmountSum">
        <span class="px-4">{{ t('openItems.openAmount') }}</span>
        <span v-if="orderOpenAmountSum === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderOpenAmountSum === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <div class="tableColumn">&nbsp;</div>
    </div>
    <!-- TABLE CONTENT OF OPEN ITEMS -->
    <OpenItemsByAgentRow
      v-for="openItemAgent in props.openItems"
      :key="openItemAgent.agentCode"
      :openItem="openItemAgent"
      :sorting="props.modelValue"
    />
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('openItems.notFoundByAgent') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtAgentDto } from '@/domain/OutstandingDebtAgentDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import OpenItemsByAgentRow from '@/modules/open-items/components/by-agent/OpenItemsByAgentRow.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    openItems: Array<OutstandingDebtAgentDto>;
    modelValue: Sorting;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const orderAgentCode = ref<Order | null>(null);
  const sortAgentCode = () => emit('update:modelValue', getSorting(ColumnName.AGENT_CODE));

  const orderAgentName = ref<Order | null>(null);
  const sortAgentName = () => emit('update:modelValue', getSorting(ColumnName.AGENT_NAME));

  const orderInvoiceCount = ref<Order | null>(null);
  const sortInvoiceCount = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_COUNT));

  const orderOpenAmountSum = ref<Order | null>(null);
  const sortOpenAmountSum = () => emit('update:modelValue', getSorting(ColumnName.OPEN_AMOUNT_SUM));

  enum ColumnName {
    AGENT_CODE = 'agentCode',
    AGENT_NAME = 'agentName',
    INVOICE_COUNT = 'invoiceCount',
    OPEN_AMOUNT_SUM = 'openAmountSum',
  }

  const getSorting = (colum: ColumnName): Sorting => {
    if (colum === ColumnName.AGENT_CODE) {
      if (orderAgentCode.value === Order.ASC) {
        orderAgentCode.value = Order.DESC;
      } else {
        orderAgentCode.value = Order.ASC;
      }
      orderAgentName.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.AGENT_CODE, orderAgentCode.value);
    } else if (colum === ColumnName.AGENT_NAME) {
      if (orderAgentName.value === Order.ASC) {
        orderAgentName.value = Order.DESC;
      } else {
        orderAgentName.value = Order.ASC;
      }
      orderAgentCode.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.AGENT_NAME, orderAgentName.value);
    } else if (colum === ColumnName.INVOICE_COUNT) {
      if (orderInvoiceCount.value === Order.ASC) {
        orderInvoiceCount.value = Order.DESC;
      } else {
        orderInvoiceCount.value = Order.ASC;
      }
      orderAgentCode.value = null;
      orderAgentName.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.INVOICE_COUNT, orderInvoiceCount.value);
    } else if (colum === ColumnName.OPEN_AMOUNT_SUM) {
      if (orderOpenAmountSum.value === Order.ASC) {
        orderOpenAmountSum.value = Order.DESC;
      } else {
        orderOpenAmountSum.value = Order.ASC;
      }
      orderAgentCode.value = null;
      orderAgentName.value = null;
      orderInvoiceCount.value = null;

      return new Sorting(ColumnName.OPEN_AMOUNT_SUM, orderOpenAmountSum.value);
    } else {
      // SORTING DEFAULT VALUE AGENT NAME
      return new Sorting(ColumnName.AGENT_NAME, Order.ASC);
    }
  };

  const defaultSorting = () => {
    orderAgentCode.value = null;
    // SORTING DEFAULT VALUE AGENT NAME
    orderAgentName.value = Order.ASC;
    orderInvoiceCount.value = null;
    orderOpenAmountSum.value = null;
  };

  onMounted(defaultSorting);
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[150px_minmax(133px,_1fr)_170px_200px_30px] min-h-48;
    @apply items-center font-light text-neutral-550;
  }

  .tableColumn {
    @apply flex items-center gap-4 hover:cursor-pointer;
  }

  .right {
    @apply justify-end;
  }
</style>
