import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { DownloadCategoryDto } from '@/domain/DownloadCategoryDto';
import type { DownloadDto } from '@/domain/DownloadDto';
import type { Page } from '@/util/Paging';
import type { Sorting } from '@/util/Sorting';

import { useApi } from '@/composables/api/useApi';
import type { DownloadsFilter } from '@/util/filters/DownloadsFilter';
import { PageImpl, PageRequest } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDownloadStore = defineStore('download-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const downloadCategories = ref<Array<DownloadCategoryDto>>([]);
  const downloads = ref<Page<DownloadDto>>(new PageImpl([], new PageRequest(10, 1), 0));

  // downloads?app_version=2023.1.4512813617&pagination.page=0&pagination.records=20&sorting.col=title&filters.search=&filters.category=&filters.language=de
  async function loadData(pageRequest: PageRequest, filter: DownloadsFilter, sorting: Sorting) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.search': filter.search,
        'filters.category': `${filter.category}`,
        'filters.language': filter.language,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/downloads?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.downloads) {
        downloads.value = api.getResultPage(container, container.data.downloads);
      }
      if (container.data.downloadCategories) {
        downloadCategories.value = container.data.downloadCategories;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function openFile(url: string, download = false) {
    try {
      const blob = await api.download(url);
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      if (download) {
        link.download = fileName;
      }
      link.target = '_blank';
      link.click();

      setTimeout(function () {
        window.URL.revokeObjectURL(downloadUrl);
      }, 333);
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    downloads,
    downloadCategories,
    openFile,
    loadData,
  };
});
