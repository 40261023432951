<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <OrderFilter v-model:orderFilter="orderFilter" v-model:orderStatus="orderStatus" v-model:agentId="agentId" />
    </TheSubheader>

    <!-- CONTENT AREA -->
    <div class="max-md:overflow-x-auto">
      <div class="md:mx-[80px] max-md:mx-16 min-w-fit">
        <OrderTableDraft
          v-if="orderStatus === OrderStatus.DRAFT"
          :orders="filteredOrders"
          @new-entry="goToCreatePage"
          @delete-order="onDelete"
          @open-order="onOpenDraft"
          @reload-on-sync="loadOrders"
        />
        <OrderTableCompleted
          v-else-if="orderStatus === OrderStatus.COMPLETED"
          :orders="filteredOrders"
          @new-entry="goToCreatePage"
          @open-order="onOpenCompleted"
        />
        <OrderTableAll
          v-else
          :orders="filteredOrders"
          @new-entry="goToCreatePage"
          @open-order-draft="onOpenDraft"
          @open-order-completed="onOpenCompleted"
          @reload-on-sync="loadOrders"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import OrderFilter from '@/modules/orders/components/order-filter/OrderFilter.vue';
  import OrderTableAll from '@/modules/orders/components/order-table/OrderTableAll.vue';
  import OrderTableCompleted from '@/modules/orders/components/order-table/OrderTableCompleted.vue';
  import OrderTableDraft from '@/modules/orders/components/order-table/OrderTableDraft.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useOrderData } from '@/composables/data/useOrderData';
  import { useUserData } from '@/composables/data/useUserData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import type { AuthUserDto } from '@/domain/masterData/AuthUserDto';
  import { useOrders } from '@/modules/orders/composables/useOrders';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { Utilities } from '@/util/Utilities';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const route = useRoute();

  const { isFullReloading, isLoading } = useMasterData();

  const { listOrdersByStatus, deleteOrder } = useOrders();
  const { resolveHierarchyUsers } = useHierarchy();
  const { loadOrderByGid } = useOrderWizardStore();
  const { syncOrders } = useOrderData();
  const { escapedRegExp } = Utilities();
  const userData = useUserData();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.orders');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const orderFilter = ref('');
  const orderStatus = ref(OrderStatus.DRAFT);
  const orders = ref<Array<OrderDto>>([]);
  const agentId = ref<number>();

  const goToCreatePage = function () {
    router.push('/orders/new');
  };

  const onDelete = async function (order: OrderDto) {
    await deleteOrder(order).then(async () => {
      await syncOrders();
    });
    await loadOrders();
  };

  const onOpenCompleted = async function (order: OrderDto) {
    await loadOrderByGid(order.gid);
    await router.push({
      name: 'order-wizard',
      params: { gid: order.gid, step: 'review' },
    });
  };

  const onOpenDraft = async function (order: OrderDto) {
    await loadOrderByGid(order.gid);
    await router.push({
      name: 'order-wizard',
      params: { gid: order.gid, step: 'customer' },
    });
  };

  const filteredOrders = computed((): Array<OrderDto> => {
    return orders.value.filter((order) => {
      try {
        const filteredItem = order.customer.name;

        return filteredItem.match(escapedRegExp(orderFilter.value));
      } catch (e) {
        return false;
      }
    });
  });

  const loadOrders = async function () {
    orders.value = await listOrdersByStatus(orderStatus.value);
    if (agentId.value) {
      orders.value = orders.value.filter((order) => order.agentId === agentId.value);
    }
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadOrders();
      }
    },
    { immediate: true }
  );

  watch([orderStatus, agentId], async () => await loadOrders());

  watch(
    () => route.params.status,
    (newStatus) => {
      if (newStatus === 'completed') {
        orderStatus.value = OrderStatus.COMPLETED;
      } else if (newStatus === 'all') {
        orderStatus.value = OrderStatus.ALL;
      } else {
        orderStatus.value = OrderStatus.DRAFT;
      }
    },
    { immediate: true }
  );
  onMounted(async () => {
    const hierarchyUsers: Array<AuthUserDto> = await resolveHierarchyUsers();
    if (hierarchyUsers.length > 1) {
      agentId.value = hierarchyUsers[0].id;
    }
  });
</script>
