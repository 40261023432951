<!-- eslint-disable max-len -->
<template>
  <svg id="ic_reload" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Pfad_10330" d="M14.269,5.671,11.979,7.96h5.007v-5L15.27,4.6Z" fill="#212529" />
    <path id="Pfad_10331" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Pfad_14640"
      data-name="Pfad 14640"
      d="M15.95,12.154a6.237,6.237,0,1,1-1.442-6.569"
      fill="none"
      stroke="#212529"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
  </svg>
</template>
