import type { CustomerNotesDto } from '@/domain/CustomerNoteDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { CustomerNotesRequest } from '@/domain/internal/CustomerNotesRequest';
import type { Sorting } from '@/util/Sorting';
import type { CustomerNotesFilter } from '@/util/filters/CustomerNotesFilter';

import { useApi } from '@/composables/api/useApi';
import { PageImpl, PageRequest, type Page } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCustomerNotesStore = defineStore('customer-note-stores', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const customerNotes = ref<Page<CustomerNotesDto>>(new PageImpl([], new PageRequest(5, 1), 0));

  const loadData = async function (
    customerId: number,
    pageRequest: PageRequest,
    sorting: Sorting,
    filter: CustomerNotesFilter
  ) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.search': filter.search,
        'filters.category': `${filter.category}`,
        'filters.date': `${filter.date}`,
        'filters.typeDateFilter': filter.typeDateFilter ? `${filter.typeDateFilter}` : '0',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/customernote/${customerId}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.customerNotes) {
        customerNotes.value = api.getResultPage(container, container.data.customerNotes);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const newCustomerNote = async function (request: CustomerNotesRequest) {
    try {
      const container = await api.fetch<ContainerDto>('/customernote?app_version=2023.1.4512813617', 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const editCustomerNote = async function (customerNoteId: number, request: CustomerNotesRequest) {
    try {
      const container = await api.fetch<ContainerDto>(
        `/customernote/${customerNoteId}?app_version=2023.1.4512813617`,
        'PUT',
        request
      );
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    customerNotes,
    newCustomerNote,
    editCustomerNote,
  };
});
