<template>
  <div class="flex items-center justify-between gap-32 py-12 md:mx-[80px] max-md:mx-16">
    <AgFormGroup :label="t('openItems.search')" class="w-full">
      <AgSearchInput v-model="internalValueSearch" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    modelValue: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const internalValueSearch = computed({
    get: () => {
      return props.modelValue;
    },
    set: (value) => {
      emit('update:modelValue', value);
    },
  });
</script>
