<!-- eslint-disable max-len -->
<template>
  <svg id="ic_show" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Gruppe_7456" data-name="Gruppe 7456" transform="translate(-1266 -206)">
      <g id="Gruppe_7055" data-name="Gruppe 7055" transform="translate(1266 206.324)">
        <path
          id="Pfad_988"
          data-name="Pfad 988"
          d="M22.695-39A10.423,10.423,0,0,0,13-32.39a10.423,10.423,0,0,0,9.695,6.61,10.423,10.423,0,0,0,9.695-6.61A10.423,10.423,0,0,0,22.695-39Zm0,3.966a2.64,2.64,0,0,0-2.644,2.644,2.64,2.64,0,0,0,2.644,2.644,2.64,2.64,0,0,0,2.644-2.644A2.64,2.64,0,0,0,22.695-35.034Z"
          transform="translate(-10.632 43.735)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        />
        <rect id="Box" width="24" height="24" transform="translate(0 -0.324)" fill="none" />
      </g>
    </g>
  </svg>
</template>
