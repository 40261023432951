<template>
  <div v-if="isLoading">
    <IcLoading />
  </div>
  <div v-else-if="articlesByCustomer.length > 0" class="mx-auto w-fit">
    <div :class="{ 'min-h-[365px]': keys.length > itemsPerPage - 1 }">
      <!-- TABLE HEADER -->
      <div class="table border-b border-neutral-300 text-neutral-550 cursor-pointer">
        <div class="tableEntry">
          <div class="flex items-center" @click.stop="updateSorting(ColumnName.ARTICLE_NAME)">
            <span class="px-4">{{ t('statistics.articleName') }}</span>
            <span v-if="sorting.col === ColumnName.ARTICLE_NAME">
              <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
              <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>

        <div class="tableEntry">
          <!-- QUANTITY YEAR TWO -->
          <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_TWO)">
            <span class="px-4">{{ t('statistics.quantity') }}&nbsp;{{ props.yearTwo }}</span>
            <span v-if="sorting.col === ColumnName.QUANTITY_TWO">
              <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
              <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- QUANTITY YEAR ONE -->
          <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_ONE)">
            <span class="px-4">{{ t('statistics.quantity') }}&nbsp;{{ props.yearOne }}</span>
            <span v-if="sorting.col === ColumnName.QUANTITY_ONE">
              <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
              <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- QUANTITY DIFFERENCE -->
          <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_DIFFERENCE)">
            <span class="px-4">{{ t('statistics.difference') }}&nbsp;({{ props.yearTwo }}-{{ props.yearOne }})</span>
            <span v-if="sorting.col === ColumnName.QUANTITY_DIFFERENCE">
              <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
              <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
      </div>

      <!-- TABLE CONTENT -->
      <div v-for="key in displayedItemsKey" :key="key">
        <div class="table">
          <div class="tableEntry">
            {{ byCustomerArticles[key].articleName }} ({{ byCustomerArticles[key].articleCode }})
          </div>

          <div class="tableEntry">
            <span class="right" :class="numberClass(byCustomerArticles[key].totalQuantityPeriodTwo)">
              {{ byCustomerArticles[key].totalQuantityPeriodTwo }}
            </span>
            <span class="right" :class="numberClass(byCustomerArticles[key].totalQuantityPeriodOne)">
              {{ byCustomerArticles[key].totalQuantityPeriodOne }}
            </span>
            <span class="right" :class="numberClass(getDifference(key))">
              {{ getDifference(key) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="px-16" :class="{ hidden: keys.length < itemsPerPage - 1 }">
      <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="keys.length" />
      <AgPaginator v-model="currentPage" :total-pages="pageCount" />
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('statistics.noData') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByArticleDto } from '@/domain/StatisticByArticleDto';
  import type { StatisticGlobalDto } from '@/domain/StatisticGlobalDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';

  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useStatisticStore();
  const { getStatisticsArticlesByCustomer } = store;
  const { articlesByCustomer } = storeToRefs(store);

  interface Props {
    customerCode: string;
    yearOne: number;
    yearTwo: number;
    startMonth?: number;
    endMonth?: number;
    agentId?: number;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    ARTICLE_CODE = 'article_code',
    ARTICLE_NAME = 'article_name',

    QUANTITY_ONE = 'quantity1',
    QUANTITY_TWO = 'quantity2',

    QUANTITY_DIFFERENCE = 'quantity_difference',
  }
  const sorting = ref<Sorting>(new Sorting(ColumnName.ARTICLE_NAME, Order.DESC));

  const isLoading = ref<boolean>(true);

  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const displayedItemsKey = ref<Array<string>>([]);

  const pageCount = computed(() => Math.ceil(keys.value.length / itemsPerPage));

  const keys = computed(() => Object.keys(byCustomerArticles.value));

  const byCustomerArticles = computed((): { [key: string]: StatisticByArticleDto } => {
    return articlesByCustomer.value.reduce(
      (byArticleStatistic: { [key: string]: StatisticByArticleDto }, statistic: StatisticGlobalDto) => {
        if (!byArticleStatistic[statistic.articleCode]) {
          const year = new Date(statistic.date * 1000).getFullYear();
          byArticleStatistic[statistic.articleCode] = {
            articleCode: statistic.articleCode,
            articleName: statistic.articleName,
            totalQuantityPeriodOne: year === props.yearOne ? statistic.quantity : 0,
            totalQuantityPeriodTwo: year === props.yearTwo ? statistic.quantity : 0,
            index: 0,
            totalAmountPeriodOne: year === props.yearOne ? statistic.amount : 0,
            totalAmountPeriodTwo: year === props.yearTwo ? statistic.amount : 0,
            totalCustomersPeriodOne: 0,
            totalCustomersPeriodTwo: 0,
          };
        } else {
          const year = new Date(statistic.date * 1000).getFullYear();
          if (year === props.yearOne) {
            byArticleStatistic[statistic.articleCode].totalQuantityPeriodOne += statistic.quantity;
            byArticleStatistic[statistic.articleCode].totalAmountPeriodOne += statistic.amount;
          } else if (year === props.yearTwo) {
            byArticleStatistic[statistic.articleCode].totalQuantityPeriodTwo += statistic.quantity;
            byArticleStatistic[statistic.articleCode].totalAmountPeriodTwo += statistic.amount;
          }
        }
        return byArticleStatistic;
      },
      {}
    );
  });

  const getDifference = (key: string) => {
    if (key) {
      return (
        byCustomerArticles.value[key].totalQuantityPeriodTwo - byCustomerArticles.value[key].totalQuantityPeriodOne
      );
    }
    return 0;
  };

  const updateSorting = (colum: ColumnName) => {
    sorting.value.col = colum;
    if (colum === ColumnName.QUANTITY_ONE) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[a]?.totalQuantityPeriodOne - byCustomerArticles.value[b]?.totalQuantityPeriodOne
        );
      } else {
        sorting.value.order = Order.ASC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[b]?.totalQuantityPeriodOne - byCustomerArticles.value[a]?.totalQuantityPeriodOne
        );
      }
    } else if (colum === ColumnName.QUANTITY_TWO) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[a]?.totalQuantityPeriodTwo - byCustomerArticles.value[b]?.totalQuantityPeriodTwo
        );
      } else {
        sorting.value.order = Order.ASC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[b]?.totalQuantityPeriodTwo - byCustomerArticles.value[a]?.totalQuantityPeriodTwo
        );
      }
    } else if (colum === ColumnName.QUANTITY_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[a].totalQuantityPeriodTwo -
            byCustomerArticles.value[a].totalQuantityPeriodOne -
            (byCustomerArticles.value[b].totalQuantityPeriodTwo - byCustomerArticles.value[b].totalQuantityPeriodOne)
        );
      } else {
        sorting.value.order = Order.ASC;
        keys.value.sort(
          (a, b) =>
            byCustomerArticles.value[b].totalQuantityPeriodTwo -
            byCustomerArticles.value[b].totalQuantityPeriodOne -
            (byCustomerArticles.value[a].totalQuantityPeriodTwo - byCustomerArticles.value[a].totalQuantityPeriodOne)
        );
      }
    } else if (colum === ColumnName.ARTICLE_CODE) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
        keys.value.sort((a, b) =>
          byCustomerArticles.value[a].articleCode.localeCompare(byCustomerArticles.value[b].articleCode)
        );
      } else {
        sorting.value.order = Order.ASC;
        keys.value.sort((a, b) =>
          byCustomerArticles.value[b].articleCode.localeCompare(byCustomerArticles.value[a].articleCode)
        );
      }
    } else if (colum === ColumnName.ARTICLE_NAME) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
        keys.value.sort((a, b) =>
          byCustomerArticles.value[a].articleName.localeCompare(byCustomerArticles.value[b].articleName)
        );
      } else {
        sorting.value.order = Order.ASC;
        keys.value.sort((a, b) =>
          byCustomerArticles.value[b].articleName.localeCompare(byCustomerArticles.value[a].articleName)
        );
      }
    }
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, keys.value.length);
    const artxpage = keys.value.slice(startIndex, endIndex);

    displayedItemsKey.value = artxpage;
  };

  watch(
    currentPage,
    () => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, keys.value.length);
      const artxpage = keys.value.slice(startIndex, endIndex);

      displayedItemsKey.value = artxpage;
    },
    { immediate: true }
  );

  onMounted(() => {
    const today = props.endMonth == 13 ? true : false;
    const eMonth = today ? new Date().getMonth() + 1 : props.endMonth;

    getStatisticsArticlesByCustomer(
      new StatisticFilter(
        undefined,
        undefined,
        props.startMonth,
        eMonth,
        props.yearOne,
        props.yearTwo,
        undefined,
        undefined,
        props.agentId,
        props.customerCode,
        today
      )
    ).then(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, keys.value.length);
      const artxpage = keys.value.slice(startIndex, endIndex);

      displayedItemsKey.value = artxpage;
      isLoading.value = false;
    });
  });
</script>

<style scoped lang="scss">
  .table {
    @apply grid grid-cols-[433px,633px] min-h-32;
  }

  .tableEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>
