<template>
  <div class="flex items-end justify-end gap-32 py-12 md:mx-[80px] max-md:mx-16">
    <AgFormGroup :label="t('language')" class="w-full">
      <AgFormSelect v-model="internalValue" :options="langOptions" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';

  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    modelValue: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: `messages.language.${lang}`,
    }))
  );

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
  });
</script>
