<template>
  <div class="flex flex-col gap-4" v-if="Object.keys(dimOptions).length > 0">
    <div v-for="key in Object.keys(dimOptions)" :key="key">
      <div>{{ getDimensionLable(key) }}&nbsp;({{ getDimensionValue(key) }})</div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';

  import { useArticleFactory } from '@/modules/articles/composables/useArticleFactory';
  import { i18n } from '@/plugins/i18n';
  import { ref, watch } from 'vue';

  const { locale } = i18n.global;
  const { getDimOptions } = useArticleFactory();

  interface Props {
    articleId: number;
    dimensionIds?: Array<number>;
  }
  const props = defineProps<Props>();

  const dimOptions = ref<{ [key: string]: Array<DimensionItemDto> }>({});

  const getDimensionLable = (key: string) => {
    const dimOpt = dimOptions.value[key][0].title[locale.value];
    if (dimOpt) {
      return dimOpt;
    }
    return '--';
  };

  const getDimensionValue = function (key: string) {
    const dimensionValues = dimOptions.value[key].map((item) => item.value.title[locale.value]);
    return dimensionValues.join(', ');
  };

  watch(
    () => props.articleId,
    async (newValue) => {
      if (newValue && props.dimensionIds) {
        const opts = await getDimOptions(props.dimensionIds);
        if (opts) {
          dimOptions.value = opts;
        }
      }
    },
    { immediate: true }
  );
</script>
