export class OutstandingFilter {
  search: string;
  due?: number;
  constructor(search = '', due?: number) {
    this.search = search;
    if (undefined !== due) {
      this.due = due;
    }
  }
}
