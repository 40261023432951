<template>
  <div class="tableHeaderRow border-b border-neutral-300">
    <div class="flex items-center border-r border-neutral-500">
      <span v-if="props.isSumRow">
        {{ t('statistics.sum') }}
      </span>
      <span v-else> {{ props.statistic.articleName }}&nbsp;({{ props.statistic.articleCode }}) </span>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
        {{ props.statistic.totalQuantityPeriodTwo }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalCustomersPeriodTwo)">
        {{ props.statistic.totalCustomersPeriodTwo }}
      </span>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
        {{ props.statistic.totalQuantityPeriodOne }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalCustomersPeriodOne)">
        {{ props.statistic.totalCustomersPeriodOne }}
      </span>
    </div>

    <div class="tableRowEntry">
      <span class="right" :class="numberClass(totalQuantity)">
        {{ totalQuantity }}
      </span>
      <span class="right" :class="numberClass(totalAmount)">
        {{ formatNumberToEuro(totalAmount) }}
      </span>
      <span class="right" :class="numberClass(revenuePercentage)">
        {{ formatNumberToPercentage(revenuePercentage) }}
      </span>
      <span class="right" :class="numberClass(totalCustomers)">
        {{ totalCustomers }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByArticleDto } from '@/domain/StatisticByArticleDto';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticByArticleDto;
    isSumRow?: boolean;
  }
  const props = defineProps<Props>();

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const totalQuantity = computed(
    () => Math.abs(props.statistic.totalQuantityPeriodTwo) - Math.abs(props.statistic.totalQuantityPeriodOne)
  );

  const totalAmount = computed(
    () => Math.abs(props.statistic.totalAmountPeriodTwo) - Math.abs(props.statistic.totalAmountPeriodOne)
  );

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmountPeriodTwo = Math.abs(props.statistic.totalAmountPeriodTwo);
      const totalAmountPeriodOne = Math.abs(props.statistic.totalAmountPeriodOne);

      const totalAmount = totalAmountPeriodTwo - totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });

  const totalCustomers = computed(
    () => props.statistic.totalCustomersPeriodTwo - props.statistic.totalCustomersPeriodOne
  );
</script>

<style scoped lang="scss">
  .tableHeaderRow {
    @apply grid grid-cols-[minmax(233px,433px)_300px_300px_350px] min-h-48;
  }

  .tableRowEntry {
    @apply flex justify-between items-center gap-8 px-8;
  }

  .right {
    @apply flex justify-end items-center w-full;
  }
</style>
