import { OrderStatus } from '@/domain/enumeration/OrderStatus';
import type { ContingentEntry } from '@/domain/internal/ContingentEntry';
import { useOrders } from '@/modules/orders/composables/useOrders';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOrdersStore = defineStore('orders-size', () => {
  const sizeCompletedOrders = ref<number>(0);
  const sizeDraftOrders = ref<number>(0);
  const totalOrders = ref<number>(0);
  const contingentMap = ref<Map<number, ContingentEntry>>(new Map<number, ContingentEntry>());

  const { getSizeOrdersByStatus } = useOrders();

  async function refreshOrdersData() {
    sizeCompletedOrders.value = await getSizeOrdersByStatus(OrderStatus.COMPLETED);
    sizeDraftOrders.value = await getSizeOrdersByStatus(OrderStatus.DRAFT);
    totalOrders.value = sizeCompletedOrders.value + sizeDraftOrders.value;
    refreshContingentMap();
  }
  function refreshContingentMap() {
    contingentMap.value.clear();
  }

  return {
    refreshOrdersData,
    sizeCompletedOrders,
    sizeDraftOrders,
    totalOrders,
    contingentMap,
    refreshContingentMap,
  };
});
