<template>
  <svg id="ic_messagecenter" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Pfad_2213" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Pfad_14637"
      data-name="Pfad 14637"
      d="M15.134,3.8H4.923A1.157,1.157,0,0,0,3.79,4.887V15.7l4.417-2.607h6.928A1.09,1.09,0,0,0,16.224,12V4.887a1.09,1.09,0,0,0-1.09-1.09Z"
      fill="none"
      stroke="#212529"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
  </svg>
</template>
