<template>
  <div class="tableHeaderRow" :class="{ 'border-b border-neutral-500': props.agentId }">
    <div class="tableRowEntry border-r border-neutral-500">
      {{ $t(`statistics.month${props.statistic.month}`) }}
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
        {{ props.statistic.totalQuantityPeriodTwo }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalCustomersPeriodTwo)">
        {{ props.statistic.totalCustomersPeriodTwo }}
      </span>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
        {{ props.statistic.totalQuantityPeriodOne }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalCustomersPeriodOne)">
        {{ props.statistic.totalCustomersPeriodOne }}
      </span>
    </div>

    <div class="tableRowEntry">
      <span class="right" :class="numberClass(totalQuantity)">
        {{ totalQuantity }}
      </span>
      <span class="right" :class="numberClass(totalAmount)">
        {{ formatNumberToEuro(totalAmount) }}
      </span>
      <span class="right" :class="numberClass(revenuePercentage)">
        {{ formatNumberToPercentage(revenuePercentage) }}
      </span>
      <span class="right" :class="numberClass(totalCustomers)">
        {{ totalCustomers }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { computed } from 'vue';

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticByMonthDto;
    agentId: number | undefined;
  }
  const props = defineProps<Props>();

  const totalQuantity = computed(
    () => Math.abs(props.statistic.totalQuantityPeriodTwo) - Math.abs(props.statistic.totalQuantityPeriodOne)
  );

  const totalAmount = computed(
    () => Math.abs(props.statistic.totalAmountPeriodTwo) - Math.abs(props.statistic.totalAmountPeriodOne)
  );

  const totalCustomers = computed(
    () => Math.abs(props.statistic.totalCustomersPeriodTwo) - Math.abs(props.statistic.totalCustomersPeriodOne)
  );

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmountPeriodTwo = Math.abs(props.statistic.totalAmountPeriodTwo);
      const totalAmountPeriodOne = Math.abs(props.statistic.totalAmountPeriodOne);

      const totalAmount = totalAmountPeriodTwo - totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };
</script>

<style scoped lang="scss">
  .tableHeaderRow {
    @apply grid grid-cols-[100px_minmax(283px,333px)_minmax(283px,333px)_minmax(333px,1fr)] min-h-48;
  }

  .tableRowEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>
