<template>
  <AgCollapsible v-if="!isSumRow" showIcon class="border-b border-neutral-500">
    <template #header>
      <div class="tableHeaderRow">
        <div class="tableRowEntry border-r border-neutral-500">
          <span> {{ props.statistic.customerName }} ({{ props.statistic.customerCode }}) </span>
        </div>

        <div class="tableRowEntry border-r border-neutral-500">
          <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
            {{ props.statistic.totalQuantityPeriodTwo }}
          </span>
          <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
            {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
          </span>
        </div>

        <div class="tableRowEntry border-r border-neutral-500">
          <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
            {{ props.statistic.totalQuantityPeriodOne }}
          </span>
          <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
            {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
          </span>
        </div>

        <div class="tableRowEntry">
          <span class="right" :class="numberClass(totalQuantity)">
            {{ totalQuantity }}
          </span>
          <span class="right" :class="numberClass(totalAmount)">
            {{ formatNumberToEuro(totalAmount) }}
          </span>
          <span class="right" :class="numberClass(revenuePercentage)">
            {{ formatNumberToPercentage(revenuePercentage) }}
          </span>
        </div>
      </div>
    </template>

    <template #default>
      <AgBox variant="accent" :showBorder="false" class="border-t border-x border-neutral-500">
        <!-- INTERNAL TABLE -->
        <StatisticsByCustomerSubTableRow
          :customerCode="props.statistic.customerCode ?? ''"
          :yearOne="props.periodOne ?? new Date().getFullYear() - 1"
          :yearTwo="props.periodTwo ?? new Date().getFullYear()"
          :startMonth="props.startMonth"
          :endMonth="props.endMonth"
          :agentId="props.agentId"
        />
      </AgBox>
    </template>
  </AgCollapsible>

  <div v-else class="tableHeaderRow pl-8 pr-24 border-b border-neutral-300 bg-neutral-100">
    <span class="flex items-center border-r border-neutral-500">
      {{ t('statistics.sum') }}
    </span>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
        {{ props.statistic.totalQuantityPeriodTwo }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
      </span>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
        {{ props.statistic.totalQuantityPeriodOne }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
      </span>
    </div>

    <div class="tableRowEntry">
      <span class="right" :class="numberClass(totalQuantity)">{{ totalQuantity }}</span>
      <span class="right" :class="numberClass(totalAmount)">{{ formatNumberToEuro(totalAmount) }}</span>
      <span class="right" :class="numberClass(revenuePercentage)">
        {{ formatNumberToPercentage(revenuePercentage) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByCustomerDto } from '@/domain/StatisticByCustomerDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import StatisticsByCustomerSubTableRow from '@/modules/statistics/components/by-customer/StatisticByCustomerSubTableRow.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticByCustomerDto;
    periodOne?: number;
    periodTwo?: number;
    startMonth?: number;
    endMonth?: number;
    agentId?: number;
    isSumRow?: boolean;
  }
  const props = defineProps<Props>();

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const totalQuantity = computed(
    () => Math.abs(props.statistic.totalQuantityPeriodTwo) - Math.abs(props.statistic.totalQuantityPeriodOne)
  );

  const totalAmount = computed(
    () => Math.abs(props.statistic.totalAmountPeriodTwo) - Math.abs(props.statistic.totalAmountPeriodOne)
  );

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmountPeriodTwo = Math.abs(props.statistic.totalAmountPeriodTwo);
      const totalAmountPeriodOne = Math.abs(props.statistic.totalAmountPeriodOne);

      const totalAmount = totalAmountPeriodTwo - totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });
</script>

<style scoped lang="scss">
  .tableHeaderRow {
    @apply grid grid-cols-[minmax(253px,1fr)_233px_233px_333px] min-h-48;
  }

  .tableRowEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>
