export enum CustomerType {
  CUSTOMER_ARTICLE = 0,
  CUSTOMER_PRICEGROUP = 1,
  PRICELIST_ARTICLE = 2,
  PRICELIST_PRICEGROUP = 3,
  CUSTOMER = 4,
  PROMOTION = 5,
  ALL = 6,
  PRICELIST = 7,
  PRICEGROUP = 8,
  DISCOUNTLIST_ARTICLE = 9,
  DISCOUNTLIST_PRICEGROUP = 10,
  DISCOUNTLIST = 11,
}
