<!-- eslint-disable max-len -->
<template>
  <svg id="ic_dashboard" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4576" width="20" height="20" fill="none" />
    <rect
      id="Rechteck_7017"
      data-name="Rechteck 7017"
      width="14"
      height="14"
      transform="translate(3 3)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_346"
      data-name="Linie 346"
      y2="13"
      transform="translate(10 3.5)"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
    <line
      id="Linie_347"
      data-name="Linie 347"
      x1="7.01"
      transform="translate(9.291 8.849)"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
    <line
      id="Linie_348"
      data-name="Linie 348"
      x1="6.5"
      transform="translate(3.5 11.344)"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
  </svg>
</template>
