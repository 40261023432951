<template>
  <svg id="ic_chevron_down" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Pfad_2415"
      data-name="Pfad 2415"
      d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    />
    <path id="Pfad_2416" data-name="Pfad 2416" d="M0,0H20V20H0Z" fill="none" />
  </svg>
</template>
