<!-- eslint-disable max-len -->
<template>
  <svg id="ic_update" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Pfad_10329" data-name="Pfad 10329" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Pfad_14641"
      data-name="Pfad 14641"
      d="M10.192,16.235A6.238,6.238,0,0,1,4.616,7.207"
      transform="translate(0.191 -0.203)"
      fill="none"
      stroke="#212529"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14642"
      data-name="Pfad 14642"
      d="M9.21,16.975v2.218l2.957-2.958L9.21,13.278v3.7Z"
      transform="translate(0.191 -0.203)"
      fill="#212529"
    />
    <path
      id="Pfad_14643"
      data-name="Pfad 14643"
      d="M9.425,4.171A6.238,6.238,0,0,1,15,13.2"
      transform="translate(0.191 -0.203)"
      fill="none"
      stroke="#212529"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14644"
      data-name="Pfad 14644"
      d="M10.407,3.432V1.214L7.45,4.172l2.957,2.957v-3.7Z"
      transform="translate(0.191 -0.203)"
      fill="#212529"
    />
  </svg>
</template>
