import { ApplicationInsights } from '@microsoft/applicationinsights-web';
let appInsights: ApplicationInsights;

export function useAppInsights() {
  const initialize = function (config: { instrumentationKey: string; appId: string }) {
    appInsights = new ApplicationInsights({
      config: {
        appId: config.appId,
        instrumentationKey: config.instrumentationKey,
        enableAutoRouteTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  };

  const trackException = function (exception: unknown) {
    appInsights.trackException({
      exception: exception as Error,
      properties: { message: (exception as Error).message },
    });
  };

  return {
    initialize,
    trackException,
  };
}
