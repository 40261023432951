<template>
  <div v-if="order" class="mt-12 mb-32 md:mx-[80px] max-md:mx-16 min-w-fit">
    <OrderInfo />
    <div class="flex flex-col gap-8 pr-24">
      <div class="py-8 basis-1/2">
        <AgFormGroup :label="t('orders.externalNote')">
          <AgFormTextarea v-model="order.orderNote" :placeholder="t('orders.hintNote')" :rows="12" />
        </AgFormGroup>
      </div>
      <div class="py-8 basis-1/2">
        <AgFormGroup :label="t('orders.internalNote')">
          <AgFormTextarea v-model="order.internalNote" :placeholder="t('orders.hintNote')" :rows="12" />
        </AgFormGroup>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import OrderInfo from '@/modules/orders/components/order-wizard/steps/OrderInfo.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';

  const { t } = i18n.global;

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.order', 'orders.note');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);
</script>
