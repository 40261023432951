import authRequired from '@/middleware/authRequired';
import NewOfferPage from '@/modules/offers/pages/NewOfferPage.vue';
import OfferWizardPage from '@/modules/offers/pages/OfferWizardPage.vue';
import OffersIndexPage from '@/modules/offers/pages/OffersIndexPage.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:status?',
    component: OffersIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/new',
    component: NewOfferPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/:gid/:step(customer|address|shopping-cart|offer-data|notes|review)',
    name: 'offer-wizard',
    component: OfferWizardPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
