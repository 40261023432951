<template>
  <div class="subTable border-b border-neutral-300">
    <div class="py-16 px-12 border-r border-neutral-300">
      {{ statistic.agentId ? agentNames[statistic.agentId] : 'Anonymous agent!?' }}
    </div>

    <div class="tableEntry border-r border-neutral-300">
      <span :class="numberClass(statistic.totalQuantityPeriodTwo)">
        {{ statistic.totalQuantityPeriodTwo }}
      </span>
      <span :class="numberClass(statistic.totalAmountPeriodTwo)">
        {{ formatNumberToEuro(statistic.totalAmountPeriodTwo) }}
      </span>
      <span :class="numberClass(statistic.totalCustomersPeriodTwo)">
        {{ statistic.totalCustomersPeriodTwo }}
      </span>
    </div>

    <div class="tableEntry border-r border-neutral-300">
      <span :class="numberClass(statistic.totalQuantityPeriodOne)">
        {{ statistic.totalQuantityPeriodOne }}
      </span>
      <span :class="numberClass(statistic.totalAmountPeriodOne)">
        {{ formatNumberToEuro(statistic.totalAmountPeriodOne) }}
      </span>
      <span :class="numberClass(statistic.totalCustomersPeriodOne)">
        {{ statistic.totalCustomersPeriodOne }}
      </span>
    </div>

    <div class="tableEntry">
      <span :class="numberClass(totalQuantity)">{{ totalQuantity }}</span>
      <span :class="numberClass(totalAmount)">{{ formatNumberToEuro(totalAmount) }}</span>
      <span :class="numberClass(revenuePercentage)">{{ formatNumberToPercentage(revenuePercentage) }}</span>
      <span :class="numberClass(totalCustomers)">{{ totalCustomers }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import { useHierarchy } from '@/composables/useHierarchy';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { computed, onMounted, ref } from 'vue';

  const { resolveHierarchyUsers } = useHierarchy();
  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticByMonthDto;
  }
  const props = defineProps<Props>();

  const agentNames = ref<{ [key: number]: string }>({});

  const numberClass = function (number: number): string {
    return number < 0 ? 'right text-infoRed' : 'right';
  };

  const totalQuantity = computed(
    () => Math.abs(props.statistic.totalQuantityPeriodTwo) - Math.abs(props.statistic.totalQuantityPeriodOne)
  );

  const totalAmount = computed(
    () => Math.abs(props.statistic.totalAmountPeriodTwo) - Math.abs(props.statistic.totalAmountPeriodOne)
  );

  const totalCustomers = computed(
    () => Math.abs(props.statistic.totalCustomersPeriodTwo) - Math.abs(props.statistic.totalCustomersPeriodOne)
  );

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmountPeriodTwo = Math.abs(props.statistic.totalAmountPeriodTwo);
      const totalAmountPeriodOne = Math.abs(props.statistic.totalAmountPeriodOne);

      const totalAmount = totalAmountPeriodTwo - totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });

  onMounted(() => {
    resolveHierarchyUsers().then((users) => {
      users.forEach((user) => {
        agentNames.value[user.id] = user.name;
      });
    });
  });
</script>

<style scoped lang="scss">
  .subTable {
    @apply grid grid-cols-[163px_293px_293px_393px] min-h-32;
  }

  .tableEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>
