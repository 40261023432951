<template>
  <div>
    <!-- TABLE COLLAPSIBLE-->
    <AgCollapsible show-icon collapse class="border-b border-neutral-500">
      <template #header>
        <!-- TABLE CONTENT -->
        <div class="table px-16" v-if="props.sumContingentRow">
          <div class="grid grid-cols-[183px_minmax(203px,363px)]">
            <!-- COLUMN CONTINGENT GROUP ARTICLE CODE -->
            <div class="tableColumn">
              {{ props.sumContingentRow.contingentGroupArticleCode }}
              {{ props.sumContingentRow.articleDimensionValueConfigDescription[locale] }}
            </div>
            <!-- COLUMN CONTINGENT GROUP ARTICLE DESCRIPTION -->
            <div class="tableColumn">
              {{ props.sumContingentRow.contGrArtDescription[locale] }}
              {{ props.sumContingentRow.articleDimensionValueConfigDescription[locale] }}
            </div>
          </div>

          <div
            class="grid grid-cols-[minmax(80px,133px)_minmax(80px,133px)_minmax(80px,133px)_minmax(80px,133px)_minmax(80px,203px)_133px]"
          >
            <!-- COLUMN TOTAL QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.totalQuantity }}
            </div>
            <!-- COLUMN DELIVERED QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.deliveredQuantity }}
            </div>
            <!-- COLUMN ORDERED QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.orderedQuantity }}
            </div>
            <!-- COLUMN AVAILABLE QUANTITY -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.availableQuantity }}
            </div>
            <!-- COLUMN ALLOCATED TO CUSTOMER -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.allocatedToCustomer }}
            </div>
            <!-- COLUMN NO ALLOCATION -->
            <div class="tableColumn right">
              {{ props.sumContingentRow.notAllocatedToCustomer }}
            </div>
          </div>
        </div>
        <div v-else>No Content</div>
      </template>
      <template #default>
        <AgBox variant="accent" :showBorder="false" class="border-t border-x border-neutral-500">
          <CustomerContingentsSubTable :contingents="props.contingents" :customers="customers" />
        </AgBox>
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import type { SumContingentDto } from '@/domain/internal/SumContingent';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import CustomerContingentsSubTable from '@/modules/contingents/components/by-group-article/CustomerContingentsSubTable.vue';

  import { i18n } from '@/plugins/i18n';

  const { locale } = i18n.global;

  interface Props {
    sumContingentRow: SumContingentDto | undefined;
    contingents: Array<ContingentDto>;
    customers: Array<CustomerDto>;
  }
  const props = defineProps<Props>();
</script>

<style scoped lang="scss">
  .table {
    @apply flex justify-between items-center min-h-48;
  }

  .tableColumn {
    @apply flex items-center min-h-48;
  }

  .right {
    @apply flex justify-end;
  }
</style>
