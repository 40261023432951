<template>
  <div class="min-w-512 px-12">
    <div class="py-12 items-start">
      <div class="tableHeader">
        <div class="headerColumn" @click.stop="sortArticleCode">
          <span>{{ $t('processedData.positionArticleCode') }}</span>
          <span v-if="orderArticleCode === Order.ASC">
            <IcChevronDown class="h-12 w-12 cursor-pointer" />
          </span>
          <span v-else-if="orderArticleCode === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <div class="headerColumn" @click.stop="sortArticleName">
          <span>{{ $t('processedData.positionArticleName') }}</span>
          <span v-if="orderArticleName === Order.ASC">
            <IcChevronDown class="h-12 w-12 cursor-pointer" />
          </span>
          <span v-else-if="orderArticleName === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <div class="headerColumn right" @click.stop="sortQuantity">
          <span>{{ $t('processedData.positionQuantity') }}</span>
          <span v-if="orderQuantity === Order.ASC">
            <IcChevronDown class="h-12 w-12 cursor-pointer" />
          </span>
          <span v-else-if="orderQuantity === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <div class="headerColumn right" @click.stop="sortNettoPriceNetto">
          <span>{{ $t('processedData.positionPriceNettoTotal') }}</span>
          <span v-if="orderNettoPriceTotal === Order.ASC">
            <IcChevronDown class="h-12 w-12 cursor-pointer" />
          </span>
          <span v-else-if="orderNettoPriceTotal === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <div class="flex items-center">
          <span>{{ $t('processedData.positionDiscount') }}</span>
        </div>
      </div>
      <div v-for="position in positionInfo" :key="position.id">
        <div class="table">
          <div class="tableColumn">{{ position.articleCode }}</div>
          <div class="tableColumn">{{ position.articleName }}</div>
          <div class="tableColumn right">{{ position.quantity }}</div>
          <div class="tableColumn right">{{ formatNumberToEuro(position.priceNettoTotal) }}</div>
          <div class="tableColumn">{{ getPositionDiscounts(position) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PositionDto } from '@/domain/PositionDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { config } from '@/config/config';
  import { Order } from '@/util/Order';
  import { ref } from 'vue';

  const { formatNumberToEuro, formatNumberToPercentage } = useNumberFormatting();

  interface Props {
    positions: Array<PositionDto>;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    ARTICLE_CODE = 'articleCode',
    ARTCILE_NAME = 'articleName',
    QUANTITY = 'quantity',
    PRICE_NETTO_TOTAL = 'priceNettoTotal',
  }

  const positionInfo = ref<Array<PositionDto>>(props.positions);

  const orderArticleCode = ref<Order | null>(null);
  const sortArticleCode = () => updateSorting(ColumnName.ARTICLE_CODE);

  const orderArticleName = ref<Order | null>(null);
  const sortArticleName = () => updateSorting(ColumnName.ARTCILE_NAME);

  const orderQuantity = ref<Order | null>(null);
  const sortQuantity = () => updateSorting(ColumnName.QUANTITY);

  const orderNettoPriceTotal = ref<Order | null>(null);
  const sortNettoPriceNetto = () => updateSorting(ColumnName.PRICE_NETTO_TOTAL);

  const updateSorting = (colum: ColumnName) => {
    if (colum === ColumnName.ARTICLE_CODE) {
      if (orderArticleCode.value === Order.ASC) {
        orderArticleCode.value = Order.DESC;
        positionInfo.value.sort((a, b) =>
          a.articleCode.localeCompare(b.articleCode, undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderArticleCode.value = Order.ASC;
        positionInfo.value.sort((a, b) =>
          b.articleCode.localeCompare(a.articleCode, undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderArticleName.value = null;
      orderQuantity.value = null;
      orderNettoPriceTotal.value = null;
    } else if (colum === ColumnName.ARTCILE_NAME) {
      if (orderArticleName.value === Order.ASC) {
        orderArticleName.value = Order.DESC;
        positionInfo.value.sort((a, b) =>
          a.articleName.localeCompare(b.articleName, undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderArticleName.value = Order.ASC;
        positionInfo.value.sort((a, b) =>
          b.articleName.localeCompare(a.articleName, undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderArticleCode.value = null;
      orderQuantity.value = null;
      orderNettoPriceTotal.value = null;
    } else if (colum === ColumnName.QUANTITY) {
      if (orderQuantity.value === Order.ASC) {
        orderQuantity.value = Order.DESC;
        positionInfo.value.sort((a, b) => a.quantity - b.quantity);
      } else {
        orderQuantity.value = Order.ASC;
        positionInfo.value.sort((a, b) => b.quantity - a.quantity);
      }
      orderArticleCode.value = null;
      orderArticleName.value = null;
      orderNettoPriceTotal.value = null;
    } else if (colum === ColumnName.PRICE_NETTO_TOTAL) {
      if (orderNettoPriceTotal.value === Order.ASC) {
        orderNettoPriceTotal.value = Order.DESC;
        positionInfo.value.sort((a, b) => a.priceNettoTotal - b.priceNettoTotal);
      } else {
        orderNettoPriceTotal.value = Order.ASC;
        positionInfo.value.sort((a, b) => b.priceNettoTotal - a.priceNettoTotal);
      }
      orderArticleCode.value = null;
      orderArticleName.value = null;
      orderQuantity.value = null;
    }
  };

  const getObjProperty = function <T, K extends keyof T>(obj: T, key: K): number {
    return (obj[key] as number) ?? 0;
  };

  const getPositionDiscounts = (position: PositionDto) => {
    const discounts: Array<string> = [];
    if (position) {
      type ObjectKey = keyof typeof position;
      for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
        const pos = `discount${i}` as ObjectKey;
        const value = getObjProperty(position, pos);
        if (value > 0) {
          discounts.push(formatNumberToPercentage(value));
        }
      }
    }
    return discounts.join(', ');
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[80px_minmax(133px,1fr)_80px_100px_minmax(133px,1fr)] gap-16 font-light text-neutral-550 min-h-32;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid grid-cols-[80px_minmax(133px,1fr)_80px_100px_minmax(133px,1fr)] gap-12 border-t border-neutral-500 min-h-32;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply flex justify-end;
  }
</style>
