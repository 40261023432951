<template>
  <header
    v-show="headerEnabled"
    id="the-header"
    class="flex h-[80px] max-lg:w-full lg:w-[calc(100%-20.8em)] items-center bg-neutral-250 justify-between"
    :class="{ invisible: headerVisible === false }"
  >
    <div class="flex flex-row text-xl w-fit header">
      <div class="font-bold" v-if="primaryTextKey">{{ t(primaryTextKey) }}</div>
      <template v-if="hasSecondaryText">
        <span class="font-light">&nbsp;|&nbsp;</span>
        <h2 class="font-light" v-if="secondaryTextKey">{{ t(secondaryTextKey) }}</h2>
      </template>
    </div>
    <div class="flex flex-row header" v-if="isOfferWizard">
      <AgButton variant="ghost" class="text-link" @click="offerSaveClose" :disabled="!isOnline">
        <span>{{ t('save&close') }}</span>
        <template #icon><IcSave /></template>
      </AgButton>
    </div>
    <div class="flex flex-row header" v-if="isOrderWizard">
      <AgButton variant="ghost" class="text-link" @click="orderSaveClose" :disabled="!isOnline">
        <span>{{ t('save&close') }}</span>
        <template #icon><IcSave /></template>
      </AgButton>
    </div>
  </header>
</template>

<script setup lang="ts">
  import IcSave from '@/components/icons/IcSave.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useOfferData } from '@/composables/data/useOfferData';
  import { useOrderData } from '@/composables/data/useOrderData';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { notify } from '@kyvg/vue3-notification';
  import { useNetwork } from '@vueuse/core';
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  const { t } = i18n.global;

  const route = useRoute();

  const { isOnline } = useNetwork();
  const { syncOffers } = useOfferData();
  const { syncOrders } = useOrderData();
  const { headerEnabled, headerVisible, saveButtonVisible, primaryTextKey, secondaryTextKey } = useTheHeader();

  const hasSecondaryText = computed(() => {
    return secondaryTextKey.value !== '';
  });

  const isOfferWizard = computed(() => {
    const name = route.name;
    if (name === 'offer-wizard' && saveButtonVisible.value) {
      return true;
    }
    return false;
  });

  const isOrderWizard = computed(() => {
    const name = route.name;
    if (name === 'order-wizard' && saveButtonVisible.value) {
      return true;
    }
    return false;
  });

  const offerSaveClose = () => {
    syncOffers().then(async () => {
      notify({
        type: 'success',
        title: t('offerSaved'),
      });
      await router.push({
        path: '/offers',
      });
    });
  };

  const orderSaveClose = () => {
    syncOrders().then(async () => {
      notify({
        type: 'success',
        title: t('orderSaved'),
      });
      await router.push({
        path: '/orders',
      });
    });
  };
</script>

<style scoped lang="scss">
  /* Vertical orientation of device */
  .header {
    @media screen and (orientation: portrait) {
      @apply md:lg:flex items-center mx-16 md:mx-[80px];
    }
    /* Horizontal orientation of device */
    @media screen and (orientation: landscape) {
      @apply md:lg:flex items-center mx-16 md:mx-[80px];
    }
  }
</style>
