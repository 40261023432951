<template>
  <div v-if="props.customer" class="text-s-20">
    <div class="my-12" v-if="billingAddress">
      <AgBox variant="strong-accent" class="space-y-12">
        <!-- TITLE AND AGENT INFO-->
        <div class="flex justify-between">
          <div class="font-bold" v-if="billingAddress.type === CustomerAddressType.BILLING">
            {{ t('customers.customer') }}
          </div>
          <div class="font-bold" v-else>{{ t('customers.customerAndDelivery') }}</div>
          <div>
            <span class="label">{{ t('customers.agent') }}:</span>
            {{ props.customer.agentName }}
          </div>
        </div>

        <!-- ROWS -->
        <div class="row">
          <!-- FIRST GROUP  -->
          <div class="w-1/3 max-lg:w-full">
            <div>
              <span class="label">{{ t('customers.companyName') }}:</span>
              {{ props.customer.name }}
            </div>
            <div>
              <span class="label">{{ t('customers.alternativeName') }}:</span>
              {{ props.customer.alternativeName }}
            </div>
            <div>
              <span class="label">{{ t('customers.street') }}:</span>
              {{ billingAddress.street }}
            </div>
            <div>
              <span class="label">{{ t('customers.email') }}:</span>
              {{ billingAddress.email }}
            </div>
            <div>
              <span class="label">{{ t('customers.vatCode') }}:</span>
              {{ props.customer.vatCode }}
            </div>
            <div>
              <span class="label">{{ t('customers.iban') }}:</span>
              {{ props.customer.iban }}
            </div>
            <div v-if="billingAddress.type === CustomerAddressType.BOTH">
              <span class="label">{{ t('customers.deliveryMethod') }}:</span>
              {{ getDeliveryMethod(billingAddress.deliveryMethodId) }}
            </div>
            <div>
              <span class="label">{{ t('customers.priceList') }}:</span>
              {{ getPriceList(props.customer.priceListId) }}
            </div>
            <div>
              <span class="label">{{ t('customers.discountList') }}:</span>
              {{ getDiscountList(props.customer.discountListId) }}
            </div>
          </div>

          <!-- SECOND GROUP -->
          <div class="w-1/3 max-lg:w-full">
            <div>
              <span class="label">{{ t('customers.customerNumber') }}:</span>
              {{ props.customer.code }}
            </div>
            <div>
              <span class="label">{{ t('customers.segment') }}:</span>
              {{ props.customer.segment }}
            </div>
            <div>
              <span class="label">{{ t('customers.website') }}:</span>
              {{ props.customer.webSiteUrl }}
            </div>
            <div>
              <span class="label">{{ t('customers.city') }}:</span>
              {{ billingAddress.city }}
            </div>
            <div>
              <span class="label">{{ t('customers.pecEmail') }}:</span>
              {{ billingAddress.pec }}
            </div>
            <div>
              <span class="label">{{ t('customers.fiscalCode') }}:</span>
              {{ props.customer.fiscalCode }}
            </div>
            <div>
              <span class="label">{{ t('customers.paymentMethod') }}:</span>
              {{ getPaymentMethod(props.customer.paymentMethodId) }}
            </div>
            <div v-if="billingAddress.type === CustomerAddressType.BOTH">
              <span class="label">{{ t('customers.deliveryTerm') }}:</span>
              {{ getDeliveryTerm(billingAddress.deliveryTermId) }}
            </div>
          </div>

          <!-- THIRD GROUP -->
          <div class="w-1/3 max-lg:w-full">
            <div>
              <span class="label">{{ t('customers.language') }}:</span>
              {{ props.customer.language }}
            </div>
            <div>
              <span class="label">{{ t('customers.zipCode') }}:</span>
              {{ billingAddress.zip }}
            </div>
            <div>
              <span class="label">{{ t('customers.district') }}:</span>
              {{ billingAddress.district }}
            </div>
            <div>
              <span class="label">{{ t('customers.phone') }}:</span>
              {{ billingAddress.phoneNumber }}
            </div>
            <div>
              <span class="label">{{ t('customers.deliveryPhoneNumber') }}:</span>
              {{ billingAddress.deliveryPhoneNumber }}
            </div>
            <div>
              <span class="label">{{ t('customers.sdiCode') }}:</span>
              {{ props.customer.sdiCode }}
            </div>
            <div>
              <span class="label">{{ t('customers.paymentTerm') }}:</span>
              {{ getPaymentTerm(props.customer.paymentTermId) }}
            </div>
            <div class="max-lg:hidden" v-if="billingAddress.type === CustomerAddressType.BOTH">&nbsp;</div>
          </div>
        </div>

        <!-- DAY OF REST AND UNLOADINGTIME -->
        <div v-if="billingAddress.type === CustomerAddressType.BOTH" class="space-y-12">
          <div class="flex gap-4">
            <span class="label">{{ t('customers.daysOfRest') }}:</span>
            <div v-for="(dayOfRest, index) in billingAddress.daysOfRest" :key="index">
              <div class="flex gap-4 items-center">
                <div class="font-bold">{{ t(`customers.day${dayOfRest.day}`) }}</div>
                <div v-if="dayOfRest.timeStart && dayOfRest.timeEnd">
                  ({{ dayOfRest.timeStart.substring(0, 5) }} - {{ dayOfRest.timeEnd.substring(0, 5) }})
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="label">{{ t('customers.unloadingTimes') }}:</div>
            <div v-for="(unloadingTime, index) in billingAddress.unloadingTimes" :key="index">
              <div class="flex gap-4 w-[160px]">
                <div>{{ t('customers.from') }}:&nbsp;{{ unloadingTime.timeStart.substring(0, 5) }}</div>
                <div>{{ t('customers.to') }}:&nbsp;{{ unloadingTime.timeEnd.substring(0, 5) }}</div>
              </div>
            </div>
          </div>
        </div>
      </AgBox>
    </div>

    <div class="my-12" v-if="deliveryAddresses">
      <AgBox v-for="address in deliveryAddresses" :key="address.code" variant="accent" class="space-y-12 mt-8">
        <AgCollapsible show-icon>
          <template #header>
            <div>
              {{ t('customers.deliveryAddress') }}&nbsp;:
              {{ completeAddressName(address) }}
            </div>
          </template>
          <template #default>
            <!-- TABLE CONTENT OF PROCESSED DATA -->
            <div class="pl-4">
              <div class="flex items-center justify-end">
                <div>
                  <span class="label">{{ t('customers.agent') }}:</span>
                  {{ props.customer.agentName }}
                </div>
              </div>
              <!-- ROWS -->
              <div class="row">
                <!-- FIRST GROUP  -->
                <div class="w-1/3 max-lg:w-full">
                  <div>
                    <span class="label">{{ t('customers.addressName') }}:</span>
                    {{ address.name }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.street') }}:</span>
                    {{ address.street }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.email') }}:</span>
                    {{ address.email }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.deliveryMethod') }}:</span>
                    {{ getDeliveryMethod(address.deliveryMethodId) }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.phone') }}:</span>
                    {{ address.phoneNumber }}
                  </div>
                </div>

                <!-- SECOND GROUP -->
                <div class="w-1/3 max-lg:w-full">
                  <div>
                    <span class="label">{{ t('customers.addressCode') }}:</span>
                    {{ address.code }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.city') }}:</span>
                    {{ address.city }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.pecEmail') }}:</span>
                    {{ address.pec }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.deliveryTerm') }}:</span>
                    {{ getDeliveryTerm(address.deliveryTermId) }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.deliveryPhoneNumber') }}:</span>
                    {{ address.deliveryPhoneNumber }}
                  </div>
                </div>

                <!-- THIRD GROUP -->
                <div class="w-1/3 max-lg:w-full">
                  <div>
                    <span class="label">{{ t('customers.district') }}:</span>
                    {{ address.district }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.region') }}:</span>
                    {{ address.region }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.zipCode') }}:</span>
                    {{ address.zip }}
                  </div>
                  <div>
                    <span class="label">{{ t('customers.country') }}:</span>
                    {{ address.nation }}
                  </div>
                </div>
              </div>

              <!-- DAY OF REST AND UNLODINGTIME -->
              <div class="flex gap-4">
                <span class="label">{{ t('customers.daysOfRest') }}:</span>
                <div v-for="(dayOfRest, index) in address.daysOfRest" :key="index">
                  <div class="flex gap-4 items-center">
                    <div class="font-bold">{{ t(`customers.day${dayOfRest.day}`) }}</div>
                    <div v-if="dayOfRest.timeStart && dayOfRest.timeEnd">
                      ({{ dayOfRest.timeStart.substring(0, 5) }} - {{ dayOfRest.timeEnd.substring(0, 5) }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div class="label">{{ t('customers.unloadingTimes') }}:</div>
                <div v-for="(unloadingTime, index) in address.unloadingTimes" :key="index">
                  <div class="flex gap-4 w-[160px]">
                    <div>{{ t('customers.from') }}:&nbsp;{{ unloadingTime.timeStart.substring(0, 5) }}</div>
                    <div>{{ t('customers.to') }}:&nbsp;{{ unloadingTime.timeEnd.substring(0, 5) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </AgCollapsible>
      </AgBox>
    </div>
  </div>
  <div v-else>{{ t('customers.customerNotFound') }}</div>
</template>

<script setup lang="ts">
  import type { AddressDto } from '@/domain/masterData/AddressDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { OfferDeliveryMethodDto } from '@/domain/offerData/OfferDeliveryMethodDto';
  import type { OfferDeliveryTermDto } from '@/domain/offerData/OfferDeliveryTermDto';
  import type { OfferPaymentMethodDto } from '@/domain/offerData/OfferPaymentMethodDto';
  import type { OfferPaymentTermDto } from '@/domain/offerData/OfferPaymentTermDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import type { PriceListDto } from '@/domain/masterData/PriceListDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { tables } = useMasterData();

  interface Props {
    customer: CustomerDto | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    customer: null,
  });

  const priceLists = ref<Array<PriceListDto>>([]);
  const discountLists = ref<Array<PriceListDto>>([]);
  const deliveryTerms = ref<Array<OfferDeliveryTermDto>>([]);
  const deliveryMethods = ref<Array<OfferDeliveryMethodDto>>([]);
  const paymentTerms = ref<Array<OfferPaymentTermDto>>([]);
  const paymentMethods = ref<Array<OfferPaymentMethodDto>>([]);

  const billingAddress = computed(() =>
    props.customer ? props.customer.addresses.find((a) => CustomerAddressType.DELIVERY !== a.type) : null
  );

  const deliveryAddresses = computed(() =>
    props.customer ? props.customer.addresses.filter((a) => CustomerAddressType.DELIVERY === a.type) : []
  );

  const completeAddressName = function (address: AddressDto) {
    if (address) {
      return address.name + ' , ' + address.city + ' , ' + address.street;
    }
    return '';
  };

  const getPriceList = function (plId: number | null) {
    if (plId && plId > 0) {
      const pl = priceLists.value.find((pl) => pl.id === plId);
      return pl ? getTranslatedText(pl.title) : '';
    }
  };

  const getDiscountList = function (dlId: number | null) {
    if (dlId && dlId > 0) {
      const dl = discountLists.value.find((dl) => dl.id === dlId);
      return dl ? getTranslatedText(dl.title) : '';
    }
  };

  const getPaymentTerm = function (ptId: number | null) {
    if (ptId && ptId > 0) {
      const pt = paymentTerms.value.find((pt) => pt.id === ptId);
      return pt ? getTranslatedText(pt.title) : '';
    }
    return '';
  };

  const getPaymentMethod = function (pmId: number | null) {
    if (pmId && pmId > 0) {
      const pm = paymentMethods.value.find((pm) => pm.id === pmId);
      return pm ? getTranslatedText(pm.title) : '';
    }
    return '';
  };

  const getDeliveryTerm = function (dtId: number | null) {
    if (dtId && dtId > 0) {
      const dt = deliveryTerms.value.find((dt) => dt.id === dtId);
      return dt ? getTranslatedText(dt.title) : '';
    }
    return '';
  };

  const getDeliveryMethod = function (dmId: number | null) {
    if (dmId && dmId > 0) {
      const dm = deliveryMethods.value.find((dm) => dm.id === dmId);
      return dm ? getTranslatedText(dm.title) : '';
    }
    return '';
  };

  const loadData = async function () {
    priceLists.value = await tables.priceLists.toArray();
    discountLists.value = await tables.discountLists.toArray();
    deliveryTerms.value = await tables.deliveryTerms.toArray();
    deliveryMethods.value = await tables.deliveryMethods.toArray();
    paymentTerms.value = await tables.paymentTerms.toArray();
    paymentMethods.value = await tables.paymentMethods.toArray();
  };

  onMounted(async () => await loadData());
</script>

<style scoped lang="scss">
  .row {
    @apply flex flex-wrap justify-between py-12;
  }
  .label {
    @apply pr-4 font-light text-neutral-550;
  }
</style>
