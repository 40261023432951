<!-- eslint-disable max-len -->
<template>
  <svg id="ic_statistics" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Gruppe_6983" data-name="Gruppe 6983" transform="translate(-1562 -413)">
      <line
        id="Linie_219"
        data-name="Linie 219"
        x2="9"
        transform="translate(1567.5 430) rotate(-90)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <line
        id="Linie_220"
        data-name="Linie 220"
        y1="14"
        transform="translate(1570.499 416)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <line
        id="Linie_221"
        data-name="Linie 221"
        y1="11"
        transform="translate(1573.501 419)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <line
        id="Linie_222"
        data-name="Linie 222"
        y1="13"
        transform="translate(1576.5 417)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <rect
        id="Rechteck_3157"
        data-name="Rechteck 3157"
        width="20"
        height="20"
        transform="translate(1562 433) rotate(-90)"
        fill="none"
      />
    </g>
  </svg>
</template>
