import { config } from '@/config/config';
import { i18n } from '@/plugins/i18n';

export function useTranslatedText() {
  const { locale } = i18n.global;

  const getTranslatedText = (toBeTranslated: { [key: string]: string } | null | undefined) => {
    if (toBeTranslated && typeof toBeTranslated === 'object') {
      if (locale.value && locale.value in toBeTranslated) {
        return toBeTranslated[locale.value];
      }
      const localesOrder: Array<string> = config.company.translatedDataLocalesOrder || [];
      const fallbackLocale = localesOrder.find((locale) => locale in toBeTranslated);
      if (fallbackLocale) {
        return toBeTranslated[fallbackLocale];
      }
    }
    if (typeof toBeTranslated === 'string') {
      return toBeTranslated;
    }
    return '';
  };

  return { getTranslatedText };
}
