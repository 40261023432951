import { useApi } from '@/composables/api/useApi';
import type { CategoryInfo } from '@/domain/CategoryInfo';
import type { ChecklistDto } from '@/domain/ChecklistDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCustomerChecklistsStore = defineStore('checklists-stores', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const actvities = ref<Array<ChecklistDto>>([]);
  const categories = ref<Array<CategoryInfo>>([]);

  async function getActivities(customerId: number) {
    try {
      const requestUri = `/checklists/customer/${customerId}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.activities) {
        actvities.value = container.data.activities;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCategories() {
    try {
      const requestUri = `/checklists/categories`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.categories) {
        categories.value = container.data.categories;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    getActivities,
    getCategories,
    actvities,
    categories,
  };
});
