import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;
export class Optional<T> {
  private readonly _value: T | null | undefined;

  private constructor(value: T | null | undefined) {
    this._value = value;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this._value === null || this._value === undefined;
  }

  public get(): T {
    if (this.isEmpty()) {
      throw new Error(t('OPTIONAL_ERROR'));
    }
    return this._value as T;
  }

  public orElse(alternative: T): T {
    if (this.isPresent()) {
      return this._value as T;
    }

    return alternative;
  }

  public static empty<T>(): Optional<T> {
    return new Optional<T>(null);
  }

  public static of<T>(value: T | null | undefined): Optional<T> {
    return new Optional(value);
  }
}
