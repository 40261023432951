import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import { useMurmurHash } from '@/composables/useMurmurHash';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type Dexie from 'dexie';

export function useIntegrityCheck() {
  const { murmur3 } = useMurmurHash();
  const { masterData } = useLocalDatabase();

  async function getHashOfTable(table: string): Promise<string> {
    const keys = await ((masterData as never)[table] as Dexie.Table).orderBy('hash').keys();
    const uniqueKeys = new Set(keys);

    return murmur3(Array.from(uniqueKeys).join(''));
  }

  async function checkMasterData(syncTables: Array<string>, response: ContainerDto) {
    const results = await Promise.all(
      syncTables.map(async (table: string) => {
        const responseData: { hash?: string } = (response.data as never)[table];

        const localHash = await getHashOfTable(table);
        if (responseData.hash !== localHash) {
          console.error('failed integrity check', {
            table,
            local: localHash,
            host: String(responseData.hash),
          });
          return false;
        }
        return true;
      })
    );
    return !results.some((i) => i === false);
  }

  return {
    checkMasterData,
  };
}
