<template>
  <div>
    <div class="flex flex-row gap-32">
      <AgFormGroup :label="t('openItems.invoiceNumber')">
        <AgFormInput v-model="props.invoice.invoiceCode" disabled />
      </AgFormGroup>
      <AgFormGroup :label="t('openItems.invoiceDate')">
        <AgFormInput v-model="date" type="date" disabled />
      </AgFormGroup>
      <AgFormGroup :label="t('openItems.openAmount')">
        <div class="flex flex-row items-center gap-4">
          <AgFormInput type="number" v-model="props.invoice.openAmount" formatToDecimal disabled right />
          <span class="font-bold">&euro;</span>
        </div>
      </AgFormGroup>
      <AgFormGroup :label="t('openItems.paidAmount')">
        <div class="flex flex-row items-center gap-4">
          <AgFormInput type="number" v-model="props.invoice.paidAmount" formatToDecimal right />
          <span class="font-bold">&euro;</span>
        </div>
      </AgFormGroup>
      <AgFormGroup label="" class="flex items-end" @click="removeItem">
        <AgButton variant="ghost" class="text-infoRed">
          <template #icon>
            <IcClose class="h-24 w-24" />
          </template>
        </AgButton>
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import type { OutstandingDebtToPayDto } from '@/domain/OutstandingDebtToPayDto';
  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const store = useByCustomerStore();
  const { selectedInvoices } = storeToRefs(store);

  interface Props {
    invoice: OutstandingDebtToPayDto;
  }
  const props = defineProps<Props>();

  const date = computed(() => {
    const date = new Date((props.invoice.invoiceDate ?? 0) * 1000);
    return date.toISOString().split('T')[0];
  });

  const removeItem = () => {
    const index = selectedInvoices.value.findIndex((item) => item.invoiceCode === props.invoice.invoiceCode);
    if (index !== -1) {
      selectedInvoices.value.splice(index, 1);
    }
  };
</script>
