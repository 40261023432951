<template>
  <div class="flex items-center gap-4">
    <span class="font-light">{{ t('itemsPaginatorInfo') }}</span>
    <span class="font-bold text-primary">{{ startIndex + 1 }}</span>
    <span class="font-light">{{ t('toPaginatorInfo') }}</span>
    <span class="font-bold text-primary">{{ endIndex }}</span>
    <span class="font-light">{{ t('ofPaginatorInfo') }}</span>
    <span class="font-bold text-primary">{{ props.numberItems }}</span>
  </div>
</template>

<script setup lang="ts">
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    pageSize: number;
    numberItems: number;
    currentPage: number;
  }
  const props = defineProps<Props>();

  const startIndex = computed(() => {
    return (props.currentPage - 1) * props.pageSize;
  });

  const endIndex = computed(() => {
    return Math.min(startIndex.value + props.pageSize, props.numberItems);
  });
</script>
