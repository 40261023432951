<template>
  <AgBox>
    <router-link to="/checklists/expired" class="router-link">
      <span>{{ t('checklistsExpiredDashboard') }}:</span>
      <span v-if="props.checklistsInfo.numberOfExpired > 0" class="text-infoRed">
        {{ props.checklistsInfo.numberOfExpired }}
      </span>
      <span v-else>-</span>
    </router-link>

    <router-link to="/checklists/open" class="router-link">
      <span>{{ t('checklistsOpenDashboard') }}:</span>
      <span v-if="props.checklistsInfo.numberOfOpen > 0">
        {{ props.checklistsInfo.numberOfOpen }}
      </span>
      <span v-else>-</span>
    </router-link>

    <router-link to="/checklists/completed" class="flex items-center justify-between text-white hover:underline">
      <span>{{ t('checklistsCompletedDashboard') }}:</span>
      <span v-if="props.checklistsInfo.numberOfCompleted > 0">
        {{ props.checklistsInfo.numberOfCompleted }}
      </span>
      <span v-else>-</span>
    </router-link>
  </AgBox>
</template>

<script setup lang="ts">
  import AgBox from '@/components/library/box/AgBox.vue';

  import type { CheckListsInfoDto } from '@/domain/CheckListsInfoDto';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    checklistsInfo: CheckListsInfoDto;
  }
  const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }

  .router-link:hover {
    @apply underline;
  }
</style>
