<template>
  <div class="tableHeaderRow border-b border-neutral-300">
    <div class="flex items-center border-r border-neutral-500">
      <div v-if="props.isSumRow">{{ t('statistics.sum') }}</div>
      <div v-else class="flex flex-col w-full gap-4 px-8">
        <span class="font-bold">{{ props.statistic.customerName }}&nbsp;({{ props.statistic.customerCode }})</span>
        <span>{{ props.statistic.articleName }}&nbsp;({{ props.statistic.articleCode }})</span>
      </div>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
        {{ props.statistic.totalQuantityPeriodTwo }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
      </span>
    </div>

    <div class="tableRowEntry border-r border-neutral-500">
      <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
        {{ props.statistic.totalQuantityPeriodOne }}
      </span>
      <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
        {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
      </span>
    </div>

    <div class="tableRowEntry">
      <span class="right" :class="numberClass(totalQuantity)">
        {{ totalQuantity }}
      </span>
      <span class="right" :class="numberClass(totalAmount)">
        {{ formatNumberToEuro(totalAmount) }}
      </span>
      <span class="right" :class="numberClass(revenuePercentage)">
        {{ formatNumberToPercentage(revenuePercentage) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticGroupedDto } from '@/domain/StatisticGroupedDto';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticGroupedDto;
    isSumRow?: boolean;
  }
  const props = defineProps<Props>();

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const totalQuantity = computed(() => props.statistic.totalQuantityPeriodTwo - props.statistic.totalQuantityPeriodOne);

  const totalAmount = computed(() => props.statistic.totalAmountPeriodTwo - props.statistic.totalAmountPeriodOne);

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmount = props.statistic.totalAmountPeriodTwo - props.statistic.totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / props.statistic.totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });
</script>

<style scoped lang="scss">
  .tableHeaderRow {
    @apply grid grid-cols-[minmax(253px,1fr)_233px_233px_333px] min-h-48;
  }

  .tableRowEntry {
    @apply flex justify-between items-center gap-4 px-8;
  }

  .right {
    @apply flex justify-end items-center w-full;
  }
</style>
