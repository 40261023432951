<template>
  <div
    v-if="notDismessed"
    class="flex flex-row justify-between items-center gap-8 p-8 rounded text-s-13"
    :class="alertClasses"
  >
    <span class="flex justify-start" v-if="hasContent"><slot></slot></span>
    <div class="flex justify-end">
      <span v-if="hasIcon">
        <slot name="icon"></slot>
      </span>
      <span v-if="props.dismissible">
        <IcClose class="cursor-pointer" @click.stop="notDismessed = false" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcClose from '@/components/icons/IcClose.vue';

  import { computed, ref, useSlots } from 'vue';

  const notDismessed = ref<boolean>(true);

  interface Props {
    variant?: 'success' | 'info' | 'warning' | 'danger';
    dismissible?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    variant: 'success',
    dismissible: false,
  });

  const alertClasses = computed(() => ({
    'bg-success bg-opacity-10 border border-success border-opacity-20 text-success': props.variant === 'success',
    'bg-info bg-opacity-10 border border-info border-opacity-20 text-info': props.variant === 'info',
    'bg-warning bg-opacity-10 border border-warning border-opacity-20 text-warning': props.variant === 'warning',
    'bg-danger bg-opacity-10 border border-danger border-opacity-20 text-danger': props.variant === 'danger',
  }));

  const hasContent = computed<boolean>(() => {
    return !!useSlots().default;
  });

  const hasIcon = computed<boolean>(() => {
    return !!useSlots().icon;
  });
</script>
