<template>
  <div>
    <div class="mb-8 flex flex-row items-center justify-between">
      <router-link to="/checklists/open" class="router-link" :title="t('checklists.goToOpenCheckLists')">
        {{ t('checklistsDashboard') }}
      </router-link>
    </div>
    <DashboardWidgetChecklistsTable :checklistsInfo="props.checklistsInfo" />
  </div>
</template>

<script setup lang="ts">
  import type { CheckListsInfoDto } from '@/domain/CheckListsInfoDto';
  import { i18n } from '@/plugins/i18n';
  import DashboardWidgetChecklistsTable from './table/DashboardWidgetChecklistsTable.vue';

  const { t } = i18n.global;

  interface Props {
    checklistsInfo: CheckListsInfoDto;
  }
  const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
  .router-link {
    @apply text-xl font-bold text-white;
  }
</style>
