import type { MsalOptions } from '@/composables/auth/useMsal';

import Notifications from '@kyvg/vue3-notification';
import App from './TheApp.vue';

import { useMsal } from '@/composables/auth/useMsal';
import { config } from '@/config/config';
import { i18n } from '@/plugins/i18n';
import { router } from '@/plugins/router';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { useAppInsights } from './composables/insights/useAppInsights';

if (config.enviroment === 'development') {
  useAppInsights().initialize({
    appId: process.env.ENENVIRONMENT + config.company.code,
    instrumentationKey: '83875f4b-70f3-4c75-a70f-d809621a157f',
  });
} else if (config.company.insightsInsturmetationKey.length > 0) {
  useAppInsights().initialize({
    appId: process.env.ENENVIRONMENT + config.company.code,
    instrumentationKey: config.company.insightsInsturmetationKey,
  });
}
useMsal().initialize({
  clientId: config.auth.clientId,
  loginAuthority: config.auth.loginAuthority,
  passwordRestAuthority: config.auth.passwordRestAuthority,
  knownAuthority: config.auth.knownAuthority,
  accessTokenScopes: ['offline_access', config.auth.accessTokenScope],
  redirectUri: config.auth.redirectUri,
  redirectLogoutUri: config.auth.redirectLogoutUri,
} as MsalOptions);

const app = createApp(App);
app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(Notifications);
app.mount('#the-app');
