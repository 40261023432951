<template>
  <svg id="ic_rewrite_24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Pfad_10329" data-name="Pfad 10329" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Pfad_14642"
      data-name="Pfad 14642"
      d="M0,5.113V8.18L4.089,4.089,0,0V5.113Z"
      transform="translate(10.244 13.806) rotate(90)"
      :style="{ fill: props.variant === 'gray' ? '#e2e2e2' : '#006fff' }"
    />
    <path
      id="Pfad_15223"
      data-name="Pfad 15223"
      d="M0,5.113V8.18L4.089,4.089,0,0V5.113Z"
      transform="translate(13.756 10.194) rotate(-90)"
      :style="{ fill: props.variant === 'gray' ? '#e2e2e2' : '#006fff' }"
    />
    <path
      id="Pfad_15221"
      data-name="Pfad 15221"
      d="M10.075,9.784H0V0"
      transform="translate(15.937 5.271) rotate(90)"
      fill="none"
      :style="{ stroke: props.variant === 'gray' ? '#e2e2e2' : '#006fff' }"
      stroke-width="2"
    />
    <path
      id="Pfad_15222"
      data-name="Pfad 15222"
      d="M10.075,9.784H0V0"
      transform="translate(8.066 18.729) rotate(-90)"
      fill="none"
      :style="{ stroke: props.variant === 'gray' ? '#e2e2e2' : '#006fff' }"
      stroke-width="2"
    />
  </svg>
</template>
<script setup lang="ts">
  interface Props {
    variant?: 'gray';
  }
  const props = withDefaults(defineProps<Props>(), { variant: undefined });
</script>
