<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <OfferFilter v-model:offerFilter="offerFilter" v-model:offerStatus="offerStatus" v-model:agentId="agentId" />
    </TheSubheader>

    <!-- CONTENT AREA -->
    <div class="max-md:overflow-x-auto">
      <div class="md:mx-[80px] max-md:mx-16 min-w-fit">
        <OfferTableDraft
          v-if="offerStatus === OfferStatus.DRAFT"
          :offers="filteredOffers"
          @new-entry="goToCreatePage"
          @delete-offer="onDelete"
          @open-offer="onOpenDraft"
          @reload-on-sync="loadOffers"
        />
        <OfferTableCompleted
          v-else-if="offerStatus === OfferStatus.COMPLETED"
          :offers="filteredOffers"
          @new-entry="goToCreatePage"
          @open-offer="onOpenCompleted"
        />
        <OfferTableAll
          v-else
          :offers="filteredOffers"
          @new-entry="goToCreatePage"
          @open-offer-draft="onOpenDraft"
          @open-offer-completed="onOpenCompleted"
          @reload-on-sync="loadOffers"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import OfferFilter from '@/modules/offers/components/offer-filter/OfferFilter.vue';
  import OfferTableAll from '@/modules/offers/components/offer-table/OfferTableAll.vue';
  import OfferTableCompleted from '@/modules/offers/components/offer-table/OfferTableCompleted.vue';
  import OfferTableDraft from '@/modules/offers/components/offer-table/OfferTableDraft.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useOfferData } from '@/composables/data/useOfferData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { Utilities } from '@/util/Utilities';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const route = useRoute();

  const { isFullReloading, isLoading } = useMasterData();
  const { listOffersByStatus, deleteOffer } = useOffers();
  const { loadOfferByGid } = useOfferWizardStore();
  const { getAllHierarchy } = useHierarchy();
  const { syncOffers } = useOfferData();
  const { escapedRegExp } = Utilities();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offers');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const offerFilter = ref('');
  const offerStatus = ref(OfferStatus.DRAFT);
  const offers = ref<Array<OfferDto>>([]);
  const agentId = ref<number>();

  const goToCreatePage = function () {
    router.push('/offers/new');
  };

  const onDelete = async function (offer: OfferDto) {
    await deleteOffer(offer).then(async () => {
      await syncOffers();
    });
    await loadOffers();
  };

  const onOpenCompleted = async function (offer: OfferDto) {
    await loadOfferByGid(offer.gid);
    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'review' },
    });
  };

  const onOpenDraft = async function (offer: OfferDto) {
    await loadOfferByGid(offer.gid);
    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'customer' },
    });
  };

  const filteredOffers = computed((): Array<OfferDto> => {
    return offers.value.filter((offer) => {
      try {
        const filteredItem = offer.customer.name;

        return filteredItem.match(escapedRegExp(offerFilter.value));
      } catch (e) {
        return false;
      }
    });
  });

  const loadOffers = async function () {
    offers.value = await listOffersByStatus(offerStatus.value);
    if (agentId.value) {
      offers.value = offers.value.filter((offer) => offer.agentId === agentId.value);
    }
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadOffers();
      }
    },
    { immediate: true }
  );

  watch([offerStatus, agentId], async () => await loadOffers());

  watch(
    () => route.params.status,
    (newStatus) => {
      if (newStatus === 'completed') {
        offerStatus.value = OfferStatus.COMPLETED;
      } else if (newStatus === 'all') {
        offerStatus.value = OfferStatus.ALL;
      } else {
        offerStatus.value = OfferStatus.DRAFT;
      }
    },
    { immediate: true }
  );
  onMounted(async () => {
    const hierarchyList: Array<UserHierarchyDto> = await getAllHierarchy();
    if (hierarchyList.length > 0) {
      agentId.value = hierarchyList[0]?.authUser?.id ?? undefined;
    }
  });
</script>
