<template>
  <div class="flex flex-col gap-8 max-w-xl">
    <div class="flex justify-between pb-16 border-b border-b-neutral-550">
      <AgCircleProgressBar
        :start="8"
        :end="12"
        :max="12"
        :size="90"
        rounded
        @click="setMorning"
        class="hover:cursor-pointer"
        :colorFilled="variant === 'black' ? '#FFFFFF' : undefined"
        :color-back="variant === 'black' ? '#E2E2E2' : undefined"
        :color-unfilled="variant === 'black' ? '#000000' : undefined"
      >
        {{ t('morning') }}
      </AgCircleProgressBar>
      <AgCircleProgressBar
        :start="14"
        :end="18"
        :max="12"
        :size="90"
        rounded
        @click="setAfternoon"
        class="hover:cursor-pointer"
        :colorFilled="variant === 'black' ? '#FFFFFF' : undefined"
        :color-back="variant === 'black' ? '#E2E2E2' : undefined"
        :color-unfilled="variant === 'black' ? '#000000' : undefined"
      >
        {{ t('afternoon') }}
      </AgCircleProgressBar>
      <AgCircleProgressBar
        :start="0"
        :end="12"
        :max="12"
        :size="90"
        rounded
        @click="setAllDay"
        class="hover:cursor-pointer"
        :colorFilled="variant === 'black' ? '#000000' : undefined"
        :color-back="variant === 'black' ? '#E2E2E2' : undefined"
        :color-unfilled="variant === 'black' ? '#000000' : undefined"
      >
        {{ t('allDay') }}
      </AgCircleProgressBar>
    </div>
    <div class="flex justify-between gap-12" :class="timeSetClasses">
      <div class="basis-1/2">
        <span class="font-bold">{{ t('from') }}</span>
        <AgFormInput
          v-model="fromValue"
          :disabled="disabled"
          type="time"
          min="00:00"
          max="23:59"
          left
          placeholder="00:00"
          class="w-full"
        />
      </div>
      <div class="basis-1/2">
        <span class="font-bold">{{ t('to') }}</span>
        <AgFormInput
          v-model="toValue"
          :disabled="disabled"
          type="time"
          min="00:00"
          max="24:00"
          left
          placeholder="00:00"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgCircleProgressBar from '@/components/library/progress/AgCircleProgressBar.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    from?: string | null;
    to?: string | null;
    disabled?: boolean;
    validationState?: undefined | null | boolean;
    variant?: 'black';
  }
  const props = withDefaults(defineProps<Props>(), {
    from: '00:00',
    to: '00:00',
    validationState: undefined,
    variant: undefined,
  });

  const emit = defineEmits(['update:from', 'update:to', 'remove-event']);

  const selectedButton = ref<number>(0);

  const fromValue = computed({
    get: () => props.from,
    set: (newValue) => emit('update:from', newValue),
  });

  const toValue = computed({
    get: () => props.to,
    set: (newValue) => emit('update:to', newValue),
  });

  const setAllDay = function () {
    fromValue.value = '00:00';
    toValue.value = '23:59';
    selectedButton.value = 3;
  };

  const setMorning = function () {
    fromValue.value = '08:00';
    toValue.value = '12:00';
    selectedButton.value = 1;
  };

  const setAfternoon = function () {
    fromValue.value = '14:00';
    toValue.value = '18:00';
    selectedButton.value = 2;
  };

  const timeSetClasses = computed(() => ({
    'border border-danger px-8': props.validationState === false,
  }));
</script>
