<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-xl font-bold">{{ $t('openItems.notifyPayment') }}</div>
      <div class="text-s-13 font-light w-fit text-neutral-550 italic mt-12">
        {{ $t('openItems.textMailPaidInvoices') }}
      </div>
    </template>
    <template #content>
      <div class="h-[200px] w-[400px] flex items-center" v-if="isLoading"><IcLoading /></div>
      <div v-else>
        <AgFormGroup :label="t('openItems.language')" required>
          <AgFormSelect v-model="language" :options="langOptions" />
        </AgFormGroup>

        <div class="my-24 font-bold">{{ t('openItems.selectedInvoices') }}</div>

        <div v-for="customerCode in Object.keys(groupedByCustomer)" :key="customerCode" class="my-24">
          <div class="font-bold">{{ customerCode }}&nbsp;{{ getCustomerName(customerCode) }}</div>
          <div v-for="invoice in groupedByCustomer[customerCode]" :key="invoice.invoiceCode">
            <PaymentModalRow :invoice="invoice" />
          </div>
        </div>

        <div class="py-12">
          <span class="font-bold">{{ $t('openItems.paymentNote') }}</span>
          <AgFormTextarea v-model="note" rows="7" />
        </div>

        <div v-if="isError">
          <div class="text-danger">{{ $t('openItems.emailRequestError') }}</div>
        </div>
      </div>
    </template>
    <template #footer v-if="!isLoading">
      <div class="flex flex-row justify-between w-full">
        <AgButton variant="secondary" @click="closeModal">
          {{ $t('openItems.close') }}
        </AgButton>
        <AgButton @click="sendEmailInvoices" :disabled="isSendDisabled">
          {{ $t('openItems.notifyPayment') }}
        </AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import PaymentModalRow from '@/modules/open-items/components/by-customer/paymentModal/PaymentModalRow.vue';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import { useEmail } from '@/composables/useEmail';
  import { config } from '@/config/config';
  import type { OutstandingDebtToPayDto } from '@/domain/OutstandingDebtToPayDto';
  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  const store = useByCustomerStore();

  const { sendInvoicesPayment } = useEmail();
  const { selectedInvoices } = storeToRefs(store);

  interface Props {
    modelValue?: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const language = ref<string>(locale.value);
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const note = ref<string>('');
  const groupedByCustomer = ref<{ [key: string]: Array<OutstandingDebtToPayDto> }>({});

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: lang,
    }))
  );

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const isSendDisabled = computed(() => selectedInvoices.value.length === 0);

  const closeModal = () => {
    internalValue.value = false;
  };

  const groupByCustomer = () => {
    return selectedInvoices.value.reduce((grouped: { [key: string]: Array<OutstandingDebtToPayDto> }, invoice) => {
      if (invoice.customerCode && !grouped[invoice.customerCode]) {
        grouped[invoice.customerCode] = [];
        grouped[invoice.customerCode].push(invoice);
      } else if (invoice.customerCode) {
        grouped[invoice.customerCode].push(invoice);
      }
      return grouped;
    }, {});
  };

  const getCustomerName = function (customerCode: string) {
    return selectedInvoices.value.find((invoice) => invoice.customerCode === customerCode)?.customerName;
  };

  const sendEmailInvoices = () => {
    isLoading.value = true;
    sendInvoicesPayment(note.value, language.value, selectedInvoices.value)
      .then(() => {
        isLoading.value = false;
        closeModal();
      })
      .catch(() => {
        isLoading.value = false;
        isError.value = true;
      });
  };
  watch(internalValue, (value) => {
    if (value) {
      isError.value = false;
      note.value = '';
      language.value = locale.value;
      groupedByCustomer.value = groupByCustomer();
    } else {
      selectedInvoices.value.forEach((invoice) => {
        invoice.paidAmount = invoice.openAmount;
      });
    }
  });
</script>
