import type { DimensionItemDto } from '@/domain/DimensionItemDto';
import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
import type { TradeAgreementPriceAndDiscounts } from '@/domain/internal/TradeAgreementPriceAndDiscounts';
import type { AddressDto } from '@/domain/masterData/AddressDto';
import type { ContingentDto } from '@/domain/masterData/ContingentDto';
import type { CustomerDto } from '@/domain/masterData/CustomerDto';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';
import type { OfferArticleDto } from '@/domain/offerData/OfferArticleDto';
import type { OfferDto } from '@/domain/offerData/OfferDto';
import type { OfferPackagingDto } from '@/domain/offerData/OfferPackagingDto';
import type { OfferTradeAgreementDto } from '@/domain/offerData/OfferTradeAgreementDto';
import type { OrderAddressDto } from '@/domain/orderData/OrderAddressDto';
import type { OrderArticleDto } from '@/domain/orderData/OrderArticleDto';
import type { OrderBusinessCategoryDto } from '@/domain/orderData/OrderBusinessCategoryDto';
import type { OrderCustomerDto } from '@/domain/orderData/OrderCustomerDto';
import type { OrderDeliveryMethodDto } from '@/domain/orderData/OrderDeliveryMethodDto';
import type { OrderDeliveryTermDto } from '@/domain/orderData/OrderDeliveryTermDto';
import type { OrderDto } from '@/domain/orderData/OrderDto';
import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';
import type { OrderPackagingDto } from '@/domain/orderData/OrderPackagingDto';
import type { OrderPaymentMethodDto } from '@/domain/orderData/OrderPaymentMethodDto';
import type { OrderPaymentTermDto } from '@/domain/orderData/OrderPaymentTermDto';
import type { OrderTradeAgreementDto } from '@/domain/orderData/OrderTradeAgreementDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { useUserData } from '@/composables/data/useUserData';
import { useCommon } from '@/composables/useCommon';
import { useNordwal } from '@/composables/useNordwal';
import { config } from '@/config/config';
import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { OrderStatus } from '@/domain/enumeration/OrderStatus';
import { SegmentType } from '@/domain/enumeration/SegmentType';
import { i18n } from '@/plugins/i18n';
import { Optional } from '@/util/Optional';
import { UUID } from '@/util/UUID';
import { cloneDeep } from 'lodash';

export function useOrderFactory() {
  const userData = useUserData();
  const masterData = useMasterData();
  const common = useCommon();

  const { t, locale } = i18n.global;

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const copyOrder = function (order: OrderDto) {
    const timestamp = currentTimestamp();

    const newOrder: OrderDto = cloneDeep(order);
    newOrder.gid = UUID().v4();
    newOrder.createdAt = timestamp;
    newOrder.modifiedAt = timestamp;

    return newOrder;
  };

  const createOrderFromOffer = async function (offer: OfferDto) {
    // ORDER CUSTOMER OBJECT
    const orderCustomer: OrderCustomerDto = {
      id: offer.customer.id,

      customerTaxCode: offer.customer.customerTaxCode,
      code: offer.customer.code,
      name: offer.customer.name,
      iban: offer.customer.iban,
      sdiCode: offer.customer.sdiCode,
      vatCode: offer.customer.vatCode,
      fiscalCode: offer.customer.fiscalCode,
      language: offer.customer.language,
      note: offer.customer.note,
      priceListId: offer.customer.priceListId,
      discountListId: offer.customer.discountListId,
      invoiceCode: offer.customer.invoiceCode,
      alternativeName: offer.customer.alternativeName,
      webSiteUrl: offer.customer.webSiteUrl,
      segment: offer.customer.segment,
      isPrivate: offer.customer.isPrivate,
    };
    const newOrder: OrderDto = await createNewOrder(orderCustomer);

    // PRIMITIVE
    newOrder.discount = offer.discount;
    newOrder.invoiceAmount = offer.invoiceAmount;
    newOrder.deliveryDateRequest = offer.deliveryDateRequest;
    newOrder.meta = offer.meta;
    newOrder.orderNote = offer.note;
    newOrder.offerReferences = [offer.gid]; // FUNDAMENTAL: THIS IS VERY IMPORTANT FOR TRANSFORMING OFFER TO ORDER

    const offerInvoice = offer.invoiceAddress;

    // INVOICE OBJECT
    const invoiceAddress: OrderAddressDto = {
      id: offerInvoice.id,
      name: offerInvoice.name,
      code: offerInvoice.code,
      street: offerInvoice.street,
      zip: offerInvoice.zip,
      city: offerInvoice.city,
      region: offerInvoice.region,
      nation: offerInvoice.nation,
      district: offerInvoice.district,
      pec: offerInvoice.pec,
      email: offerInvoice.email,
      type: offerInvoice.type,
      phoneNumber: offerInvoice.phoneNumber,
      deliveryPhoneNumber: offerInvoice.deliveryPhoneNumber,
      daysOfRest: offerInvoice.daysOfRest,
      unloadingTimes: offerInvoice.unloadingTimes,
      businessCategory: offerInvoice.businessCategory,
      deliveryPhoneNotification: offerInvoice.deliveryPhoneNotification,
      latitude: offerInvoice.latitude,
      longitude: offerInvoice.longitude,
      photos: offerInvoice.photos,
    };
    newOrder.invoiceAddress = invoiceAddress;
    newOrder.deliveryAddressEqualToBilling = offer.deliveryAddressEqualToBilling;
    // DELIVERY OBJECT
    if (offer.deliveryAddress) {
      const offerDelivery = offer.deliveryAddress;

      const deliveryAddress: OrderAddressDto = {
        id: offerDelivery.id,
        name: offerDelivery.name,
        code: offerDelivery.code,
        street: offerDelivery.street,
        zip: offerDelivery.zip,
        city: offerDelivery.city,
        region: offerDelivery.region,
        nation: offerDelivery.nation,
        district: offerDelivery.district,
        pec: offerDelivery.pec,
        email: offerDelivery.email,
        type: offerDelivery.type,
        phoneNumber: offerDelivery.phoneNumber,
        deliveryPhoneNumber: offerDelivery.deliveryPhoneNumber,
        daysOfRest: offerDelivery.daysOfRest,
        unloadingTimes: offerDelivery.unloadingTimes,
        businessCategory: offerDelivery.businessCategory,
        deliveryPhoneNotification: offerDelivery.deliveryPhoneNotification,
        latitude: offerDelivery.latitude,
        longitude: offerDelivery.longitude,
        photos: offerDelivery.photos,
      };
      newOrder.deliveryAddress = deliveryAddress;
    }
    // DELIVERY METHOD OBJECT
    if (offer.deliveryMethod) {
      const deliveryMethod: OrderDeliveryMethodDto = {
        id: offer.deliveryMethod.id,
        code: offer.deliveryMethod.code,
        title: offer.deliveryMethod.title,
        default: offer.deliveryMethod.default,
      };
      newOrder.deliveryMethod = deliveryMethod;
    }
    // DELIVERY TERM OBJECT
    if (offer.deliveryTerm) {
      const deliveryTerm: OrderDeliveryTermDto = {
        id: offer.deliveryTerm.id,
        code: offer.deliveryTerm.code,
        title: offer.deliveryTerm.title,
        default: offer.deliveryTerm.default,
      };
      newOrder.deliveryTerm = deliveryTerm;
    }
    // PAYMENT METHOD OBJECT
    if (offer.paymentMethod) {
      const paymentMethod: OrderPaymentMethodDto = {
        id: offer.paymentMethod.id,
        code: offer.paymentMethod.code,
        title: offer.paymentMethod.title,
        default: offer.paymentMethod.default,
        ibanRequired: offer.paymentMethod.ibanRequired,
      };
      newOrder.paymentMethod = paymentMethod;
    }
    // PAYMENT TERM OBJECT
    if (offer.paymentTerm) {
      const paymentTerm: OrderPaymentTermDto = {
        id: offer.paymentTerm.id,
        code: offer.paymentTerm.code,
        title: offer.paymentTerm.title,
        default: offer.paymentTerm.default,
      };
      newOrder.paymentTerm = paymentTerm;
    }
    newOrder.depot = await getDefaultDepot();
    // ORDER ITEMS OBJECT
    if (offer.items.length > 0) {
      newOrder.items = offer.items.map((item) => {
        const orderItem: OrderItemDto = {
          quantity: item.quantity,
          freeQuantity: item.freeQuantity,
          unitPrice: item.unitPrice,
          tax: item.tax,
          finalPrice: item.finalPrice,
          discount1: item.discount1,
          discount2: item.discount2,
          discount3: item.discount3,
          discount4: item.discount4,
          discount5: item.discount5,
          discount6: item.discount6,
          note: item.note,
          isSomeDiscountEdited: item.isSomeDiscountEdited,
          isTradeAgreementPriceEdited: item.isTradeAgreementPriceEdited,
          requestedPartialDelivery: undefined,
          article: offerArticleToOrderArticle(item.article),
          packaging: offerPackagingToOrderPackaging(item.packaging),
          tradeAgreement: offerTradeAgreementToOrderTradeAgreementDto(item.tradeAgreement),
        };
        return orderItem;
      });
    }
    return cloneDeep(newOrder);
  };

  const offerArticleToOrderArticle = function (offerArticle: OfferArticleDto) {
    const orderArticle: OrderArticleDto = {
      id: offerArticle.id,
      code: offerArticle.code,
      type: offerArticle.type,
      unit: offerArticle.unit,
      sizeForNumber: offerArticle.sizeForNumber,
      sizeForWeight: offerArticle.sizeForWeight,
      contingentGroupId: offerArticle.contingentGroupId,
      title: offerArticle.title,
      productionDescription: offerArticle.productionDescription,
      priceGroupIds: offerArticle.priceGroupIds,
    };
    return orderArticle;
  };

  const offerPackagingToOrderPackaging = function (offerPackaging: OfferPackagingDto) {
    const orderPackaging: OrderPackagingDto = {
      id: offerPackaging.id,
      unit: offerPackaging.unit,
      size: offerPackaging.size,
    };
    return orderPackaging;
  };

  const offerTradeAgreementToOrderTradeAgreementDto = function (offerTradeAgreement: OfferTradeAgreementDto) {
    const orderTradeAgreement: OrderTradeAgreementDto = {
      id: offerTradeAgreement.id,
      price: offerTradeAgreement.price,
      isPromotional: offerTradeAgreement.isPromotional,
      editedPrice: offerTradeAgreement.editedPrice,
    };
    return orderTradeAgreement;
  };

  const createOrderForCustomer = async function (customerCode: string): Promise<OrderDto> {
    const customer = await masterData.tables.customers.where('code').equals(customerCode).first();

    if (!customer) {
      throw new Error(t('orders.CUSTOMER_NOT_FOUND'));
    }
    const orederCustomer: OrderCustomerDto = await toOrderCustomer(customer);
    const order: OrderDto = await createOrderWithCustomer(orederCustomer, customer.addresses);
    const discount: Optional<DiscountDto> = await common.getHeadDiscount(customer.id);
    order.discount = 0;
    if (discount.isPresent() && discount.get().discount1) {
      order.discount = discount.get().discount1 || 0;
    }
    order.agentId = customer.agentId;
    if (customer.agentName) {
      order.agentName = customer.agentName;
    }
    return order;
  };

  const createOrderForOrderCustomer = async function (orderCustomer: OrderCustomerDto): Promise<OrderDto> {
    const order: OrderDto = await createNewOrder(orderCustomer);
    order.invoiceAddress = createNewEmptyInvoiceAddress();
    order.deliveryAddress = null;

    return order;
  };

  const toOrderCustomer = async function (customer: CustomerDto): Promise<OrderCustomerDto> {
    const orderCustomer: OrderCustomerDto = {
      // CUSTOMER INFO
      id: customer.id,
      priceListId: customer.priceListId,
      discountListId: customer.discountListId,
      customerTaxCode: customer.customerTaxCode,
      code: customer.code,
      invoiceCode: customer.invoiceCode,
      name: customer.name,
      vatCode: customer.vatCode,
      fiscalCode: customer.fiscalCode,
      sdiCode: customer.sdiCode,
      iban: customer.iban,
      language: customer.language?.toLowerCase(),
      note: customer.note,
      alternativeName: customer.alternativeName,
      webSiteUrl: customer.webSiteUrl,
      segment: customer.segment,
    };
    // Remove whitespaces from customer's iban
    if (orderCustomer.iban) {
      orderCustomer.iban = orderCustomer.iban.replace(/\s+/g, '');
    }
    // Remove whitespaces from fiscal code
    if (orderCustomer.fiscalCode) {
      orderCustomer.fiscalCode = orderCustomer.fiscalCode.replace(/\s+/g, '');
    }
    return orderCustomer;
  };

  const resolveBusinessCategory = async function (
    businessCategoryId: number | null
  ): Promise<OrderBusinessCategoryDto | null> {
    if (!businessCategoryId) {
      return null;
    }
    const businessCategory = await masterData.tables.businessCategories.get(businessCategoryId);
    if (!businessCategory) {
      throw new Error(t('orders.CATEGORIES_ERROR') + businessCategoryId);
    }
    return {
      id: businessCategory.id,
      code: businessCategory.code,
      title: businessCategory.title,
    };
  };

  const createOrderWithCustomer = async function (
    orderCustomer: OrderCustomerDto,
    addresses: Array<AddressDto>
  ): Promise<OrderDto> {
    const billingAddress = addresses.find((a) => a.type != CustomerAddressType.DELIVERY);
    if (!billingAddress) {
      //Sorry, customer has no billing address!
      return Promise.reject(t('orders.noBillingAddress'));
    }
    const order: OrderDto = await createNewOrder(orderCustomer);
    const newInvoiceAddress: OrderAddressDto = {
      id: billingAddress.id,
      code: billingAddress.code,
      name: billingAddress.name,
      type: billingAddress.type,
      street: billingAddress.street,
      city: billingAddress.city,
      zip: billingAddress.zip,
      region: billingAddress.region,
      nation: billingAddress.nation,
      district: billingAddress.district,
      pec: billingAddress.pec,
      email: billingAddress.email,
      daysOfRest: billingAddress.daysOfRest,
      unloadingTimes: billingAddress.unloadingTimes,
      deliveryPhoneNotification: billingAddress.deliveryPhoneNotification,
      phoneNumber: billingAddress.phoneNumber,
      latitude: '',
      longitude: '',
      photos: [],
      deliveryPhoneNumber: billingAddress.deliveryPhoneNumber,
      businessCategory: await resolveBusinessCategory(billingAddress.businessCategoryId),
    };
    order.invoiceAddress = newInvoiceAddress;
    // SET TO DELIVERY TO DEFAULT VALUE NULL
    order.deliveryAddress = null;
    if (CustomerAddressType.BOTH === billingAddress.type) {
      order.deliveryAddress = cloneDeep(order.invoiceAddress);
    } else {
      const deliveryAddresses = addresses.filter((a) => a.type != CustomerAddressType.BILLING);
      if (deliveryAddresses.length === 1) {
        const newDeliveryAddress: OrderAddressDto = {
          id: deliveryAddresses[0].id,
          code: deliveryAddresses[0].code,
          name: deliveryAddresses[0].name,
          type: deliveryAddresses[0].type,
          street: deliveryAddresses[0].street,
          city: deliveryAddresses[0].city,
          zip: deliveryAddresses[0].zip,
          region: deliveryAddresses[0].region,
          nation: deliveryAddresses[0].nation,
          district: deliveryAddresses[0].district,
          pec: deliveryAddresses[0].pec,
          email: deliveryAddresses[0].email,
          daysOfRest: deliveryAddresses[0].daysOfRest,
          unloadingTimes: deliveryAddresses[0].unloadingTimes,
          deliveryPhoneNotification: deliveryAddresses[0].deliveryPhoneNotification,
          phoneNumber: deliveryAddresses[0].phoneNumber,
          latitude: '',
          longitude: '',
          photos: [],
          deliveryPhoneNumber: deliveryAddresses[0].deliveryPhoneNumber,
          businessCategory: await resolveBusinessCategory(deliveryAddresses[0].businessCategoryId),
        };
        order.deliveryAddress = newDeliveryAddress;
      }
    }
    return order;
  };

  const createNewOrder = async function (customer: OrderCustomerDto): Promise<OrderDto> {
    const hierarchy = await getAllHierarchy();

    const timestamp = currentTimestamp();

    const order: OrderDto = {
      gid: UUID().v4(),
      createdAt: timestamp,
      modifiedAt: timestamp,
      orderVersion: 0,
      status: OrderStatus.DRAFT,
      orderDate: null,
      orderNumber: '',
      agentHierarchyList: hierarchy,
      meta: {
        validationActive: {
          customer: false,
          shoppingCart: false,
          orderData: false,
          notes: false,
        },
      },
      synced: 0,
      customer: customer,
      invoiceAddress: createNewEmptyInvoiceAddress(),
      deliveryAddress: null,
      paymentMethod: await getDefaultPaymentMethod(),
      paymentTerm: await getDefaultPaymentTerm(),
      deliveryMethod: await getDefaultDeliveryMethod(),
      deliveryTerm: await getDefaultDeliveryTerm(),
      depot: await getDefaultDepot(),
      deliveryDateRequest: null,
      shippingDateRequest: null,
      discount: 0,
      invoiceAmount: null,
      internalNote: '',
      orderNote: customer.note ?? '',
      items: [],
      offerReferences: [],
      deliveryAddressEqualToBilling: false,
      userId: 0,
    };
    if (hierarchy[0]) {
      order.agentId = hierarchy[0].authUser.id;
      order.agentName = hierarchy[0].authUser.name;

      if (hierarchy[0].workerAuthUser) {
        order.userId = hierarchy[0].workerAuthUser.id;
        order.userName = hierarchy[0].workerAuthUser.name;
      } else {
        order.userId = hierarchy[0].authUser.id;
        order.userName = hierarchy[0].authUser.name;
      }
    }
    return order;
  };

  const createNewEmptyOrderCustomer = function (): OrderCustomerDto {
    const newEmptyOrderCustomer: OrderCustomerDto = {
      id: null,
      customerTaxCode: 'VKIT',
      segment: SegmentType.SALES,
      code: '',
      name: '',
      language: '',
      note: '',
      vatCode: '',
      fiscalCode: '',
      sdiCode: '',
      iban: '',
      priceListId: -1,
      discountListId: null,
      alternativeName: '',
      invoiceCode: '',
      webSiteUrl: '',
      isPrivate: false,
    };
    return newEmptyOrderCustomer;
  };

  const createNewEmptyInvoiceAddress = function (): OrderAddressDto {
    const newEmptyInvoiceAddress: OrderAddressDto = {
      id: null,
      code: '',
      name: '',
      district: '',
      zip: '',
      type: CustomerAddressType.BILLING,
      street: '',
      phoneNumber: '',
      deliveryPhoneNumber: null,
      email: '',
      pec: '',
      city: '',
      nation: '',
      region: '',
      latitude: null,
      longitude: null,
      deliveryPhoneNotification: false,
      unloadingTimes: [],
      daysOfRest: [],
      photos: [],
      businessCategory: null,
    };
    return newEmptyInvoiceAddress;
  };

  const createNewEmptyDeliveryAddress = function (): OrderAddressDto {
    const newEmptyDeliveryAddress: OrderAddressDto = {
      id: null,
      code: '',
      name: '',
      district: '',
      zip: '',
      type: CustomerAddressType.DELIVERY,
      street: '',
      phoneNumber: '',
      deliveryPhoneNumber: null,
      email: '',
      pec: '',
      city: '',
      nation: '',
      region: '',
      latitude: null,
      longitude: null,
      deliveryPhoneNotification: false,
      unloadingTimes: [],
      daysOfRest: [],
      photos: [],
      businessCategory: null,
    };
    return newEmptyDeliveryAddress;
  };

  const getAllHierarchy = async function () {
    return (await userData.tables.hierarchy.toArray()) || [];
  };

  const getDefaultPaymentMethod = async function () {
    return (await masterData.tables.paymentMethods.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultPaymentTerm = async function () {
    return (await masterData.tables.paymentTerms.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultDeliveryMethod = async function () {
    return (await masterData.tables.deliveryMethods.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultDeliveryTerm = async function () {
    return (await masterData.tables.deliveryTerms.filter((item) => item.default === true).first()) || null;
  };

  const getDefaultDepot = async function () {
    const depots = await masterData.tables.depots.toArray();
    if (depots.length === 0) {
      return null;
    }
    const defaultDepot = await masterData.tables.depots.filter((item) => item.default === true).first();
    if (defaultDepot) {
      return defaultDepot;
    }
    return depots[0]; //get the first, because no default value was founded
  };

  const getDimOptionsByArticle = async function (order: OrderDto, articleId: number, priceGroupIds: Array<number>) {
    const article = await masterData.tables.articles.get(articleId);
    if (article) {
      const customerId = order.customer.id;
      const priceListId = order.customer.priceListId;

      const allTradeAgreement = await common.getTradeAgreements(articleId, priceGroupIds, customerId, priceListId);
      const taDimensionIds = allTradeAgreement
        .filter((t) => !!t.articleDimensionValueConfigId)
        .map((t) => t.articleDimensionValueConfigId);

      let dimensionIds: number[] = article.articleDimensionValueConfigIds;
      if (dimensionIds.length === 0) {
        return null;
      }
      if (taDimensionIds.length > 0) {
        dimensionIds = dimensionIds.filter((d) => taDimensionIds.includes(d));
      }
      return await getDimOptions(dimensionIds, article.defaultDimensionValueConfigIds);
    }
    return null;
  };

  const getDimOptions = async function (dimensionIds: Array<number>, defaultDimensions: Array<number> = []) {
    if (dimensionIds.length === 0) {
      return null;
    }
    const dimensionValueConfigs = await masterData.tables.articleDimensionValueConfigs.toArray();
    const dimOptions: { [key: string]: Array<DimensionItemDto> } = {};

    dimensionValueConfigs.sort((a, b) => {
      const vb = b.title[locale.value];
      const va = a.title[locale.value];

      if (!va && !vb) {
        return 0;
      } else if (!va) {
        return 1;
      } else if (!vb) {
        return -1;
      }
      return va.localeCompare(vb);
    });

    dimensionValueConfigs.forEach((item) => {
      if (dimensionIds.includes(item.id)) {
        const key = item.dimensionConfig.id.toString();
        if (dimOptions[key]) {
          dimOptions[key].push({
            id: item.dimensionConfig.id,
            code: item.dimensionConfig.code,
            title: item.dimensionConfig.title,
            value: {
              id: item.id,
              code: item.code,
              title: item.title,
              default: defaultDimensions.includes(item.id),
            },
          });
        } else {
          dimOptions[key] = [
            {
              id: item.dimensionConfig.id,
              code: item.dimensionConfig.code,
              title: item.dimensionConfig.title,
              value: {
                id: item.id,
                code: item.code,
                title: item.title,
                default: defaultDimensions.includes(item.id),
              },
            },
          ];
        }
      }
    });
    return dimOptions;
  };

  const getTradeAgreementPriceAndDisconts = async function (
    order: OrderDto,
    articleId: number,
    priceGroupIds: Array<number>,
    qt: number = 0,
    dimValConfId?: number
  ) {
    const tradeAgreementPriceAndDiscounts: TradeAgreementPriceAndDiscounts = {
      tradeAgreement: undefined,
      price: 0,
      discounts: [],
    };

    if (order && qt > 0) {
      const customerId = order.customer.id;
      const priceListId = order.customer.priceListId;
      const discountListId = order.customer.discountListId;
      const segment = order.customer.segment;

      const allTradeAgreement = await common.getTradeAgreements(articleId, priceGroupIds, customerId, priceListId);
      const tradeAgreements = [];
      if (dimValConfId) {
        const tempTa = allTradeAgreement
          .filter((t) => t.fromQuantity <= qt && t.articleDimensionValueConfigId === dimValConfId)
          .sort((t1, t2) => t1.fromQuantity - t2.fromQuantity);

        tradeAgreements.push(...tempTa);
      } else {
        const tempTa = allTradeAgreement
          .filter((t) => t.fromQuantity <= qt && !t.articleDimensionValueConfigId)
          .sort((t1, t2) => t1.fromQuantity - t2.fromQuantity);

        tradeAgreements.push(...tempTa);
      }
      /* GET TRADE AGREEMENT */
      const tradeAgreement = getFinalTradeAgreement(tradeAgreements, segment);

      /* GET DISCOUNT */
      const isPromotional =
        tradeAgreement && tradeAgreement.price > 0 && tradeAgreement.customerType === CustomerType.PROMOTION;

      let discount: DiscountDto | undefined = undefined;
      if (!isPromotional && tradeAgreement) {
        if (config.company.code === 'NORDWAL') {
          const nordwal = useNordwal();

          const priceGroupId = tradeAgreement.priceGroupId ?? null;

          const discounts = await nordwal.getPositionDiscounts(
            tradeAgreement.customerType,
            articleId,
            priceGroupId,
            customerId,
            priceListId,
            qt
          );
          if (discounts.length > 0 && tradeAgreement.customerType !== CustomerType.ALL && tradeAgreement.price === 0) {
            const baseTa = await nordwal.getBaseTradeAgreements(articleId, segment);
            if (baseTa) {
              tradeAgreement.price = baseTa.salesPrice;
              if (segment && segment === SegmentType.INDUSTRIAL) {
                tradeAgreement.price = baseTa.industrialPrice;
              }
            }
          }
          if (discounts.length > 0) {
            discount = nordwal.getFinalDiscount(tradeAgreement, discounts, segment, qt);
          }
        } else {
          const priceGroupId = tradeAgreement.priceGroupId ?? null;

          const discounts = await common.getPositionDiscounts(
            articleId,
            priceGroupId,
            customerId,
            priceListId,
            discountListId,
            qt
          );
          if (discounts.length > 0) {
            discount = getFinalDiscount(tradeAgreement, discounts, qt);
          }
        }
        if (discount) {
          /* NON ZERO DISCOUNT */
          type objectKey = keyof typeof discount;
          for (let i = 0; i < config.company.maxItemDiscounts; i++) {
            const key = `discount${i + 1}` as objectKey;
            const value = discount[key] as number;
            if (value && value > 0) {
              tradeAgreementPriceAndDiscounts.discounts.push(value);
            }
          }
        }
      }
      tradeAgreementPriceAndDiscounts.tradeAgreement = tradeAgreement;
      tradeAgreementPriceAndDiscounts.price = getPrice(segment, tradeAgreement);
    }
    return tradeAgreementPriceAndDiscounts;
  };

  const filterBySegment = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
      return true;
    }
    if (segment && segment === SegmentType.INDUSTRIAL) {
      return ta.industrialPrice > 0;
    }
    return ta.salesPrice > 0;
  };

  const getFinalTradeAgreement = function (tas: TradeAgreementDto[], segment: string | null) {
    if (tas.length === 1) {
      return tas.pop();
    }
    const groupByQuantity = tas.reduce((group: { [key: number]: TradeAgreementDto[] }, ta: TradeAgreementDto) => {
      const fromQuantity = ta.fromQuantity;

      group[fromQuantity] = group[fromQuantity] ?? [];
      group[fromQuantity].push(ta);

      return group;
    }, {});
    const finaltas: TradeAgreementDto[] = [];

    Object.values(groupByQuantity).forEach((value) => {
      if (value.length > 1) {
        const taPromotion = value.filter((ta) => ta.customerType === CustomerType.PROMOTION);
        const taCustomerArticle = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_ARTICLE);
        const taCustomerPriceGroup = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_PRICEGROUP);
        const taPriceListArticle = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_ARTICLE);
        const taPriceListPriceGroup = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_PRICEGROUP);
        const taAll = value.filter((ta) => ta.customerType === CustomerType.ALL && filterBySegment(ta, segment));

        if (taPromotion.length > 0) {
          finaltas.push(taPromotion[0]);
        } else if (taCustomerArticle.length > 0) {
          finaltas.push(taCustomerArticle[0]);
        } else if (taCustomerPriceGroup.length > 0) {
          finaltas.push(taCustomerPriceGroup[0]);
        } else if (taPriceListArticle.length > 0) {
          finaltas.push(taPriceListArticle[0]);
        } else if (taPriceListPriceGroup.length > 0) {
          finaltas.push(taPriceListPriceGroup[0]);
        } else if (taAll.length > 0) {
          finaltas.push(taAll[0]);
        }
      } else {
        finaltas.push(...value);
      }
    });
    return finaltas.pop();
  };

  const getPrice = (segment: string | null, ta?: TradeAgreementDto) => {
    if (!ta) {
      console.warn('Trade agreement is not defined');
      return 0;
    }
    if (ta.customerType === CustomerType.ALL) {
      if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
        return ta.price;
      }
      if (segment && segment.length > 0 && segment === SegmentType.INDUSTRIAL) {
        return ta.industrialPrice;
      }
      return ta.salesPrice;
    }
    return ta.price;
  };

  const getFinalDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], qt: number) {
    if (ds.length === 1) {
      return ds[0];
    }
    let finalDiscount: DiscountDto | undefined = undefined;

    const disWithoutDim = ds
      .filter((d) => !d.articleDimensionValueConfigId)
      .sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

    let discounts = disWithoutDim;
    if (ta.articleDimensionValueConfigId) {
      const disWithDim = ds
        .filter((d) => d.articleDimensionValueConfigId === ta.articleDimensionValueConfigId)
        .sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

      if (disWithDim.length > 0) {
        discounts = disWithDim;
      }
      if (discounts.length > 0 && discounts[0].fromQuantity > ta.fromQuantity) {
        const tfds = disWithoutDim.filter((d) => d.fromQuantity > 0 && d.fromQuantity < discounts[0].fromQuantity);
        if (tfds.length > 0) {
          const fdsg = tfds.filter((d) => d.fromQuantity > ta.fromQuantity);
          const fdsm = tfds.filter((d) => d.fromQuantity <= ta.fromQuantity);
          fdsg.unshift(fdsm[fdsm.length - 1]);

          discounts.unshift(...fdsg);
        }
      }
    }
    const discountsFromQt = discounts.filter((d) => d.fromQuantity === qt);
    if (discountsFromQt.length > 0) {
      finalDiscount = discountsFromQt[0];
    } else {
      discountsFromQt.push(
        discounts.reduce((d1, d2) => {
          return Math.abs(d2.fromQuantity - qt) < Math.abs(d1.fromQuantity - qt) ? d2 : d1;
        })
      );
      finalDiscount = discountsFromQt.length > 0 ? discountsFromQt[0] : undefined;
    }
    return finalDiscount;
  };

  const copyAddress = function (addressIncoming: AddressDto) {
    const outgoingAddress: AddressDto = {
      id: addressIncoming.id,
      businessCategoryId: addressIncoming.businessCategoryId,
      deliveryMethodId: addressIncoming.deliveryMethodId,
      deliveryTermId: addressIncoming.deliveryTermId,
      code: addressIncoming.code,
      name: addressIncoming.name,
      type: addressIncoming.type,
      street: addressIncoming.street,
      zip: addressIncoming.zip,
      city: addressIncoming.city,
      district: addressIncoming.district,
      region: addressIncoming.region,
      nation: addressIncoming.nation,
      phoneNumber: addressIncoming.phoneNumber,
      deliveryPhoneNumber: addressIncoming.deliveryPhoneNumber,
      email: addressIncoming.email,
      pec: addressIncoming.pec,
      deliveryPhoneNotification: addressIncoming.deliveryPhoneNotification,
      daysOfRest: addressIncoming.daysOfRest,
      unloadingTimes: addressIncoming.unloadingTimes,
    };
    return outgoingAddress;
  };

  const copyToOrderAddress = async function (addressIncoming: AddressDto) {
    const outgoingAddress: OrderAddressDto = {
      id: addressIncoming.id,
      businessCategoryId: addressIncoming.businessCategoryId,
      deliveryMethodId: addressIncoming.deliveryMethodId,
      deliveryTermId: addressIncoming.deliveryTermId,
      code: addressIncoming.code,
      name: addressIncoming.name,
      type: addressIncoming.type,
      street: addressIncoming.street,
      zip: addressIncoming.zip,
      city: addressIncoming.city,
      district: addressIncoming.district,
      region: addressIncoming.region,
      nation: addressIncoming.nation,
      phoneNumber: addressIncoming.phoneNumber,
      email: addressIncoming.email,
      pec: addressIncoming.pec,
      latitude: '',
      longitude: '',
      deliveryPhoneNotification: addressIncoming.deliveryPhoneNotification,
      daysOfRest: addressIncoming.daysOfRest,
      unloadingTimes: addressIncoming.unloadingTimes,
      photos: [],
      deliveryPhoneNumber: addressIncoming.deliveryPhoneNumber,
      businessCategory: await resolveBusinessCategory(addressIncoming.businessCategoryId),
    };
    return outgoingAddress;
  };

  const copyOrderAddress = function (addressIn: OrderAddressDto) {
    const addressOut: OrderAddressDto = {
      id: addressIn.id,
      businessCategoryId: addressIn.businessCategoryId,
      deliveryMethodId: addressIn.deliveryMethodId,
      deliveryTermId: addressIn.deliveryTermId,
      code: addressIn.code,
      name: addressIn.name,
      type: addressIn.type,
      street: addressIn.street,
      zip: addressIn.zip,
      city: addressIn.city,
      district: addressIn.district,
      region: addressIn.region,
      nation: addressIn.nation,
      phoneNumber: addressIn.phoneNumber,
      deliveryPhoneNumber: addressIn.deliveryPhoneNumber,
      email: addressIn.email,
      pec: addressIn.pec,
      latitude: addressIn.latitude,
      longitude: addressIn.longitude,
      photos: addressIn.photos,
      businessCategory: addressIn.businessCategory,
      deliveryPhoneNotification: addressIn.deliveryPhoneNotification,
      daysOfRest: addressIn.daysOfRest,
      unloadingTimes: addressIn.unloadingTimes,
    };
    return addressOut;
  };

  const initializeAddress = function (): OrderAddressDto {
    const initialazedAddress: OrderAddressDto = {
      id: null,
      businessCategoryId: null,
      deliveryMethodId: null,
      deliveryTermId: null,
      businessCategory: null,

      code: '',
      name: '',
      type: '',
      street: '',
      zip: '',
      city: '',
      district: '',
      region: '',
      nation: '',
      phoneNumber: '',
      deliveryPhoneNumber: '',
      email: '',
      pec: '',
      latitude: '',
      longitude: '',

      deliveryPhoneNotification: false,

      photos: [],
      daysOfRest: [],
      unloadingTimes: [],
    };
    return initialazedAddress;
  };

  const getContingentCustomerAndArticle = async function (
    article: ArticlePackaging,
    articleId: number,
    packageId: number,
    customerId: number,
    dimensionId: number,
    contingentGroupId?: number,
    agentId?: number
  ): Promise<ContingentDto | undefined> {
    if (!contingentGroupId || !agentId || !article || !article[articleId] || !article[articleId][packageId]) {
      return undefined;
    }
    const internalArticle = article[articleId][packageId];
    if (internalArticle.dimensions) {
      const customerContingent = await masterData.tables.contingents
        .where('[agentId+customerId+contingentGroupArticleId+articleDimensionValueConfigId]')
        .equals([agentId, customerId, contingentGroupId, dimensionId])
        .first();
      if (customerContingent) {
        return customerContingent;
      }

      const customerContingentGroupArticle = await masterData.tables.contingents
        .where('[agentId+contingentGroupArticleId+articleDimensionValueConfigId]')
        .equals([agentId, contingentGroupId, dimensionId])
        .filter((c) => c.customerId === null)
        .first();
      if (customerContingentGroupArticle) {
        return customerContingentGroupArticle;
      }
    }
    return undefined;
  };

  const getContingentCustomerAndOrderArticle = async function (
    dimensionId: number,
    customerId: number | null,
    article?: OrderArticleDto,
    agentId?: number
  ): Promise<ContingentDto | undefined> {
    if (!article || !article.contingentGroupId || !agentId) {
      return undefined;
    }
    if (article.dimensions) {
      const customerContingent = await masterData.tables.contingents
        .where('[agentId+customerId+contingentGroupArticleId+articleDimensionValueConfigId]')
        .equals([agentId ?? -1, customerId ?? -1, article.contingentGroupId, dimensionId])
        .first();
      if (customerContingent) {
        return customerContingent;
      }

      const customerContingentGroupArticle = await masterData.tables.contingents
        .where('[agentId+contingentGroupArticleId+articleDimensionValueConfigId]')
        .equals([agentId, article.contingentGroupId, dimensionId])
        .filter((c) => c.customerId === null)
        .first();
      if (customerContingentGroupArticle) {
        return customerContingentGroupArticle;
      }
    }
    return undefined;
  };

  const getCustomer = async (customerCode: string | undefined) => {
    if (customerCode) {
      const customer = await masterData.tables.customers.where('code').equals(customerCode).first();
      return customer;
    } else {
      return;
    }
  };

  const getArticleCustomerTax = async (customerTaxCode: string | null | undefined, articleId: number) => {
    if (!customerTaxCode) {
      return undefined;
    }
    const article = await masterData.tables.articles.where('id').equals(articleId).first();
    if (article && article.articleTaxCode) {
      const tax = await masterData.tables.taxes
        .where('[customerTaxCode+articleTaxCode]')
        .equals([customerTaxCode, article.articleTaxCode])
        .first();
      if (tax) {
        return tax.value;
      }
    }
    return undefined;
  };

  return {
    copyOrder,
    createOrderFromOffer,
    createOrderForCustomer,
    createOrderForOrderCustomer,
    createNewEmptyOrderCustomer,
    createNewEmptyDeliveryAddress,
    toOrderCustomer,
    getDimOptionsByArticle,
    getDimOptions,
    getTradeAgreementPriceAndDisconts,
    filterBySegment,
    getPrice,
    getFinalTradeAgreement,
    getFinalDiscount,
    copyAddress,
    copyToOrderAddress,
    initializeAddress,
    copyOrderAddress,
    getContingentCustomerAndArticle,
    getCustomer,
    getContingentCustomerAndOrderArticle,
    getArticleCustomerTax,
  };
}
