<template>
  <svg id="ic_paymentinfo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <circle id="Ellipse_224" data-name="Ellipse 224" cx="25" cy="25" r="25" fill="none" />
    <g id="Gruppe_9350" data-name="Gruppe 9350" transform="translate(-2170.934 -1228.906)">
      <path
        id="Pfad_14634"
        data-name="Pfad 14634"
        d="M17.594,25.443H5.844A1.936,1.936,0,0,1,3.75,23.72V6.5A1.936,1.936,0,0,1,5.844,4.778H17.594A1.936,1.936,0,0,1,19.688,6.5V23.72A1.936,1.936,0,0,1,17.594,25.443Z"
        transform="translate(2181.184 1240.825)"
        fill="none"
        stroke="#86939f"
        stroke-width="2"
      />
      <path
        id="Pfad_14635"
        data-name="Pfad 14635"
        d="M6.287,7c0-.019,0-3.766,0-3.785a1.487,1.487,0,0,1,1.423-1.5H19.3a1.492,1.492,0,0,1,1.424,1.553v16.28a1.494,1.494,0,0,1-1.411,1.553H14.443"
        transform="translate(2186.289 1239.184)"
        fill="none"
        stroke="#86939f"
        stroke-width="2"
      />
      <line
        id="Linie_351"
        data-name="Linie 351"
        x2="7.725"
        transform="translate(2189.039 1251.708)"
        fill="none"
        stroke="#212529"
        stroke-width="2"
      />
      <line
        id="Linie_352"
        data-name="Linie 352"
        x2="7.725"
        transform="translate(2189.039 1255.936)"
        fill="none"
        stroke="#212529"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
