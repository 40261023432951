import { ref } from 'vue';

const footerVisible = ref(true);
const footerEnabled = ref(true);

export function useTheFooter() {
  const visible = function (isVisible: boolean) {
    footerVisible.value = isVisible;
  };

  const enabled = function (isEnabled: boolean) {
    footerEnabled.value = isEnabled;
  };

  return {
    visible,
    enabled,
    footerVisible,
    footerEnabled,
  };
}
