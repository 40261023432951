export class DownloadsFilter {
  search: string;
  language: string;
  category?: number;
  constructor(search = '', language = '', category?: number) {
    this.search = search;
    this.language = language;
    if (undefined !== category) {
      this.category = category;
    }
  }
}
