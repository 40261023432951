<template>
  <div v-if="offer" class="md:mx-[80px] max-md:mx-16 mt-12 mb-32 space-y-12 min-w-fit">
    <OfferInfo />

    <div class="flex flex-col gap-12">
      <AgSearchSelect
        :options="selectAdressesOptions"
        :placeholder="t('offers.deliveryAddress')"
        v-model="selectedAddressCode"
        @update:model-value="() => (selectedNewDeliveryAddress = false)"
      />
      <div v-if="selectedAddressCode === 'fromBilling' || offer.deliveryAddressEqualToBilling">
        <AgAlert variant="info">
          {{ t('offers.editingBillingAndDelivery') }}
          <template #icon><IcInfo /></template>
        </AgAlert>
      </div>
      <div class="flex gap-8">
        <input
          type="radio"
          id="newAddr"
          name="newAddrOrFromBilling"
          value="newAddr"
          v-model="selectedNewDeliveryAddress"
          class="hover:cursor-pointer"
          :checked="wizzardAddressOrrigin[offer.gid] === 'newAddress'"
          @change="selectedAddressCode = 'newAddress'"
        />
        <label for="newAddr" class="font-bold min-w-fit cursor-pointer">{{ t('offers.newDeliveryAddress') }}</label>
      </div>
      <div v-if="isNewCustomer()">
        <input
          type="radio"
          id="fromBilling"
          name="newAddrOrFromBilling"
          value="fromBilling"
          class="hover:cursor-pointer"
          :checked="wizzardAddressOrrigin[offer.gid] === 'fromBilling'"
          @change="selectedAddressCode = 'fromBilling'"
        />
        <label for="fromBilling" class="ml-4 font-bold cursor-pointer">
          {{ t('offers.deliveryLikeBillingAddress') }}
        </label>
      </div>

      <div class="pb-24" v-if="selectedDeliveryAddress">
        <div class="w-fit">
          <input
            type="checkbox"
            id="phoneNotice"
            name="phoneNotice"
            value="phoneNotice"
            class="mt-8 cursor-pointer"
            v-model="selectedDeliveryAddress.deliveryPhoneNotification"
          />
          <label
            for="phoneNotice"
            class="ml-4 cursor-pointer"
            @click.prevent="
              selectedDeliveryAddress.deliveryPhoneNotification = !selectedDeliveryAddress.deliveryPhoneNotification
            "
          >
            <span class="font-bold">{{ t('offers.telephoneNotice') }}&nbsp;</span>
            <span>{{ t('offers.telephoneNoticeAdvie') }}</span>
          </label>
        </div>
        <div class="flex justify-start">
          {{ t('offers.selectedAdress') }}:&nbsp;
          <span v-if="selectedDeliveryAddress.code">{{ selectedDeliveryAddress.code }}</span>
          <span v-else>{{ t('offers.newDeliveryAddress').toLowerCase() }}</span>
        </div>
        <div class="space-y-4">
          <div>
            <AgFormGroup :label="t('offers.name')" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.name"
                :validation-state="validationAddress.name.state"
                :errorMessage="validationAddress.name.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex flex-row max-md:flex-col gap-12">
            <!-- ADDRESS STREET -->
            <AgFormGroup :label="t('offers.street')" class="basis-1/3" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.street"
                :validation-state="validationAddress.street.state"
                :errorMessage="validationAddress.region.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS CITY -->
            <AgFormGroup :label="t('offers.city')" class="basis-1/3" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.city"
                :validation-state="validationAddress.city.state"
                :errorMessage="validationAddress.city.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS DISTRICT -->
            <AgFormGroup :label="t('offers.district')" class="basis-1/3">
              <AgFormInput v-model="selectedDeliveryAddress.district" />
            </AgFormGroup>
          </div>
          <div class="flex max-sm:flex-col gap-12 py-4">
            <!-- COUNTRY -->
            <AgFormGroup class="basis-2/3" :label="t('offers.country')" required>
              <AgSearchSelect
                :options="countryOptions"
                v-model="selectedDeliveryAddress.nation"
                :validation-state="validationAddress.nation.state"
                :errorMessage="validationAddress.nation.msg"
              />
            </AgFormGroup>
            <!-- ZIP CODE -->
            <AgFormGroup class="basis-1/3" :label="t('offers.zipCode')" required>
              <AgFormInput
                type="string"
                v-model="selectedDeliveryAddress.zip"
                :validation-state="validationAddress.zip.state"
                :errorMessage="validationAddress.zip.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS REGION -->
            <AgFormGroup class="basis-1/3" :label="t('offers.region')" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.region"
                :validation-state="validationAddress.region.state"
                :errorMessage="validationAddress.region.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex flex-row max-md:flex-col gap-12">
            <!-- PHONE -->
            <AgFormGroup class="basis-1/3" :label="t('offers.phone')" required>
              <AgFormInput
                type="tel"
                v-model="selectedDeliveryAddress.phoneNumber"
                :validation-state="validationAddress.phone.state"
                :errorMessage="validationAddress.phone.msg"
              />
            </AgFormGroup>
            <!-- DELIVERY PHONE ADVICE-->
            <AgFormGroup
              class="basis-1/3"
              :label="t('offers.deliveryPhoneNumber')"
              :required="selectedDeliveryAddress.deliveryPhoneNotification"
            >
              <AgFormInput
                type="tel"
                v-model="selectedDeliveryAddress.deliveryPhoneNumber"
                :validation-state="validationAddress.deliveryPhone.state"
                :errorMessage="validationAddress.deliveryPhone.msg"
              />
            </AgFormGroup>
            <!-- MAIL -->
            <AgFormGroup class="basis-1/3" :label="t('offers.mail')" :required="offer.deliveryAddressEqualToBilling">
              <AgFormInput
                type="email"
                v-model="selectedDeliveryAddress.email"
                :validation-state="validationAddress.email.state"
                :errorMessage="validationAddress.email.msg"
              />
            </AgFormGroup>
            <!-- PEC -->
            <AgFormGroup class="basis-1/3" :label="t('offers.pecMail')">
              <AgFormInput
                type="email"
                v-model="selectedDeliveryAddress.pec"
                :validation-state="validationAddress.pec.state"
                :errorMessage="validationAddress.pec.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex max-sm:flex-col gap-12">
            <!-- LATITUDE -->
            <AgFormGroup class="basis-1/3" :label="t('offers.latitude')">
              <AgFormInputGeolocation v-model="selectedDeliveryAddress.latitude" geoType="Latitude" />
            </AgFormGroup>
            <!-- LONGITUDE -->
            <AgFormGroup class="basis-1/3" :label="t('offers.longitude')">
              <AgFormInputGeolocation v-model="selectedDeliveryAddress.longitude" geoType="Longitude" />
            </AgFormGroup>
            <!-- BUSINESS CATEGORY -->
            <AgFormGroup class="basis-1/3" :label="t('offers.section')">
              <AgSearchSelect :options="businessCategoryOptions" v-model="selectedBusinessCategory" />
            </AgFormGroup>
          </div>
        </div>
        <div class="py-8">
          <div class="text-l pt-12 font-bold">{{ t('offers.daysOfRest') }}</div>

          <!-- DELIVERY ADRESS DAYS OF REST -->
          <div class="flex max-md:flex-wrap gap-12 py-8 justify-between">
            <div v-for="(dayOfRest, index) in daysOfRest" :key="index">
              <div class="flex flex-col items-center hover:cursor-pointer" @click="openPopupDayOfRest(dayOfRest.day)">
                <AgCircleProgressBar :start="dayOfRest.start" :end="dayOfRest.end" :max="12" :size="90" rounded>
                  {{ t(`offers.day${dayOfRest.day}`) }}
                </AgCircleProgressBar>
                <div>{{ dayOfRest.timeStart.substring(0, 5) }} - {{ dayOfRest.timeEnd.substring(0, 5) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-8">
          <!-- DELIVERY ADRESS UNLOADING TIMES -->
          <div class="flex justify-between items-center gap-16">
            <div class="text-l pt-12 font-bold">
              {{ t('offers.unloadingTimes') }}
            </div>
            <AgButton
              variant="ghost"
              class="px-8 text-link"
              @click="openPopupAddUnloadingTime"
              :disabled="selectedDeliveryAddress.unloadingTimes.length > 3"
            >
              {{ t(`offers.add`) }}<template #icon><IcAdd /></template>
            </AgButton>
          </div>
          <div class="flex flex-wrap max-md:items-center gap-12 mb-8">
            <div v-for="(unloadingTime, index) in unloadingTimes" :key="index">
              <div
                class="flex gap-4 hover:cursor-pointer w-[160px] hover:bg-neutral-500 hover:rounded-lg p-4"
                @click="openPopupEditUnloadingTime(index)"
              >
                <div>{{ t('offers.from') }}:&nbsp;{{ unloadingTime.timeStart.substring(0, 5) }}</div>
                <div>{{ t('offers.to') }}:&nbsp;{{ unloadingTime.timeEnd.substring(0, 5) }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-between items-center gap-16">
          <div class="text-l font-bold">
            {{ t('offers.photos') }}
          </div>
          <AgButton
            v-if="!stream"
            variant="ghost"
            class="px-8 text-link"
            @click="openStream"
            :disabled="!isMediaDevices"
          >
            {{ t('offers.openCamera') }}
            <template #icon><IcAdd /></template>
          </AgButton>
          <AgButton v-if="stream" class="my-8 text-link" variant="ghost" @click.prevent="closeStream">
            {{ t('offers.closeCamera') }}
            <template #icon><IcClose /></template>
          </AgButton>
        </div>

        <div class="gap-4 text-s-13 flex items-center">
          <AgButton variant="ghost" class="cursor-default flex">
            <template #icon>
              <IcInfo class="text-infoBlue h-[20px] w-[20px]" />
            </template>
          </AgButton>
          <span class="italic font-light">{{ t('offers.imagesTaken') }}</span>
        </div>

        <div class="py-8">
          <div class="flex flex-row justify-between max-md:flex-col gap-8 border-neutral-550">
            <div v-if="stream" class="basis-1/2 border-r border-neutral-550">
              <video ref="video"><track kind="captions" /></video>
              <canvas ref="canvas" class="hidden"></canvas>
              <div class="py-8 flex">
                <AgButton
                  class="px-8"
                  variant="secondary"
                  @click.prevent="takePhoto"
                  :disabled="selectedPhoto === undefined && fileImages.length > 1"
                >
                  {{ t('offers.takePhoto') }}
                  <template #icon><IcAdd /></template>
                </AgButton>
              </div>
            </div>
            <div v-if="isLoading">
              <IcLoading />
            </div>
            <div v-else-if="!isLoading && fileImages.length > 0" class="gap-2">
              <div :class="getPhotoOneClass" class="flex flex-row">
                <span v-if="fileImages[0] && fileImages[0].size > 0">
                  <img
                    :src="imgToDisplayableImage(0)"
                    alt="Photo one"
                    width="240"
                    height="180"
                    :class="{ 'border-danger border-4 ': selectedPhoto === 0 }"
                  />
                </span>
                <span v-else>{{ t('offers.imageNotFound') }}</span>
                <div class="flex flex-col">
                  <AgButton variant="ghost" class="text-danger" @click="removeImage(0)">
                    <IcDelete class="flex justify-start" />
                  </AgButton>
                  <AgButton variant="ghost" class="text-link" @click="setSelectedPhoto(0)">
                    <IcEdit />
                  </AgButton>
                  <AgButton variant="ghost" class="text-success" @click="downloadPhoto(0, true)">
                    <IcDownload />
                  </AgButton>
                </div>
              </div>
              <div v-if="fileImages.length > 1" :class="getPhotoTwoClass" class="flex flex-row mt-8">
                <span v-if="fileImages[1] && fileImages[1].size > 0">
                  <img
                    :src="imgToDisplayableImage(1)"
                    alt="Photo two"
                    width="240"
                    height="180"
                    :class="{ 'border-danger border-4 ': selectedPhoto === 1 }"
                  />
                </span>
                <span v-else>{{ t('offers.imageNotFound') }}</span>
                <div class="flex flex-col">
                  <AgButton variant="ghost" class="text-danger" @click="removeImage(1)">
                    <IcDelete />
                  </AgButton>
                  <AgButton variant="ghost" class="text-link" @click="setSelectedPhoto(1)">
                    <IcEdit />
                  </AgButton>
                  <AgButton variant="ghost" class="text-success" @click="downloadPhoto(1, true)">
                    <IcDownload />
                  </AgButton>
                </div>
              </div>
            </div>
            <div v-else-if="!isLoading && fileImages.length === 0 && (!stream || !track)" class="w-full">
              <AgAlert variant="info" :dismissible="true">{{ t('offers.noPhotos') }}</AgAlert>
            </div>
          </div>
          <div class="w-full my-12" v-if="imgTooBig">
            <AgAlert variant="danger">{{ t('offers.photoTooBig') }}</AgAlert>
          </div>
        </div>
      </div>
    </div>
    <PopupUnloadingTime v-model="showUnloadingTime" :indexUnloadingTime="selectUnloadingTime" />
    <PopupDayOfRest v-model="showDayOfRest" :day="selectDayOfRest" />
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { AddressDto } from '@/domain/masterData/AddressDto';
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { DayOfRestDto } from '@/domain/masterData/DayOfRestDto';
  import type { OfferAddressDto } from '@/domain/offerData/OfferAddressDto';
  import type { OfferBusinessCategoryDto } from '@/domain/offerData/OfferBusinessCategoryDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInputGeolocation from '@/components/library/form-geolocation/AgFormInputGeolocation.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgCircleProgressBar from '@/components/library/progress/AgCircleProgressBar.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import PopupDayOfRest from '@/modules/offers/components/offer-wizard/steps/Address/PopupDayOfRest.vue';
  import PopupUnloadingTime from '@/modules/offers/components/offer-wizard/steps/Address/PopupUnloadingTime.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useCommon } from '@/composables/useCommon';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import {
    isModifiedAddressValid,
    validateModifiedAddressForm,
  } from '@/modules/offers/components/offer-wizard/steps/Address/AddressValidation';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';

  interface DayOfRest {
    day: number;
    start: number;
    end: number;
    timeStart: string;
    timeEnd: string;
  }

  const { t } = i18n.global;

  const { tables } = useMasterData();

  const { getTranslatedText } = useTranslatedText();
  const { savePhoto, openFile, deletePhoto } = useCommon();
  const { initializeAddress, copyOfferAddress, getCustomer } = useOfferFactory();

  const store = useOfferWizardStore();
  const { offer } = storeToRefs(store);
  const { wizzardAddressOrrigin } = storeToRefs(store);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.addressWizard');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const deliveryAddresses = ref<Array<AddressDto>>([]);
  const selectedDeliveryAddress = ref<OfferAddressDto>();

  const businessCategories = ref<Array<OfferBusinessCategoryDto>>([]);
  const countries = ref<Array<CountryDto>>([]);

  const selectDayOfRest = ref<number>(-1);
  const selectUnloadingTime = ref<number>(-1);

  const selectedNewDeliveryAddress = ref<boolean>(false);
  const showUnloadingTime = ref<boolean>(false);
  const showDayOfRest = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const isPhotoTaken = ref<boolean>(false);
  const imgTooBig = ref<boolean>(false);

  const selectedAddressCode = ref<string>();

  const fileImages = ref<Array<File>>([]);

  const video = ref<HTMLVideoElement>();
  const canvas = ref<HTMLCanvasElement>();

  const stream = ref<MediaStream>();
  const track = ref<MediaStreamTrack>();

  const selectedPhoto = ref<number | undefined>(undefined);

  const isMediaDevices = computed(() => (navigator.mediaDevices ? true : false));

  const isNewCustomer = function () {
    if (!offer.value) {
      return false;
    }
    return offer.value.customer.id === null;
  };

  const getPhotoOneClass = computed(() => {
    if (fileImages.value.length > 0) {
      return 'visible';
    }
    return 'invisible';
  });

  const getPhotoTwoClass = computed(() => {
    if (fileImages.value.length > 1) {
      return 'visible';
    }
    return 'invisible';
  });

  const openPopupEditUnloadingTime = (indexUnloadingTime: number) => {
    showUnloadingTime.value = true;
    selectUnloadingTime.value = indexUnloadingTime;
  };

  const unloadingTimes = computed(() => {
    if (!offer.value) {
      return [];
    }
    if (offer.value.deliveryAddressEqualToBilling) {
      return offer.value.invoiceAddress.unloadingTimes ?? [];
    } else if (offer.value.deliveryAddress) {
      return offer.value.deliveryAddress.unloadingTimes ?? [];
    }
    return [];
  });

  const openPopupAddUnloadingTime = () => {
    if (offer.value && selectedDeliveryAddress.value) {
      selectedDeliveryAddress.value.unloadingTimes.push({
        id: null,
        timeStart: '00:00',
        timeEnd: '00:00',
      });
      selectUnloadingTime.value = selectedDeliveryAddress.value.unloadingTimes.length - 1;
      showUnloadingTime.value = true;
    }
  };

  const openPopupDayOfRest = (dayOfRest: number) => {
    selectDayOfRest.value = dayOfRest;
    showDayOfRest.value = true;
  };

  const daysOfRest = computed(() => {
    const daysOfRest: Array<DayOfRest> = [];
    let orderDaysOfRest: Array<DayOfRestDto> = [];

    if (offer.value) {
      const address = offer.value.deliveryAddressEqualToBilling
        ? offer.value.invoiceAddress
        : offer.value.deliveryAddress;
      if (address) {
        orderDaysOfRest = address.daysOfRest.length > 0 ? address.daysOfRest : [];

        for (let i = 1; i < 8; i++) {
          const dayOfRest = orderDaysOfRest.find((d) => d.day === i);
          if (dayOfRest) {
            const start = dayOfRest.timeStart ? parseInt(dayOfRest.timeStart.split(':')[0]) : 0;
            let end = dayOfRest.timeEnd ? parseInt(dayOfRest.timeEnd.split(':')[0]) : 0;

            if (start === 0 && end === 0) {
              end = 12;
            }
            daysOfRest.push({
              day: dayOfRest.day,
              start: start,
              end: end,
              timeStart: dayOfRest.timeStart ?? '00:00',
              timeEnd: dayOfRest.timeEnd ?? '00:00',
            });
          } else {
            daysOfRest.push({
              day: i,
              start: 0,
              end: 0,
              timeStart: '',
              timeEnd: '',
            });
          }
        }
      }
    }
    return daysOfRest;
  });

  const loadDeliveryAddresses = async function () {
    if (!offer.value) {
      return;
    }
    if (offer.value.customer.code) {
      const customer = await getCustomer(offer.value.customer.code);
      if (customer) {
        deliveryAddresses.value = customer.addresses.filter((a) => a.type != CustomerAddressType.BILLING);
      }
    }
  };

  const loadPhotos = async function (photos: Array<string>) {
    photos.forEach(async (name, index) => {
      const blob = await downloadPhoto(index, false);
      if (blob) {
        fileImages.value[index] = new File([blob], name);
      } else {
        fileImages.value[index] = new File([], name);
      }
    });
    isLoading.value = false;
  };

  const loadCountries = async function () {
    countries.value = await tables.countries.toArray();
  };

  const countryOptions = computed((): Array<AgSearchSelectOption> => {
    return countries.value.map((c) => {
      return {
        value: c.code,
        label: getTranslatedText(c.title).toUpperCase(),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const selectedBusinessCategory = computed({
    get: () => {
      return selectedDeliveryAddress.value?.businessCategoryId;
    },
    set: (businessCategoryId) => {
      const businessCategory = businessCategories.value.find((bc) => bc.id === businessCategoryId);
      if (!businessCategory || !selectedDeliveryAddress.value) {
        return;
      }
      selectedDeliveryAddress.value.businessCategoryId = businessCategory.id;
      if (offer.value && offer.value.deliveryAddressEqualToBilling)
        offer.value.invoiceAddress.businessCategory = businessCategory;
    },
  });

  const loadBusinessCategories = async function () {
    businessCategories.value = await tables.businessCategories.toArray();
  };

  const businessCategoryOptions = computed((): Array<AgSearchSelectOption> => {
    return businessCategories.value.map((bc) => {
      return {
        value: bc.id,
        label: getTranslatedText(bc.title).charAt(0).toUpperCase() + getTranslatedText(bc.title).slice(1),
        searchableString: getTranslatedText(bc.title),
      };
    });
  });

  const getAddressString = function (address: AddressDto) {
    if (address.type === CustomerAddressType.BOTH) {
      return t('offers.deliveryLikeBillingAddress');
    }
    return [
      address.code || '',
      address.street || '',
      address.zip || '',
      address.city || '',
      address.region || '',
      address.district || '',
      address.nation || '',
    ]
      .map((x) => x.trim())
      .filter((x) => x)
      .join(' / ');
  };

  const emit = defineEmits(['update:modelValue']);

  const validationAddress = computed(() => {
    emit('update:modelValue', {
      id: 1,
      error: isModifiedAddressValid(
        selectedDeliveryAddress.value,
        countries.value,
        offer.value?.deliveryAddressEqualToBilling
      ),
    });
    return validateModifiedAddressForm(
      selectedDeliveryAddress.value,
      countries.value,
      offer.value?.deliveryAddressEqualToBilling
    );
  });

  const selectAdressesOptions = computed((): Array<AgSearchSelectOption> => {
    return deliveryAddresses.value.map((da) => {
      return {
        value: da.type === CustomerAddressType.BOTH ? 'fromBilling' : da.code,
        label: getAddressString(da),
        searchableString: getAddressString(da),
      };
    });
  });

  function openStream() {
    const constraints = {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        stream.value = mediaStream;
        track.value = mediaStream.getTracks()[0];

        isPhotoTaken.value = false;
      })
      .catch((error) => console.log('Error: ', error));
    isLoading.value = false;
  }

  const setSelectedPhoto = (index: number) => {
    selectedPhoto.value = index;
    if (!stream.value && !track.value) {
      openStream();
    }
  };

  async function takePhoto() {
    if (canvas.value && video.value && stream.value) {
      isPhotoTaken.value = true;

      const { videoWidth, videoHeight } = video.value;
      canvas.value.width = videoWidth;
      canvas.value.height = videoHeight;

      const context = canvas.value.getContext('2d');
      if (context) {
        context.drawImage(video.value, 0, 0, videoWidth, videoHeight);

        canvas.value.toBlob(async (blob) => {
          if (blob) {
            const index = fileImages.value.length % 2;

            const gid = offer.value?.gid ?? `${new Date().toISOString().split('T')[0]}`;
            const date = `${new Date().getFullYear()}`;
            const timestamp = Date.now();

            const fileName = `${gid}_${date}_${timestamp}.png`;
            const file = new File([blob], fileName);
            if (file.size > 500000) {
              imgTooBig.value = true;
              setTimeout(() => {
                imgTooBig.value = false;
              }, 3000);
            } else if (selectedPhoto.value === undefined) {
              fileImages.value[index] = file;
              await savePhoto(file);
              if (selectedDeliveryAddress.value && selectedDeliveryAddress.value.photos) {
                selectedDeliveryAddress.value.photos[index] = fileName;
              }
            } else if (selectedPhoto.value !== undefined) {
              fileImages.value[selectedPhoto.value] = file;
              if (selectedDeliveryAddress.value && selectedDeliveryAddress.value.photos) {
                selectedDeliveryAddress.value.photos[selectedPhoto.value] = fileName;
              }
              await savePhoto(file);
              selectedPhoto.value = undefined;
            }
          }
        });
      }
    }
  }

  function closeStream() {
    if (stream.value && track.value) {
      track.value.stop();
    }
    stream.value = undefined;
    track.value = undefined;
    canvas.value = undefined;
    video.value = undefined;
    selectedPhoto.value = undefined;
  }

  const removeImage = async function (index: number) {
    if (
      selectedDeliveryAddress.value &&
      selectedDeliveryAddress.value.photos &&
      selectedDeliveryAddress.value.photos.length > 0 &&
      index < selectedDeliveryAddress.value.photos.length
    ) {
      const fileName = selectedDeliveryAddress.value.photos[index];
      if (fileImages.value[index] && fileImages.value[index].size > 0) {
        deletePhoto(fileName);
      }
      selectedDeliveryAddress.value.photos.splice(index, 1);
      fileImages.value.splice(index, 1);
    }
  };

  const downloadPhoto = async function (index: number, open: boolean) {
    if (
      selectedDeliveryAddress.value &&
      selectedDeliveryAddress.value.photos &&
      selectedDeliveryAddress.value.photos.length > 0 &&
      index < selectedDeliveryAddress.value.photos.length
    ) {
      const fileName = selectedDeliveryAddress.value.photos[index];
      return openFile(fileName, open);
    }
  };

  const imgToDisplayableImage = function (index: number) {
    try {
      if (fileImages.value && fileImages.value.length > 0 && index < fileImages.value.length) {
        const file = fileImages.value[index];
        if (file && file.size > 0) {
          const url = window.URL.createObjectURL(file);
          return url;
        }
      }
    } catch (e) {}
    return '';
  };

  watch(video, async (element) => {
    if (element instanceof HTMLVideoElement) {
      video.value = element;
      video.value.srcObject = stream.value ?? null;
      await video.value.play();
    }
  });

  watch(offer, async () => await loadDeliveryAddresses(), { immediate: true });

  watch(
    selectedAddressCode,
    (value) => {
      if (!offer.value) {
        return;
      }
      if (value) {
        if (value === 'newAddress') {
          wizzardAddressOrrigin.value[offer.value.gid] = 'newAddress';
          selectedDeliveryAddress.value = initializeAddress();
        } else if (value === 'fromBilling') {
          wizzardAddressOrrigin.value[offer.value.gid] = 'fromBilling';
          selectedDeliveryAddress.value = offer.value.invoiceAddress;
          selectedDeliveryAddress.value.type = CustomerAddressType.BOTH;
          offer.value.deliveryAddressEqualToBilling = true;
          offer.value.deliveryAddress = null;
        }
      }
    },
    { immediate: true }
  );

  watch(
    selectedDeliveryAddress,
    async (newAddress) => {
      if (!offer.value || !newAddress) {
        return;
      }
      if (newAddress.type === CustomerAddressType.BOTH) {
        offer.value.deliveryAddressEqualToBilling = true;
        offer.value.deliveryAddress = null;

        selectedDeliveryAddress.value = offer.value.invoiceAddress;
      } else {
        offer.value.deliveryAddressEqualToBilling = false;
        offer.value.deliveryAddress = copyOfferAddress(newAddress);
      }
    },
    { immediate: true, deep: true }
  );

  onBeforeMount(async () => {
    await loadCountries();
    await loadBusinessCategories();
    await loadDeliveryAddresses();

    if (offer.value && offer.value.deliveryAddressEqualToBilling) {
      selectedAddressCode.value = 'fromBilling';
      selectedDeliveryAddress.value = copyOfferAddress(offer.value.invoiceAddress);
    } else if (offer.value && offer.value.deliveryAddress) {
      selectedAddressCode.value = offer.value.deliveryAddress.code;
      selectedDeliveryAddress.value = copyOfferAddress(offer.value.deliveryAddress);
    } else {
      selectedAddressCode.value = 'newAddress';
      selectedDeliveryAddress.value = initializeAddress();
    }
    if (selectedDeliveryAddress.value && selectedDeliveryAddress.value.photos.length > 0) {
      isLoading.value = true;
      await loadPhotos(selectedDeliveryAddress.value.photos);
    }
  });

  onBeforeUnmount(() => {
    closeStream();
    if (!selectedDeliveryAddress.value) {
      return;
    }
    if (offer.value && offer.value.deliveryAddressEqualToBilling) {
      offer.value.invoiceAddress = selectedDeliveryAddress.value;
    } else if (offer.value?.deliveryAddress) {
      offer.value.deliveryAddress = selectedDeliveryAddress.value;
    }
  });
</script>
