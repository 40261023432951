<template>
  <WizardNavigation v-model="internalValue" :steps="steps" :validation-states="cpValidationStates" />
</template>

<script setup lang="ts">
  import WizardNavigation from '@/components/library/wizard-navigation/WizardNavigation.vue';
  import { useOfferData } from '@/composables/data/useOfferData';

  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watchEffect } from 'vue';

  const { syncOffers } = useOfferData();
  const offerStore = useOfferWizardStore();

  const { validationStates } = storeToRefs(offerStore);

  interface Props {
    modelValue: string;
  }
  const props = defineProps<Props>();

  const cpValidationStates = ref<Array<boolean>>([]);

  const emit = defineEmits(['update:modelValue']);

  const steps = ref([
    {
      id: 'customer',
      position: 0,
      labelKey: 'offers.customerWizard',
    },
    {
      id: 'address',
      position: 1,
      labelKey: 'offers.addressWizard',
    },
    {
      id: 'shopping-cart',
      position: 2,
      labelKey: 'offers.shoppingCartWizard',
    },
    {
      id: 'offer-data',
      position: 3,
      labelKey: 'offers.offerDetailsWizard',
    },
    {
      id: 'notes',
      position: 4,
      labelKey: 'offers.notesWizard',
    },
    {
      id: 'review',
      position: 5,
      labelKey: 'offers.reviewWizard',
    },
  ]);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => {
      emit('update:modelValue', newValue);
      syncOffers();
    },
  });

  watchEffect(() => (cpValidationStates.value = validationStates.value));
</script>
