<template>
  <AgModal v-if="internalValue">
    <template #header>
      <div v-if="orderItem">
        <div class="text-2xl font-bold">{{ t('orders.article') }}</div>
        <div
          v-if="orderItem.article.articleType || orderItem.article.articleGroup"
          class="text-s-13 font-light w-fit text-neutral-550 italic flex flex-row mt-12"
        >
          <div v-if="orderItem.article.articleGroup">{{ orderItem?.article.articleGroup[locale] }}/</div>
          <div v-if="orderItem.article.articleType">{{ orderItem?.article.articleType[locale] }}</div>
        </div>
        <div class="text-l font-bold">{{ orderItem.article.title[locale] }}</div>
        <div v-if="orderItem">
          <span class="text-s-13 text-neutral-500 font-light">{{ t('orders.articleCode') }}:&nbsp;</span>
          <span class="text-s-13">{{ orderItem.article.code }}</span>
        </div>
        <!-- CHECK STOCK AVAILABILITY IF ENABLED  -->
        <div
          v-if="hasStockAvailability(orderItem.article.articleTypeCode)"
          class="text-s-13 text-neutral-500 flex flex-row pb-24"
        >
          <span class="text-s text-neutral-550">{{ t('orders.stockInfo') }}:&nbsp;</span>
          <span class="text-link text-s hover:cursor-pointer" @click.stop="showStocks = !showStocks">
            {{ t('orders.showStockInfo') }}
          </span>
          <OrderWizardPopupStocks v-model="showStocks" :articleCode="orderItem.article.code" />
        </div>
      </div>
    </template>

    <template #content>
      <div v-if="orderItem">
        <div class="px-16">
          <span class="italic text-infoBlue">{{ getTranslatedText(articleInfo) }}</span>
          <div v-if="orderItem.packaging" class="text-s pt-16">
            <!-- PACKAGING -->
            <div class="flex flex-col text-base py-12">
              <div class="font-light italic text-s w-full flex flex-row items-center">
                <span class="font-bold">{{ t('orders.packaging') }} ({{ t('orders.size') }}):&nbsp;</span>
                <span class="font-bold">
                  {{ getTranslatedText(orderItem.packaging.unit?.title) }} ({{ orderItem.packaging.size }})
                </span>
              </div>
              <div
                v-if="config.company.modules.CONTINGENTS && currentContinget !== undefined"
                class="text-s-20"
                :class="numberClass(currentContinget.available)"
              >
                <span>{{ $t('orders.contingentAvailableQuantity') }}:&nbsp;</span>
                <span>{{ currentContinget.originalQuantity }}</span>
                <span>,&nbsp;qty :&nbsp;</span>
                <span>{{ getArticleTotalQty() }}</span>
                <span>,&nbsp;{{ $t('orders.difference') }} :&nbsp;</span>
                <span>{{ currentContinget.available }}</span>
              </div>
            </div>
            <div class="flex flex-row">
              <!-- QUANTITY -->
              <div class="flex flex-row gap-8 pt-8 basis-1/3">
                <div class="flex flex-col">
                  <div class="flex flex-row gap-4">
                    <div class="flex flex-col gap-4">
                      <div class="font-bold">{{ t('orders.quantity') }}</div>
                      <AgFormInput
                        type="number"
                        variant="shoppingCart"
                        v-model="orderItem.quantity"
                        :placeholder="orderItem.quantity || orderItem.quantity === 0 ? '-' : ''"
                        @input="updatePrice(orderItem)"
                        :validation-state="quantityValidationState(orderItem.quantity)"
                      />
                    </div>
                    <div class="flex flex-col gap-4" v-if="config.company.enableFreeQuantity">
                      <div class="font-bold">{{ t('orders.freeQta') }}</div>
                      <div v-if="config.company.enableFreeQuantity">
                        <AgFormInput
                          type="number"
                          variant="shoppingCart"
                          v-model="orderItem.freeQuantity"
                          :placeholder="orderItem.freeQuantity || orderItem.freeQuantity === 0 ? '-' : ''"
                          :validation-state="freeQuantityValidationState(orderItem.freeQuantity)"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="isPartialiDelivery(orderItem.article.articleTypeCode)">
                    <input
                      type="checkbox"
                      id="partialDelivery"
                      name="partialDelivery"
                      value="partialDelivery"
                      class="mt-8 cursor-pointer"
                      v-model="partialDelivery"
                    />
                    <label for="partialDelivery" class="ml-4">{{ t('orders.partialDelivery') }}</label>
                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full">
                <!-- DIMENSIONS -->
                <div class="flex flex-wrap gap-16 w-full basis-2/3 ml-24" v-if="Object.keys(dimensions).length > 0">
                  <AgFormGroup
                    v-for="key in Object.keys(dimensions)"
                    :key="key"
                    label=""
                    :class="{ 'w-full': Object.keys(dimensions).length === 1 }"
                  >
                    <span class="font-bold">{{ getDimensionLabel(key) }}</span>
                    <div class="mt-4">
                      <AgFormSelect
                        variant="shoppingCart"
                        :options="dimensions[key]"
                        :modelValue="getDimensionValue(key)"
                        @update:modelValue="setDimensions($event, key, orderItem)"
                      />
                    </div>
                  </AgFormGroup>
                </div>
                <!-- PRODUCTION ARTICLE -->
                <div
                  v-if="isProductionArticle(orderItem.article.articleTypeCode)"
                  class="flex gap-16 w-full basis-2/3 ml-24"
                >
                  <div class="mt-8 w-full flex flex-col gap-4">
                    <span class="font-bold"> {{ t('orders.productDescription') }}</span>
                    <AgFormTextarea v-model="internalDescription" rows="2" variant="shoppingCart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ITEM TRADE AGREEMENT PRICE -->
        <div class="px-16 pt-16">
          <div class="flex flex-grow flex-col border-neutral-300 p-24 py-24 bg-neutral-100 mt-8">
            <div class="flex justify-between font-bold items-center">
              <span>{{ t('orders.unitPrice') }}</span>

              <div class="flex items-center">
                <div v-if="config.company.enablePriceEditable && isEditingPrice" class="flex items-center max-w-128">
                  <AgFormInput
                    type="number"
                    v-model="orderItem.tradeAgreement.editedPrice"
                    right
                    @input="getNetPrice(orderItem)"
                    @focusout="isEditingPrice = false"
                    @change="setPriceChanged"
                  />
                  &nbsp;&euro;
                </div>
                <span v-else class="flex justify-end">{{ formatNumberToEuro(tradeAgreementPrice) }}</span>
                <AgButton
                  v-if="config.company.enablePriceEditable"
                  variant="ghost"
                  @click="() => (isEditingPrice = !isEditingPrice)"
                >
                  <template #icon>
                    <IcEdit class="ml-8 h-24 w-24 cursor-pointer" :class="{ 'text-primary': isEditingPrice }" />
                  </template>
                </AgButton>
              </div>
            </div>
            <div class="flex flex-row justify-between">
              <span class="justify-start">
                {{ t('orders.unitPrice') }} * {{ t('orders.size') }} * {{ t('orders.quantity') }}
              </span>
              <span>
                {{ formatNumberToEuro(getPricePerSizeAndQuantity(orderItem)) }}
              </span>
            </div>
            <div
              v-if="config.company.minPrice"
              class="flex flex-row justify-between"
              :class="{ 'text-infoRed font-bold': isPriceLowerThanMinPrice }"
            >
              <span class="justify-start">{{ t('orders.minPrice') }}</span>
              <span>{{ formatNumberToEuro(minPrice) }} </span>
            </div>
            <div v-if="config.company.minPrice" class="flex flex-row justify-between">
              <span class="justify-start">
                {{ t('orders.minPrice') }} * {{ t('orders.size') }} * {{ t('orders.quantity') }}
              </span>
              <span>
                {{ formatNumberToEuro(minPricePerSizeAndQuantity) }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-col px-16 pt-16">
          <div class="text-danger flex justify-end" v-if="orderItem.tradeAgreement.isPromotional">
            {{ t('orders.isPromotional') }}
          </div>
          <div v-else>
            <div class="flex justify-between">
              <span class="font-bold">{{ t('orders.positionDiscounts') }}</span>
              <span class="text-neutral-500 font-light">
                {{ maxItemDiscounts }}&nbsp;{{ t('orders.applicableDiscounts') }}
              </span>
            </div>
            <div class="flex justify-end">
              <AgButton
                variant="secondary"
                @click="addDiscount"
                class="p-8 w-fit text-link border-0"
                :disabled="disabledDiscountButton(orderItem)"
              >
                <template #default>{{ t('orders.discount') }}</template>
                <template #icon><IcAdd /></template>
              </AgButton>
            </div>
          </div>
        </div>
        <!-- ITEM DISCOUNT -->
        <div v-if="showDiscounts" class="px-16">
          <div v-for="row in discountRowsCount" :key="row" class="flex flex-row justify-between mb-24">
            <div class="gap-12 flex max-md:flex-col max-md:items-start items-center">
              <div class="font-bold">{{ t('orders.discount') }} {{ row }}</div>
              <div
                class="gap-4 flex flex-row max-w-128 min-w-[40px]"
                :class="{ 'items-center': (validationDisocunts as any)[`discount${row}`].state }"
              >
                <AgFormInput
                  right
                  type="number"
                  v-model="(orderItem as any)[`discount${row}`]"
                  :validation-state="(validationDisocunts as any)[`discount${row}`].state"
                  :error-message="(validationDisocunts as any)[`discount${row}`].msg"
                  @input="getNetPrice(orderItem)"
                  @change="setSomeDiscountChanged"
                />
                &percnt;
              </div>
            </div>
            <div class="flex items-center max-md:items-end gap-x-24">
              {{ formatNumberToEuro(discountAmount[row - 1]) }}
              <div class="flex items-center max-md:items-end">
                {{ formatNumberToEuro(netPrice[row - 1]) }}
                <AgButton variant="ghost" @click="removeDiscount(row)">
                  <template #icon><IcClose /></template>
                </AgButton>
              </div>
            </div>
          </div>
        </div>
        <!-- ITEM NOTE -->
        <div class="flex flex-grow flex-col px-16 pt-16 mb-24">
          <div class="font-bold">{{ t('orders.articleNote') }}</div>
          <AgFormTextarea v-model="orderItem.note" rows="1" />
        </div>
      </div>
    </template>

    <!-- BUTTON -->
    <template #footer>
      <AgButton variant="secondary" @click="closeModal">{{ t('orders.cancel') }}</AgButton>
      <AgButton @click="save" :disabled="isDisabled">{{ t('orders.edit') }} </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { ContingentEntry } from '@/domain/internal/ContingentEntry';
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import OrderWizardPopupStocks from '@/modules/orders/components/order-wizard/steps/shopping-cart/article-table-row/OrderWizardPopupStocks.vue';

  import IcEdit from '@/components/icons/IcEdit.vue';
  import { useCommon } from '@/composables/useCommon';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerType } from '@/domain/enumeration/CustomerType';
  import {
    areModifiedDiscountsValid,
    validateAllDiscounts,
  } from '@/modules/orders/components/order-wizard/steps/shopping-cart/ArticleDiscountsValidation';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { cloneDeep } from 'lodash';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { locale, t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();
  const { getMinPriceByArticle, getArticleInfo } = useCommon();
  const { getDimOptionsByArticle, getTradeAgreementPriceAndDisconts } = useOrderFactory();
  const { getContingentCustomerAndOrderArticle } = useOrderFactory();

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  const orderItem = ref<OrderItemDto | null>(null);
  const articleInfo = ref<{ [key: string]: string }>({});
  const contMap = ref<Map<number, ContingentEntry>>(new Map<number, ContingentEntry>());

  const maxItemDiscounts = ref<number>(config.company.maxItemDiscounts);
  const discountAmount = ref<Array<number>>([]);
  const netPrice = ref<Array<number>>([]);
  const minPrice = ref<number>();
  const discountRowsCount = ref<number>(0);

  const newProductionDescription = ref<string>();
  const showStocks = ref<boolean>(false);
  const isEditingPrice = ref<boolean>(false);

  const dimensions = ref<{ [key: string]: Array<AgFormSelectOption> }>({});
  const dimOptions = ref<{ [key: string]: Array<DimensionItemDto> }>({});
  const dimensionArrayWithValues = ref<Array<DimensionItemDto>>([]);

  const currentContinget = ref<ContingentEntry | undefined>({
    originalQuantity: 0,
    available: 0,
  });

  interface Props {
    modelValue: boolean;
    customerId: number | null;
    agentId?: number;
    item?: OrderItemDto;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue', 'update:orderItem']);

  const partialDelivery = computed({
    get: () => {
      return orderItem.value?.requestedPartialDelivery;
    },
    set: (newValue) => {
      if (orderItem.value) {
        orderItem.value.requestedPartialDelivery = newValue;
      }
    },
  });

  const internalDescription = computed({
    get: () => props.item?.article.productionDescription,
    set: (newValue) => (newProductionDescription.value = newValue),
  });

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const qt = computed(() => {
    if (orderItem.value) {
      return orderItem.value.quantity ?? 0;
    }
    return 0;
  });

  const fqt = computed(() => {
    if (orderItem.value) {
      return orderItem.value.freeQuantity ?? 0;
    }
    return 0;
  });

  const tradeAgreementPrice = computed(() => {
    if (orderItem.value) {
      if (orderItem.value.isTradeAgreementPriceEdited) {
        return orderItem.value.tradeAgreement.editedPrice ?? 0;
      }
      return orderItem.value.tradeAgreement.price;
    }
    return 0;
  });

  const showDiscounts = computed(() => {
    return !orderItem.value?.tradeAgreement.isPromotional && orderItem.value?.quantity && orderItem.value?.quantity > 0;
  });

  const numberClass = function (number: number | undefined): string {
    if (number === undefined) {
      return '';
    }
    return number < 0 ? 'text-infoRed' : '';
  };

  const freeQuantityValidationState = function (freeQuantity: number | null) {
    if (!config.company.enableFreeQuantity) {
      return false;
    }
    if (freeQuantity != null && freeQuantity < 0) {
      return false;
    }
    return true;
  };

  const quantityValidationState = function (quantity: number | null) {
    if (quantity != null && quantity < 0) {
      return false;
    }
    return true;
  };

  const validationDisocunts = computed(() => {
    const discounts: Array<number> = [];
    type ObjectKey = keyof typeof orderItem.value;
    for (let i = 1; i <= maxItemDiscounts.value; i++) {
      const key = `discount${i}` as ObjectKey;
      if (orderItem.value && typeof orderItem.value[key] === 'number') {
        discounts.push(orderItem.value[key]);
      }
    }
    emit('update:orderItem', {
      id: 1,
      error: areModifiedDiscountsValid(netPrice.value, discounts, minPricePerSizeAndQuantity.value),
    });
    return validateAllDiscounts(netPrice.value, discounts, minPricePerSizeAndQuantity.value);
  });

  const isPriceLowerThanMinPrice = computed(() => {
    if (!orderItem.value) {
      return true;
    }
    if (config.company.minPrice) {
      if ((tradeAgreementPrice.value ?? 0) < (minPrice.value ?? 0)) {
        return true;
      }
    }
    return false;
  });

  const isDisabled = computed((): boolean => {
    if (!orderItem.value) {
      return true;
    }
    if (isPriceLowerThanMinPrice.value) {
      return true;
    }
    for (let i = 1; i < maxItemDiscounts.value + 1; i++) {
      type ObjectKey = keyof typeof validationDisocunts.value;
      const key = `discount${i}` as ObjectKey;
      if (validationDisocunts.value[key].state === false) {
        return true;
      }
    }
    if (
      (orderItem.value.quantity && orderItem.value.quantity > 0) ||
      (orderItem.value.freeQuantity && orderItem.value.freeQuantity > 0)
    ) {
      return false;
    }
    return true;
  });

  const hasStockAvailability = function (articleTypeCode: string = '') {
    return (
      config.company.enableStockAvailability &&
      config.company.partialDeliveryArticleTypeCode &&
      config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode)
    );
  };

  const isPartialiDelivery = function (articleTypeCode: string = '') {
    return config.company.partialDelivery && config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode);
  };

  const isProductionArticle = function (articleTypeCode: string = '') {
    return config.company.productionArticle && config.company.productionArticleTypeCode === articleTypeCode;
  };

  const getPricePerSizeAndQuantity = function (item: OrderItemDto) {
    if (!item) {
      return 0;
    }
    const size = item.packaging.size > 0 ? item.packaging.size : 1;
    return tradeAgreementPrice.value * size * (item.quantity ?? 1);
  };

  const minPricePerSizeAndQuantity = computed(() => {
    if (!orderItem.value) {
      return 0;
    }
    const size = orderItem.value.packaging.size > 0 ? orderItem.value.packaging.size : 1;
    return minPrice.value ? minPrice.value * size * (orderItem.value.quantity ?? 1) : 0;
  });

  const updatePrice = async function (item: OrderItemDto | null) {
    if (order.value && item) {
      const qt = item.quantity ?? 0;
      const articleId = item.article.id;
      const priceGroupIds = item.article.priceGroupIds;

      // Add first dimension value config id if present
      let dimValConfId = undefined;
      if (dimensionArrayWithValues.value.length > 0) {
        dimValConfId = dimensionArrayWithValues.value[0].value.id;
      }
      // GET Trade Agreement and discounts
      const taDiscountLists = await getTradeAgreementPriceAndDisconts(
        order.value,
        articleId,
        priceGroupIds,
        qt,
        dimValConfId
      );
      if (taDiscountLists.tradeAgreement) {
        const tradeAgreement = taDiscountLists.tradeAgreement;
        const isPromotional = tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION ? true : false;

        item.tradeAgreement = {
          id: tradeAgreement.id,
          price: taDiscountLists.price,
          isPromotional: isPromotional,
          editedPrice: item.tradeAgreement.editedPrice,
        };
        // CHECK DISCOUNTS IF PRESENT
        if (discountRowsCount.value > 0) {
          resetDiscount(item);
        }
        const discounts = taDiscountLists.discounts;
        if (discounts.length > 0) {
          type objectKey = keyof typeof item;
          for (let i = 0; i < discounts.length; i++) {
            const key = `discount${i + 1}` as objectKey;
            setObjProperty(item, key, discounts[i]);
          }
          discountRowsCount.value = discounts.length;
          getNetPrice(item);
        }
      }
    }
  };

  const resetDiscount = (item: OrderItemDto) => {
    if (item) {
      type objectKey = keyof typeof item;
      for (let i = 0; i < discountRowsCount.value; i++) {
        const key = `discount${i + 1}` as objectKey;
        delete item[key];
      }
      discountRowsCount.value = 0;
    }
  };

  const closeModal = function () {
    contMap.value.clear();
    dimensions.value = {};
    isEditingPrice.value = false;
    internalValue.value = false;
  };

  const addDiscount = function () {
    if (discountRowsCount.value < maxItemDiscounts.value) {
      discountRowsCount.value++;
    }
  };

  const disabledDiscountButton = function (orderItem: OrderItemDto) {
    if (discountRowsCount.value >= maxItemDiscounts.value) {
      return true;
    }
    return !orderItem.quantity || orderItem.quantity === 0 || orderItem.tradeAgreement.isPromotional;
  };

  const removeDiscount = function (row: number) {
    if (!orderItem.value) {
      return;
    }
    for (let i = row; i <= maxItemDiscounts.value; i++) {
      type ObjectKey = keyof typeof orderItem.value;

      const key = `discount${i}` as ObjectKey;
      const key1 = `discount${i + 1}` as ObjectKey;

      const value = i === maxItemDiscounts.value ? null : orderItem.value[key1];

      setObjProperty(orderItem.value, key, value);
    }
    discountRowsCount.value--;
    getNetPrice(orderItem.value);
  };

  const getNetPrice = function (item: OrderItemDto | null) {
    if (!item) {
      return;
    }
    const size = item.packaging.size > 0 ? item.packaging.size : 1;
    let price = tradeAgreementPrice.value * size * (item.quantity ?? 1);
    let lastPrice = 0;

    type objKey = keyof typeof orderItem.value;

    [...new Array(maxItemDiscounts.value)].map((_, i) => {
      const key = `discount${i + 1}` as objKey;
      const discount = (getObjProperty(item, key) as number) ?? 0;
      if (discount > 0) {
        lastPrice = price;
        price = price * (1 - discount / 100);
      } else {
        lastPrice = 0;
      }
      netPrice.value[i] = price;
      discountAmount.value[i] = lastPrice > 0 ? lastPrice - price : 0;
    });
  };

  const setPriceChanged = function () {
    if (orderItem.value) {
      orderItem.value.isTradeAgreementPriceEdited = true;
    }
  };

  const setSomeDiscountChanged = function () {
    if (orderItem.value) {
      orderItem.value.isSomeDiscountEdited = true;
    }
  };

  const save = function () {
    if (!orderItem.value || !orderItem.value || !order.value) {
      return;
    }
    const articleId = orderItem.value.article.id;
    const packagingId = orderItem.value.packaging.id;
    const productionDescription = orderItem.value.article.productionDescription;
    const articleTypeCode = orderItem.value.article.articleTypeCode;
    const dimensions = orderItem.value.article.dimensions;
    const items = order.value.items;

    let qt = 0;
    let frqt = 0;

    if (
      (newProductionDescription.value && productionDescription !== newProductionDescription.value) ||
      (dimensionArrayWithValues.value.length > 0 && !compareArrayDimensions(dimensions, dimensionArrayWithValues.value))
    ) {
      const findIndex = items.findIndex((item) =>
        articleCompare(
          item,
          articleId,
          packagingId,
          newProductionDescription.value,
          articleTypeCode,
          dimensionArrayWithValues.value
        )
      );
      if (findIndex > -1) {
        qt = order.value.items[findIndex].quantity ?? 0;
        frqt = order.value.items[findIndex].freeQuantity ?? 0;
        // Remove copy item from offer
        order.value.items.splice(findIndex, 1);
      }
    }
    const index = items.findIndex((item) =>
      articleCompare(item, articleId, packagingId, productionDescription, articleTypeCode, dimensions)
    );
    if (index > -1) {
      qt += orderItem.value.quantity ?? 0;
      frqt += orderItem.value.freeQuantity ?? 0;

      orderItem.value.quantity = qt;
      orderItem.value.freeQuantity = frqt;

      if (newProductionDescription.value !== undefined) {
        orderItem.value.article.productionDescription = newProductionDescription.value;
        newProductionDescription.value = undefined;
      }
      if (dimensionArrayWithValues.value.length > 0) {
        orderItem.value.article.dimensions = dimensionArrayWithValues.value;
      }
      const discounts: Array<number> = [];
      type ObjectKey = keyof typeof orderItem.value;
      for (let i = 1; i <= maxItemDiscounts.value; i++) {
        const key = `discount${i}` as ObjectKey;
        if (orderItem.value[key] && typeof orderItem.value[key] === 'number') {
          discounts.push(orderItem.value[key]);
        }
      }
      orderItem.value.unitPrice = getUnitPrice(tradeAgreementPrice.value, discounts);
      orderItem.value.finalPrice = getFinalPrice(
        tradeAgreementPrice.value,
        discounts,
        orderItem.value.packaging.size,
        qt
      );
      order.value.items.splice(index, 1, orderItem.value);
    }
    closeModal();
  };

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const articleCompare = (
    item: OrderItemDto,
    artId: number,
    pkgId: number,
    prdDesc?: string,
    artType?: string,
    dims?: Array<DimensionItemDto>
  ) => {
    if (config.company.productionArticle && config.company.productionArticleTypeCode === artType) {
      return (
        item.article.id === artId &&
        item.packaging.id === pkgId &&
        item.article.productionDescription === prdDesc &&
        compareArrayDimensions(item.article.dimensions, dims)
      );
    } else {
      return (
        item.article.id === artId &&
        item.packaging.id === pkgId &&
        compareArrayDimensions(item.article.dimensions, dims)
      );
    }
  };

  const setObjProperty = function <T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  };

  const getObjProperty = function <T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key] as T[K];
  };

  const setDimensions = function (id: number, key: string, item: OrderItemDto | null) {
    const indexToRemove = dimensionArrayWithValues.value.findIndex((item) => item.id === parseInt(key));
    if (indexToRemove != -1) {
      dimensionArrayWithValues.value.splice(indexToRemove, 1);
    }
    const dimensionItem = dimOptions.value[key].find((item) => item.value.id === id);
    if (dimensionItem) {
      dimensionArrayWithValues.value.push(dimensionItem);
    }
    dimensionArrayWithValues.value.sort((a, b) => a.id - b.id);
    // IF NECESSARY SET CONTINGENT
    if (config.company.modules.CONTINGENTS) {
      setContingentReference(0, 0, 0, false, false);
    }
    if (item) {
      updatePrice(item);
    }
  };

  const loadDimensions = function () {
    if (!props.item || !order.value) {
      return;
    }
    const articleId = props.item.article.id;
    const priceGroupIds = props.item.article.priceGroupIds;

    getDimOptionsByArticle(order.value, articleId, priceGroupIds).then((opts) => {
      if (opts) {
        dimOptions.value = opts;
        for (const [key, items] of Object.entries(opts)) {
          items.forEach((item) => {
            if (dimensions.value[key]) {
              dimensions.value[key].push({
                key: item.value.id,
                label: item.value.title[locale.value],
              });
            } else {
              dimensions.value[key] = [
                {
                  key: item.value.id,
                  label: item.value.title[locale.value],
                },
              ];
            }
          });
        }
      }
    });
  };

  const getDimensionLabel = (key: string) => dimOptions.value[key][0].title[locale.value] ?? '--';

  const getDimensionValue = function (key: string) {
    if (orderItem.value && orderItem.value.article.dimensions) {
      const dimension = orderItem.value.article.dimensions.find((d) => d.id === parseInt(key));
      if (dimension) {
        return dimension.value.id;
      }
    }
    return -1;
  };

  const compareArrayDimensions = (a?: Array<DimensionItemDto>, b?: Array<DimensionItemDto>) => {
    return a && b && a.length === b.length && a.every((adim) => b.some((bdim) => adim.value.id === bdim.value.id));
  };

  const getArticleTotalQty = function () {
    if (!orderItem.value) {
      return 0;
    }
    return (orderItem.value.quantity ?? 0) + (orderItem.value.freeQuantity ?? 0);
  };

  const loadContingentItemInCart = function () {
    if (!order.value) {
      return;
    }
    order.value.items.forEach(async (cartItem) => {
      if (!order.value || !cartItem.article.dimensions || cartItem.packaging.id === orderItem.value?.packaging.id) {
        return;
      }
      const dimCode = config.company.contingentDimensionCode;
      const dimId = cartItem.article.dimensions.find((d) => d.code === dimCode)?.value.id ?? -1;
      if (dimId === -1) {
        currentContinget.value = undefined;
        console.warn('WARING: no contingent dimensions code found !!');
        return;
      }
      const contingent = await getContingentCustomerAndOrderArticle(
        dimId,
        order.value.customer.id,
        cartItem.article,
        order.value.agentId
      );
      if (contingent) {
        const newEntry: ContingentEntry = {
          originalQuantity: 0,
          available: 0,
        };
        const oldCont = contMap.value.get(contingent.id);
        if (oldCont) {
          oldCont.available -= (cartItem.quantity ?? 0) + (cartItem.freeQuantity ?? 0);
        } else {
          newEntry.originalQuantity = contingent.availableQuantity;
          newEntry.available = contingent.availableQuantity - ((cartItem.quantity ?? 0) + (cartItem.freeQuantity ?? 0));
          contMap.value.set(contingent.id, newEntry);
        }
      }
    });
    setContingentReference(0, 0, 0, true, true);
  };

  const setContingentReference = async function (
    toRefill: number,
    qty: number,
    fqty: number,
    reduceQty: boolean = false,
    reduceFQty: boolean = false
  ) {
    const currentItem = orderItem.value;
    if (!currentItem || !currentItem.article.dimensions) {
      return;
    }
    const dimCode = config.company.contingentDimensionCode;
    const dimId = dimensionArrayWithValues.value.find((d) => d.code === dimCode)?.value.id ?? -1;
    if (dimId === -1) {
      currentContinget.value = undefined;
      console.warn('WARING: no contingent dimensions code found !!');
      return;
    }
    const contingent = await getContingentCustomerAndOrderArticle(
      dimId,
      props.customerId,
      props.item?.article,
      props.agentId
    );
    if (contingent) {
      const oldCont = contMap.value.get(contingent.id);

      if (oldCont) {
        if (oldCont.originalQuantity !== contingent.availableQuantity) {
          oldCont.available = contingent.availableQuantity - (qty + fqty);
        }
        oldCont.originalQuantity = contingent.availableQuantity;
        if (reduceQty) {
          oldCont.available -= qty;
        }
        if (reduceFQty) {
          oldCont.available -= fqty;
        }
        oldCont.available += toRefill;

        currentContinget.value = oldCont;
      } else if (currentContinget.value) {
        {
          currentContinget.value.originalQuantity = contingent.availableQuantity;
          if (reduceFQty && reduceFQty) {
            currentContinget.value.available = contingent.availableQuantity - (qty + fqty);
          } else if (reduceQty) {
            currentContinget.value.available = contingent.availableQuantity - qty;
          } else if (reduceFQty) {
            currentContinget.value.available = contingent.availableQuantity - fqty;
          } else {
            currentContinget.value.available = contingent.availableQuantity;
          }
          currentContinget.value.available += toRefill;

          contMap.value.set(contingent.id, currentContinget.value);
        }
      }
    } else {
      currentContinget.value = undefined;
    }
  };

  watch(
    () => [qt.value, fqt.value],
    async (newValue, oldValue) => {
      if (config.company.modules.CONTINGENTS) {
        let toRefill: number = 0;
        let reduceQty: boolean = false;
        let reduceFQty: boolean = false;
        if (oldValue) {
          if (oldValue[0] !== newValue[0]) {
            toRefill += oldValue[0] ?? 0;
            reduceQty = true;
          }
          if (oldValue[1] !== newValue[1]) {
            toRefill += oldValue[1] ?? 0;
            reduceFQty = true;
          }
        }
        await setContingentReference(toRefill, newValue[0] ?? 0, newValue[1] ?? 0, reduceQty, reduceFQty);
      }
    }
  );

  watch(
    internalValue,
    async (value) => {
      if (value && props.item) {
        articleInfo.value = await getArticleInfo(props.item.article.id);
        const hasDimesnions = props.item.article.dimensions && props.item.article.dimensions.length > 0;
        if (hasDimesnions && config.company.enabledProductionDimensions) {
          loadDimensions();

          dimensionArrayWithValues.value = props.item.article.dimensions ? [...props.item.article.dimensions] : [];

          if (config.company.modules.CONTINGENTS) {
            setContingentReference(0, 0, 0, false, false);
          }
        }
        orderItem.value = cloneDeep(props.item);

        discountAmount.value = [];
        netPrice.value = [];

        minPrice.value = await getMinPriceByArticle(props.item.article.id);

        if (orderItem.value) {
          if (config.company.modules.CONTINGENTS) {
            loadContingentItemInCart();
          }
          const item = orderItem.value;
          type ObjectKey = keyof typeof item;

          discountRowsCount.value = [...new Array(maxItemDiscounts.value)]
            .map((_, i) => {
              const key = `discount${i + 1}` as ObjectKey;
              const value = (getObjProperty(item, key) as number) ?? 0;
              if (value > 0) {
                getNetPrice(item);
              }
              return value;
            })
            .filter((d) => d > 0).length;
        }
      } else {
        contMap.value.clear();
        orderItem.value = null;
      }
    },
    { immediate: true }
  );
</script>
