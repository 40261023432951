<template>
  <AgModal v-if="internalModal" fit-height fit-width>
    <template #header>
      <div class="text-xl font-bold">{{ t('openItems.sendEmail') }}</div>
    </template>
    <template #content>
      <div class="space-y-16">
        <div>{{ t(subTitle) }}</div>
        <div>
          <AgFormGroup :label="t('openItems.mail')" required>
            <AgFormInput v-model="internalEmail" type="email" :validation-state="errorMessage.length === 0" />
          </AgFormGroup>
        </div>
        <div>
          <AgFormGroup :label="t('openItems.language')" required>
            <AgFormSelect v-model="language" :options="langOptions" />
          </AgFormGroup>
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click.stop="closeModal"> {{ t('openItems.close') }}</AgButton>
      <AgButton
        variant="primary"
        :disabled="isLoading || errorMessage.length > 0"
        @click.stop="sendEmail"
        v-if="!isLoading"
      >
        {{ t('openItems.send') }}
      </AgButton>
      <div v-if="isLoading">
        <IcLoading />
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { OutstandingDebtsEmailRequest } from '@/domain/email/OutstandingDebtsEmailRequest';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useEmail } from '@/composables/useEmail';
  import { config } from '@/config/config';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { i18n } from '@/plugins/i18n';
  import { Filter } from '@/util/Filter';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { computed, ref, watch } from 'vue';

  const { locale, t } = i18n.global;

  const { sendOutstandingDebts, sendAgentOutstandingDebts, sendCustomerOutstandingDebts } = useEmail();

  interface Props {
    modal: boolean;
    whichEmail: WhichEmail;
    email: string;
    filter?: Filter;
    sorting?: Sorting;
    customerId?: number;
    agentId?: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modal: false,
    filter: () => new Filter('', 0),
    sorting: () => new Sorting('', Order.ASC),
    customerId: -1,
    agentId: -1,
  });

  const errorMessage = ref<string>('');
  const language = ref<string>(locale.value);
  const isLoading = ref<boolean>(false);

  const emit = defineEmits(['update:modal', 'update:email']);

  const internalModal = computed({
    get: () => props.modal,
    set: (value) => emit('update:modal', value),
  });

  const internalEmail = computed({
    get: () => props.email,
    set: (value) => emit('update:email', value),
  });

  const closeModal = function () {
    internalModal.value = false;
  };

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: lang,
    }))
  );

  const subTitle = computed((): string => {
    switch (props.whichEmail) {
      case WhichEmail.OUTSTANDING_DEBTS: {
        return 'openItems.textMailOutstandingDebts';
      }
      case WhichEmail.CUSTOMER_OUTSTANDING_DEBTS: {
        return 'openItems.textMailOutstandingDebtsCustomer';
      }
      case WhichEmail.AGENT_OUTSTANDING_DEBTS: {
        return 'openItems.textMailOutstandingDebtsAgnet';
      }
      default:
        return 'openItems.error';
    }
  });

  const sendEmail = () => {
    isLoading.value = true;

    switch (props.whichEmail) {
      case WhichEmail.OUTSTANDING_DEBTS: {
        const request: OutstandingDebtsEmailRequest = {
          language: language.value,
          mailTo: props.email,
          filters: props.filter,
          sorting: props.sorting,
        };
        sendOutstandingDebts(request).then(() => {
          isLoading.value = false;
          closeModal();
        });
        break;
      }
      case WhichEmail.CUSTOMER_OUTSTANDING_DEBTS: {
        const request: OutstandingDebtsEmailRequest = {
          language: language.value,
          mailTo: props.email,
          filters: props.filter,
          sorting: props.sorting,
        };
        sendCustomerOutstandingDebts(props.customerId, request).then(() => {
          isLoading.value = false;
          closeModal();
        });
        break;
      }
      case WhichEmail.AGENT_OUTSTANDING_DEBTS: {
        const request: OutstandingDebtsEmailRequest = {
          language: language.value,
          mailTo: props.email,
          filters: props.filter,
          sorting: props.sorting,
        };
        sendAgentOutstandingDebts(props.agentId, request).then(() => {
          isLoading.value = false;
          closeModal();
        });
        break;
      }
    }
  };

  const validateEmail = (email: string) => {
    const regexp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const isValidEmail = regexp.test(email);
    if (isValidEmail) {
      errorMessage.value = '';
    } else {
      if (email.trim().length === 0) {
        errorMessage.value = 'Email is required';
      } else {
        errorMessage.value = 'Invalid Email Address';
      }
    }
  };

  watch(
    () => props.email,
    (value) => validateEmail(value),
    { immediate: true }
  );
</script>
