import type { ConfigType } from './ConfigType';

export const config: ConfigType = {
  enviroment: process.env.ENVIRONMENT ?? '',
  port: 8080,
  version: process.env.APP_VERSION ?? '',
  backend: {
    url: process.env.API_BASE_URL ?? '',
  },
  auth: {
    clientId: process.env.MSAL_CLIENT_ID ?? '',
    loginAuthority: process.env.MSAL_LOGIN_AUTHORITY ?? '',
    passwordRestAuthority: process.env.MSAL_PASSWORD_RESET_AUTHORITY ?? '',
    knownAuthority: process.env.MSAL_KNOWN_AUTHORITY ?? '',
    accessTokenScope: process.env.MSAL_ACCESS_TOKEN_SCOPE ?? '',
    redirectUri: window.location.origin,
    redirectLogoutUri: `${location.origin}/#/login`,
  },
  company: process.env.COMPANY_CONFIG ? JSON.parse(process.env.COMPANY_CONFIG) : {},
};
