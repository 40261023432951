<template>
  <svg id="ic_catalog" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_3157" width="20" height="20" fill="none" />
    <g
      id="Rechteck_7018"
      data-name="Rechteck 7018"
      transform="translate(3 3)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    >
      <rect width="6" height="6" stroke="none" />
      <rect x="0.75" y="0.75" width="4.5" height="4.5" fill="none" />
    </g>
    <g
      id="Rechteck_7019"
      data-name="Rechteck 7019"
      transform="translate(3 11)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    >
      <rect width="6" height="6" stroke="none" />
      <rect x="0.75" y="0.75" width="4.5" height="4.5" fill="none" />
    </g>
    <line
      id="Linie_227"
      x2="5.982"
      transform="translate(11.018 3.728)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_226"
      x2="5.982"
      transform="translate(11.018 8.239)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_224_"
      x2="5.982"
      transform="translate(11.018 11.78)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_225"
      x2="5.982"
      transform="translate(11.018 16.292)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
