<template>
  <AgModal v-if="internalValue" :has-footer="false" fit-height :fit-width="true">
    <template #header>
      <div class="flex flex-row items-center justify-between">
        <div class="text-l font-bold">{{ title }}</div>
        <AgButton variant="ghost" @click.stop="closeModal">
          <template #icon><IcClose /></template>
        </AgButton>
      </div>
    </template>

    <template #content>
      <div class="mb-16">
        <div class="tableHeader">
          <div class="modalHeaderColumn" @click.stop="sortYear">
            <span class="px-4">{{ t('openItems.year') }}</span>
            <span v-if="orderYear === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderYear === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <div class="modalHeaderColumn" @click.stop="sortInvoiceCode">
            <span class="px-4">{{ t('openItems.invoiceNumber') }}</span>
            <span v-if="orderInvoiceCode === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderInvoiceCode === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- INVOCE DATE -->
          <div class="modalHeaderColumn" @click.stop="sortInvoiceDate">
            <span class="px-4">{{ t('openItems.invoiceDate') }}</span>
            <span v-if="orderInvoiceDate === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderInvoiceDate === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <div class="modalHeaderColumn right" @click.stop="sortInvoiceAmount">
            <span class="px-4">{{ t('openItems.invoiceAmount') }}</span>
            <span v-if="orderInvoiceAmount === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderInvoiceAmount === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <div class="modalHeaderColumn right" @click.stop="sortOpenAmount">
            <span class="px-4">{{ t('openItems.openAmount') }}</span>
            <span v-if="orderOpenAmount === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderOpenAmount === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <div class="modalHeaderColumn right" @click.stop="sortDueDate">
            <span class="px-4">{{ t('openItems.invoiceDueDate') }}</span>
            <span v-if="orderDueDate === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderDueDate === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <div class="modalHeaderColumn right" @click.stop="sortNote">
            <span class="px-4">{{ t('openItems.note') }}</span>
            <span v-if="orderNote === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderNote === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>

        <div v-for="openItem in getDataInfo" :key="openItem.id" class="table">
          <div class="modalTableColumn">
            <span class="signal" :class="getClasses(openItem.dueIndicator)">&nbsp;</span>
            <span>{{ openItem.year }}</span>
          </div>
          <div class="modalTableColumn">
            {{ openItem.invoiceCode }}
          </div>
          <div class="modalTableColumn">
            {{ formatDate(openItem.invoiceDate) }}
          </div>
          <div class="modalTableColumn right">
            {{ formatNumberToEuro(openItem.invoiceAmount) }}
          </div>
          <div class="modalTableColumn right" :class="{ 'text-[#dc3545]': openItem.openAmount < 0 }">
            {{ formatNumberToEuro(openItem.openAmount) }}
          </div>
          <div class="modalTableColumn right">
            {{ formatDate(openItem.dueDate) }}
          </div>
          <div class="modalTableColumn right">
            {{ openItem.note }}
          </div>
        </div>
      </div>

      <!-- OVERDUE INDICATOR -->
      <div class="py-12 space-y-8">
        <div>
          <span class="mr-8 px-4 bg-infoYellow">&nbsp;</span>
          <span>{{ t('openItems.due') }}</span>
        </div>
        <div>
          <span class="mr-8 px-4 bg-infoOrange">&nbsp;</span>
          <span>{{ t('openItems.oneMonthdue') }}</span>
        </div>
        <div>
          <span class="mr-8 px-4 bg-infoRed">&nbsp;</span>
          <span>{{ t('openItems.sixMonthDue') }}</span>
        </div>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    showModal: boolean;
    openItems: Array<OutstandingDebtDto>;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:showModal']);

  const internalValue = computed({
    get: () => props.showModal,
    set: (value) => emit('update:showModal', value),
  });

  const title = computed(() => {
    if (props.openItems.length > 0) {
      return `${t('openItems.openItemsFor')} ${props.openItems[0].customerName}`;
    } else {
      return t('openItems.openItems');
    }
  });

  const data = ref<Array<OutstandingDebtDto>>([]);
  const getDataInfo = computed(() => {
    props.openItems.forEach((item) => {
      data.value.push(item);
    });
    return data.value;
  });

  const closeModal = () => (internalValue.value = false);

  const orderYear = ref<Order | null>(null);
  const sortYear = () => updateSorting(ColumnName.YEAR);

  const orderInvoiceCode = ref<Order | null>(null);
  const sortInvoiceCode = () => updateSorting(ColumnName.INVOICE_CODE);

  const orderInvoiceDate = ref<Order | null>(null);
  const sortInvoiceDate = () => updateSorting(ColumnName.INVOICE_DATE);

  const orderInvoiceAmount = ref<Order | null>(null);
  const sortInvoiceAmount = () => updateSorting(ColumnName.INVOICE_AMOUNT);

  const orderOpenAmount = ref<Order | null>(null);
  const sortOpenAmount = () => updateSorting(ColumnName.OPEN_AMOUNT);

  const orderDueDate = ref<Order | null>(null);
  const sortDueDate = () => updateSorting(ColumnName.DUE_DATE);

  const orderNote = ref<Order | null>(null);
  const sortNote = () => updateSorting(ColumnName.NOTE);

  enum ColumnName {
    YEAR = 'year',
    INVOICE_CODE = 'invoiceCode',
    INVOICE_DATE = 'invoiceDate',
    INVOICE_AMOUNT = 'invoiceAmount',
    OPEN_AMOUNT = 'openAmount',
    DUE_DATE = 'dueDate',
    NOTE = 'note',
  }

  const updateSorting = (column: ColumnName) => {
    if (column === ColumnName.YEAR) {
      if (orderYear.value === Order.ASC) {
        orderYear.value = Order.DESC;
        data.value.sort((a, b) => a.year - b.year);
      } else {
        orderYear.value = Order.ASC;
        data.value.sort((a, b) => b.year - a.year);
      }
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.INVOICE_CODE) {
      if (orderInvoiceCode.value === Order.ASC) {
        orderInvoiceCode.value = Order.DESC;
        data.value.sort((a, b) =>
          a.invoiceCode.localeCompare(b.invoiceCode, undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderInvoiceCode.value = Order.ASC;
        data.value.sort((a, b) =>
          b.invoiceCode.localeCompare(a.invoiceCode, undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderYear.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.INVOICE_DATE) {
      if (orderInvoiceDate.value === Order.ASC) {
        orderInvoiceDate.value = Order.DESC;
        data.value.sort((a, b) => (a.invoiceDate ?? 0) - (b.invoiceDate ?? 0));
      } else {
        orderInvoiceDate.value = Order.ASC;
        data.value.sort((a, b) => (b.invoiceDate ?? 0) - (a.invoiceDate ?? 0));
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.INVOICE_AMOUNT) {
      if (orderInvoiceAmount.value === Order.ASC) {
        orderInvoiceAmount.value = Order.DESC;
        data.value.sort((a, b) => (a.invoiceAmount ?? 0) - (b.invoiceAmount ?? 0));
      } else {
        orderInvoiceAmount.value = Order.ASC;
        data.value.sort((a, b) => (b.invoiceAmount ?? 0) - (a.invoiceAmount ?? 0));
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.OPEN_AMOUNT) {
      if (orderOpenAmount.value === Order.ASC) {
        orderOpenAmount.value = Order.DESC;
        data.value.sort((a, b) => a.openAmount - b.openAmount);
      } else {
        orderOpenAmount.value = Order.ASC;
        data.value.sort((a, b) => b.openAmount - a.openAmount);
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.DUE_DATE) {
      if (orderDueDate.value === Order.ASC) {
        orderDueDate.value = Order.DESC;
        data.value.sort((a, b) => (a.dueDate ?? 0) - (b.dueDate ?? 0));
      } else {
        orderDueDate.value = Order.ASC;
        data.value.sort((a, b) => (b.dueDate ?? 0) - (a.dueDate ?? 0));
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderNote.value = null;
    } else if (column === ColumnName.NOTE) {
      if (orderNote.value === Order.ASC) {
        orderNote.value = Order.DESC;
        data.value.sort((a, b) =>
          (a.note ?? '').localeCompare(b.note ?? '', undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderNote.value = Order.ASC;
        data.value.sort((a, b) =>
          (b.note ?? '').localeCompare(a.note ?? '', undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
    }
  };

  const getClasses = (dueIndicator?: number) => {
    if (dueIndicator) {
      if (dueIndicator === 1) {
        return 'signal--yellow'; // due
      } else if (dueIndicator === 2) {
        return 'signal--orange'; // >1 mounth
      } else if (dueIndicator === 3) {
        return 'signal--red'; // >6 mounth
      }
    }
    return ''; // not due
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[90px_140px_150px_165px_165px_150px_minmax(163px,1fr)] min-h-32;
    @apply font-light text-neutral-550;
  }

  .modalHeaderColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid grid-cols-[100px_140px_150px_135px_167px_156px_minmax(163px,1fr)] min-h-32;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .modalTableColumn {
    @apply flex items-center border-t border-neutral-500;
  }

  .right {
    @apply justify-end;
  }

  .signal {
    padding: 0 0 0 6px;
    margin: 0 0.7em 0 0;

    &--yellow {
      background-color: #f8bb00;
    }
    &--red {
      background-color: #e74668;
    }
    &--orange {
      background-color: #ed5818;
    }
  }
</style>
