export class PDataFilter {
  search: string;
  articleCode: string;

  state?: number;
  customerId?: number;
  month?: number;
  year?: number;
  agentId?: number | null;
  constructor(
    search = '',
    articleCode = '',
    state?: number,
    customerId?: number,
    month?: number,
    year?: number,
    agentId?: number | null
  ) {
    this.search = search;
    this.articleCode = articleCode;
    if (undefined !== state) {
      this.state = state;
    }
    if (undefined !== customerId) {
      this.customerId = customerId;
    }
    if (undefined !== month) {
      this.month = month;
    }
    if (undefined !== year) {
      this.year = year;
    }
    if (undefined !== articleCode) {
      this.articleCode = articleCode;
    }
    if (undefined !== agentId) {
      this.agentId = agentId;
    }
  }
}
