<template>
  <svg id="ic_updown" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4580" width="20" height="20" transform="translate(0 20) rotate(-90)" fill="none" />
    <line
      id="Linie_355"
      data-name="Linie 355"
      y2="9.295"
      transform="translate(6.906 14.098) rotate(180)"
      fill="none"
      stroke="#E74668"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <line
      id="Linie_502"
      data-name="Linie 502"
      y2="9.295"
      transform="translate(13.082 5.93)"
      fill="none"
      stroke="#212529"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_11570"
      d="M5.843,9l3.094,3.167L12.03,9"
      transform="translate(15.844 16.098) rotate(180)"
      fill="none"
      stroke="#E74668"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_11570-2"
      data-name="Pfad_11570"
      d="M5.843,9l3.094,3.167L12.03,9"
      transform="translate(4.146 3.93)"
      fill="none"
      stroke="#212529"
      stroke-linecap="round"
      stroke-width="1.5"
    />
  </svg>
</template>
