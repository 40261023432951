<template>
  <div>
    <div class="table">
      <div class="tableColumn">
        {{ getCategoriesById[props.download.categoryId] }}
      </div>
      <div class="tableColumn">{{ props.download.title }}</div>
      <div class="tableColumn">{{ props.download.fileName }}</div>
      <div class="tableColumn right">
        <a :href="props.download.fileUrl" @click.prevent="openFile(props.download.fileUrl, true)" target="_blank">
          <IcDownload /><span class="hidden">Download file</span>
        </a>
      </div>
      <div class="tableColumn right">
        <a :href="props.download.fileUrl" @click.prevent="openFile(props.download.fileUrl, false)" target="_blank">
          <IcShow /><span class="hidden">Show file</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/domain/DownloadDto';

  import IcDownload from '@/components/icons/IcDownload.vue';

  import IcShow from '@/components/icons/IcShow.vue';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { useDownloadStore } from '@/modules/downloads/stores/useDownloadStore';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { getTranslatedText } = useTranslatedText();

  const store = useDownloadStore();
  const { downloadCategories } = storeToRefs(store);

  const { openFile } = store;

  interface Categories {
    [key: number]: string;
  }

  interface Props {
    download: DownloadDto;
  }
  const props = defineProps<Props>();

  const getCategoriesById = computed((): { [key: number]: string } => {
    const categories: Categories = {};

    downloadCategories.value.forEach((category) => {
      if (category) {
        categories[category.id] = getTranslatedText(category.title);
      }
    });
    return categories;
  });
</script>

<style scoped lang="scss">
  .table {
    @apply grid min-w-fit auto-cols-fr grid-flow-col-dense items-center gap-4 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }
</style>
