<template>
  <svg id="Logo_Agent2Go_white" xmlns="http://www.w3.org/2000/svg" width="120" height="21.251" viewBox="0 0 120 21.251">
    <g id="Gruppe_9042" data-name="Gruppe 9042" transform="translate(0 0)">
      <path
        id="Pfad_14745"
        data-name="Pfad 14745"
        d="M5,10.7H9.626L14.5,23.681H10.14l-.575-1.713H5.06l-.575,1.713H.1Zm3.6,8.173-.907-2.765a10.19,10.19,0,0,1-.363-1.683,12.909,12.909,0,0,1-.363,1.683l-.907,2.765Z"
        transform="translate(-0.1 -7.839)"
        fill="currentColor"
      />
      <path
        id="Pfad_14746"
        data-name="Pfad 14746"
        d="M53.928,9.7a11.557,11.557,0,0,1,4.234.781V14.3a6.991,6.991,0,0,0-3.448-.871c-1.905,0-3.6.841-3.6,3.065A2.864,2.864,0,0,0,53.9,19.466h.514V15.74h4.052V22.41a12.317,12.317,0,0,1-4.446.871c-3.78,0-7.319-2.164-7.319-6.791S50.269,9.7,53.928,9.7"
        transform="translate(-32.607 -7.139)"
        fill="currentColor"
      />
      <path
        id="Pfad_14747"
        data-name="Pfad 14747"
        d="M101.289,14.065H95.694v1.5h4.929v3.155H95.694v1.563h5.716v3.4H91.4V10.7h9.889Z"
        transform="translate(-63.789 -7.839)"
        fill="currentColor"
      />
      <path
        id="Pfad_14748"
        data-name="Pfad 14748"
        d="M129.8,10.7h3.629l4.415,6.1a18.534,18.534,0,0,1-.121-2.224V10.7h4.325V23.681h-3.629L134,17.611a18.035,18.035,0,0,1,.091,2.194v3.906H129.8Z"
        transform="translate(-90.576 -7.839)"
        fill="currentColor"
      />
      <path
        id="Pfad_14749"
        data-name="Pfad 14749"
        d="M187.313,14.456H182.2v9.225h-4.294V14.456H174.4V10.7h11.31Z"
        transform="translate(-121.688 -7.839)"
        fill="currentColor"
      />
      <path
        id="Pfad_14750"
        data-name="Pfad 14750"
        d="M274.671,11c2.631,0,5.02,1.142,5.02,4.087,0,1.833-1.149,2.825-2.359,3.666a11.456,11.456,0,0,0-2.268,1.683h4.657v3.456H269.5V22.148c.03-2.734,1.936-3.816,3.6-4.9,1.149-.751,2.147-1.082,2.147-1.833s-.454-.811-1.21-.811a7.279,7.279,0,0,0-4.143,1.593V12.352A9.055,9.055,0,0,1,274.671,11"
        transform="translate(-188.028 -8.049)"
        fill="currentColor"
      />
      <path
        id="Pfad_14751"
        data-name="Pfad 14751"
        d="M314.358,9.7a11.557,11.557,0,0,1,4.234.781V14.3a6.991,6.991,0,0,0-3.448-.871c-1.905,0-3.6.841-3.6,3.065a2.864,2.864,0,0,0,2.782,2.975h.514V15.74h4.022V22.41a12.316,12.316,0,0,1-4.446.871c-3.78,0-7.319-2.164-7.319-6.791S310.7,9.7,314.358,9.7"
        transform="translate(-214.257 -7.139)"
        fill="currentColor"
      />
      <path
        id="Pfad_14752"
        data-name="Pfad 14752"
        d="M357.177,9.7c4.718,0,7.077,3.065,7.077,6.791s-2.359,6.791-7.077,6.791-7.077-3.065-7.077-6.791S352.459,9.7,357.177,9.7m0,3.876c-1.694,0-2.661,1.142-2.661,2.915s.968,2.915,2.661,2.915,2.661-1.142,2.661-2.915-.968-2.915-2.661-2.915"
        transform="translate(-244.253 -7.139)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7119"
        data-name="Rechteck 7119"
        width="4.178"
        height="3.871"
        transform="translate(70.449 1.751) rotate(-24.775)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7120"
        data-name="Rechteck 7120"
        width="4.178"
        height="3.871"
        transform="translate(72.496 6.133) rotate(-24.775)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7121"
        data-name="Rechteck 7121"
        width="4.169"
        height="3.863"
        transform="translate(74.385 10.628) rotate(-24.7)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7122"
        data-name="Rechteck 7122"
        width="4.178"
        height="3.871"
        transform="translate(65.83 3.893) rotate(-24.775)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7123"
        data-name="Rechteck 7123"
        width="4.169"
        height="3.863"
        transform="translate(67.755 8.368) rotate(-24.7)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_7124"
        data-name="Rechteck 7124"
        width="4.169"
        height="3.863"
        transform="translate(69.778 12.775) rotate(-24.7)"
        fill="currentColor"
      />
      <path
        id="Pfad_14753"
        data-name="Pfad 14753"
        d="M211.61,57.1a.691.691,0,1,1-.7.691.693.693,0,0,1,.7-.691m0-1.8a2.494,2.494,0,1,0,2.51,2.494,2.5,2.5,0,0,0-2.51-2.494"
        transform="translate(-145.894 -39.037)"
        fill="currentColor"
      />
      <path
        id="Pfad_14754"
        data-name="Pfad 14754"
        d="M215.73,27.5a2.286,2.286,0,0,1-2.062-1.287l-6.386-13.533a.9.9,0,0,1,.436-1.2.91.91,0,0,1,1.207.433l6.381,13.522a.481.481,0,0,0,.626.219l5.4-2.487a.91.91,0,0,1,1.2.439.9.9,0,0,1-.441,1.2L216.681,27.3A2.358,2.358,0,0,1,215.73,27.5Z"
        transform="translate(-144.596 -8.355)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
