<template>
  <div class="flex items-center justify-between gap-24 py-12">
    <AgFormGroup :label="t('customers.checklistsCategory')" class="w-1/2">
      <AgFormSelect :options="categoryOptions" v-model="internalCategory" />
    </AgFormGroup>
    <AgFormGroup :label="t('customers.state')" class="w-1/2">
      <AgFormSelect :options="stateOptions" v-model="internalState" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import { useCustomerChecklistsStore } from '@/modules/customers/composables/useChecklistsStore';

  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const store = useCustomerChecklistsStore();
  const { getCategories } = store;
  const { categories } = storeToRefs(store);

  interface Props {
    state: number;
    category: number;
  }

  const props = defineProps<Props>();

  const categoryOptions = ref<Array<AgFormSelectOption>>([]);

  const internalState = computed({
    get: () => props.state,
    set: (value) => emit('update:state', value),
  });

  const internalCategory = computed({
    get: () => props.category,
    set: (value) => {
      if (value === -1) {
        emit('update:category', null);
      }
      emit('update:category', value);
    },
  });

  const emit = defineEmits(['update:state', 'update:category']);

  const stateOptions = computed(() => [
    { key: -1, label: 'customers.all' },
    { key: 0, label: 'customers.activityOpenState' },
    { key: 1, label: 'customers.activityClosedState' },
    { key: 2, label: 'customers.activityExpiredState' },
  ]);

  const loadCategoryList = async function () {
    await getCategories();
    categoryOptions.value.push({ label: t('checklists.all'), key: -1 });

    categories.value.forEach((category) => {
      categoryOptions.value.push({ label: category.name, key: category.id });
    });
  };

  onMounted(async () => {
    await loadCategoryList();
  });
</script>
