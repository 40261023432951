<template>
  <div v-if="props.article">
    <!-- CONTENT HEADER -->
    <div class="flex flex-col text-base py-8">
      <!-- ARTICLE TITLE -->
      <span class="italic font-light text-infoBlue">{{ getTranslatedText(props.article.infoTitle) }}</span>
      <div class="text-ellipsis overflow-hidden font-bold flex flex-row items-center justify-between">
        <span class="text-l">{{ getTranslatedText(props.article.title) }}</span>
        <AgButton variant="ghost" @click.stop="removeItem">
          <template #icon><IcClose /></template>
        </AgButton>
      </div>
      <!-- ARTICLE CODE AND EXT.CODE -->
      <div class="flex gap-16 items-center">
        <div class="flex gap-4 items-center">
          <div class="text-neutral-550 text-s">{{ t('offers.articleCode') }}:&nbsp;</div>
          <div>{{ props.article.code }}</div>
        </div>
        <div v-if="props.article.externalCode" class="flex gap-16 items-center">
          <div class="text-neutral-550 text-s">{{ t('offers.exCode') }}:&nbsp;</div>
          <div>{{ props.article.externalCode }}</div>
        </div>
      </div>
      <!-- ARTICLE TYPE -->
      <div class="flex gap-4 items-center">
        <div class="text-neutral-550 text-s">{{ t('offers.articleType') }}:&nbsp;</div>
        <div>{{ getTranslatedText(props.article.articleType?.title) }}</div>
      </div>
      <!-- CHECK STOCK AVAILABILITY IF ENABLED  -->
      <div v-if="hasStockAvailability(props.article.articleType?.code)">
        <span class="text-s text-neutral-550">{{ t('orders.stockInfo') }}:&nbsp;</span>
        <span class="text-link text-s hover:cursor-pointer" @click.stop="showStocks = !showStocks">
          {{ t('orders.showStockInfo') }}
        </span>
        <OfferWizardPopupStocks v-model="showStocks" :articleCode="article.code" />
      </div>
    </div>

    <AgCollapsible :collapse="false" show-icon color-icon="link" disabled-auto-collapse>
      <template #header>
        <div class="flex justify-end text-link text-s font-bold">
          {{ t('offers.showDetails') }}
        </div>
      </template>
      <!-- ARTICLE AND PACKAGING -->
      <template #default>
        <div class="text-s" v-if="props.article.packagings">
          <div
            v-for="packaging in props.article.packagings"
            :key="packaging.id"
            class="border-b-2 border-b-neutral-200 pb-12"
          >
            <!-- PACKAGE -->
            <div class="flex text-base">
              <div class="font-light italic text-s w-full flex flex-row items-center">
                <span class="font-bold">{{ t('offers.packaging') }} ({{ t('offers.size') }}):&nbsp;</span>
                <span class="font-bold">{{ getTranslatedText(packaging.unit?.title) }} ({{ packaging.size }})</span>
              </div>
            </div>
            <div class="flex flex-row gap-12">
              <!-- TITLE -->
              <div class="flex flex-row gap-8 pt-8 basis-1/3">
                <div class="flex flex-col gap-4">
                  <div class="font-bold">{{ t('offers.quantity') }}</div>
                  <AgFormInput
                    type="number"
                    variant="shoppingCart"
                    v-model="internalValue[article.id][packaging.id].quantity"
                    placeholder="--"
                    :validation-state="quantityValidationState(internalValue[article.id][packaging.id].quantity)"
                  />
                </div>
                <div class="flex flex-col gap-4" v-if="config.company.enableFreeQuantity">
                  <div class="font-bold">{{ t('offers.freeQta') }}</div>
                  <div v-if="config.company.enableFreeQuantity">
                    <AgFormInput
                      type="number"
                      variant="shoppingCart"
                      v-model="internalValue[article.id][packaging.id].freeQuantity"
                      placeholder="--"
                      :validation-state="
                        freeQuantityValidationState(internalValue[article.id][packaging.id].freeQuantity)
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-full">
                <!-- DIMENSIONS -->
                <div class="flex flex-wrap gap-16 basis-2/3" v-if="Object.keys(dimensions).length > 0">
                  <AgFormGroup
                    v-for="key in Object.keys(dimensions)"
                    :key="key"
                    label=""
                    :class="{ 'w-full': Object.keys(dimensions).length === 1 }"
                  >
                    <div class="font-bold">{{ getDimensionLable(key) }}</div>
                    <div class="mt-4">
                      <AgFormSelect
                        variant="shoppingCart"
                        :options="dimensions[key]"
                        :modelValue="getDimensionValue(key)"
                        @update:modelValue="setDimension($event, key, packaging.id)"
                      />
                    </div>
                  </AgFormGroup>
                </div>
                <!-- PRODUCTION ARTILCLE -->
                <div v-if="isProductionArticle(props.article.articleType?.code)" class="flex gap-16 basis-2/3">
                  <div class="mt-8 w-full flex flex-col gap-4">
                    <span class="font-bold"> {{ t('offers.productDescription') }}</span>
                    <AgFormTextarea
                      v-model="internalValue[article.id][packaging.id].productionDescription"
                      rows="2"
                      variant="shoppingCart"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>{{ t('offers.noPackageInfo') }}</div>
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import OfferWizardPopupStocks from '@/modules/offers/components/offer-wizard/steps/shopping-cart/article-table-row/OfferWizardPopupStocks.vue';

  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { locale, t } = i18n.global;

  const { getDimOptionsByArticle } = useOfferFactory();
  const { getTranslatedText } = useTranslatedText();

  const store = useOfferWizardStore();
  const { offer } = storeToRefs(store);

  interface Props {
    article: ShoppingCartArticleDto;
    modelValue: ArticlePackaging;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue', 'removeArticle']);

  const showStocks = ref<boolean>(false);
  const dimensions = ref<{ [key: string]: Array<AgFormSelectOption> }>({});
  const dimOptions = ref<{ [key: string]: Array<DimensionItemDto> }>({});

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const hasStockAvailability = function (articleTypeCode: string = '') {
    return (
      config.company.enableStockAvailability &&
      config.company.partialDeliveryArticleTypeCode &&
      config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode)
    );
  };

  const isProductionArticle = function (articleTypeCode: string = '') {
    return config.company.productionArticle && config.company.productionArticleTypeCode === articleTypeCode;
  };

  const removeItem = () => {
    emit('removeArticle', props.article);
  };

  const freeQuantityValidationState = function (freeQuantity: number | null) {
    if (!config.company.enableFreeQuantity) {
      return false;
    }
    if (freeQuantity != null && freeQuantity < 0) {
      return false;
    }
    return true;
  };

  const quantityValidationState = function (quantity: number | null) {
    if (quantity != null && quantity < 0) {
      return false;
    }
    return true;
  };

  const getDimensions = async function (articleId: number, defaultDimensions: Array<number>) {
    if (!offer.value) {
      return;
    }
    const opts = await getDimOptionsByArticle(offer.value, articleId, defaultDimensions);

    const dimensionDefaultValues: Array<DimensionItemDto> = [];
    if (opts) {
      dimOptions.value = opts;

      let dimIndex = 0;
      for (const [key, items] of Object.entries(opts)) {
        items.forEach((item, index) => {
          if (index === 0) {
            dimensionDefaultValues[dimIndex] = item;
          }
          if (dimensions.value[key]) {
            dimensions.value[key].push({
              key: item.value.id,
              label: item.value.title[locale.value],
            });
            if (item.value.default) {
              dimensionDefaultValues[dimIndex] = item;
            }
          } else {
            dimensions.value[key] = [
              {
                key: item.value.id,
                label: item.value.title[locale.value],
              },
            ];
            if (item.value.default) {
              dimensionDefaultValues[dimIndex] = item;
            }
          }
        });
        dimIndex++;
      }
      props.article.packagings.forEach((packaging) => {
        internalValue.value[props.article.id][packaging.id].dimensions = [...dimensionDefaultValues];
      });
    }
  };

  const getDimensionLable = (key: string) => dimOptions.value[key][0].title[locale.value] ?? '--';

  const getDimensionValue = function (key: string) {
    const dimensionItems = dimOptions.value[key];

    let defaultDimensionId = dimensionItems[0].value.id;
    dimensionItems.forEach((item) => {
      if (item.value.default) {
        defaultDimensionId = item.value.id;
      }
    });
    return defaultDimensionId;
  };

  const setDimension = function (id: number, key: string, packagingId: number) {
    const tempDimensions = internalValue.value[props.article.id][packagingId].dimensions || [];

    const indexToRemove = tempDimensions.findIndex((item) => item.id === parseInt(key));
    if (indexToRemove != -1) {
      tempDimensions.splice(indexToRemove, 1);
    }
    const dimensionItem = dimOptions.value[key].find((item) => item.value.id === id);
    if (dimensionItem) {
      tempDimensions.push(dimensionItem);
    }
  };

  onMounted(async () => {
    const hasDimesnions =
      props.article.articleDimensionValueConfigIds && props.article.articleDimensionValueConfigIds.length > 0;

    if (hasDimesnions && config.company.enabledProductionDimensions) {
      await getDimensions(props.article.id, props.article.priceGroupIds);
    }
  });
</script>

<style scoped lang="scss">
  .agPopper {
    --popper-theme-background-color: #006fff;
    --popper-theme-background-color-hover: #006fff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
