<template>
  <AgModal v-if="internalValue" fit-height fit-width>
    <template #header>
      <div class="text-2xl font-bold">{{ t('offers.sendOfferByEmail') }}</div>
    </template>
    <!-- MAIL DATA -->

    <template #content>
      <div class="md:min-w-[333px]">
        <div class="py-12">{{ t('offers.emailTextReminder') }}</div>
        <div class="space-y-16 py-16">
          <div>
            <AgFormGroup :label="t('offers.mail')" required>
              <AgFormInput v-model="emailTo" type="email" :validation-state="errorMessage.length === 0" />
            </AgFormGroup>
            <div v-if="errorMessage.length > 0" class="text-danger p-4">
              {{ errorMessage }}
            </div>
          </div>
          <div>
            <AgFormGroup :label="t('offers.language')" required>
              <AgFormSelect v-model="language" :options="langOptions" />
            </AgFormGroup>
          </div>
        </div>

        <div class="mx-auto py-24">
          <div v-if="isLoading">
            <IcLoading />
          </div>
          <div v-if="isError" class="text-danger">
            {{ t('offers.emailRequestError') }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click.stop="closeModal" :disabled="isLoading">
        {{ t('offers.close') }}
      </AgButton>
      <AgButton @click.stop="sendEmail" :disabled="isLoading || errorMessage.length > 0">
        {{ t('offers.sendMail') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { OfferEmailRequest } from '@/domain/email/OfferEmailRequest';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { config } from '@/config/config';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { accountName } = useAuthentication();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  interface Props {
    modelValue?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const errorMessage = ref<string>('');
  const language = ref<string>('');
  const emailTo = ref<string>('');
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: t(`offers.language.${lang}`),
    }))
  );

  const sendEmail = function () {
    if (!offer.value || !offer.value.gid) {
      return;
    }
    isLoading.value = true;
    isError.value = false;

    const request: OfferEmailRequest = {
      emailTo: emailTo.value,
      language: language.value,
    };
    console.log('Request to send email...', request);
    const result = confirm("Sending emails that we don't have right now!");
    setTimeout(() => {
      if (result) {
        isLoading.value = false;
      } else {
        isLoading.value = false;
        isError.value = true;
      }
    }, 9 * 1000);
  };

  const validateEmail = (email: string) => {
    const regexp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const isValidEmail = regexp.test(email);
    if (isValidEmail) {
      errorMessage.value = '';
    } else {
      if (email.trim().length === 0) {
        errorMessage.value = t('offers.emailRequired');
      } else {
        errorMessage.value = t('offers.emailInvalid');
      }
    }
  };

  watch(emailTo, (value) => validateEmail(value), { immediate: true });
  watch(
    () => props.modelValue,
    (value) => {
      if (value) {
        isLoading.value = false;
        isError.value = false;
        emailTo.value = accountName.value;
        language.value = config.company.translatedDataLocalesOrder[0];
        errorMessage.value = '';
      }
    },
    { immediate: true }
  );
</script>
