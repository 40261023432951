<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="ic_search">
      <g id="Gruppe_5007" data-name="Gruppe 5007" transform="translate(3.597 3.561)">
        <g id="Icon_feather-search" data-name="Icon feather-search">
          <path
            id="Pfad_9572"
            data-name="Pfad 9572"
            d="M15.086,9.793A5.293,5.293,0,1,1,9.793,4.5,5.293,5.293,0,0,1,15.086,9.793Z"
            transform="translate(-4.5 -4.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Pfad_9573"
            data-name="Pfad 9573"
            d="M28.71,28.548l-3.735-3.573"
            transform="translate(-15.904 -15.67)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
      <path id="Pfad_2162" data-name="Pfad 2162" d="M0,0H20V20H0Z" fill="none" />
    </g>
  </svg>
</template>
