<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ArticleFilter v-model="articleFilter" />
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isDataLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-263px)]">
        <div v-for="key in Object.keys(filteredArticles)" :key="key">
          <AgCollapsible show-icon showBorder v-if="filteredArticles[key].length > 0">
            <template #header>
              <div class="px-4 py-[18px]" :class="getMatch(key)">
                {{ key }} - {{ t('articles.elementNumber') }}: {{ filteredArticles[key].length }}
              </div>
            </template>

            <template #default>
              <!-- ARTICLE INFO -->
              <ArticleInfo :articles="filteredArticles[key]" :filter="articleFilter" />
            </template>
          </AgCollapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ArticleFilter from '@/modules/articles/components/ArticleFilter.vue';
  import ArticleInfo from '@/modules/articles/components/ArticleInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { useArticleFactory } from '@/modules/articles/composables/useArticleFactory';
  import { useDimensionStore } from '@/modules/articles/stores/useDimensionStore';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  const { isFullReloading, isLoading } = useMasterData();

  const { getTranslatedText } = useTranslatedText();
  const { escapedRegExpTwo } = Utilities();
  const { loadDimensionValueConfigs } = useDimensionStore();
  const { getShoppingCartArticles } = useArticleFactory();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('articles.overview');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const articleFilter = ref<string>('');
  const isDataLoading = ref<boolean>(true);
  const articles = ref<Array<ShoppingCartArticleDto>>([]);
  const groupedArticles = ref<{ [key: string]: Array<ShoppingCartArticleDto> }>({});
  const filteredArticles = ref<{ [key: string]: Array<ShoppingCartArticleDto> }>({});

  const getMatch = function (ref?: string) {
    if (ref && articleFilter.value.length > 1) {
      const regexp = escapedRegExpTwo(articleFilter.value);
      const match = ref.match(regexp) ? true : false;
      if (match) {
        return 'bg-yellow px-4 w-fit';
      }
    }
    return '';
  };

  const filtered = function () {
    if (articleFilter.value.length > 1 && articles.value.length > 0) {
      const articlesFiltered = articles.value.filter((article) => {
        try {
          const title = article.title ? getTranslatedText(article.title) : '';
          const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : '';
          const type = article.articleType ? getTranslatedText(article.articleType.title) : '';
          const externalCode = article.externalCode ? article.externalCode : '';

          const filteredArticle = [article.code, externalCode, title, article.searchName, group, type].join('');

          const regexp = escapedRegExpTwo(articleFilter.value);
          return filteredArticle.match(regexp) ? true : false;
        } catch (e) {
          return false;
        }
      });
      filteredArticles.value = {};
      articlesFiltered.forEach((article) => {
        const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
        if (!filteredArticles.value[group]) {
          filteredArticles.value[group] = [];
        }
        filteredArticles.value[group].push(article);
      });
    } else {
      filteredArticles.value = groupedArticles.value;
    }
  };

  const groupingArticles = function () {
    if (Object.keys(groupedArticles.value).length > 0) {
      groupedArticles.value = {};
    }
    articles.value.forEach((article) => {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      if (!groupedArticles.value[group]) {
        groupedArticles.value[group] = [];
      }
      groupedArticles.value[group].push(article);
    });
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadDimensionValueConfigs();

        const shoppingCartArticles = await getShoppingCartArticles();

        isDataLoading.value = false;
        if (shoppingCartArticles.isPresent()) {
          articles.value = shoppingCartArticles.get();
          groupingArticles();
        }
      }
    },
    { immediate: true }
  );

  watch(articleFilter, filtered, { immediate: true });
</script>
