<template>
  <svg
    id="stats_arrow_down"
    data-name="stats arrow down"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
  >
    <g id="Gruppe_7487" data-name="Gruppe 7487">
      <path
        id="Pfad_12467"
        data-name="Pfad 12467"
        d="M1.636,3.0507l8.6267,8.6267H2.3431v1.9799s11.3137,0,11.3137,0V2.3436h-1.9799v7.9196S3.0503,1.6365,3.0503,1.6365l-1.4142,1.4142Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
