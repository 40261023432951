<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16 flex items-center justify-between">
    <AgFormGroup :label="t('search')" class="w-full">
      <AgSearchInput v-model="internalValue" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    modelValue: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
  });
</script>
