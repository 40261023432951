<template>
  <svg id="ic_copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_3157" data-name="Rechteck 3157" width="20" height="20" fill="none" />
    <g id="Gruppe_5130" data-name="Gruppe 5130" transform="translate(-1283.595 -527.544)">
      <path
        id="Pfad_9738"
        data-name="Pfad 9738"
        d="M-21652.232-7302.254v-1.765h-7.6v10.544h2.418"
        transform="translate(22947.93 7835)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <g
        id="Rechteck_3163"
        data-name="Rechteck 3163"
        transform="translate(1291.25 533.544)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      >
        <rect width="9" height="12" stroke="none" />
        <rect x="0.75" y="0.75" width="7.5" height="10.5" fill="none" />
      </g>
    </g>
  </svg>
</template>
