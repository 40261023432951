import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
import type { AuthData } from '@/domain/auth/AuthData';
import type { OfferDto } from '@/domain/offerData/OfferDto';
import type { OrderDto } from '@/domain/orderData/OrderDto';
import Dexie from 'dexie';

export class UserDatabase extends Dexie {
  authentication!: Dexie.Table<AuthData, string>;
  timestamps!: Dexie.Table<{ id: string; value?: number | null }, string>;
  orders!: Dexie.Table<OrderDto, string>;
  deletedOrders!: Dexie.Table<OrderDto, string>;
  offers!: Dexie.Table<OfferDto, string>;
  deletedOffers!: Dexie.Table<OfferDto, string>;
  userHierarchy!: Dexie.Table<UserHierarchyDto, number>;

  constructor() {
    super('a2gUserData');

    /**
     * IMPORTANT NOTE:
     * Do not alter the stores of a version which is already in production!
     * If you do this, every user has to destroy and recreate the db to activate the new store definition.
     * If you want to alter the stores, create a new version!
     * @see http://dexie.org/docs/Version/Version.upgrade()
     */

    this.version(1).stores({
      authentication: '&id',
      timestamps: '&id',
      orders: '&gid,status,synced',
      deletedOrders: '&gid',
    });

    this.version(2).stores({
      orders: '&gid,status,synced,invoiceCustomer.vatCode',
      offers: '&gid,status,synced',
      deletedOffers: '&gid',
    });

    this.version(3).stores({
      userHierarchy: '&id',
    });

    this.version(4).stores({
      offers: '&gid,status,synced,invoiceCustomer.vatCode',
    });

    this.version(5).stores({
      orders: '&gid,status,synced,createdAt,invoiceCustomer.vatCode,invoiceCustomer.name',
    });

    this.version(6).stores({
      offers: '&gid,status,synced,createdAt,invoiceCustomer.vatCode,invoiceCustomer.name',
    });

    this.version(7).stores({
      offers: '&gid,status,synced,createdAt,customer.vatCode,customer.name',
      orders: '&gid,status,synced,createdAt,customer.vatCode,customer.name',
    });
    this.version(8).stores({
      offers: '&gid,status,synced,createdAt,customer.vatCode,customer.name,[status+synced]',
      orders: '&gid,status,synced,createdAt,customer.vatCode,customer.name,[status+synced]',
    });
  }
}
