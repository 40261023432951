<template>
  <div v-if="props.openItems && props.openItems.length > 0">
    <!-- TABLE HEADER -->
    <div class="tableHeader">
      <!-- COLUM CUSTOMER CODE -->
      <div class="tableColumn" @click="sortCustomerCode">
        <span class="px-4">{{ t('openItems.customerNumber') }}</span>
        <span v-if="orderCustomerCode === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderCustomerCode === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM CUSTOMER NAME -->
      <div class="tableColumn" @click="sortCustomerName">
        <span class="px-4">{{ t('openItems.customerName') }}</span>
        <span v-if="orderCustomerName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderCustomerName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM NUMBER OF INVOICES -->
      <div class="tableColumn" @click="sortInvoiceCount">
        <span class="px-4">{{ t('openItems.numberOfInvoices') }}</span>
        <span v-if="orderInvoiceCount === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderInvoiceCount === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM NUMBER OF INVOICES -->
      <div class="tableColumn right" @click="sortOpenAmountSum">
        <span class="px-4">{{ t('openItems.openAmount') }}</span>
        <span v-if="orderOpenAmountSum === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderOpenAmountSum === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUM SEND EMAIL - EMPTY LABEL ICON -->
      <div class="tableColumn">&nbsp;</div>
    </div>
    <!-- TABLE CONTENT OF OPEN ITEMS -->
    <div v-for="openItemCustomer in props.openItems" :key="openItemCustomer.customerId">
      <OpenItemsByCustomerRow :isSelectionActive="props.isSelectionActive" :openItem="openItemCustomer" />
    </div>

    <!-- NOTE -->
    <div class="py-12 space-y-8">
      <div>
        <span class="mr-8 px-4 bg-infoYellow">&nbsp;</span>
        <span>{{ t('openItems.due') }}</span>
      </div>
      <div>
        <span class="mr-8 px-4 bg-infoOrange">&nbsp;</span>
        <span>{{ t('openItems.oneMonthdue') }}</span>
      </div>
      <div>
        <span class="mr-8 px-4 bg-infoRed">&nbsp;</span>
        <span>{{ t('openItems.sixMonthDue') }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('openItems.notFoundByCustomer') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtCustomerDto } from '@/domain/OutstandingDebtCustomerDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import OpenItemsByCustomerRow from '@/modules/open-items/components/by-customer/OpenItemsByCustomerRow.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    openItems: Array<OutstandingDebtCustomerDto>;
    modelValue: Sorting;
    isSelectionActive: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const orderCustomerCode = ref<Order | null>(null);
  const sortCustomerCode = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_CODE));

  const orderCustomerName = ref<Order | null>(null);
  const sortCustomerName = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_NAME));

  const orderInvoiceCount = ref<Order | null>(null);
  const sortInvoiceCount = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_COUNT));

  const orderOpenAmountSum = ref<Order | null>(null);
  const sortOpenAmountSum = () => emit('update:modelValue', getSorting(ColumnName.OPEN_AMOUNT_SUM));

  enum ColumnName {
    CUSTOMER_CODE = 'customerCode',
    CUSTOMER_NAME = 'customerName',
    INVOICE_COUNT = 'invoiceCount',
    OPEN_AMOUNT_SUM = 'openAmountSum',
  }

  const getSorting = (colum: ColumnName): Sorting => {
    if (colum === ColumnName.CUSTOMER_CODE) {
      if (orderCustomerCode.value === Order.ASC) {
        orderCustomerCode.value = Order.DESC;
      } else {
        orderCustomerCode.value = Order.ASC;
      }
      orderCustomerName.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.CUSTOMER_CODE, orderCustomerCode.value);
    } else if (colum === ColumnName.CUSTOMER_NAME) {
      if (orderCustomerName.value === Order.ASC) {
        orderCustomerName.value = Order.DESC;
      } else {
        orderCustomerName.value = Order.ASC;
      }
      orderCustomerCode.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.CUSTOMER_NAME, orderCustomerName.value);
    } else if (colum === ColumnName.INVOICE_COUNT) {
      if (orderInvoiceCount.value === Order.ASC) {
        orderInvoiceCount.value = Order.DESC;
      } else {
        orderInvoiceCount.value = Order.ASC;
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderOpenAmountSum.value = null;

      return new Sorting(ColumnName.INVOICE_COUNT, orderInvoiceCount.value);
    } else if (colum === ColumnName.OPEN_AMOUNT_SUM) {
      if (orderOpenAmountSum.value === Order.ASC) {
        orderOpenAmountSum.value = Order.DESC;
      } else {
        orderOpenAmountSum.value = Order.ASC;
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceCount.value = null;

      return new Sorting(ColumnName.OPEN_AMOUNT_SUM, orderOpenAmountSum.value);
    } else {
      // SORTING DEFAULT VALUE CUSTOMER NAME
      return new Sorting(ColumnName.CUSTOMER_NAME, Order.ASC);
    }
  };

  const defaultSorting = () => {
    orderCustomerCode.value = null;
    // SORTING DEFAULT VALUE CUSTOMER NAME
    orderCustomerName.value = Order.ASC;
    orderInvoiceCount.value = null;
    orderOpenAmountSum.value = null;
  };

  onMounted(defaultSorting);
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid  grid-cols-[160px_minmax(200px,1fr)_170px_200px_30px] min-h-48;
    @apply items-center py-8 font-light text-neutral-550;
  }

  .tableColumn {
    @apply flex items-center gap-4 hover:cursor-pointer;
  }

  .right {
    @apply justify-end;
  }
</style>
