import { useApi } from '@/composables/api/useApi';
import type { CategoryInfo } from '@/domain/CategoryInfo';
import type { ChecklistDto } from '@/domain/ChecklistDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { ParameterChecklistDto } from '@/domain/ParameterChecklistDto';
import type { CheckListsFilter } from '@/util/filters/CheckListsFilter';
import { PageImpl, PageRequest, type Page } from '@/util/Paging';
import type { Sorting } from '@/util/Sorting';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCheckListStore = defineStore('checklists', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const actvities = ref<Page<ChecklistDto>>(new PageImpl([], new PageRequest(10, 1), 0));
  const categories = ref<Array<CategoryInfo>>([]);

  async function getActivities(pagination: PageRequest, sorting: Sorting, filter: CheckListsFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pagination.number}`,
        'pagination.records': `${pagination.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.state': `${filter.state}`,
        ...(filter.agentActivityId ? { 'filters.agentActivityId': `${filter.agentActivityId}` } : ''),
        ...(filter.category ? { 'filters.category': `${filter.category}` } : ''),
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/checklists?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.activities) {
        actvities.value = api.getResultPage(container, container.data.activities);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  const updateChecklist = async (checklist: ParameterChecklistDto) => {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/checklists/update?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', checklist);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };
  async function getCategories() {
    try {
      const requestUri = `/checklists/categories`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.categories) {
        categories.value = container.data.categories;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }
  return {
    actvities,
    categories,
    getActivities,
    getCategories,
    updateChecklist,
  };
});
