<template>
  <div class="flex items-center justify-between gap-24 py-12 md:mx-[80px] max-md:mx-16">
    <AgFormGroup :label="t('openItems.search')" class="w-2/3">
      <AgSearchInput v-model="internalValueSearch" />
    </AgFormGroup>

    <AgFormGroup :label="t('openItems.dueDate')" class="w-1/3">
      <AgFormSelect v-model="internalValueDue" :options="options" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';

  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    selectDue: number;
    inputSearch: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:selectDue', 'update:inputSearch']);

  const options = ref<Array<AgFormSelectOption>>([
    {
      key: 0,
      label: 'openItems.allOpenItems',
    },
    {
      key: 1,
      label: 'openItems.allOpenItemsdue',
    },
    {
      key: 2,
      label: 'openItems.dueOneMonthAgo',
    },
    {
      key: 3,
      label: 'openItems.dueSixMonthsAgo',
    },
  ]);

  const internalValueDue = computed({
    get: () => props.selectDue,
    set: (value) => emit('update:selectDue', value),
  });

  const internalValueSearch = computed({
    get: () => props.inputSearch,
    set: (value) => emit('update:inputSearch', value),
  });
</script>
