<!-- eslint-disable max-len -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.859" height="16" viewBox="0 0 20.859 16">
    <g id="Burger">
      <path
        id="Pfad_347"
        data-name="Pfad 347"
        d="M21.29,23.52H42.149"
        transform="translate(-21.29 -22.52)"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        id="Pfad_348"
        data-name="Pfad 348"
        d="M21.29,23.52H42.149"
        transform="translate(-21.29 -15.52)"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        id="Pfad_349"
        data-name="Pfad 349"
        d="M21.29,23.52H42.149"
        transform="translate(-21.29 -8.52)"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
