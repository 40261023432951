<template>
  <div :class="boxClasses">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  interface Props {
    variant?: 'neutral' | 'accent' | 'strong-accent' | 'alternative';
    rounded?: boolean;
    showBorder?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    variant: 'neutral',
    rounded: false,
    showBorder: true,
  });

  const boxClasses = computed(() => ({
    'bg-opacity-0 p-32': props.variant === 'neutral',
    'bg-neutral-100 p-32': props.variant === 'accent',
    'bg-neutral-200 p-32': props.variant === 'strong-accent',
    'bg-[#DBE5D8] p-32': props.variant === 'alternative',
    'border border-neutral-500': props.showBorder,
    rounded: props.rounded,
  }));
</script>
