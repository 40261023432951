<template>
  <router-link
    :to="props.to"
    class="sidebar-item block py-12 font-light text-neutral-600"
    :class="{ 'router-link-active': isActive }"
  >
    {{ props.label }}
  </router-link>
</template>

<script setup lang="ts">
  import { router } from '@/plugins/router';
  import { ref } from 'vue';

  interface Props {
    label: string;
    to: string;
  }
  const props = defineProps<Props>();

  const isActive = ref<boolean>(false);

  router.beforeEach((to) => {
    if (to.fullPath && to.fullPath.startsWith(props.to)) {
      isActive.value = true;
    } else {
      isActive.value = false;
    }
  });
</script>

<style lang="scss" scoped>
  .sidebar-item.router-link-active {
    @apply font-bold text-primary;
  }
</style>
