<template>
  <div class="menu bg-[#EFEFEF] px-32 py-12">
    <a
      v-if="config.company.modules.OFFER || config.company.modules.ORDER"
      href="#"
      class="sidebar-item block py-12 text-base font-light"
      :class="{
        'text-default': !props.isSyncing,
        'text-neutral-550': props.isSyncing,
      }"
      @click.stop.prevent="syncNow"
    >
      <div class="flex place-items-center gap-12 truncate">
        <div class="sync-link" :class="{ active: props.isSyncing }">
          <IcUpdate />
        </div>
        <div>{{ t('syncDataSidebar') }}</div>
      </div>
    </a>
    <a
      href="#"
      class="sidebar-item block py-12 text-base font-light"
      :class="{
        'text-default': !props.isLoadingMasterData,
        'text-neutral-550': props.isLoadingMasterData,
      }"
      @click.stop.prevent="reloadData"
    >
      <div class="flex place-items-center gap-12 truncate">
        <div class="refresh-link" :class="{ active: props.isLoadingMasterData }">
          <IcReload />
        </div>
        <div>{{ t('reloadDataSidebar') }}</div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
  import IcReload from '@/components/icons/IcReload.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';

  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    isLoadingMasterData: boolean;
    isSyncing: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['sync-now', 'reload-data']);

  const syncNow = () => {
    emit('sync-now');
  };

  const reloadData = () => {
    emit('reload-data');
  };
</script>

<style scoped lang="scss">
  .menu > a {
    @apply border-t border-white;

    &:last-child {
      @apply border-b;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  .sync-link.active {
    svg {
      animation: spin-reverse 1s linear infinite;
    }
  }

  .refresh-link.active {
    svg {
      animation: spin 1s linear infinite;
    }
  }
</style>
