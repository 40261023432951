<template>
  <div class="py-[40px] overflow-x-auto">
    <div class="mx-auto w-fit">
      <!-- TABLE HEADER PERIODS-->
      <StatisticCustomSearchTablePeriodsHeader
        :periodOne="props.periodOne"
        :periodTwo="props.periodTwo"
        :startMonth="props.startMonth"
        :endMonth="props.endMonth"
      />

      <!-- TABLE HEADER -->
      <StatisticCustomSearchHeader @update:sorting="emitNewValue($event)" />

      <!-- ROWS -->
      <div v-if="props.statistics && props.statistics.length > 0">
        <div v-for="statistic in props.statistics" :key="statistic.index">
          <StatisticCustomSearchTableRow :statistic="statistic" />
        </div>
        <StatisticCustomSearchTableRow :statistic="sumStatistic" :isSumRow="true" class="bg-neutral-100 font-bold" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticGroupedDto } from '@/domain/StatisticGroupedDto';

  import StatisticCustomSearchHeader from '@/modules/statistics/components/customSearch/StatisticCustomSearchHeader.vue';
  import StatisticCustomSearchTablePeriodsHeader from '@/modules/statistics/components/customSearch/StatisticCustomSearchPeriodsHeader.vue';
  import StatisticCustomSearchTableRow from '@/modules/statistics/components/customSearch/StatisticCustomSearchTableRow.vue';

  import { Sorting } from '@/util/Sorting';
  import { computed } from 'vue';

  interface Props {
    statistics: Array<StatisticGroupedDto>;
    periodOne: number;
    periodTwo: number;
    startMonth: number;
    endMonth: number;
    subPositions?: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:sorting']);

  const sumStatistic = computed(() => {
    const st: StatisticGroupedDto = {
      totalAmountPeriodOne: 0,
      totalQuantityPeriodOne: 0,
      totalCustomersPeriodOne: 0,
      totalAmountPeriodTwo: 0,
      totalQuantityPeriodTwo: 0,
      totalCustomersPeriodTwo: 0,
      articleCode: '',
      articleName: '',
      customerName: '',
      customerCode: '',
      index: 0,
    };
    props.statistics.forEach((stat: StatisticGroupedDto) => {
      st.totalQuantityPeriodOne += stat.totalQuantityPeriodOne;
      st.totalAmountPeriodOne += stat.totalAmountPeriodOne;
      st.totalQuantityPeriodTwo += stat.totalQuantityPeriodTwo;
      st.totalAmountPeriodTwo += stat.totalAmountPeriodTwo;
    });
    return st;
  });

  const emitNewValue = (value: Sorting) => {
    emit('update:sorting', value);
  };
</script>
