import { useApi } from '@/composables/api/useApi';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { OfferEmailRequest } from '@/domain/email/OfferEmailRequest';
import type { OrderEmailRequest } from '@/domain/email/OrderEmailRequest';
import type { OutstandingDebtsEmailPayRequest } from '@/domain/email/OutstandingDebtsEmailPayRequest';
import type { OutstandingDebtsEmailRequest } from '@/domain/email/OutstandingDebtsEmailRequest';
import type { OutstandingDebtToPayDto } from '@/domain/OutstandingDebtToPayDto';
import { useError } from '@/util/useError';

export function useEmail() {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const sendOffer = async function (gid: string, request: OfferEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/offer/${gid}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendOrder = async function (gid: string, request: OrderEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/order/${gid}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendOrderEmail = async function (gid: string, request: OrderEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/send/order/email/${gid}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendOutstandingDebts = async function (request: OutstandingDebtsEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/outstandingdebts?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendCustomerOutstandingDebts = async function (customerId: number, request: OutstandingDebtsEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/outstandingdebts/${customerId}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendAgentOutstandingDebts = async function (agentId: number, request: OutstandingDebtsEmailRequest) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/outstandingdebts/agent/${agentId}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const sendInvoicesPayment = async function (
    note: string,
    language: string,
    paidInvoices: Array<OutstandingDebtToPayDto>
  ) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/email/outstandingdebts/pay?${searchParams}`;

      const request: OutstandingDebtsEmailPayRequest = {
        note: note,
        language: language,
        invoices: paidInvoices,
      };

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', request);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    sendOffer,
    sendOrder,
    sendOrderEmail,
    sendOutstandingDebts,
    sendCustomerOutstandingDebts,
    sendAgentOutstandingDebts,
    sendInvoicesPayment,
  };
}
