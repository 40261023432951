import type { EModules } from '@/config/EModules';
import { config } from '@/config/config';

export function useFeatureFlags() {
  const featureEnabled = function (featureId: EModules) {
    const modules = config.company.modules;
    if (modules) {
      return modules[featureId];
    }
    return false;
  };

  return {
    featureEnabled,
  };
}
