<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('downloads.categoryFilter') }}</div>
      <div class="headerColumn hover:cursor-pointer" @click="sortTitle">
        <span>{{ t(`downloads.title`) }}</span>
        <span v-if="orderTitle === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderTitle === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <div class="headerColumn hover:cursor-pointer" @click="sortFileName">
        <span>{{ t(`downloads.fileName`) }}</span>
        <span v-if="orderFileName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderFileName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <div class="headerColumn right">{{ t('downloads.downloadFile') }}</div>
      <div class="headerColumn right">{{ t('downloads.showFile') }}</div>
    </div>
    <DownloadRow v-for="(download, index) in props.downloads" :download="download" :key="index" />
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/domain/DownloadDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import DownloadRow from '@/modules/downloads/components/DownloadRow.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    downloads: Array<DownloadDto>;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const orderTitle = ref<Order | null>(null);
  const sortTitle = () => emit('update:modelValue', getSorting(ColumnName.TITLE));

  const orderFileName = ref<Order | null>(null);
  const sortFileName = () => emit('update:modelValue', getSorting(ColumnName.FILE_NAME));

  enum ColumnName {
    TITLE = 'title',
    FILE_NAME = 'fileName',
  }

  const getSorting = (colum: ColumnName): Sorting => {
    if (colum === ColumnName.TITLE) {
      if (orderTitle.value === Order.ASC) {
        orderTitle.value = Order.DESC;
      } else {
        orderTitle.value = Order.ASC;
      }
      orderFileName.value = null;

      return new Sorting(ColumnName.TITLE, orderTitle.value);
    } else if (colum === ColumnName.FILE_NAME) {
      if (orderFileName.value === Order.ASC) {
        orderFileName.value = Order.DESC;
      } else {
        orderFileName.value = Order.ASC;
      }
      orderTitle.value = null;

      return new Sorting(ColumnName.FILE_NAME, orderFileName.value);
    } else {
      // SORTING DEFAULT VALUE FILE NAME
      return new Sorting(ColumnName.FILE_NAME, Order.ASC);
    }
  };

  const defaultSorting = () => {
    orderTitle.value = null;
    // SORTING DEFAULT VALUE FILE NAME
    orderFileName.value = Order.ASC;
  };

  onMounted(() => defaultSorting());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid min-w-fit auto-cols-fr grid-flow-col-dense items-center gap-4 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center gap-4;
  }

  .right {
    @apply justify-end;
  }
</style>
