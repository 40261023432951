<template>
  <div v-if="paginatedContingentsKeys && paginatedContingentsKeys.length === 0" class="py-24">
    <AgAlert variant="info">{{ t('contingents.notFoundByArticle') }}</AgAlert>
  </div>
  <div v-else>
    <!-- PAGINATED CONTENT -->
    <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="sumContingentsGroup.size" />
    <!-- PAGINATOR -->
    <AgPaginator v-model="currentPage" :total-pages="pageCount" class="my-24" />

    <div class="w-fit mx-auto">
      <!-- TABLE HEADER -->
      <div class="table border-b border-neutral-500 px-16">
        <div class="grid grid-cols-[183px_minmax(203px,363px)]">
          <!-- CONTINGENT GROUP ARTICLE CODE -->
          <div class="headerTitle" @click="sortContingentGroupArticleCode">
            <span class="px-4">{{ t('contingents.contingentGroupArticleCode') }}</span>
            <span v-if="orderContingentGroupArticleCode === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderContingentGroupArticleCode === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <!-- CONTINGENT GROUP ARTICLE DESCRIPTION -->
          <div class="headerTitle" @click="sortContingentGroupArticleDescription">
            <span class="px-4">{{ t('contingents.contingentGroupArticleDescription') }}</span>
            <span v-if="orderContingentGroupArticleDescription === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderContingentGroupArticleDescription === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>

        <div
          class="grid grid-cols-[minmax(80px,133px)_minmax(80px,133px)_minmax(80px,133px)_minmax(80px,133px)_minmax(80px,203px)_133px]"
        >
          <!-- TOTAL QUANTITY -->
          <div class="headerTitle right" @click="sortTotal">
            <span class="px-4">{{ t('contingents.total') }}</span>
            <span v-if="orderTotal === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderTotal === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- DELIVERED QUANTITY -->
          <div class="headerTitle right" @click="sortDelivered">
            <span class="px-4">{{ t('contingents.delivered') }}</span>
            <span v-if="orderDelivered === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderDelivered === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- ORDERED QUANTITY -->
          <div class="headerTitle right" @click="sortOrdered">
            <span class="px-4">{{ t('contingents.ordered') }}</span>
            <span v-if="orderOrdered === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderOrdered === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- AVAILABLE QUANTITY -->
          <div class="headerTitle right" @click="sortAvailable">
            <span class="px-4">{{ t('contingents.available') }}</span>
            <span v-if="orderAvailable === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderAvailable === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- ALLOCATED TO CUSTOMER -->
          <div class="headerTitle right" @click="sortAllocatedToCustomer">
            <span class="px-4">{{ t('contingents.allocatedToCustomer') }}</span>
            <span v-if="orderAllocatedToCustomer === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderAllocatedToCustomer === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- NO ALLOCATION -->
          <div class="headerTitle right" @click="sortNoAllocation">
            <span class="px-4">{{ t('contingents.noAllocation') }}</span>
            <span v-if="orderNoAllocation === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderNoAllocation === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
      </div>

      <!-- TABLE CONTENT OF CONTINGENTS BY CUSTOMER -->
      <div v-for="key in paginatedContingentsKeys">
        <ContingentByContGrArticleRow
          :sumContingentRow="sumContingentsGroup.get(key)"
          :contingents="props.contingents.get(key) ?? []"
          :customers="customers"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { SumContingentDto } from '@/domain/internal/SumContingent';
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import ContingentByContGrArticleRow from '@/modules/contingents/components/by-group-article/ContingentByContGrArticleRow.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  interface Props {
    contingents: Map<string, Array<ContingentDto>>;
  }
  const props = defineProps<Props>();

  const { tables } = useMasterData();
  const { locale } = i18n.global;

  const orderContingentGroupArticleCode = ref<Order | null>(null);
  const sortContingentGroupArticleCode = () => getSorting(ColumnName.CONTINGENT_GROUP_ARTICLE_CODE);

  const orderContingentGroupArticleDescription = ref<Order | null>(null);
  const sortContingentGroupArticleDescription = () => getSorting(ColumnName.CONTINGENT_GROUP_ARTICLE_DESCRIPTION);

  const orderTotal = ref<Order | null>(null);
  const sortTotal = () => getSorting(ColumnName.TOTAL);

  const orderDelivered = ref<Order | null>(null);
  const sortDelivered = () => getSorting(ColumnName.DELIVERED);

  const orderOrdered = ref<Order | null>(null);
  const sortOrdered = () => getSorting(ColumnName.ORDERED);

  const orderAvailable = ref<Order | null>(null);
  const sortAvailable = () => getSorting(ColumnName.AVAILABLE);

  const orderAllocatedToCustomer = ref<Order | null>(null);
  const sortAllocatedToCustomer = () => getSorting(ColumnName.ALLOCATED_TO_CUSTOMER);

  const orderNoAllocation = ref<Order | null>(null);
  const sortNoAllocation = () => getSorting(ColumnName.NO_ALLOCATION);

  const sumContingentsGroup = ref<Map<string, SumContingentDto>>(new Map());
  const contingentGroupArticles = ref<Array<ContingentGroupArticleDto>>([]);
  const articleDimensionValueConfigs = ref<Array<ArticleDimensionValueConfigsDto>>([]);
  const customers = ref<Array<CustomerDto>>([]);

  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const pageCount = computed(() => Math.ceil(sumContingentsGroup.value.size / itemsPerPage));

  const paginatedContingentsKeys = computed(() => {
    const orderedKeys = Array.from(sumContingentsGroup.value.keys());

    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, orderedKeys.length);
    const customersPerPage = orderedKeys.slice(startIndex, endIndex);

    return customersPerPage;
  });

  const loadSumContingentsByContGrArticleId = function () {
    sumContingentsGroup.value.clear();

    props.contingents.forEach((value: ContingentDto[], key: string) => {
      const sum: SumContingentDto = {
        agentId: 0,
        contingentGroupArticleId: 0,
        contingentGroupArticleCode: '',
        contGrArtDescription: {},
        articleDimensionValueConfigId: 0,
        articleDimensionValueConfigDescription: {},
        customerId: 0,
        customerCode: '',
        customerName: '',
        totalQuantity: 0,
        availableQuantity: 0,
        orderedQuantity: 0,
        deliveredQuantity: 0,
        allocatedToCustomer: 0,
        notAllocatedToCustomer: 0,
        filter: '',
      };
      value.forEach((c) => {
        sum.contingentGroupArticleId = c.contingentGroupArticleId;
        sum.articleDimensionValueConfigId = c.articleDimensionValueConfigId;
        sum.totalQuantity += c.totalQuantity;
        sum.deliveredQuantity += c.deliveredQuantity;
        sum.orderedQuantity += c.orderedQuantity;
        sum.availableQuantity += c.availableQuantity;
        sum.allocatedToCustomer = sum.allocatedToCustomer || 0; // Initialize allocatedToCustomer to 0
        sum.notAllocatedToCustomer = sum.notAllocatedToCustomer || 0; // Initialize notAllocatedToCustomer to 0

        if (c.customerId !== null && c.customerId !== undefined) {
          sum.allocatedToCustomer += c.availableQuantity;
        } else {
          sum.notAllocatedToCustomer += c.availableQuantity;
        }
      });
      const sumContingentGroupArticle = contingentGroupArticles.value.find(
        (cga) => cga.id === sum.contingentGroupArticleId
      );
      if (sumContingentGroupArticle) {
        sum.contingentGroupArticleCode = sumContingentGroupArticle.code;
        sum.contGrArtDescription = sumContingentGroupArticle.title;
      } else {
        sum.contingentGroupArticleCode = '--';
        sum.contGrArtDescription = {};
      }

      const sumArticleDimensionValueConfig = articleDimensionValueConfigs.value.find(
        (adv) => adv.id === sum.articleDimensionValueConfigId
      );
      if (sumArticleDimensionValueConfig) {
        sum.articleDimensionValueConfigDescription = sumArticleDimensionValueConfig.title;
      } else {
        sum.articleDimensionValueConfigDescription = {};
      }
      sumContingentsGroup.value.set(key, sum);
    });
  };

  enum ColumnName {
    CONTINGENT_GROUP_ARTICLE_CODE = 'contingentGroupArticleCode',
    CONTINGENT_GROUP_ARTICLE_DESCRIPTION = 'contingentGroupArticleDescription',
    TOTAL = 'total',
    DELIVERED = 'delivered',
    ORDERED = 'ordered',
    AVAILABLE = 'available',
    ALLOCATED_TO_CUSTOMER = 'allocatedToCustomer',
    NO_ALLOCATION = 'noAllocation',
  }

  const getSorting = (column: ColumnName) => {
    if (column === ColumnName.CONTINGENT_GROUP_ARTICLE_CODE) {
      if (orderContingentGroupArticleCode.value === Order.ASC) {
        orderContingentGroupArticleCode.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const contingentArticleGrCodeA = sumContingentsGroup.value.get(a)?.contingentGroupArticleCode ?? '';
          const contingentArticleGrCodeB = sumContingentsGroup.value.get(b)?.contingentGroupArticleCode ?? '';
          return contingentArticleGrCodeB.localeCompare(contingentArticleGrCodeA);
        });
      } else {
        orderContingentGroupArticleCode.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const contingentArticleGrCodeA = sumContingentsGroup.value.get(a)?.contingentGroupArticleCode ?? '';
          const contingentArticleGrCodeB = sumContingentsGroup.value.get(b)?.contingentGroupArticleCode ?? '';
          return contingentArticleGrCodeA.localeCompare(contingentArticleGrCodeB);
        });
      }
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.CONTINGENT_GROUP_ARTICLE_DESCRIPTION) {
      if (orderContingentGroupArticleDescription.value === Order.ASC) {
        orderContingentGroupArticleDescription.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const contGrArticleDescriptionA = sumContingentsGroup.value.get(a)?.contGrArtDescription[locale.value] ?? '';
          const contGrArticleDescriptionB = sumContingentsGroup.value.get(b)?.contGrArtDescription[locale.value] ?? '';
          return contGrArticleDescriptionB.localeCompare(contGrArticleDescriptionA);
        });
      } else {
        orderContingentGroupArticleDescription.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const contGrArticleDescriptionA = sumContingentsGroup.value.get(a)?.contGrArtDescription[locale.value] ?? '';
          const contGrArticleDescriptionB = sumContingentsGroup.value.get(b)?.contGrArtDescription[locale.value] ?? '';
          return contGrArticleDescriptionA.localeCompare(contGrArticleDescriptionB);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.TOTAL) {
      if (orderTotal.value === Order.ASC) {
        orderTotal.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.totalQuantity ?? 0) - (sumContingentB?.totalQuantity ?? 0);
        });
      } else {
        orderTotal.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.totalQuantity ?? 0) - (sumContingentA?.totalQuantity ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.DELIVERED) {
      if (orderDelivered.value === Order.ASC) {
        orderDelivered.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.deliveredQuantity ?? 0) - (sumContingentB?.deliveredQuantity ?? 0);
        });
      } else {
        orderDelivered.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.deliveredQuantity ?? 0) - (sumContingentA?.deliveredQuantity ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.ORDERED) {
      if (orderOrdered.value === Order.ASC) {
        orderOrdered.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.orderedQuantity ?? 0) - (sumContingentB?.orderedQuantity ?? 0);
        });
      } else {
        orderOrdered.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.orderedQuantity ?? 0) - (sumContingentA?.orderedQuantity ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.AVAILABLE) {
      if (orderAvailable.value === Order.ASC) {
        orderAvailable.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.availableQuantity ?? 0) - (sumContingentB?.availableQuantity ?? 0);
        });
      } else {
        orderAvailable.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.availableQuantity ?? 0) - (sumContingentA?.availableQuantity ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAllocatedToCustomer.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.ALLOCATED_TO_CUSTOMER) {
      if (orderAllocatedToCustomer.value === Order.ASC) {
        orderAllocatedToCustomer.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.allocatedToCustomer ?? 0) - (sumContingentB?.allocatedToCustomer ?? 0);
        });
      } else {
        orderAllocatedToCustomer.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.allocatedToCustomer ?? 0) - (sumContingentA?.allocatedToCustomer ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderNoAllocation.value = null;
    } else if (column === ColumnName.NO_ALLOCATION) {
      if (orderNoAllocation.value === Order.ASC) {
        orderNoAllocation.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.notAllocatedToCustomer ?? 0) - (sumContingentB?.notAllocatedToCustomer ?? 0);
        });
      } else {
        orderNoAllocation.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.notAllocatedToCustomer ?? 0) - (sumContingentA?.notAllocatedToCustomer ?? 0);
        });
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleDescription.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
      orderAllocatedToCustomer.value = null;
    }
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE ARTICLE DESCRIPTION
    orderContingentGroupArticleCode.value = null;
    orderContingentGroupArticleDescription.value = Order.ASC;
    orderTotal.value = null;
    orderDelivered.value = null;
    orderOrdered.value = null;
    orderAvailable.value = null;
    orderAllocatedToCustomer.value = null;
    orderNoAllocation.value = null;
  };

  watch(
    props.contingents,
    async (value) => {
      if (value) {
        customers.value = await tables.customers.toArray();
        contingentGroupArticles.value = await tables.contingentGroupArticles.toArray();
        articleDimensionValueConfigs.value = await tables.articleDimensionValueConfigs.toArray();
        currentPage.value = 1;
        loadSumContingentsByContGrArticleId();
        defaultSorting();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .table {
    @apply flex justify-between items-center min-h-48;
  }

  .headerTitle {
    @apply flex items-center font-light text-neutral-550 hover:cursor-pointer;
  }

  .right {
    @apply flex justify-end;
  }
</style>
