import { OfferStatus } from '@/domain/enumeration/OfferStatus';
import { useOffers } from '@/modules/offers/composables/useOffers';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOffersStore = defineStore('offers-size', () => {
  const sizeCompletedOffers = ref<number>(0);
  const sizeDraftOffers = ref<number>(0);
  const totalOffers = ref<number>(0);

  const { getSizeOffersByStatus } = useOffers();

  async function refreshOffersData() {
    sizeCompletedOffers.value = await getSizeOffersByStatus(OfferStatus.COMPLETED);
    sizeDraftOffers.value = await getSizeOffersByStatus(OfferStatus.DRAFT);

    totalOffers.value = sizeCompletedOffers.value + sizeDraftOffers.value;
  }

  return {
    refreshOffersData,
    sizeCompletedOffers,
    sizeDraftOffers,
    totalOffers,
  };
});
