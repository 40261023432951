import { createI18n } from 'vue-i18n';

import { config } from '@/config/config';

import de from '@/assets/i18n/de.json';
import en from '@/assets/i18n/en.json';
import it from '@/assets/i18n/it.json';

import deArticlesModule from '@/modules/articles/assets/i18n/de.json';
import enArticlesModule from '@/modules/articles/assets/i18n/en.json';
import itArticlesModule from '@/modules/articles/assets/i18n/it.json';

import deCustomersModule from '@/modules/customers/assets/i18n/de.json';
import enCustomersModule from '@/modules/customers/assets/i18n/en.json';
import itCustomersModule from '@/modules/customers/assets/i18n/it.json';

import deDownloadsModule from '@/modules/downloads/assets/i18n/de.json';
import enDownloadsModule from '@/modules/downloads/assets/i18n/en.json';
import itDownloadsModule from '@/modules/downloads/assets/i18n/it.json';

import deMessagesModule from '@/modules/messages/assets/i18n/de.json';
import enMessagesModule from '@/modules/messages/assets/i18n/en.json';
import itMessagesModule from '@/modules/messages/assets/i18n/it.json';

import deOffersModule from '@/modules/offers/assets/i18n/de.json';
import enOffersModule from '@/modules/offers/assets/i18n/en.json';
import itOffersModule from '@/modules/offers/assets/i18n/it.json';

import deOpenItemsModule from '@/modules/open-items/assets/i18n/de.json';
import enOpenItemsModule from '@/modules/open-items/assets/i18n/en.json';
import itOpenItemsModule from '@/modules/open-items/assets/i18n/it.json';

import deOrdersModule from '@/modules/orders/assets/i18n/de.json';
import enOrdersModule from '@/modules/orders/assets/i18n/en.json';
import itOrdersModule from '@/modules/orders/assets/i18n/it.json';

import deProcessedDataModule from '@/modules/processed-data/assets/i18n/de.json';
import enProcessedDataModule from '@/modules/processed-data/assets/i18n/en.json';
import itProcessedDataModule from '@/modules/processed-data/assets/i18n/it.json';

import deStatisticsModule from '@/modules/statistics/assets/i18n/de.json';
import enStatisticsModule from '@/modules/statistics/assets/i18n/en.json';
import itStatisticsModule from '@/modules/statistics/assets/i18n/it.json';

import deContingetModule from '@/modules/contingents/assets/i18n/de.json';
import enContingetModule from '@/modules/contingents/assets/i18n/en.json';
import itContingetModule from '@/modules/contingents/assets/i18n/it.json';

import deChecklistModule from '@/modules/checklists/assets/i18n/de.json';
import enChecklistModule from '@/modules/checklists/assets/i18n/en.json';
import itChecklistModule from '@/modules/checklists/assets/i18n/it.json';

const dateTimeFormats = {
  en: {
    shortWeekday: {
      weekday: 'short',
    },
    shortDay: {
      day: 'numeric',
    },
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    shortTime: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    long: {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
    },
  },
  it: {
    shortWeekday: {
      weekday: 'short',
    },
    shortDay: {
      day: 'numeric',
    },
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    shortTime: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    long: {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
    },
  },
  de: {
    shortWeekday: {
      weekday: 'short',
    },
    shortDay: {
      day: 'numeric',
    },
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    shortTime: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    long: {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
};

const currentLocale = Object.values(config.company.availableLanguages).includes(navigator.language.substring(0, 2))
  ? navigator.language.substring(0, 2)
  : config.company.translatedDataLocalesOrder[0];

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: currentLocale,
  fallbackLocale: config.company.translatedDataLocalesOrder[0],
  availableLocales: Object.values(config.company.availableLanguages),
  defaultLocale: config.company.translatedDataLocalesOrder[0],
  messages: {
    de: {
      ...de,
      articles: deArticlesModule,
      customers: deCustomersModule,
      downloads: deDownloadsModule,
      messages: deMessagesModule,
      offers: deOffersModule,
      openItems: deOpenItemsModule,
      orders: deOrdersModule,
      processedData: deProcessedDataModule,
      statistics: deStatisticsModule,
      contingents: deContingetModule,
      checklists: deChecklistModule,
    },
    it: {
      ...it,
      articles: itArticlesModule,
      customers: itCustomersModule,
      downloads: itDownloadsModule,
      messages: itMessagesModule,
      offers: itOffersModule,
      openItems: itOpenItemsModule,
      orders: itOrdersModule,
      processedData: itProcessedDataModule,
      statistics: itStatisticsModule,
      contingents: itContingetModule,
      checklists: itChecklistModule,
    },
    en: {
      ...en,
      articles: enArticlesModule,
      customers: enCustomersModule,
      downloads: enDownloadsModule,
      messages: enMessagesModule,
      offers: enOffersModule,
      openItems: enOpenItemsModule,
      orders: enOrdersModule,
      processedData: enProcessedDataModule,
      statistics: enStatisticsModule,
      contingents: enContingetModule,
      checklists: enChecklistModule,
    },
  },
  datetimeFormats: dateTimeFormats as never,
});
