export class StatisticFilter {
  search: string;
  agentId?: number;
  fromDate?: number;
  toDate?: number;
  startMonth?: number;
  endMonth?: number;
  yearOne?: number;
  yearTwo?: number;
  articleName?: string;
  customerCode?: string;
  today?: boolean = false;

  constructor(
    fromDate?: number,
    toDate?: number,
    startMonth?: number,
    endMonth?: number,
    yearOne?: number,
    yearTwo?: number,
    articleName = '',
    search = '',
    agentId?: number,
    customerCode = '',
    today = false
  ) {
    this.search = search;

    this.customerCode = customerCode;
    this.articleName = articleName;
    this.today = today;

    if (fromDate) {
      this.fromDate = fromDate / 1000;
    }
    if (toDate) {
      this.toDate = toDate / 1000;
    }
    if (undefined !== startMonth) {
      this.startMonth = startMonth;
    }
    if (undefined !== endMonth) {
      this.endMonth = endMonth;
    }
    if (undefined !== yearOne) {
      this.yearOne = yearOne;
    }
    if (undefined !== yearTwo) {
      this.yearTwo = yearTwo;
    }
    if (undefined !== agentId) {
      this.agentId = agentId;
    }
  }
}
