import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import CustomerOverviewIndexPage from '@/modules/customers/pages/CustomerOverviewIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: CustomerOverviewIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
