<template>
  <div class="bg-neutral-200 px-32 py-12 text-s font-light">
    <div>
      <span class="pr-4 text-neutral-500">{{ t('statusSidebar') }}</span>
      <UseNetwork v-slot="{ isOnline }">
        <span class="text-neutral-900" v-if="isOnline">Online</span>
        <span v-else>Offline</span>
      </UseNetwork>
    </div>
    <div class="truncate">
      <span class="pr-4 text-neutral-500">{{ t('loadedDataSidebar') }}</span>
      <span v-if="props.lastDataSync" class="text-neutral-900">
        <span v-if="props.lastDataSync.interval > 0">{{ props.lastDataSync.interval }}&nbsp;</span>
        <span>{{ t(props.lastDataSync.key) }}</span>
      </span>
      <span v-else class="text-neutral-900">{{ t('loadedNeverSidebar') }}</span>
    </div>
    <div class="truncate" v-if="config.company.modules.ORDER">
      <span class="pr-4 text-neutral-500">{{ t('loadedOrdersSidebar') }}</span>
      <span v-if="props.lastOrderSync" class="text-neutral-900">
        <span v-if="props.lastOrderSync.interval > 0">{{ props.lastOrderSync.interval }}&nbsp;</span>
        <span>{{ t(props.lastOrderSync.key) }}</span>
      </span>
      <span v-else class="text-neutral-900">{{ t('loadedNeverSidebar') }}</span>
    </div>
    <div class="truncate" v-if="config.company.modules.OFFER">
      <span class="pr-4 text-neutral-500">{{ t('loadedOffersSidebar') }}</span>
      <span v-if="props.lastOfferSync" class="text-neutral-900">
        <span v-if="props.lastOfferSync.interval > 0">{{ props.lastOfferSync.interval }}&nbsp;</span>
        <span>{{ t(props.lastOfferSync.key) }}</span>
      </span>
      <span v-else class="text-neutral-900">{{ t('loadedNeverSidebar') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IntervalKey } from '@/domain/internal/IntervalKey';

  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { UseNetwork } from '@vueuse/components';

  const { t } = i18n.global;

  interface Props {
    online: boolean;
    lastDataSync: IntervalKey | null;
    lastOrderSync: IntervalKey | null;
    lastOfferSync: IntervalKey | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    online: false,
    lastDataSync: null,
    lastOrderSync: null,
    lastOfferSync: null,
  });
</script>
