import type { CommunicationDto } from '@/domain/CommunicationDto';
import type { OutstandingDebtInfoDto } from '@/domain/OutstandingDebtInfoDto';
import type { StatisticsInfoDto } from '@/domain/StatisticsInfoDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';

import { useApi } from '@/composables/api/useApi';
import type { CheckListsInfoDto } from '@/domain/CheckListsInfoDto';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDashboardStore = defineStore('dashboard-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const messages = ref<Array<CommunicationDto>>([]);
  const outstandingDebtInfo = ref<OutstandingDebtInfoDto>({
    openAmountSum: 0,
    customerCount: 0,
    invoiceCount: 0,
  });
  const statisticsInfo = ref<StatisticsInfoDto>({
    totalAmountPeriodOne: 0,
    totalAmountPeriodTwo: 0,
  });
  const checklistsInfo = ref<CheckListsInfoDto>({
    numberOfOpen: 0,
    numberOfCompleted: 0,
    numberOfExpired: 0,
  });

  async function loadData(language: string) {
    try {
      const container = await api.fetch<ContainerDto>(`/dashboard?app_version=2023.1.4512813617&lang=${language}`);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.communications) {
        messages.value = container.data.communications;
      }
      if (container.data.outstandingDebts) {
        outstandingDebtInfo.value = container.data.outstandingDebts as OutstandingDebtInfoDto;
      }
      if (container.data.statisticsInfo) {
        statisticsInfo.value = container.data.statisticsInfo;
      }
      if (container.data.checklistsInfo) {
        checklistsInfo.value = container.data.checklistsInfo;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    messages,
    outstandingDebtInfo,
    statisticsInfo,
    checklistsInfo,
    loadData,
  };
});
