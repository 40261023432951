<template>
  <router-link
    :to="props.to"
    class="sidebar-item block py-12 text-l font-light text-default"
    :class="{ 'router-link-active': isActive }"
    :title="props.label"
    exact
  >
    <div class="flex place-items-center gap-12">
      <div v-if="hasIcon">
        <slot name="icon"></slot>
      </div>
      <div>{{ props.label }}</div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
  import { router } from '@/plugins/router';
  import { computed, ref, useSlots } from 'vue';

  interface Props {
    label: string;
    to: string;
  }
  const props = defineProps<Props>();

  const hasIcon = computed<boolean>(() => {
    return !!useSlots().icon;
  });

  const isActive = ref<boolean>(false);

  router.beforeEach((to) => {
    if (to.fullPath && to.fullPath.startsWith(props.to)) {
      isActive.value = true;
    } else {
      isActive.value = false;
    }
  });
</script>

<style scoped lang="scss">
  .sidebar-item.router-link-active {
    @apply font-bold text-primary;
  }
</style>
