<template>
  <div>
    <TheSubheader>
      <StatisticsByMonthFilter
        v-model:agentId="agentId"
        v-model:startMonth="startMonth"
        v-model:endMonth="endMonth"
        v-model:yearOne="yearOne"
        v-model:yearTwo="yearTwo"
        v-model:inputSearch="inputSearch"
        @newSearch="newSearch"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 max-md:mx-16 md:px-[80px]">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else-if="statistics.length > 0">
        <!-- TABLE STATISTICS BY MONTH -->
        <StatisticByMonthTable
          :statistics="statistics"
          :yearOne="yearOne"
          :yearTwo="yearTwo"
          :startMonth="startMonth"
          :endMonth="endMonth"
          :inputSearch="inputSearch"
          :agentId="agentId"
        />
      </div>
      <div v-else>
        <AgAlert variant="info">{{ t('statistics.noData') }}</AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import StatisticByMonthTable from '@/modules/statistics/components/by-month/StatisticByMonthTable.vue';
  import StatisticsByMonthFilter from '@/modules/statistics/components/filters/StatisticsByMonthFilter.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getAllHierarchy } = useHierarchy();

  const store = useStatisticStore();
  const { byMonth, agentsByMonth } = storeToRefs(store);
  const { getStatisticsByMonth, getStatisticsAgentsByMonth } = store;

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('statistics.statistics', 'statistics.byMonth');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const isLoading = ref<boolean>(true);

  const agentId = ref<number>();
  const inputSearch = ref<string>('');

  const date: Date = new Date();
  const startMonth = ref<number>(1);
  const endMonth = ref<number>(13);

  const yearOne = ref<number>(date.getFullYear() - 1);
  const yearTwo = ref<number>(date.getFullYear());

  const statistics = ref<Array<StatisticByMonthDto>>([]);

  const newSearch = function (reloadPage: boolean) {
    if (reloadPage) {
      isLoading.value = true;
    }
    const today = endMonth.value == 13 ? true : false;
    const eMonth = today ? new Date().getMonth() + 1 : endMonth.value;

    const filter = new StatisticFilter(
      undefined,
      undefined,
      startMonth.value,
      eMonth,
      yearOne.value,
      yearTwo.value,
      undefined,
      inputSearch.value,
      agentId.value,
      undefined,
      today
    );
    if (agentId.value) {
      getStatisticsAgentsByMonth(filter)
        .then(() => {
          isLoading.value = false;
          statistics.value = agentsByMonth.value;
        })
        .catch((error) => console.log(error));
    } else {
      getStatisticsByMonth(filter)
        .then(() => {
          isLoading.value = false;
          statistics.value = byMonth.value;
        })
        .catch((error) => console.log(error));
    }
  };

  watch([inputSearch, agentId], () => newSearch(false), { immediate: false });

  onMounted(async () => {
    const hierarchyList = await getAllHierarchy();
    if (hierarchyList.length > 0 && hierarchyList[0].children.length > 0) {
      agentId.value = hierarchyList[0].authUser.id;
    } else {
      newSearch(true);
    }
  });
</script>
