<template>
  <div class="table" :class="tableClasses()" @click.stop="updateSelection()">
    <div v-if="props.isSelectionActive" class="tableColumn cursor-pointer" @click.stop="updateSelection()">
      <IcCheck v-if="isInvoiceSelected" class="w-24 h-24" />
      <IcAdd v-else class="w-24 h-24" />
    </div>
    <div class="tableColumn">
      <span class="signal" :class="getClasses(props.openItem.dueIndicator)"> &nbsp; </span>
      {{ props.openItem.year }}
    </div>
    <div class="tableColumn">
      {{ props.openItem.invoiceCode }}
    </div>
    <div class="tableColumn">
      {{ formatDate(props.openItem.invoiceDate) }}
    </div>
    <div class="tableColumn right pr-12">
      {{ formatNumberToEuro(props.openItem.invoiceAmount) }}
    </div>
    <div class="tableColumn right pr-12" :class="{ 'text-[#dc3545]': props.openItem.openAmount < 0 }">
      {{ formatNumberToEuro(props.openItem.openAmount) }}
    </div>
    <div class="tableColumn right">
      {{ formatDate(props.openItem.dueDate) }}
    </div>
    <div class="tableColumn right">
      {{ props.openItem.note }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';
  import type { OutstandingDebtToPayDto } from '@/domain/OutstandingDebtToPayDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcCheck from '@/components/icons/IcCheck.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();

  const store = useByCustomerStore();
  const { selectedInvoices } = storeToRefs(store);

  interface Props {
    isSelectionActive: boolean;
    openItem: OutstandingDebtDto;
    customerCode: string;
    customerName: string;
  }
  const props = defineProps<Props>();

  const isInvoiceSelected = computed(() =>
    selectedInvoices.value.some((item: OutstandingDebtToPayDto) => item.invoiceCode === props.openItem.invoiceCode)
  );

  const updateSelection = () => {
    if (!props.isSelectionActive) return;
    if (isInvoiceSelected.value) {
      const index = selectedInvoices.value.findIndex((item) => item.invoiceCode === props.openItem.invoiceCode);
      selectedInvoices.value.splice(index, 1);
    } else {
      const toPayOutstanding: OutstandingDebtToPayDto = {
        id: props.openItem.id,
        invoiceCode: props.openItem.invoiceCode,
        invoiceDate: props.openItem.invoiceDate,
        dueDate: props.openItem.dueDate,
        openAmount: props.openItem.openAmount,
        paidAmount: props.openItem.openAmount,
        year: props.openItem.year,
        invoiceAmount: props.openItem.invoiceAmount,
        customerCode: props.customerCode,
        customerName: props.customerName,
      };

      selectedInvoices.value.push(toPayOutstanding);
    }
  };

  const getClasses = (dueIndicator?: number) => {
    if (dueIndicator) {
      if (dueIndicator === 1) {
        return 'signal--yellow'; // due
      } else if (dueIndicator === 2) {
        return 'signal--orange'; // >1 mounth
      } else if (dueIndicator === 3) {
        return 'signal--red'; // >6 mounth
      }
    }
    return ''; // not due
  };

  const tableClasses = () => {
    if (props.isSelectionActive) {
      return 'grid grid-cols-[30px_65px_133px_163px_160px_160px_150px_minmax(163px,1fr)] gap-4 py-4';
    }
    return 'grid grid-cols-[65px_133px_163px_160px_160px_150px_minmax(163px,1fr)] gap-4 py-4';
  };
</script>

<style scoped lang="scss">
  .table {
    @apply border-t border-neutral-500;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .signal {
    padding: 0 0 0 6px;
    margin: 0 0.7em 0 0;

    &--yellow {
      background-color: #f8bb00;
    }
    &--red {
      background-color: #e74668;
    }
    &--orange {
      background-color: #ed5818;
    }
  }
</style>
