<template>
  <div class="relative">
    <input
      aria-label="AgSearchInput"
      type="text"
      class="h-[40px] w-full rounded border p-8 text-s-20 focus:border-primary focus:outline"
      v-model="internalValue"
      :id="id"
      :placeholder="props.placeholder"
      :class="searchInputClasses"
      :disabled="props.disabled"
    />
    <div class="absolute inset-y-1/4 right-8 text-neutral-500">
      <IcSearch />
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcSearch from '@/components/icons/IcSearch.vue';

  import { computed, getCurrentInstance } from 'vue';

  const id = `search-input-${getCurrentInstance()?.uid}`;

  interface Props {
    placeholder?: string;
    modelValue?: string;
    validationState?: undefined | null | boolean;
    disabled?: undefined | boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    placeholder: '',
    validationState: null,
    disabled: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => {
      return props.modelValue;
    },
    set: (newValue) => {
      emit('update:modelValue', newValue);
    },
  });

  const searchInputClasses = computed(() => ({
    'border-danger': props.validationState === false,
    'border-neutral-550': props.validationState !== false,
  }));
</script>
