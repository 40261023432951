<template>
  <div class="py-8" :class="{ 'bg-white': isDashboardOrLogin === false }">
    <footer
      v-show="footerEnabled"
      id="the-footer"
      :class="{
        'text-white': isDashboardOrLogin,
        invisible: footerVisible === false,
      }"
    >
      <KonvertoImprintLine />
    </footer>
  </div>
</template>

<script setup lang="ts">
  import KonvertoImprintLine from '@/components/framework/the-footer/KonvertoImprintLine.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const { footerEnabled, footerVisible } = useTheFooter();

  const isDashboardOrLogin = computed(() => {
    return '/dashboard' === route.path || '/login' === route.path;
  });
</script>
