<template>
  <div class="mx-auto px-64">
    <div class="tableHeader">
      <!-- COLUMN CUSTOMER NAME -->
      <div class="headerColumn" @click.stop.prevent="sortCustomerName">
        <span class="px-4">{{ t('contingents.customerName') }}</span>
        <span v-if="orderCustomerName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderCustomerName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <div class="headerColumn justify-between">
        <!-- COLUMN TOTAL QUANTITY -->
        <div class="flex items-center gap-4" @click.stop.prevent="sortTotal">
          <span class="px-4">{{ t('contingents.total') }}</span>
          <span v-if="orderTotal === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderTotal === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- COLUMN DELIVERED QUANTITY -->
        <div class="flex items-center gap-4" @click.stop.prevent="sortDelivered">
          <span class="px-4">{{ t('contingents.delivered') }}</span>
          <span v-if="orderDelivered === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderDelivered === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- COLUMN ORDERED QUANTITY -->
        <div class="flex items-center gap-4" @click.stop.prevent="sortOrdered">
          <span class="px-4">{{ t('contingents.ordered') }}</span>
          <span v-if="orderOrdered === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderOrdered === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
        <!-- COLUMN AVAILABLE QUANTITY -->
        <div class="flex items-center gap-4" @click.stop.prevent="sortAvailable">
          <span class="px-4">{{ t('contingents.available') }}</span>
          <span v-if="orderAvailable === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderAvailable === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
      </div>
    </div>

    <div v-for="row in rowContingents" :key="row.id" class="table">
      <div>
        <span v-if="row.name !== t('contingents.customerNotAssigned')">
          {{ row.name }} <span v-if="row.code">({{ row.code }})</span>
        </span>
        <span v-else class="text-infoRed">{{ t('contingents.customerNotAssigned') }}</span>
      </div>
      <div class="flex justify-between gap-4">
        <div class="flex justify-end min-w-48">{{ row.totalQuantity }}</div>
        <div class="flex justify-end min-w-48">{{ row.deliveredQuantity }}</div>
        <div class="flex justify-end min-w-48">{{ row.orderedQuantity }}</div>
        <div class="flex justify-end min-w-48">{{ row.availableQuantity }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  interface RowContingent {
    id: number;
    name: string;
    code: string;
    totalQuantity: number;
    deliveredQuantity: number;
    orderedQuantity: number;
    availableQuantity: number;
  }

  interface Props {
    contingents: Array<ContingentDto>;
    customers: Array<CustomerDto>;
  }
  const props = defineProps<Props>();

  const rowContingents = ref<Array<RowContingent>>([]);

  const getCustomer = function (id: number): CustomerDto | undefined {
    return props.customers.find((c) => c.id === id);
  };

  const orderCustomerName = ref<Order | null>(null);
  const sortCustomerName = () => getSorting(ColumnName.CUSTOMER_NAME);

  const orderTotal = ref<Order | null>(null);
  const sortTotal = () => getSorting(ColumnName.TOTAL);

  const orderDelivered = ref<Order | null>(null);
  const sortDelivered = () => getSorting(ColumnName.DELIVERED);

  const orderOrdered = ref<Order | null>(null);
  const sortOrdered = () => getSorting(ColumnName.ORDERED);

  const orderAvailable = ref<Order | null>(null);
  const sortAvailable = () => getSorting(ColumnName.AVAILABLE);

  enum ColumnName {
    CUSTOMER_NAME = 'customerName',
    TOTAL = 'total',
    DELIVERED = 'delivered',
    ORDERED = 'ordered',
    AVAILABLE = 'available',
  }

  const getSorting = (column: ColumnName) => {
    if (column === ColumnName.CUSTOMER_NAME) {
      if (orderCustomerName.value === Order.ASC) {
        orderCustomerName.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        orderCustomerName.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.name.localeCompare(a.name));
      }
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.TOTAL) {
      if (orderTotal.value === Order.ASC) {
        orderTotal.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.totalQuantity - b.totalQuantity);
      } else {
        orderTotal.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.totalQuantity - a.totalQuantity);
      }
      orderCustomerName.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.DELIVERED) {
      if (orderDelivered.value === Order.ASC) {
        orderDelivered.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.deliveredQuantity - b.deliveredQuantity);
      } else {
        orderDelivered.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.deliveredQuantity - a.deliveredQuantity);
      }
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.ORDERED) {
      if (orderOrdered.value === Order.ASC) {
        orderOrdered.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.orderedQuantity - b.orderedQuantity);
      } else {
        orderOrdered.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
      }
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.AVAILABLE) {
      if (orderAvailable.value === Order.ASC) {
        orderAvailable.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.availableQuantity - b.availableQuantity);
      } else {
        orderAvailable.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.availableQuantity - a.availableQuantity);
      }
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
    }
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE CUSTOMER NAME
    orderCustomerName.value = Order.ASC;
    orderTotal.value = null;
    orderDelivered.value = null;
    orderOrdered.value = null;
    orderAvailable.value = null;
  };

  watch(
    () => props.contingents,
    (value) => {
      if (value && value.length > 0) {
        props.contingents.forEach((c) => {
          let code = '';
          let name = '';
          if (c.customerId) {
            const customer = getCustomer(c.customerId);
            name = customer ? customer.name : t('contingents.customerNotAssigned');
            code = customer ? customer.code : '';
          }
          const total = c.totalQuantity;
          const delivered = c.deliveredQuantity;
          const ordered = c.orderedQuantity;
          const available = c.availableQuantity;

          rowContingents.value.push({
            id: c.id,
            name: name,
            code: code,
            deliveredQuantity: delivered,
            availableQuantity: available,
            orderedQuantity: ordered,
            totalQuantity: total,
          });
        });
        getSorting(ColumnName.CUSTOMER_NAME);
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-2 auto-cols-fr font-light text-neutral-550 min-h-32;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid grid-cols-2 auto-cols-fr min-h-32 px-16 border-t border-neutral-500;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }
</style>
