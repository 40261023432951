<template>
  <div class="mx-auto px-32">
    <!-- TABLE CONTENT -->
    <div class="tableHeader">
      <!-- COLUMN CONTINGENT GROUP ARTICLE CODE -->
      <div class="headerColumn" @click.stop.prevent="sortContingentGroupArticleCode">
        <span> {{ t('contingents.contingentGroupArticleCode') }}</span>
        <span v-if="orderContingentGroupArticleCode === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderContingentGroupArticleCode === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN CONTINGENT GROUP ARTICLE NAME -->
      <div class="headerColumn" @click.stop.prevent="sortContingentGroupArticleName">
        <span> {{ t('contingents.contingentGroupArticleDescription') }}</span>
        <span v-if="orderContingentGroupArticleName === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderContingentGroupArticleName === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN TOTAL QUANTITY -->
      <div class="headerColumn right" @click.stop.prevent="sortTotal">
        <span> {{ t('contingents.total') }}</span>
        <span v-if="orderTotal === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderTotal === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN DELIVERED QUANTITY -->
      <div class="headerColumn right" @click.stop.prevent="sortDelivered">
        <span> {{ t('contingents.delivered') }}</span>
        <span v-if="orderDelivered === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderDelivered === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN ORDERED QUANTITY -->
      <div class="headerColumn right" @click.stop.prevent="sortOrdered">
        <span> {{ t('contingents.ordered') }}</span>
        <span v-if="orderOrdered === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderOrdered === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
      <!-- COLUMN AVAILABLE QUANTITY -->
      <div class="headerColumn right" @click.stop.prevent="sortAvailable">
        <span> {{ t('contingents.available') }}</span>
        <span v-if="orderAvailable === Order.ASC">
          <IcChevronDown class="h-12 w-12" />
        </span>
        <span v-else-if="orderAvailable === Order.DESC">
          <IcChevronUp class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
    </div>

    <div v-for="row in rowContingents" :key="row.id">
      <div class="table">
        <div class="tableColumn">
          <span>{{ row.contGrArtCode }} {{ row.artDimValueConfig }}</span>
        </div>
        <div class="tableColumn">
          <span v-if="row.contGrArtName !== t('contingents.contGrArticleNotAssigned')">
            {{ row.contGrArtName }}
            {{ row.artDimValueConfig }}
          </span>
        </div>
        <div class="tableColumn right">
          {{ row.totalQuantity }}
        </div>
        <div class="tableColumn right">
          {{ row.deliveredQuantity }}
        </div>
        <div class="tableColumn right">
          {{ row.orderedQuantity }}
        </div>
        <div class="tableColumn right">
          {{ row.availableQuantity }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  interface RowContingent {
    id: number;
    contGrArtCode: string;
    contGrArtName: string;
    artDimValueConfig: string;
    totalQuantity: number;
    deliveredQuantity: number;
    orderedQuantity: number;
    availableQuantity: number;
  }

  interface Props {
    contingents: Array<ContingentDto>;
    contingentGroupArticles: Array<ContingentGroupArticleDto>;
    articleDimensionValueConfigs: Array<ArticleDimensionValueConfigsDto>;
  }
  const props = defineProps<Props>();

  const rowContingents = ref<Array<RowContingent>>([]);

  const getContingentGroupArticle = (id: number): ContingentGroupArticleDto | undefined => {
    return props.contingentGroupArticles.find((cga) => cga.id === id);
  };

  const getArticleDimensionValueConfig = (id: number): ArticleDimensionValueConfigsDto | undefined => {
    return props.articleDimensionValueConfigs.find((d) => d.id === id);
  };

  const orderContingentGroupArticleCode = ref<Order | null>(null);
  const sortContingentGroupArticleCode = () => getSorting(ColumnName.CONTINGENT_GROUP_ARTICLE_CODE);

  const orderContingentGroupArticleName = ref<Order | null>(null);
  const sortContingentGroupArticleName = () => getSorting(ColumnName.CONTINGENT_GROUP_ARTICLE_NAME);

  const orderTotal = ref<Order | null>(null);
  const sortTotal = () => getSorting(ColumnName.TOTAL);

  const orderDelivered = ref<Order | null>(null);
  const sortDelivered = () => getSorting(ColumnName.DELIVERED);

  const orderOrdered = ref<Order | null>(null);
  const sortOrdered = () => getSorting(ColumnName.ORDERED);

  const orderAvailable = ref<Order | null>(null);
  const sortAvailable = () => getSorting(ColumnName.AVAILABLE);

  enum ColumnName {
    CONTINGENT_GROUP_ARTICLE_CODE = 'contingentGroupArticleCode',
    CONTINGENT_GROUP_ARTICLE_NAME = 'contingentGroupArticleName',

    TOTAL = 'total',
    DELIVERED = 'delivered',
    ORDERED = 'ordered',
    AVAILABLE = 'available',
  }

  const getSorting = (column: ColumnName) => {
    if (column === ColumnName.CONTINGENT_GROUP_ARTICLE_CODE) {
      if (orderContingentGroupArticleCode.value === Order.ASC) {
        orderContingentGroupArticleCode.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.contGrArtCode.localeCompare(b.contGrArtCode));
      } else {
        orderContingentGroupArticleCode.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.contGrArtCode.localeCompare(a.contGrArtCode));
      }
      orderContingentGroupArticleName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.CONTINGENT_GROUP_ARTICLE_NAME) {
      if (orderContingentGroupArticleName.value === Order.ASC) {
        orderContingentGroupArticleName.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.contGrArtName.localeCompare(b.contGrArtName));
      } else {
        orderContingentGroupArticleName.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.contGrArtName.localeCompare(a.contGrArtName));
      }
      orderContingentGroupArticleCode.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.TOTAL) {
      if (orderTotal.value === Order.ASC) {
        orderTotal.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.totalQuantity - b.totalQuantity);
      } else {
        orderTotal.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.totalQuantity - a.totalQuantity);
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleName.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.DELIVERED) {
      if (orderDelivered.value === Order.ASC) {
        orderDelivered.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.deliveredQuantity - b.deliveredQuantity);
      } else {
        orderDelivered.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.deliveredQuantity - a.deliveredQuantity);
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleName.value = null;
      orderTotal.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.ORDERED) {
      if (orderOrdered.value === Order.ASC) {
        orderOrdered.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.orderedQuantity - b.orderedQuantity);
      } else {
        orderOrdered.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.AVAILABLE) {
      if (orderAvailable.value === Order.ASC) {
        orderAvailable.value = Order.DESC;
        rowContingents.value.sort((a, b) => a.availableQuantity - b.availableQuantity);
      } else {
        orderAvailable.value = Order.ASC;
        rowContingents.value.sort((a, b) => b.availableQuantity - a.availableQuantity);
      }
      orderContingentGroupArticleCode.value = null;
      orderContingentGroupArticleName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
    }
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE ARTICLE NAME
    orderContingentGroupArticleCode.value = null;
    orderContingentGroupArticleName.value = Order.ASC;
    orderTotal.value = null;
    orderDelivered.value = null;
    orderOrdered.value = null;
    orderAvailable.value = null;
  };

  watch(
    () => props.contingents,
    (value) => {
      if (value && value.length > 0) {
        props.contingents.forEach((c) => {
          let contGrArtCode = '';
          let contGrArtName = '';
          if (c.contingentGroupArticleId) {
            const contingentGroupArticle = getContingentGroupArticle(c.contingentGroupArticleId);
            contGrArtCode = contingentGroupArticle ? contingentGroupArticle.code : '';
            contGrArtName = contingentGroupArticle
              ? contingentGroupArticle.title[locale.value]
              : t('contingents.contGrArticleNotAssigned');
          }

          let artDimValueConfig = '';
          if (c.articleDimensionValueConfigId) {
            const articleDimensionValueConfig = getArticleDimensionValueConfig(c.articleDimensionValueConfigId);
            artDimValueConfig = articleDimensionValueConfig ? articleDimensionValueConfig.title[locale.value] : '';
          }
          const total = c.totalQuantity;
          const delivered = c.deliveredQuantity;
          const ordered = c.orderedQuantity;
          const available = c.availableQuantity;

          rowContingents.value.push({
            id: c.id,
            contGrArtCode: contGrArtCode,
            contGrArtName: contGrArtName,
            artDimValueConfig: artDimValueConfig,
            deliveredQuantity: delivered,
            availableQuantity: available,
            orderedQuantity: ordered,
            totalQuantity: total,
          });
        });
        defaultSorting();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[193px_minmax(233px,373px)_133px_133px_133px_133px] font-light text-neutral-550 min-h-32;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid grid-cols-[193px_minmax(233px,373px)_133px_133px_133px_133px] border-t border-neutral-500 min-h-32;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply flex justify-end;
  }
</style>
