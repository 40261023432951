<template>
  <div class="my-12 space-y-12">
    <AgBox>
      <SentOrdersTable :is-customer-blocked="isCustomerBlocked" :sent-orders="sentOrders" />
    </AgBox>
    <AgBox>
      <DraftOrdersTable :is-customer-blocked="isCustomerBlocked" :draft-orders="draftOrders" />
    </AgBox>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import DraftOrdersTable from '@/modules/customers/components/orders/DraftOrdersTable.vue';
  import SentOrdersTable from '@/modules/customers/components/orders/SentOrdersTable.vue';

  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import { useOrders } from '@/modules/orders/composables/useOrders';
  import { ref, watch } from 'vue';

  const { listOrdersByCustomerVatCode } = useOrders();

  interface Props {
    customer: CustomerDto | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    customer: null,
  });

  const isCustomerBlocked = ref<boolean>(false);
  const sentOrders = ref<Array<OrderDto>>([]);
  const draftOrders = ref<Array<OrderDto>>([]);

  const loadOrders = async function () {
    if (props.customer && props.customer.vatCode) {
      isCustomerBlocked.value = props.customer.blocked;

      const orders: OrderDto[] = await listOrdersByCustomerVatCode(props.customer.vatCode);

      sentOrders.value = orders.filter((o) => o.status === OrderStatus.COMPLETED);
      draftOrders.value = orders.filter((o) => o.status === OrderStatus.DRAFT);
    } else {
      isCustomerBlocked.value = false;
      sentOrders.value = [];
      draftOrders.value = [];
    }
  };

  watch(() => props.customer, loadOrders, { immediate: true });
</script>
