<template>
  <svg id="ic_delete" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      id="Pfad_2209"
      data-name="Pfad 2209"
      d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM8,9h8V19H8Zm7.5-5-1-1h-5l-1,1H5V6H19V4Z"
      fill="currentColor"
    />
    <path id="Pfad_2210" data-name="Pfad 2210" d="M0,0H24V24H0Z" fill="none" />
  </svg>
</template>
