<template>
  <div>
    <TheSubheader>
      <CheckListFilter v-model:agentId="agentId" v-model:category="category" />
    </TheSubheader>
    <div class="mb-32 mt-12 max-md:mx-16 md:px-[80px] overflow-x-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else-if="actvities.content.length > 0">
        <!-- PAGINATOR -->
        <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="actvities.totalElements" />
        <AgPaginator v-model="currentPage" :total-pages="actvities.totalPages" class="my-24" />

        <!-- SORT AREA -->
        <div class="flex flex-wrap gap-4 justify-between">
          <!-- FIRST -->
          <div class="flex flex-nowrap gap-4">
            <div>
              <AgButton
                variant="ghost"
                @click="updateSorting(ColumnName.DUE_DATE)"
                class="bg-neutral-300 rounded-full pl-24 px-8"
              >
                <span :class="{ 'font-normal': sorting.col !== ColumnName.DUE_DATE }">
                  {{ $t('checklists.dueDate') }}
                </span>
                <template #icon>
                  <span v-if="sorting.order === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                  <span v-else-if="sorting.order === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                  <span v-else>&nbsp;</span>
                </template>
              </AgButton>
            </div>
          </div>
          <div>
            <AgButton @click="saveChanges">
              <span>{{ $t('checklists.save') }}</span>
            </AgButton>
          </div>
        </div>

        <CheckList :activities="actvities.content" />
      </div>
      <div v-else>
        <AgAlert variant="info">{{ $t('checklists.noData') }}</AgAlert>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import type { ParameterChecklistDto } from '@/domain/ParameterChecklistDto';
  import CheckList from '@/modules/checklists/components/CheckList.vue';
  import CheckListFilter from '@/modules/checklists/filter/CheckListFilter.vue';
  import { useCheckListStore } from '@/modules/checklists/store/useCheckListStore';
  import { CheckListsFilter } from '@/util/filters/CheckListsFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('checklists.checklists', 'checklists.expired');

  const store = useCheckListStore();
  const { actvities } = storeToRefs(store);
  const { getActivities, updateChecklist } = store;

  const route = useRoute();

  const { getAllHierarchy } = useHierarchy();

  enum ColumnName {
    DUE_DATE = 'dueDate',
  }

  const currentPage = ref<number>(1);
  const pageSize = ref<number>(1);

  const isLoading = ref<boolean>(true);
  const agentId = ref<number>();
  const category = ref<number>(-1);
  const selectedActivityId = ref<number>();

  const state = 2;

  const sorting = ref<Sorting>(new Sorting(ColumnName.DUE_DATE, Order.ASC));

  const updateSorting = function (columnName: ColumnName) {
    sorting.value.col = columnName;
    if (sorting.value.order === Order.ASC) {
      sorting.value.order = Order.DESC;
    } else {
      sorting.value.order = Order.ASC;
    }
  };

  const saveChanges = async function () {
    const params: Array<ParameterChecklistDto> = actvities.value.content.map((activity) => {
      const isCustomerActivity = activity.customers && activity.customers.length > 0;
      return {
        activityId: activity.activityId,
        agentActivityId: activity.agentActivityId,
        comment: isCustomerActivity ? '' : activity.comment,
        completed: isCustomerActivity ? undefined : activity.completed,
        customers: activity.customers?.map((customer) => {
          return {
            agentCustomerActivityId: customer.agentCustomerActivityId,
            customerId: customer.customerId,
            completed: customer.completed,
            comment: customer.comment,
          };
        }),
      };
    });
    isLoading.value = true;
    for (const param of params) {
      await updateChecklist(param);
    }
    await newSearch(false);
    isLoading.value = false;
  };

  const newSearch = async function (reloadPage: boolean = false) {
    const paginator = new PageRequest(pageSize.value, currentPage.value);
    const filter = new CheckListsFilter(state, category.value, agentId.value, selectedActivityId.value);
    if (reloadPage) {
      isLoading.value = true;
    }
    await getActivities(paginator, sorting.value, filter);
  };

  watch(
    [currentPage, pageSize, agentId, category, sorting, selectedActivityId],
    () => {
      newSearch().then(() => {
        isLoading.value = false;
      });
    },
    { deep: true, immediate: false }
  );

  watch(
    () => route.params.agentActivityId,
    (newValue) => {
      if (newValue) {
        const id = route.params.agentActivityId;
        const numerId = Number(id);
        if (isNaN(numerId)) {
          selectedActivityId.value = undefined;
        } else {
          agentId.value = -1;
          selectedActivityId.value = numerId;
        }
      }
    },
    { immediate: true }
  );

  onMounted(async () => {
    const hierarchyList = await getAllHierarchy();
    if (hierarchyList.length > 0 && !selectedActivityId.value) {
      agentId.value = hierarchyList[0].authUser.id;
    }
  });
</script>
