import { i18n } from '@/plugins/i18n';

export function useDateFormatting() {
  const { d } = i18n.global;

  const formatDate = (timestamp?: number | null) => {
    if (timestamp) {
      return d(timestamp * 1000, 'short');
    }
    return '';
  };

  const formatDateTime = (timestamp?: number | null) => {
    if (timestamp) {
      return d(timestamp * 1000, 'shortTime');
    }
    return '';
  };

  const formatUnixTimestamp = (timestamp?: number | null) => {
    if (timestamp) {
      return d(timestamp, 'short');
    }
    return '';
  };

  return { formatDate, formatDateTime, formatUnixTimestamp };
}
