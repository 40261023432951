<template>
  <svg id="ic_arrow_back" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="arrow_back" data-name="arrow back" transform="translate(12 12)">
      <g id="Gruppe_7487" data-name="Gruppe 7487" transform="translate(-12 -12)">
        <path id="Pfad_12466" data-name="Pfad 12466" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Pfad_12467"
          data-name="Pfad 12467"
          d="M20,11H7.8l5.6-5.6L12,4,4,12l8,8,1.4-1.4L7.8,13H20Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
