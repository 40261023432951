<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16">
    <div class="flex items-center gap-12">
      <AgFormGroup class="w-full" :label="t('statistics.filter')">
        <AgSearchSelect :options="filterOptions" v-model="internalInputSearch" />
      </AgFormGroup>
      <AgFormGroup class="w-full" :label="t('statistics.article')">
        <AgSearchSelect :options="articleOptions" v-model="internalArticle" />
      </AgFormGroup>
      <AgFormGroup v-if="agentOptions.length > 2" class="w-full" :label="t('statistics.agent')">
        <AgFormSelect :options="agentOptions" v-model="internalAgent" />
      </AgFormGroup>
    </div>

    <hr class="mt-24 mb-16 text-neutral-500" />

    <div class="flex items-center gap-12">
      <AgFormGroup class="w-full" :label="t('statistics.from')">
        <AgFormSelect
          :options="startMonthOptions"
          :validation-state="validationStateStartMonth"
          v-model="internalStartMonth"
        />
      </AgFormGroup>
      <AgFormGroup :label="t('statistics.to')" class="w-full">
        <AgFormSelect :options="endMonthOptions" v-model="internalEndMonth" />
      </AgFormGroup>
      <AgFormGroup :label="t('statistics.yearOne')" class="w-full">
        <AgFormSelect :options="yearsOptions" v-model="internalYearOne" />
      </AgFormGroup>
      <AgFormGroup :label="t('statistics.yearTwo')" class="w-full">
        <AgFormSelect :options="yearsOptions" v-model="internalYearTwo" />
      </AgFormGroup>
      <AgButton class="mt-[20px] h-[40px]" @click="newSearch()" :disabled="!validationStateStartMonth">
        {{ t('statistics.search') }}
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { ArticleInfoDto } from '@/domain/ArticleInfoDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useHierarchy } from '@/composables/useHierarchy';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { resolveHierarchyUsers } = useHierarchy();
  const { getArticleList, getFilterList } = useStatisticStore();

  const store = useStatisticStore();
  const { articles, filters } = storeToRefs(store);

  interface Props {
    agentId: number | undefined;
    articleName: string;
    inputSearch: string;
    startMonth: number;
    endMonth: number;
    yearOne: number;
    yearTwo: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits([
    'update:startMonth',
    'update:endMonth',
    'update:yearOne',
    'update:yearTwo',
    'update:articleName',
    'update:agentId',
    'update:inputSearch',
    'newSearch',
  ]);

  const validationStateStartMonth = ref<boolean>(true);

  const internalStartMonth = ref<number>(props.startMonth);
  const internalEndMonth = ref<number>(props.endMonth);
  const internalYearOne = ref<number>(props.yearOne);
  const internalYearTwo = ref<number>(props.yearTwo);

  const agentOptions = ref<Array<AgFormSelectOption>>([]);

  const filterOptions = ref<Array<AgSearchSelectOption>>([]);
  const articleOptions = ref<Array<AgSearchSelectOption>>([]);

  const internalInputSearch = computed({
    get: () => props.inputSearch,
    set: (value) => emit('update:inputSearch', value),
  });

  const internalAgent = computed({
    get: () => props.agentId,
    set: (value) => {
      if (value === -1) {
        emit('update:agentId');
      } else {
        emit('update:agentId', value);
      }
    },
  });

  const internalArticle = computed({
    get: () => props.articleName,
    set: (value) => emit('update:articleName', value),
  });

  const startMonthOptions = computed(() => {
    const months: Array<AgFormSelectOption> = [];
    for (let i = 1; i <= 12; i++) {
      months.push({ label: t(`statistics.month${i}`), key: i });
    }
    return months;
  });

  const endMonthOptions = computed(() => {
    const months: Array<AgFormSelectOption> = [];
    for (let i = 1; i <= 12; i++) {
      months.push({ label: t(`statistics.month${i}`), key: i });
    }
    months.push({ label: t(`statistics.today`), key: 13 });
    return months;
  });

  const yearsOptions = computed(() => {
    const years: Array<AgFormSelectOption> = [];
    for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear(); i++) {
      years.push({ label: i.toString(), key: i });
    }
    return years;
  });

  const newSearch = function () {
    emit('update:startMonth', internalStartMonth.value);
    emit('update:endMonth', internalEndMonth.value);
    emit('update:yearOne', internalYearOne.value);
    emit('update:yearTwo', internalYearTwo.value);
    emit('newSearch');
  };

  const loadFilterOptions = async function () {
    await getFilterList();

    filterOptions.value.push({ label: t('statistics.all'), value: '', searchableString: '' });
    filters.value.forEach((filter: string) => {
      filterOptions.value.push({
        label: filter + ' ',
        value: filter,
        searchableString: filter,
      });
    });
  };

  const loadArticleOptions = async function () {
    await getArticleList();

    articleOptions.value.push({ label: t('statistics.all'), value: '', searchableString: '' });
    articles.value.forEach((article: ArticleInfoDto) => {
      articleOptions.value.push({
        label: `${article.articleName} (${article.articleCode})`,
        value: article.articleName,
        searchableString: `${article.articleName}${article.articleCode}`,
      });
    });
  };

  const loadHierarchyList = async function () {
    const hierarchyUserList = await resolveHierarchyUsers();

    if (hierarchyUserList.length > 1) {
      agentOptions.value.push({ label: t('statistics.all'), key: -1 });

      hierarchyUserList.forEach((currentUser) => {
        agentOptions.value.push({ label: currentUser.name, key: currentUser.id });
      });
    }
  };

  watch(
    () => internalStartMonth.value,
    (newValue) => {
      if (newValue > internalEndMonth.value) {
        validationStateStartMonth.value = false;
      } else {
        validationStateStartMonth.value = true;
      }
    },
    { immediate: false }
  );

  watch(
    () => internalEndMonth.value,
    (newValue) => {
      if (newValue < internalStartMonth.value) {
        validationStateStartMonth.value = false;
      } else {
        validationStateStartMonth.value = true;
      }
    },
    { immediate: false }
  );

  onMounted(async () => {
    await loadHierarchyList();
    await loadFilterOptions();
    await loadArticleOptions();
  });
</script>
