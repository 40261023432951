import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import ProcessedDataIndexPage from '@/modules/processed-data/pages/ProcessedDataIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: ProcessedDataIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
