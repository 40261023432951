<template>
  <div class="flex flex-col gap-8 py-12 md:mx-[80px] max-md:mx-16">
    <AgFormGroup :label="t('downloads.searchFilter')" class="w-full">
      <AgSearchInput v-model="internalValueSearch" />
    </AgFormGroup>
    <div class="flex gap-32">
      <AgFormGroup :label="t('downloads.categoryFilter')" class="w-full">
        <AgFormSelect v-model="categorySelect" :options="optionsCategory" />
      </AgFormGroup>
      <AgFormGroup :label="t('downloads.languageFilter')" class="w-full">
        <AgFormSelect v-model="langSelect" :options="langOptions" />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';

  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { useDownloadStore } from '@/modules/downloads/stores/useDownloadStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  const store = useDownloadStore();
  const { downloadCategories } = storeToRefs(store);

  const { getTranslatedText } = useTranslatedText();

  interface Props {
    selectLang: string;
    selectCat: number;
    inputSearch: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:selectLang', 'update:selectCat', 'update:inputSearch']);

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: `downloads.language.${lang}`,
    }))
  );

  const optionsCategory = computed((): Array<AgFormSelectOption> => {
    const categories = downloadCategories.value.map((c) => {
      return {
        key: c.id,
        label: getTranslatedText(c.title),
      };
    });
    categories.splice(0, 0, { key: 0, label: '' });

    return categories;
  });

  const langSelect = computed({
    get: () => props.selectLang,
    set: (value) => emit('update:selectLang', value),
  });

  const categorySelect = computed({
    get: () => props.selectCat,
    set: (value) => emit('update:selectCat', value),
  });

  const internalValueSearch = computed({
    get: () => props.inputSearch,
    set: (value) => emit('update:inputSearch', value),
  });
</script>
