import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import DownloadsIndexPage from '@/modules/downloads/pages/DownloadsIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: DownloadsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
