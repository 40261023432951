import authRequired from '@/middleware/authRequired';
import type { RouteRecordRaw } from 'vue-router';
import ByArticleIndexPage from './pages/ByContingentGroupArticleIndexPage.vue';
import ByCustomerIndexPage from './pages/ByCustomerIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/by-article-group',
    component: ByArticleIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-customer',
    component: ByCustomerIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
