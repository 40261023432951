import { Order } from '@/util/Order';

export class Sorting {
  col: string;
  order: Order;

  constructor(col = '', order = Order.ASC) {
    this.col = col;
    this.order = order;
  }
}
