<template>
  <div class="m-24 border border-neutral-550">
    <div class="p-12">
      <div class="tableHeader" :class="tableHeaderClasses()">
        <div v-if="props.isSelectionActive" class="headerColumn">
          <span>&nbsp;</span>
        </div>

        <div class="headerColumn" @click.stop.prevent="sortYear">
          <span class="px-4">{{ t('openItems.year') }}</span>
          <span v-if="orderYear === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderYear === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn" @click.stop.prevent="sortInvoiceCode">
          <span class="px-4">{{ t('openItems.invoiceNumber') }}</span>
          <span v-if="orderInvoiceCode === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderInvoiceCode === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn" @click.stop.prevent="sortInvoiceDate">
          <span class="px-4">{{ t('openItems.invoiceDate') }}</span>
          <span v-if="orderInvoiceDate === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderInvoiceDate === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn right" @click.stop.prevent="sortInvoiceAmount">
          <span class="px-4">{{ t('openItems.invoiceAmount') }}</span>
          <span v-if="orderInvoiceAmount === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderInvoiceAmount === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn right" @click.stop.prevent="sortOpenAmount">
          <span class="px-4">{{ t('openItems.openAmount') }}</span>
          <span v-if="orderOpenAmount === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderOpenAmount === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn right" @click.stop.prevent="sortDueDate">
          <span class="px-4">{{ t('openItems.invoiceDueDate') }}</span>
          <span v-if="orderDueDate === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderDueDate === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>

        <div class="headerColumn right" @click.stop.prevent="sortNote">
          <span class="px-4">{{ t('openItems.note') }}</span>
          <span v-if="orderNote === Order.ASC">
            <IcChevronDown class="h-12 w-12" />
          </span>
          <span v-else-if="orderNote === Order.DESC">
            <IcChevronUp class="h-12 w-12" />
          </span>
          <span v-else class="h-12 w-12">&nbsp;</span>
        </div>
      </div>

      <div v-if="shouldRender">
        <template v-for="openItem in openItems" :key="openItem.id">
          <CustomerInvoiceSubTableRow
            :isSelectionActive="props.isSelectionActive"
            :openItem="openItem"
            :customerCode="props.customerCode"
            :customerName="props.customerName"
          />
        </template>
      </div>
      <div v-else>
        <IcLoading />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';

  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';
  import CustomerInvoiceSubTableRow from './CustomerInvoiceSubTableRow.vue';

  const { t } = i18n.global;

  interface Props {
    isSelectionActive: boolean;
    customerId: number;
    customerCode: string;
    customerName: string;
  }
  const props = defineProps<Props>();

  const sorting = ref<Sorting>(new Sorting('invoiceDate', Order.ASC));

  const store = useByCustomerStore();
  const { openItems } = storeToRefs(store);

  const { getCustomerInvoices } = store;

  const shouldRender = ref(false);

  const orderYear = ref<Order | null>(null);
  const sortYear = () => (sorting.value = getSorting(ColumnName.YEAR));

  const orderInvoiceCode = ref<Order | null>(null);
  const sortInvoiceCode = () => (sorting.value = getSorting(ColumnName.INVOICE_CODE));

  const orderInvoiceDate = ref<Order | null>(null);
  const sortInvoiceDate = () => (sorting.value = getSorting(ColumnName.INVOICE_DATE));

  const orderInvoiceAmount = ref<Order | null>(null);
  const sortInvoiceAmount = () => (sorting.value = getSorting(ColumnName.INVOICE_AMOUNT));

  const orderOpenAmount = ref<Order | null>(null);
  const sortOpenAmount = () => (sorting.value = getSorting(ColumnName.OPEN_AMOUNT));

  const orderDueDate = ref<Order | null>(null);
  const sortDueDate = () => (sorting.value = getSorting(ColumnName.DUE_DATE));

  const orderNote = ref<Order | null>(null);
  const sortNote = () => (sorting.value = getSorting(ColumnName.NOTE));

  enum ColumnName {
    YEAR = 'year',
    INVOICE_CODE = 'invoiceCode',
    INVOICE_DATE = 'invoiceDate',
    INVOICE_AMOUNT = 'invoiceAmount',
    OPEN_AMOUNT = 'openAmount',
    DUE_DATE = 'dueDate',
    NOTE = 'note',
  }

  const getSorting = (colum: ColumnName): Sorting => {
    if (colum === ColumnName.YEAR) {
      if (orderYear.value === Order.ASC) {
        orderYear.value = Order.DESC;
      } else {
        orderYear.value = Order.ASC;
      }
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.YEAR, orderYear.value);
    } else if (colum === ColumnName.INVOICE_CODE) {
      if (orderInvoiceCode.value === Order.ASC) {
        orderInvoiceCode.value = Order.DESC;
      } else {
        orderInvoiceCode.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_CODE, orderInvoiceCode.value);
    } else if (colum === ColumnName.INVOICE_DATE) {
      if (orderInvoiceDate.value === Order.ASC) {
        orderInvoiceDate.value = Order.DESC;
      } else {
        orderInvoiceDate.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_DATE, orderInvoiceDate.value);
    } else if (colum === ColumnName.INVOICE_AMOUNT) {
      if (orderInvoiceAmount.value === Order.ASC) {
        orderInvoiceAmount.value = Order.DESC;
      } else {
        orderInvoiceAmount.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.INVOICE_AMOUNT, orderInvoiceAmount.value);
    } else if (colum === ColumnName.OPEN_AMOUNT) {
      if (orderOpenAmount.value === Order.ASC) {
        orderOpenAmount.value = Order.DESC;
      } else {
        orderOpenAmount.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderDueDate.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.OPEN_AMOUNT, orderOpenAmount.value);
    } else if (colum === ColumnName.DUE_DATE) {
      if (orderDueDate.value === Order.ASC) {
        orderDueDate.value = Order.DESC;
      } else {
        orderDueDate.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderNote.value = null;

      return new Sorting(ColumnName.DUE_DATE, orderDueDate.value);
    } else if (colum === ColumnName.NOTE) {
      if (orderNote.value === Order.ASC) {
        orderNote.value = Order.DESC;
      } else {
        orderNote.value = Order.ASC;
      }
      orderYear.value = null;
      orderInvoiceCode.value = null;
      orderInvoiceDate.value = null;
      orderInvoiceAmount.value = null;
      orderOpenAmount.value = null;
      orderDueDate.value = null;

      return new Sorting(ColumnName.NOTE, orderNote.value);
    } else {
      // SORTING DEFAULT VALUE INVOICE DATE
      return new Sorting(ColumnName.INVOICE_DATE, Order.ASC);
    }
  };

  const defaultSorting = () => {
    orderYear.value = null;
    orderInvoiceCode.value = null;
    // SORTING DEFAULT VALUE INVOICE DATE
    orderInvoiceDate.value = Order.ASC;
    orderInvoiceAmount.value = null;
    orderOpenAmount.value = null;
    orderDueDate.value = null;
    orderNote.value = null;
  };

  const tableHeaderClasses = () => {
    if (props.isSelectionActive) {
      return 'grid grid-cols-[30px_65px_133px_163px_160px_160px_150px_minmax(163px,1fr)] gap-4 min-h-32';
    }
    return 'grid grid-cols-[65px_133px_163px_160px_160px_150px_minmax(163px,1fr)] gap-4 min-h-32';
  };

  watch(
    [() => props.customerId, sorting],
    () => {
      getCustomerInvoices(props.customerId, sorting.value)
        .then(() => (shouldRender.value = true))
        .catch((error) => console.log(error));
    },
    { immediate: true }
  );

  onMounted(() => defaultSorting());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .right {
    @apply justify-end;
  }
</style>
