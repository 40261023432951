<template>
  <svg id="ic_info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4633" data-name="Rechteck 4633" width="20" height="20" fill="none" />
    <g
      id="Pfad_11099"
      data-name="Pfad 11099"
      transform="translate(-0.831 -1)"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M19,11a8,8,0,1,1-8-8A8,8,0,0,1,19,11Z" stroke="none" />
      <path
        d="M 11 17.5 C 14.58411026000977 17.5 17.5 14.58411026000977 17.5 11 C 17.5 7.415890216827393 14.58411026000977 4.5 11 4.5 C 7.415890216827393 4.5 4.5 7.415890216827393 4.5 11 C 4.5 14.58411026000977 7.415890216827393 17.5 11 17.5 M 11 19 C 6.581719875335693 19 3 15.41827964782715 3 11 C 3 6.581719875335693 6.581719875335693 3 11 3 C 15.41827964782715 3 19 6.581719875335693 19 11 C 19 15.41827964782715 15.41827964782715 19 11 19 Z"
        stroke="none"
        fill="currentColor"
      />
    </g>
    <g id="Gruppe_6844" data-name="Gruppe 6844" transform="translate(-1041.7 -271.226)">
      <rect
        id="Rechteck_4361"
        data-name="Rechteck 4361"
        width="1.736"
        height="4"
        transform="translate(1051 280.451)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_4362"
        data-name="Rechteck 4362"
        width="1.736"
        height="1.5"
        transform="translate(1051 278)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
