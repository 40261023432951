<template>
  <svg id="ic_clientdetails" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <circle id="Ellipse_224" data-name="Ellipse 224" cx="25" cy="25" r="25" fill="none" />
    <g id="Icon_feather-user-check" data-name="Icon feather-user-check" transform="translate(14.16 7.906)">
      <path
        id="Pfad_11170"
        data-name="Pfad 11170"
        d="M23.967,26.969c0-2.923-2.682-4.469-5.991-4.469H7.491C4.182,22.5,1.5,24.046,1.5,26.969v3.47"
        transform="translate(-2.738 -1.072)"
        fill="none"
        stroke="#86939f"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Pfad_11171"
        data-name="Pfad 11171"
        d="M20.213,11.232A6.732,6.732,0,1,1,13.482,4.5,6.732,6.732,0,0,1,20.213,11.232Z"
        transform="translate(-3.668 -1.372)"
        fill="none"
        stroke="#86939f"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Pfad_11172"
        data-name="Pfad 11172"
        d="M25.5,17.244l3.649,3.649,8.537-8.534"
        transform="translate(-9.469 11.502)"
        fill="none"
        stroke="#008232"
        stroke-linecap="square"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
