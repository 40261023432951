<template>
  <div>
    <div class="p-4 text-s-20">
      <span :class="formGroupClasses" class="font-bold">
        {{ label }}
      </span>
    </div>
    <slot :required="required"></slot>
    <div class="p-4 text-s-20" v-if="validationState" :class="formGroupClasses">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  interface Props {
    label: string;
    required?: boolean;
    errorMessage?: string | null;
    validationState?: undefined | null | boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    label: '',
    required: false,
    errorMessage: null,
    validationState: null,
  });

  const formGroupClasses = computed(() => ({
    'text-danger': props.validationState === false,
    'after:content-["*"]': props.required && props.label.length > 0,
  }));
</script>
