<template>
  <div v-if="offer">
    <div class="flex items-start justify-between gap-24 py-12 text-s-13 max-md:flex-row max-sm:flex-col shrink grow-0">
      <div class="flex basis-1/2 flex-col gap-y-8 justify-end">
        <!-- CUSTOMER INFO -->
        <div class="flex justify-end bg-neutral-300 rounded-full p-4 w-fit shrink grow-0 px-12">
          {{ t('offers.customer') }}: {{ offer.customer.code + ' - ' + offer.customer.name }}
        </div>
        <!-- AGENT INFO -->
        <div class="flex justify-end border border-neutral-300 rounded-full p-4 w-fit shrink grow-0 px-12">
          <span class="text-neutral-550">{{ t('offers.agentName') }}:&nbsp;</span>{{ offer.agentName }}
        </div>
      </div>
      <!-- OPEN ITEMS INFO -->
      <div class="flex gap-x-4 grow-0" v-if="invoiceNumber">
        <!-- OPEN ITEMS NUMBER -->
        <div class="flex gap-4 items-center justify-center w-fit bg-neutral-300 rounded-full p-4 shrink">
          <div class="flex items-center justify-center rounded-full bg-infoBlue text-white min-w-24 min-h-24">
            {{ invoiceNumber.totalNumberOpenItems }}
          </div>
          <span class="ml-[2px] mr-4">{{ t('offers.openItems') }}</span>
        </div>
        <!-- TODAY < X < 1 -->
        <div class="flex gap-4 items-center justify-center w-fit bg-neutral-300 rounded-full p-4 shrink">
          <div class="flex items-center justify-center rounded-full bg-infoYellow text-white min-w-24 min-h-24">
            {{ invoiceNumber.totalLeastOneMonth }}
          </div>
          <span class="ml-[2px] mr-4">{{ t('offers.openItemstotalLeast1Month') }}</span>
        </div>
        <!-- 1 < X < 6  -->
        <div class="flex gap-4 items-center justify-center w-fit bg-neutral-300 rounded-full p-4 shrink">
          <div class="flex items-center justify-center rounded-full bg-infoOrange text-white min-w-24 min-h-24">
            {{ invoiceNumber.totalOneMonth }}
          </div>
          <span class="ml-[2px] mr-4">{{ t('offers.openItems1M') }}</span>
        </div>
        <!-- X > 6  -->
        <div class="flex gap-4 items-center justify-center bg-neutral-300 rounded-full p-4 shrink">
          <div class="flex items-center justify-center rounded-full bg-infoRed text-white min-w-24 min-h-24">
            {{ invoiceNumber.totalSixMonth }}
          </div>
          <span class="ml-[2px] mr-4">{{ t('offers.openItems6M') }}</span>
        </div>
      </div>
    </div>
    <div v-if="isCustomerBlocked && customer !== undefined" class="py-12">
      <AgAlert variant="danger">
        {{ t('offers.userBlocked') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <div v-else-if="isCustomerBlocked" class="py-12">
      <AgAlert variant="danger">
        {{ t('offers.notAssociated') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { InvoiceNumberDto } from '@/domain/InvoiceNumberDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import IcAlert from '@/components/icons/IcAlert.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useCommon } from '@/composables/useCommon';
  import { config } from '@/config/config';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { getInvoiceNumber } = useCommon();

  const { tables } = useMasterData();

  const offerStore = useOfferWizardStore();
  const { offer, isCustomerBlocked } = storeToRefs(offerStore);

  const invoiceNumber = ref<InvoiceNumberDto | null>(null);
  const customer = ref<CustomerDto | undefined>(undefined);

  const getCustomer = async function () {
    const code = offer.value?.customer.code ?? '';
    const customer = await tables.customers.where('code').equals(code).first();
    return customer;
  };

  onMounted(async () => {
    customer.value = await getCustomer();
    setTimeout(async () => {
      if (config.company.modules.OPEN_ITEMS && offer.value?.customer.id) {
        invoiceNumber.value = await getInvoiceNumber(offer.value?.customer.id);
      }
    }, 1000);
  });
</script>
