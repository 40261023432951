import type { OfferDto } from '@/domain/offerData/OfferDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { useOffers } from '@/modules/offers/composables/useOffers';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

export const useOfferWizardStore = defineStore('offer-wizard', () => {
  const theOffer = ref<OfferDto | null>(null);
  const isCustomerBlocked = ref<boolean>(false);
  const validationStates = ref<Array<boolean>>([true, true, true, true, true, true]);
  const wizzardAddressOrrigin = ref<{ [key: string]: string }>({});

  const masterData = useMasterData();
  const offers = useOffers();

  watch(
    theOffer,
    async (newValue, oldValue) => {
      if (newValue && oldValue) {
        const copy = cloneDeep(newValue);
        copy.synced = 0;

        await offers.saveOffer(copy);

        isCustomerBlocked.value = await isBlocked();
      }
    },
    { immediate: true, deep: true }
  );

  const reset = function () {
    theOffer.value = null;
    isCustomerBlocked.value = false;
    validationStates;
  };

  const loadOfferByGid = async function (gid: string) {
    const maybeOffer = await offers.getOfferByGid(gid);
    if (maybeOffer.isPresent()) {
      theOffer.value = maybeOffer.get();
      isCustomerBlocked.value = await isBlocked();
    } else {
      theOffer.value = null;
      isCustomerBlocked.value = false;
    }
  };

  const isLoaded = computed(() => {
    return theOffer.value !== null;
  });

  const isBlocked = async function () {
    if (!theOffer.value || !theOffer.value.customer.id) {
      return false;
    }
    const id = theOffer.value.customer.id;
    const customer = await masterData.tables.customers.where('id').equals(id).first();
    if (!customer) {
      return true;
    }
    return customer.blocked;
  };

  return {
    reset,
    validationStates: validationStates,
    offer: theOffer,
    loadOfferByGid,
    isLoaded,
    isCustomerBlocked,
    wizzardAddressOrrigin,
  };
});
