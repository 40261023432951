<template>
  <div class="m-32">
    <div>DASHBOARD</div>
    {{ userList }}
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/domain/admin/AuthUserDto';

  import { useDashboard } from '@/admin/composables/useDashboard';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { onMounted, ref } from 'vue';

  const { getUsers } = useDashboard();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.dashboardTitle');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const userList = ref<Array<AuthUserDto>>([]);

  onMounted(async () => {
    userList.value = await getUsers();
  });
</script>
