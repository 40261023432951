<template>
  <div class="table grid-cols-[minmax(150px,1fr)_150px_130px_200px_200px]">
    <div>{{ props.offer.customer.name }}</div>
    <div class="flex flex-col">
      <span>{{ props.offer.agentName }}</span>
      <span v-if="props.offer.agentId !== props.offer.userId">{{ props.offer.userName }}</span>
    </div>
    <div v-if="props.customerStatus">{{ t('statusInactive') }}</div>
    <div v-else>{{ t('statusActive') }}</div>
    <div>{{ formatDateTime(props.offer.createdAt) }}</div>
    <div class="flex justify-end">{{ props.offer.offerNumber }}</div>
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  const { formatDateTime } = useDateFormatting();

  interface Props {
    offer: OfferDto;
    customerStatus: boolean;
  }
  const props = defineProps<Props>();
</script>

<style scoped lang="scss">
  .table {
    @apply grid items-center border-t border-neutral-500 gap-4 py-8;
  }

  .table:hover {
    @apply bg-neutral-200;
  }
</style>
