<template>
  <svg
    id="stats_arrow_up"
    data-name="stats arrow up"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
  >
    <g id="Gruppe_7487" data-name="Gruppe 7487">
      <path
        id="Pfad_12467"
        data-name="Pfad 12467"
        d="M3.0507,14.3639L11.6774,5.7372v7.9196h1.9799s0-11.3137,0-11.3137H2.3436v1.9799h7.9196S1.6365,12.9497,1.6365,12.9497l1.4142,1.4142Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
