<template>
  <!-- TABLE CONTENT -->
  <div class="table">
    <!-- COLUMN YEAR -->
    <div>
      <span class="signal" :class="getClasses(props.openItem.dueIndicator)">&nbsp;</span>
      <span>{{ props.openItem.year }}</span>
    </div>
    <!-- COLUMN INVOICE CODE -->
    <div>{{ props.openItem.invoiceCode }}</div>
    <!-- COLUMN INVOICE DATE -->
    <div>{{ formatDate(props.openItem.invoiceDate) }}</div>
    <!-- COLUMN CUSTOMER CODE -->
    <div>{{ props.openItem.customerCode }}</div>
    <!-- COLUMN CUSTOMER NAME -->
    <div class="min-w-[133px]">{{ props.openItem.customerName }}</div>
    <!-- COLUMN INVOICE AMOUNT -->
    <div class="flex justify-end">
      {{ formatNumberToEuro(props.openItem.invoiceAmount) }}
    </div>
    <!-- COLUMN OPEN AMOUNT -->
    <div class="flex justify-end" :class="{ 'text-infoRed': props.openItem.openAmount < 0 }">
      {{ formatNumberToEuro(props.openItem.openAmount) }}
    </div>
    <!-- COLUMN DUE DATE -->
    <div class="flex justify-end">
      {{ formatDate(props.openItem.dueDate) }}
    </div>
    <!-- COLUMN NOTE -->
    <div class="flex justify-end">{{ props.openItem.note }}</div>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';

  interface Props {
    openItem: OutstandingDebtDto;
  }
  const props = defineProps<Props>();

  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();

  const getClasses = (dueIndicator?: number) => {
    if (dueIndicator) {
      if (dueIndicator === 1) {
        return 'signal--yellow'; // 0 due
      } else if (dueIndicator === 2) {
        return 'signal--orange'; // >1 mounth
      } else if (dueIndicator === 3) {
        return 'signal--red'; // >6 mounth
      }
    }
    return ''; // not due
  };
</script>

<style scoped lang="scss">
  .table {
    @apply grid grid-cols-[80px_140px_150px_130px_minmax(200px,1fr)_165px_165px_150px_minmax(200px,1fr)] gap-4 min-h-48;
    @apply items-center border-t border-neutral-500;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .signal {
    padding: 0 0 0 6px;
    margin: 0 0.7em 0 0;

    &--yellow {
      background-color: #f8bb00;
    }
    &--red {
      background-color: #e74668;
    }
    &--orange {
      background-color: #ed5818;
    }
  }
</style>
