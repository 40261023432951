<template>
  <div>
    <MessageRow v-for="message in props.messages" :message="message" :key="message.id" />
  </div>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/domain/CommunicationDto';

  import MessageRow from '@/modules/messages/components/MessageRow.vue';

  interface Props {
    messages: Array<CommunicationDto>;
  }
  const props = defineProps<Props>();
</script>
