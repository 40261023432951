<template>
  <svg
    id="stats_arrow_equal"
    data-name="stats arrow equal"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
  >
    <g id="Gruppe_7487" data-name="Gruppe 7487">
      <path
        id="Pfad_12467"
        data-name="Pfad 12467"
        d="M0,9h12.2l-5.6,5.6,1.4,1.4,8-8L8,0l-1.4,1.4,5.6,5.6H0v2Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
