import authRequired from '@/middleware/authRequired';
import OpenCheckListIndexPage from '@/modules/checklists/pages/OpenCheckListIndexPage.vue';

import type { RouteRecordRaw } from 'vue-router';
import CompletedCheckListsIndexPage from './pages/CompletedCheckListsIndexPage.vue';
import ExpiredChceklistsIndexPage from './pages/ExpiredChceklistsIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/open',
    component: OpenCheckListIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/completed',
    component: CompletedCheckListsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/expired',
    component: ExpiredChceklistsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/:agentActivityId',
    name: 'activity/open',
    component: OpenCheckListIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/expired/:agentActivityId',
    name: 'activity/expired',
    component: ExpiredChceklistsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/completed/:agentActivityId',
    name: 'activity/completed',
    component: CompletedCheckListsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
