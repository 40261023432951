import type { DimensionItemDto } from '@/domain/DimensionItemDto';
import type { ArticleDto } from '@/domain/masterData/ArticleDto';
import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { useCommon } from '@/composables/useCommon';
import { useTranslatedText } from '@/composables/useTransalteText';
import { Optional } from '@/util/Optional';

export function useArticleFactory() {
  const masterData = useMasterData();

  const { getTranslatedText } = useTranslatedText();
  const { getExternalCode, getArticleGroup, getArticleType } = useCommon();

  const getShoppingCartArticles = async function () {
    const articles = await masterData.tables.articles.toArray();
    articles.sort((a1, a2) => getTranslatedText(a1.title).localeCompare(getTranslatedText(a2.title)));

    const shoppingCartArticles = await Promise.all(
      articles.map(async (article) => await articleToShoppingCartArticle(article))
    );
    return Optional.of(shoppingCartArticles);
  };

  const articleToShoppingCartArticle = async function (
    article: ArticleDto,
    customerId?: number | null
  ): Promise<ShoppingCartArticleDto> {
    return {
      id: article.id,
      code: article.code,

      externalCode: await getExternalCode(article.id, customerId),

      type: article.type,
      unit: article.unit,
      searchName: article.searchName,
      sizeForNumber: article.sizeForNumber,
      sizeForWeight: article.sizeForWeight,
      contingentGroupId: article.contingentGroupId,
      articleTaxCode: article.articleTaxCode,
      minPrice: article.minPrice,
      title: article.title,
      infoTitle: article.infoTitle,

      articleGroup: await getArticleGroup(article.articleGroupId),
      articleType: await getArticleType(article.articleTypeId),

      packagings: article.packagings
        ? article.packagings.sort((pa, pb) =>
            getTranslatedText(pa.unit?.title).localeCompare(getTranslatedText(pb.unit?.title))
          )
        : [],

      articleDimensionValueConfigIds: article.articleDimensionValueConfigIds,
      defaultDimensionValueConfigIds: article.defaultDimensionValueConfigIds,
      priceGroupIds: article.priceGroupIds,
    };
  };

  const getDimOptions = async function (dimensionIds: Array<number>, defaultDimensions: Array<number> = []) {
    if (dimensionIds.length === 0) {
      return null;
    }
    const dimensionValueConfigs = await masterData.tables.articleDimensionValueConfigs.toArray();
    const dimOptions: { [key: string]: Array<DimensionItemDto> } = {};

    dimensionValueConfigs.forEach((item) => {
      if (dimensionIds.includes(item.id)) {
        const key = item.dimensionConfig.id.toString();
        if (dimOptions[key]) {
          dimOptions[key].push({
            id: item.dimensionConfig.id,
            code: item.dimensionConfig.code,
            title: item.dimensionConfig.title,
            value: {
              id: item.id,
              code: item.code,
              title: item.title,
              default: defaultDimensions.includes(item.id),
            },
          });
        } else {
          dimOptions[key] = [
            {
              id: item.dimensionConfig.id,
              code: item.dimensionConfig.code,
              title: item.dimensionConfig.title,
              value: {
                id: item.id,
                code: item.code,
                title: item.title,
                default: defaultDimensions.includes(item.id),
              },
            },
          ];
        }
      }
    });
    return dimOptions;
  };

  return {
    getShoppingCartArticles,
    getDimOptions,
  };
}
