<template>
  <div>
    <TheSubheader>
      <StatisticByCustomerFilter
        v-model:agentId="agentId"
        v-model:inputSearch="inputSearch"
        v-model:customerCode="customerCode"
        v-model:startMonth="startMonth"
        v-model:endMonth="endMonth"
        v-model:yearOne="yearOne"
        v-model:yearTwo="yearTwo"
        @newSearch="newSearch"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 max-md:mx-16 md:px-[80px]">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else-if="byCustomer.content.length > 0">
        <!-- PAGINATOR -->
        <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="byCustomer.totalElements" />
        <AgPaginator v-model="currentPage" :total-pages="byCustomer.totalPages" class="my-24" />
        <!-- TABLE STATISTICS BY CUSTOMER -->
        <StatisticByCustomerTable
          v-model:sorting="sorting"
          :statistics="byCustomer.content"
          :periodOne="yearOne"
          :periodTwo="yearTwo"
          :startMonth="startMonth"
          :endMonth="endMonth"
          :agentid="agentId"
        />
      </div>
      <div v-else>
        <AgAlert variant="info">{{ t('statistics.noData') }}</AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import StatisticByCustomerTable from '@/modules/statistics/components/by-customer/StatisticByCustomerTable.vue';
  import StatisticByCustomerFilter from '@/modules/statistics/components/filters/StatisticByCustomerFilter.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getAllHierarchy } = useHierarchy();

  const store = useStatisticStore();
  const { byCustomer } = storeToRefs(store);
  const { getStatisticsByCustomer } = store;

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('statistics.statistics', 'statistics.byCustomer');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const currentPage = ref<number>(1);
  const pageSize = ref<number>(10);

  const isLoading = ref<boolean>(true);

  const sorting = ref<Sorting>(new Sorting('customername', Order.ASC));

  const agentId = ref<number>();
  const customerCode = ref<string>('');
  const inputSearch = ref<string>('');

  const date: Date = new Date();
  const startMonth = ref<number>(1);
  const endMonth = ref<number>(13);

  const yearOne = ref<number>(date.getFullYear() - 1);
  const yearTwo = ref<number>(date.getFullYear());

  const newSearch = function () {
    const today = endMonth.value == 13 ? true : false;
    const eMonth = today ? new Date().getMonth() + 1 : endMonth.value;
    const filter = new StatisticFilter(
      undefined,
      undefined,
      startMonth.value,
      eMonth,
      yearOne.value,
      yearTwo.value,
      undefined,
      inputSearch.value,
      agentId.value,
      customerCode.value,
      today
    );
    getStatisticsByCustomer(new PageRequest(pageSize.value, currentPage.value), sorting.value, filter)
      .then(() => (isLoading.value = false))
      .catch((error) => console.log(error));
  };

  watch([currentPage, sorting, customerCode, inputSearch, agentId], () => newSearch(), {
    immediate: false,
    deep: true,
  });

  onMounted(async () => {
    const hierarchyList = await getAllHierarchy();
    if (hierarchyList.length > 0 && hierarchyList[0].children.length > 0) {
      agentId.value = hierarchyList[0].authUser.id;
    } else {
      isLoading.value = true;
      newSearch();
    }
  });
</script>
