<template>
  <div class="my-12">
    <div class="tableHeader">
      <div class="headerColumn">{{ t('customers.status') }}</div>
      <div class="headerColumn">{{ t('customers.numberOfInvoices') }}</div>
      <div class="headerColumn right">{{ t('customers.openAmount') }}</div>
    </div>

    <AgCollapsible show-icon class="border-t border-neutral-500">
      <template #header>
        <div class="table">
          <div class="tableColumn">
            <span class="signal signal--orange">&nbsp;</span>
            <span>{{ t('customers.oneMonthdue') }}</span>
          </div>
          <div class="tableColumn">{{ dueGroup.invoiceCount }}</div>
          <div class="tableColumn right" :class="{ 'text-infoRed': dueGroup.openAmount < 0 }">
            {{ formatNumberToEuro(dueGroup.openAmount) }}
          </div>
        </div>
      </template>
      <template #default>
        <CustomerOpenItemsRow :items="dueGroup.items" />
      </template>
    </AgCollapsible>

    <AgCollapsible show-icon class="border-t border-neutral-500">
      <template #header>
        <div class="table">
          <div class="tableColumn">
            <span class="signal signal--red">&nbsp;</span>
            <span>{{ t('customers.sixMonthDue') }}</span>
          </div>
          <div class="tableColumn">{{ overdueGroup.invoiceCount }}</div>
          <div class="tableColumn right" :class="{ 'text-infoRed': overdueGroup.openAmount < 0 }">
            {{ formatNumberToEuro(overdueGroup.openAmount) }}
          </div>
        </div>
      </template>
      <template #default>
        <CustomerOpenItemsRow :items="overdueGroup.items" />
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import CustomerOpenItemsRow from '@/modules/customers/components/open-items/CustomerOpenItemsRow.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToEuro } = useNumberFormatting();

  const store = useByCustomerStore();
  const { getCustomerInvoices } = store;

  const { openItems } = storeToRefs(store);

  interface Props {
    customerId: number;
  }
  const props = defineProps<Props>();

  const sorting = ref<Sorting>(new Sorting('invoiceDate', Order.ASC));

  const dueGroup = computed(() => {
    const dueInvoices = openItems.value.filter((item) => item.dueIndicator && item.dueIndicator === 2);
    let amount = 0;
    dueInvoices.forEach((item) => {
      amount = amount + item.openAmount;
    });
    return {
      dueIndicator: 2,
      openAmount: amount,
      invoiceCount: dueInvoices.length,
      items: dueInvoices,
    };
  });

  const overdueGroup = computed(() => {
    const dueInvoices = openItems.value.filter((item) => item.dueIndicator && item.dueIndicator === 3);
    let amount = 0;
    dueInvoices.forEach((item) => {
      amount = amount + item.openAmount;
    });
    return {
      dueIndicator: 3,
      openAmount: amount,
      invoiceCount: dueInvoices.length,
      items: dueInvoices,
    };
  });

  onMounted(async () => await getCustomerInvoices(props.customerId, sorting.value));
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-flow-col grid-cols-3 gap-4 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid grid-flow-col grid-cols-3 gap-4 py-8;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .signal {
    padding: 0 0 0 6px;
    margin: 0 0.7em 0 0;

    &--red {
      background-color: #e74668;
    }
    &--orange {
      background-color: #ed5818;
    }
  }
</style>
