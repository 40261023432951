<template>
  <AgBox>
    <router-link to="/offers" class="router-link">
      <span>{{ t('draftOffersDashboard') }}:</span><span>{{ sizeDraftOffers }}</span>
    </router-link>
    <router-link to="/offers/completed" class="router-link">
      <span>{{ t('completedOffersDashboard') }}:</span><span>{{ sizeCompletedOffers }}</span>
    </router-link>
    <div class="flex items-center justify-between">
      <span>{{ t('totalOffersDashboard') }}:</span><span>{{ totalOffers }}</span>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import AgBox from '@/components/library/box/AgBox.vue';

  import { useOffersStore } from '@/modules/offers/stores/useOffersStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { onMounted } from 'vue';

  const { t } = i18n.global;

  const offerStore = useOffersStore();
  const { sizeCompletedOffers, sizeDraftOffers, totalOffers } = storeToRefs(offerStore);

  onMounted(async () => await offerStore.refreshOffersData());
</script>

<style scoped lang="scss">
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }

  .router-link:hover {
    @apply underline;
  }
</style>
