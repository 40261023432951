<template>
  <div class="py-[40px] overflow-x-auto">
    <div class="mx-auto w-fit">
      <!-- TABLE HEADER PERIODS-->
      <StatisticByMonthPeriodsHeader
        :yearOne="props.yearOne"
        :yearTwo="props.yearTwo"
        :startMonth="props.startMonth"
        :endMonth="props.endMonth"
        :agentId="props.agentId"
      />

      <!-- TABLE HEADER -->
      <StatisticTableByMonthHeader :statistics="props.statistics" :agentId="props.agentId" />

      <!-- ROWS -->
      <div v-if="props.statistics && props.statistics.length > 0">
        <div v-for="statistic in statistics" :key="statistic.month">
          <StatisticByMonthTableRow
            :statistic="statistic"
            :yearOne="props.yearOne"
            :yearTwo="props.yearTwo"
            :startMonth="props.startMonth"
            :endMonth="props.endMonth"
            :inputSearch="props.inputSearch"
            :agentId="props.agentId"
          />
        </div>
        <StatisticByMonthTableRow class="font-bold" isSumRow :statistic="sumStatistic" :agentId="props.agentId" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import StatisticByMonthPeriodsHeader from '@/modules/statistics/components/by-month/StatisticByMonthPeriodsHeader.vue';
  import StatisticByMonthTableRow from '@/modules/statistics/components/by-month/StatisticByMonthTableRow.vue';
  import StatisticTableByMonthHeader from '@/modules/statistics/components/by-month/StatisticTableByMonthHeader.vue';

  import { computed } from 'vue';

  interface Props {
    statistics: Array<StatisticByMonthDto>;
    yearOne: number;
    yearTwo: number;
    startMonth: number;
    endMonth: number;
    inputSearch: string;
    agentId: number | undefined;
  }
  const props = defineProps<Props>();

  const sumStatistic = computed(() => {
    let st: StatisticByMonthDto = {
      totalAmountPeriodOne: 0,
      totalQuantityPeriodOne: 0,
      totalAmountPeriodTwo: 0,
      totalQuantityPeriodTwo: 0,
      totalCustomersPeriodOne: 0,
      totalCustomersPeriodTwo: 0,
      month: -1,
    };
    props.statistics.forEach((stat: StatisticByMonthDto) => {
      st.totalQuantityPeriodOne += stat.totalQuantityPeriodOne;
      st.totalAmountPeriodOne += stat.totalAmountPeriodOne;
      st.totalQuantityPeriodTwo += stat.totalQuantityPeriodTwo;
      st.totalAmountPeriodTwo += stat.totalAmountPeriodTwo;
      st.totalCustomersPeriodOne += stat.totalCustomersPeriodOne;
      st.totalCustomersPeriodTwo += stat.totalCustomersPeriodTwo;
    });
    return st;
  });
</script>
