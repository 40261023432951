<template>
  <svg id="ic_outstanding" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4578" width="20" height="20" fill="none" />
    <path
      id="Pfad_13553"
      d="M5.033,2.769l-2.411,3.5v7.742a1.462,1.462,0,0,0,1.607,1.249H15.48a1.462,1.462,0,0,0,1.6-1.249V6.266l-2.407-3.5Z"
      transform="translate(0.148 1)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_13554"
      d="M2.622,6.266H17.084"
      transform="translate(0.148 1)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="Pfad_13555"
      d="M13.07,8.766c0,1.231-1.4,1.2-3.179,1.2s-3.251.067-3.251-1.2"
      transform="translate(0.148 1)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>
