<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="ic_add">
      <path id="Pfad_422" data-name="Pfad 422" d="M0,0H24V24H0Z" fill="none" />
      <g id="Gruppe_4715" data-name="Gruppe 4715" transform="translate(-1430.5 -543.5)">
        <line
          id="Linie_136"
          data-name="Linie 136"
          y2="16"
          transform="translate(1442.5 547.5)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        />
        <line
          id="Linie_137"
          data-name="Linie 137"
          x2="16"
          transform="translate(1434.5 555.5)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>
