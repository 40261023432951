<template>
  <div v-if="paginatedContingentsKeys && paginatedContingentsKeys.length === 0" class="py-24">
    <AgAlert variant="info">{{ t('contingents.notFoundByCustomer') }}</AgAlert>
  </div>
  <div v-else>
    <!-- PAGINATED CONTENT -->
    <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="sumContingentsGroup.size" />
    <!-- PAGINATOR -->
    <AgPaginator v-model="currentPage" :total-pages="pageCount" class="my-24" />

    <div class="w-fit mx-auto">
      <!-- TABLE HEADER -->
      <div class="table border-b border-neutral-500 px-16">
        <div class="grid grid-cols-[143px_minmax(233px,333px)]">
          <!-- CUSTOMER CODE -->
          <div class="headerTitle" @click="sortCustomerCode">
            <span class="px-4"> {{ t('contingents.customerCode') }} </span>
            <span v-if="orderCustomerCode === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderCustomerCode === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>

          <!-- CUSTOMER NAME -->
          <div class="headerTitle flex items-center" @click="sortCustomerName">
            <span class="px-4"> {{ t('contingents.customerName') }} </span>
            <span v-if="orderCustomerName === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderCustomerName === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>

        <div class="grid grid-cols-[minmax(80px,180px)_minmax(80px,180px)_minmax(80px,180px)_minmax(80px,180px)]">
          <!-- TOTAL QUANTITY -->
          <div class="headerTitle right" @click="sortTotal">
            <span class="px-4"> {{ t('contingents.total') }} </span>
            <span v-if="orderTotal === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderTotal === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- DELIVERED QUANTITY -->
          <div class="headerTitle right" @click="sortDelivered">
            <span class="px-4"> {{ t('contingents.delivered') }} </span>
            <span v-if="orderDelivered === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderDelivered === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- ORDERED QUANTITY -->
          <div class="headerTitle right" @click="sortOrdered">
            <span class="px-4"> {{ t('contingents.ordered') }} </span>
            <span v-if="orderOrdered === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderOrdered === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
          <!-- AVAILABLE QUANTITY -->
          <div class="headerTitle right" @click="sortAvailable">
            <span class="px-4"> {{ t('contingents.available') }} </span>
            <span v-if="orderAvailable === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderAvailable === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
      </div>

      <!-- TABLE CONTENT OF CONTINGENTS BY CUSTOMER -->
      <div v-for="key in paginatedContingentsKeys" :key="key">
        <ContingentByCustomerRow
          :customerId="key"
          :sumContingentRow="sumContingentsGroup.get(key)"
          :contingents="props.contingents.get(key) ?? []"
          :contingentGroupArticles="contingentGroupArticles"
          :articleDimensionValueConfigs="articleDimensionValueConfigs"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { SumContingentDto } from '@/domain/internal/SumContingent';
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import ContingentByCustomerRow from '@/modules/contingents/components/by-customer/ContingentByCustomerRow.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  interface Props {
    contingents: Map<number, Array<ContingentDto>>;
  }
  const props = defineProps<Props>();

  const { tables } = useMasterData();

  const orderCustomerCode = ref<Order | null>(null);
  const sortCustomerCode = () => getSorting(ColumnName.CUSTOMER_CODE);

  const orderCustomerName = ref<Order | null>(null);
  const sortCustomerName = () => getSorting(ColumnName.CUSTOMER_NAME);

  const orderTotal = ref<Order | null>(null);
  const sortTotal = () => getSorting(ColumnName.TOTAL);

  const orderDelivered = ref<Order | null>(null);
  const sortDelivered = () => getSorting(ColumnName.DELIVERED);

  const orderOrdered = ref<Order | null>(null);
  const sortOrdered = () => getSorting(ColumnName.ORDERED);

  const orderAvailable = ref<Order | null>(null);
  const sortAvailable = () => getSorting(ColumnName.AVAILABLE);

  const sumContingentsGroup = ref<Map<number, SumContingentDto>>(new Map());
  const customers = ref<Array<CustomerDto>>([]);
  const contingentGroupArticles = ref<Array<ContingentGroupArticleDto>>([]);
  const articleDimensionValueConfigs = ref<Array<ArticleDimensionValueConfigsDto>>([]);

  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const pageCount = computed(() => Math.ceil(sumContingentsGroup.value.size / itemsPerPage));

  const paginatedContingentsKeys = computed(() => {
    const orderedKeys = Array.from(sumContingentsGroup.value.keys());

    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, orderedKeys.length);
    const customersPerPage = orderedKeys.slice(startIndex, endIndex);

    return customersPerPage;
  });

  const loadSumContingentsByCustomerId = function () {
    sumContingentsGroup.value.clear();

    props.contingents.forEach((value: ContingentDto[], key: number) => {
      const sum: SumContingentDto = {
        agentId: 0,
        contingentGroupArticleId: 0,
        contingentGroupArticleCode: '',
        contGrArtDescription: {},
        articleDimensionValueConfigId: 0,
        articleDimensionValueConfigDescription: {},
        customerId: 0,
        customerCode: '',
        customerName: '',
        totalQuantity: 0,
        availableQuantity: 0,
        orderedQuantity: 0,
        deliveredQuantity: 0,
        allocatedToCustomer: 0,
        notAllocatedToCustomer: 0,
        filter: '',
      };
      value.forEach((c) => {
        sum.availableQuantity += c.availableQuantity;
        sum.deliveredQuantity += c.deliveredQuantity;
        sum.orderedQuantity += c.orderedQuantity;
        sum.totalQuantity += c.totalQuantity;
      });
      sum.customerId = key;

      const sumContingentCustomer = customers.value.find((c) => c.id === key);
      if (sumContingentCustomer) {
        sum.customerCode = sumContingentCustomer.code;
        sum.customerName = sumContingentCustomer.name;
      } else {
        sum.customerCode = '--';
        sum.customerName = '--';
      }
      sumContingentsGroup.value.set(key, sum);
    });
  };

  enum ColumnName {
    CUSTOMER_CODE = 'customerCode',
    CUSTOMER_NAME = 'customerName',

    TOTAL = 'total',
    DELIVERED = 'delivered',
    ORDERED = 'ordered',
    AVAILABLE = 'available',
  }

  const getSorting = (column: ColumnName) => {
    if (column === ColumnName.CUSTOMER_CODE) {
      if (orderCustomerCode.value === Order.ASC) {
        orderCustomerCode.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const codeA = sumContingentsGroup.value.get(a)?.customerCode ?? '';
          const codeB = sumContingentsGroup.value.get(b)?.customerCode ?? '';
          return codeB.localeCompare(codeA);
        });
      } else {
        orderCustomerCode.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const codeA = sumContingentsGroup.value.get(a)?.customerCode ?? '';
          const codeB = sumContingentsGroup.value.get(b)?.customerCode ?? '';
          return codeA.localeCompare(codeB);
        });
      }
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.CUSTOMER_NAME) {
      if (orderCustomerName.value === Order.ASC) {
        orderCustomerName.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const nameA = sumContingentsGroup.value.get(a)?.customerName ?? '';
          const nameB = sumContingentsGroup.value.get(b)?.customerName ?? '';
          return nameB.localeCompare(nameA);
        });
      } else {
        orderCustomerName.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const nameA = sumContingentsGroup.value.get(a)?.customerName ?? '';
          const nameB = sumContingentsGroup.value.get(b)?.customerName ?? '';
          return nameA.localeCompare(nameB);
        });
      }
      orderCustomerCode.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.TOTAL) {
      if (orderTotal.value === Order.ASC) {
        orderTotal.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.totalQuantity ?? 0) - (sumContingentB?.totalQuantity ?? 0);
        });
      } else {
        orderTotal.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.totalQuantity ?? 0) - (sumContingentA?.totalQuantity ?? 0);
        });
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.DELIVERED) {
      if (orderDelivered.value === Order.ASC) {
        orderDelivered.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.deliveredQuantity ?? 0) - (sumContingentB?.deliveredQuantity ?? 0);
        });
      } else {
        orderDelivered.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.deliveredQuantity ?? 0) - (sumContingentA?.deliveredQuantity ?? 0);
        });
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderOrdered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.ORDERED) {
      if (orderOrdered.value === Order.ASC) {
        orderOrdered.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.orderedQuantity ?? 0) - (sumContingentB?.orderedQuantity ?? 0);
        });
      } else {
        orderOrdered.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.orderedQuantity ?? 0) - (sumContingentA?.orderedQuantity ?? 0);
        });
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderAvailable.value = null;
    } else if (column === ColumnName.AVAILABLE) {
      if (orderAvailable.value === Order.ASC) {
        orderAvailable.value = Order.DESC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentA?.availableQuantity ?? 0) - (sumContingentB?.availableQuantity ?? 0);
        });
      } else {
        orderAvailable.value = Order.ASC;
        paginatedContingentsKeys.value.sort((a, b) => {
          const sumContingentA = sumContingentsGroup.value.get(a);
          const sumContingentB = sumContingentsGroup.value.get(b);
          return (sumContingentB?.availableQuantity ?? 0) - (sumContingentA?.availableQuantity ?? 0);
        });
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderTotal.value = null;
      orderDelivered.value = null;
      orderOrdered.value = null;
    }
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE CUSTOMER VALUE
    orderCustomerCode.value = null;
    orderCustomerName.value = Order.ASC;
    orderTotal.value = null;
    orderDelivered.value = null;
    orderOrdered.value = null;
    orderAvailable.value = null;
  };

  watch(
    props.contingents,
    async (value) => {
      if (value) {
        customers.value = await tables.customers.toArray();
        contingentGroupArticles.value = await tables.contingentGroupArticles.toArray();
        articleDimensionValueConfigs.value = await tables.articleDimensionValueConfigs.toArray();
        currentPage.value = 1;
        loadSumContingentsByCustomerId();
        defaultSorting();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .table {
    @apply flex justify-between items-center min-h-48;
  }

  .headerTitle {
    @apply flex items-center font-light text-neutral-550 hover:cursor-pointer;
  }

  .right {
    @apply flex justify-end;
  }
</style>
