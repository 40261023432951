export function useNumberFormatting() {
  const formatNumberToEuro = (number?: number | null) => {
    if (typeof number !== 'undefined' && number !== null) {
      return formatNumber(number, 2, '€');
    }
    return '-';
  };

  const formatNumberToPercentage = (number?: number | null) => {
    const val = formatNumber(number, 2);
    return val ? val + '%' : '-';
  };

  const formatNumber = (number?: number | null, decimals: number | null = null, unit: string | null = null) => {
    if (number === null || typeof number === 'undefined') {
      return number;
    }
    const regExp = new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g'),
      values = (decimals !== null ? number.toFixed(decimals) : number.toString()).split('.');
    return values[0].replace(regExp, '$&.') + (values[1] ? ',' + values[1] : '') + (unit ? unit : '');
  };

  const formatToNumber = (number?: number | null) => {
    if (typeof number !== 'undefined' && number !== null) {
      return formatNumber(number, 2);
    }
    return '-';
  };

  return { formatToNumber, formatNumberToEuro, formatNumberToPercentage };
}
