<!-- eslint-disable max-len -->
<template>
  <svg id="ic_logout" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Rechteck_4632" data-name="Rechteck 4632" width="24" height="24" fill="none" />
    <path
      id="Pfad_9735"
      data-name="Pfad 9735"
      d="M-.788-1.4.623.011l5-5-5-5L-.788-8.579l2.58,2.59h-9.67v2h9.67Zm8.91-12.59h-14a2,2,0,0,0-2,
      2v4h2v-4h14v14h-14v-4h-2v4a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2v-14a2.006,2.006,0,0,0-2-2"
      transform="translate(10.879 16.989)"
      fill="currentColor"
    />
  </svg>
</template>
