<template>
  <svg id="ic_offer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <rect id="Rechteck_4577" width="20" height="20" fill="none" />
    <path
      id="Pfad_14634"
      data-name="Pfad 14634"
      d="M11.157,18.222H4.871A1.121,1.121,0,0,1,3.75,17.1V5.9A1.121,1.121,0,0,1,4.871,4.778h6.287A1.121,1.121,0,0,1,12.278,5.9V17.1A1.121,1.121,0,0,1,11.157,18.222Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      id="Pfad_14635"
      data-name="Pfad 14635"
      d="M7.988,5.157c0-.012,0-2.45,0-2.463a.967.967,0,0,1,.926-.973h6.43a.971.971,0,0,1,.926,1.01V13.324a.972.972,0,0,1-.918,1.01H12.187"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_351"
      data-name="Linie 351"
      x2="4"
      transform="translate(6 8.75)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      id="Linie_352"
      data-name="Linie 352"
      x2="4"
      transform="translate(6 11.5)"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
