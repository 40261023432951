<template>
  <div class="relative">
    <input
      type="text"
      aria-label="AgFormInputGeoLocation"
      class="h-[40px] w-full rounded border-[1px] p-8 text-s-20 focus:outline-0 focus:border-black"
      v-model="internalValue"
      :id="id"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="inputTextClasses"
    />
    <div>
      <IcAlert class="absolute inset-y-12 right-24 text-danger" v-if="validationState === false" />
      <IcPin class="absolute inset-y-12 right-4 cursor-pointer" @click="getLocation" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcPin from '@/components/icons/IcPin.vue';

  import { i18n } from '@/plugins/i18n';
  import { notify } from '@kyvg/vue3-notification';
  import { computed, getCurrentInstance } from 'vue';

  const { t } = i18n.global;

  const id = `form-input-geolocation-${getCurrentInstance()?.uid}`;

  interface Props {
    geoType: 'Latitude' | 'Longitude';
    placeholder?: string;
    modelValue?: unknown;
    disabled?: boolean;
    validationState?: undefined | null | boolean;
    errorMessage?: undefined | null | string;
  }
  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    validationState: undefined,
    errorMessage: undefined,
    geoType: 'Latitude',
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const getLocation = function () {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
      } else if (result.state === 'prompt') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
        } else {
          notify({
            title: t('offers.noDataGPS'),
            text: t('offers.noDataGPSMessage'),
          });
        }
      } else if (result.state === 'denied') {
        notify({
          title: t('offers.noDataGPS'),
          text: t('offers.noDataGPSMessage'),
        });
        emit('update:modelValue', '');
      }
    });
  };

  function onGeoSuccess(event: GeolocationPosition) {
    if (props.geoType === 'Latitude') {
      emit('update:modelValue', `${event.coords.latitude}`);
    } else {
      emit('update:modelValue', `${event.coords.longitude}`);
    }
  }

  function onGeoError(event: GeolocationPositionError) {
    notify({
      title: t('offers.noDataGPS'),
      text: t('offers.noDataGPSMessage') + ':' + event.message,
    });
  }

  const inputTextClasses = computed(() => ({
    'border-danger': props.validationState === false,
    'border-neutral-550': props.validationState !== false,
    'text-[#afb0b1]': props.disabled,
  }));
</script>

<style scoped lang="scss">
  .tooltip {
    @apply absolute bottom-[130%] left-1/2 z-50 flex -translate-x-1/2 text-s;
    @apply whitespace-nowrap rounded-md bg-black p-8 text-white opacity-0;
    @apply after:absolute after:left-1/2 after:top-full after:-translate-x-1/2;
    @apply after:border-4 after:border-t-black;
  }
</style>
