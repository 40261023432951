<template>
  <div>
    <div v-if="orders.length === 0">
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOrders') }}</span>
      </div>
      <div class="pt-12">{{ t('customers.noDataFound') }}</div>
    </div>
    <div v-else>
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOrders') }}</span>
      </div>
      <div class="max-h-512 overflow-y-auto py-12 min-w-[533px]">
        <div class="tableHeader">
          <div class="headerColumn">{{ t('customers.creationDate') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryMethod') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryTerm') }}</div>
          <div class="headerColumn right">&nbsp;</div>
        </div>
        <div v-for="order in orders" :key="order.gid">
          <AgCollapsible show-icon class="border-t border-neutral-500">
            <template #header>
              <div class="table">
                <div class="tableColumn">
                  {{ formatDate(order.createdAt) }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(order.deliveryMethod?.title) }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(order.deliveryTerm?.title) }}
                </div>
                <div class="tableColumn right space-x-8">
                  <AgButton variant="ghost" @click.stop="onCopy(order)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcCopy /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onEdit(order)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcEdit /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onDelete(order)">
                    <template #icon><IcDelete /></template>
                  </AgButton>
                </div>
              </div>
            </template>
            <template #default>
              <ArticlesTable :items="order.items" />
            </template>
          </AgCollapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import IcCopy from '@/components/icons/IcCopy.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ArticlesTable from '@/modules/customers/components/ArticlesTable.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrders } from '@/modules/orders/composables/useOrders';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { cloneDeep } from 'lodash';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  const { deleteOrder, saveOrder } = useOrders();
  const { copyOrder } = useOrderFactory();
  const { formatDate } = useDateFormatting();
  const { getTranslatedText } = useTranslatedText();

  interface Props {
    draftOrders: Array<OrderDto>;
    isCustomerBlocked: boolean;
  }
  const props = defineProps<Props>();

  const orders = ref<Array<OrderDto>>([]);

  const onEdit = async function (order: OrderDto) {
    await router.push({
      name: 'order-wizard',
      params: { gid: order.gid, step: 'customer' },
    });
  };

  const onCopy = async function (order: OrderDto) {
    const newOrder = await copyOrder(cloneDeep(order));

    await saveOrder(newOrder).then(
      async () =>
        await router.push({
          name: 'order-wizard',
          params: { gid: newOrder.gid, step: 'review' },
        })
    );
  };

  const onDelete = async function (order: OrderDto) {
    await deleteOrder(order).then(() => orders.value.splice(orders.value.indexOf(order), 1));
  };

  watch(
    () => props.draftOrders,
    () => (orders.value = [...props.draftOrders]),
    {
      immediate: true,
    }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col gap-8 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid auto-cols-fr grid-flow-col gap-8 py-8;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }

  .tooltip {
    @apply invisible absolute -mt-32 rounded bg-neutral-500 p-4;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-20;
  }

  .signal {
    padding: 0.2em;
    margin: 0 0.7em 0 0;
    background-color: #1f4092;
  }
</style>
