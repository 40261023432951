<template>
  <div v-if="offer" class="md:px-[80px] max-md:px-16 mt-12 mb-32 min-w-fit">
    <OfferInfo />

    <div class="py-8">
      <div>
        <AgFormGroup :label="t('offers.note')">
          <AgFormTextarea v-model="offer.note" :placeholder="t('offers.hintNote')" :rows="18" />
        </AgFormGroup>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';

  const { t } = i18n.global;

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.notesWizard');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);
</script>
