<template>
  <div class="flex items-center justify-between gap-24 py-12">
    <AgFormGroup :label="t('customers.search')" class="w-2/3">
      <AgFormInput v-model="internalSearch" />
    </AgFormGroup>
    <AgFormGroup :label="t('customers.category')" class="w-1/3">
      <AgFormSelect v-model="internalCategory" :options="categoryOptions" />
    </AgFormGroup>
    <AgFormGroup :label="t('customers.dateFilter')" class="w-2/3">
      <AgFormInput type="date" v-model="internalDateFilter" :disabled="props.selectTypeDateFilter === 0" />
    </AgFormGroup>
    <AgFormGroup :label="t('customers.typeDateFilter')" class="w-1/3">
      <AgFormSelect v-model="internalTypeDateFilter" :options="options" />
    </AgFormGroup>
    <AgFormGroup label="&nbsp;" class="w-1/4">
      <AgButton class="rounded" @click="resetFilters">{{ t('customers.clearFilters') }}</AgButton>
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    selectTypeDateFilter: number;
    inputSearch: string;
    category: string;
    dateFilter: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    inputSearch: '',
    category: '',
    dateFilter: new Date().toLocaleDateString(),
  });

  const emit = defineEmits([
    'update:inputSearch',
    'update:category',
    'update:dateFilter',
    'update:selectTypeDateFilter',
  ]);

  const categoryOptions: Array<AgFormSelectOption> = [
    { label: 'customers.visit', key: t('customers.visit') },
    { label: 'customers.contact', key: t('customers.contact') },
    { label: 'customers.general', key: t('customers.general') },
  ];

  const options = ref<Array<AgFormSelectOption>>([
    { key: 1, label: 'customers.greaterThan' },
    { key: 2, label: 'customers.lowerThan' },
    { key: 3, label: 'customers.equalTo' },
  ]);

  const internalSearch = computed({
    get: () => props.inputSearch,
    set: (value) => emit('update:inputSearch', value),
  });

  const internalCategory = computed({
    get: () => props.category,
    set: (value) => emit('update:category', value),
  });

  const internalTypeDateFilter = computed({
    get: () => props.selectTypeDateFilter,
    set: (value) => emit('update:selectTypeDateFilter', value),
  });

  const internalDateFilter = computed({
    get: () => props.dateFilter || new Date().toISOString().split('T')[0],
    set: (value) => emit('update:dateFilter', value),
  });

  const resetFilters = () => {
    internalSearch.value = '';
    internalCategory.value = '';
    internalTypeDateFilter.value = 0;
    internalDateFilter.value = '';
  };
</script>
