<template>
  <svg id="ic_deliveryadress" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <circle id="Ellipse_224" data-name="Ellipse 224" cx="25" cy="25" r="25" fill="none" />
    <path
      id="Icon_material-location-on"
      data-name="Icon material-location-on"
      d="M16.341,3A8.834,8.834,0,0,0,7.5,11.841c0,6.63,8.841,16.418,8.841,16.418s8.841-9.788,8.841-16.418A8.834,8.834,0,0,0,16.341,3Z"
      transform="translate(8.66 9.047)"
      fill="none"
      stroke="#86939f"
      stroke-width="2"
    />
    <g
      id="Ellipse_230"
      data-name="Ellipse 230"
      transform="translate(21.039 15.938)"
      fill="none"
      stroke="#212529"
      stroke-width="2"
    >
      <ellipse cx="3.962" cy="3.962" rx="3.962" ry="3.962" stroke="none" />
      <ellipse cx="3.962" cy="3.962" rx="2.962" ry="2.962" fill="none" />
    </g>
  </svg>
</template>
