<template>
  <AgModal v-if="internalValue">
    <template #header>
      <div class="flex justify-between">
        <div class="text-2xl font-bold">{{ t('orders.addArticle') }}</div>
        <AgButton variant="ghost" class="text-link" @click.stop="emptyAll">
          {{ t('orders.empty') }}
          <template #icon><IcDelete /></template>
        </AgButton>
      </div>
      <!-- SEARCH -->
      <div class="flex items-center justify-between pt-8 pb-16">
        <AgFormGroup :label="t('orders.search')" class="w-full">
          <AgSearchSelect :options="articleOptions" :placeholder="t('orders.search')" v-model="articleId" />
        </AgFormGroup>
      </div>
    </template>
    <template #content>
      <div v-if="Object.values(selectedArticles).length > 0">
        <div class="flex flex-col">
          <div v-for="key in Object.keys(selectedArticles)" :key="key" class="px-4 my-8">
            <div v-if="selectedArticles[key].size > 0" class="elementNumber">
              <span class="text-neutral-550">{{ key }}&nbsp;/&nbsp;</span>
              <span class="font-bold">{{ t('orders.elementNumber') }} {{ selectedArticles[key].size }}</span>
            </div>
            <!-- ARTICLES -->
            <div v-for="article in selectedArticles[key].values()" :key="article.id" class="row">
              <OrderArticleTableRow
                class="w-full"
                v-model="articlesPackaging"
                :article="article"
                :customerId="order?.customer.id ?? -1"
                :agentId="order?.agentId ?? -1"
                @remove-article="removeArticle(article, key)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center items-center h-full">
        <LogoCart variant="gray" />
      </div>
    </template>
    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal">{{ t('orders.cancel') }}</AgButton>
      <div class="flex gap-x-16">
        <AgButton variant="secondary" @click="resetArticles">{{ t('orders.reset') }}</AgButton>
        <AgButton variant="primary" @click="addArticleToOrder" :disabled="isSaveButtonDisabled">
          {{ t('orders.apply') }}
        </AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
  import type { ContingentEntry } from '@/domain/internal/ContingentEntry';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import IcDelete from '@/components/icons/IcDelete.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import LogoCart from '@/components/logo/LogoCart.vue';
  import OrderArticleTableRow from '@/modules/orders/components/order-wizard/steps/shopping-cart/article-table-row/OrderArticleTableRow.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useCommon } from '@/composables/useCommon';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerType } from '@/domain/enumeration/CustomerType';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrdersStore } from '@/modules/orders/stores/useOrdersStore';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { isFullReloading } = useMasterData();

  const { getTradeAgreementPriceAndDisconts, getContingentCustomerAndOrderArticle, getArticleCustomerTax } =
    useOrderFactory();

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  const orderStore = useOrdersStore();
  const { refreshContingentMap } = orderStore;
  const { contingentMap } = storeToRefs(orderStore);

  const common = useCommon();

  const articles = ref<Array<ShoppingCartArticleDto>>([]);
  const articleId = ref<number>();

  const selectedArticles = ref<{ [key: string]: Set<ShoppingCartArticleDto> }>({});
  const articlesPackaging = ref<ArticlePackaging>({});

  interface Props {
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
    selectedArticles.value = {};
    refreshContingentMap();
    articleId.value = undefined;
  };

  const addArticleToOrder = async function () {
    if (!order.value || Object.values(selectedArticles.value).length === 0) {
      return;
    }
    const formArticle: ArticlePackaging = articlesPackaging.value;
    const tempArticles = Object.values(selectedArticles.value)
      .map((value) => Array.from(value))
      .flat();

    if (tempArticles.length === 0) {
      return;
    }
    for (const article of tempArticles) {
      for (const packaging of article.packagings) {
        const quantity = formArticle[article.id][packaging.id].quantity;
        const freeQuantity = formArticle[article.id][packaging.id].freeQuantity;
        const productionDescription = formArticle[article.id][packaging.id].productionDescription ?? '';
        const dimensions = formArticle[article.id][packaging.id].dimensions ?? [];
        const requestedPartialDelivery = formArticle[article.id][packaging.id].requestedPartialDelivery;

        if (quantity || freeQuantity) {
          const articleFound =
            config.company.productionArticle && config.company.productionArticleTypeCode === article.articleType?.code
              ? orderProductionArticle(article.id, packaging.id, productionDescription, dimensions)
              : orderContainArticle(article.id, packaging.id, dimensions);

          let newQuantity = quantity ?? 0;
          if (articleFound) {
            newQuantity += articleFound.quantity ?? 0;
          }
          // Add first dimension value config id if present
          const dimValConfId = dimensions && dimensions.length > 0 ? dimensions[0].value.id : undefined;
          // GET Trade Agreement and discounts
          const taDiscountLists = await getTradeAgreementPriceAndDisconts(
            order.value,
            article.id,
            article.priceGroupIds,
            newQuantity,
            dimValConfId
          );
          const tradeAgreement = taDiscountLists.tradeAgreement;
          const discounts = taDiscountLists.discounts;
          const price = taDiscountLists.price;

          const isPromotional = tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION ? true : false;

          if (articleFound) {
            const qt = articleFound.quantity ?? 0;
            const fqt = articleFound.freeQuantity ?? 0;

            articleFound.quantity = qt + (quantity || 0);
            articleFound.freeQuantity = fqt + (freeQuantity || 0);
            articleFound.requestedPartialDelivery = requestedPartialDelivery;

            articleFound.article.productionDescription = productionDescription;
            articleFound.article.dimensions = dimensions;

            articleFound.unitPrice = getUnitPrice(price, discounts);
            articleFound.tradeAgreement = {
              id: tradeAgreement?.id ?? -1,
              price: price,
              isPromotional: isPromotional,
              editedPrice: undefined,
            };
            if (discounts.length > 0) {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < discounts.length; i++) {
                const key = `discount${i + 1}` as objectKey;
                setObjProperty(articleFound, key, discounts[i]);
              }
            } else {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < config.company.maxItemDiscounts; i++) {
                const key = `discount${i + 1}` as objectKey;
                if (articleFound[key]) {
                  delete articleFound[key];
                }
              }
            }
          } else {
            order.value.items.push({
              note: '',
              requestedPartialDelivery: requestedPartialDelivery,
              article: {
                id: article.id,
                code: article.code,
                type: article.type,
                unit: article.unit,
                externalCode: article?.externalCode,
                productionDescription: productionDescription,
                dimensions: dimensions,
                priceGroupIds: article.priceGroupIds,
                contingentGroupId: article.contingentGroupId,

                sizeForNumber: article.sizeForNumber,
                sizeForWeight: article.sizeForWeight,

                title: article.title,

                articleGroupId: article.articleGroup?.id,
                articleGroup: article.articleGroup?.title,

                articleType: article.articleType?.title,
                articleTypeCode: article.articleType?.code,
              },
              packaging,
              tradeAgreement: {
                id: tradeAgreement?.id ?? -1,
                price: price,
                isPromotional: isPromotional,
                editedPrice: undefined,
              },
              quantity: quantity || 0,
              freeQuantity: freeQuantity || 0,
              finalPrice: getFinalPrice(price, discounts, packaging.size, quantity ?? 0),
              unitPrice: getUnitPrice(price, discounts),
              isSomeDiscountEdited: false,
              isTradeAgreementPriceEdited: false,
              tax: await getArticleCustomerTax(order.value.customer.customerTaxCode, article.id),
              ...Object.fromEntries(discounts.map((value, i) => [`discount${i + 1}`, value])),
            });
          }
        }
      }
    }
    closeModal();
  };

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const setObjProperty = function <T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  };

  const orderContainArticle = (articleId: number, packagingId: number, dimensions: Array<DimensionItemDto>) => {
    if (order.value) {
      return order.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
    }
    return null;
  };

  const orderProductionArticle = (
    articleId: number,
    packagingId: number,
    description: string,
    dimensions: Array<DimensionItemDto>
  ) => {
    if (order.value) {
      return order.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          item.article.productionDescription === description &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
    }
    return null;
  };

  const compareArrayDimensions = (a?: Array<DimensionItemDto>, b?: Array<DimensionItemDto>) => {
    return a && b && a.length === b.length && a.every((adim) => b.some((bdim) => adim.value.id === bdim.value.id));
  };

  const groupingArticles = function () {
    const article = articles.value.find((article) => article.id === articleId.value);
    if (article) {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      if (selectedArticles.value[group]) {
        selectedArticles.value[group].add(article);
      } else {
        selectedArticles.value[group] = new Set();
        selectedArticles.value[group].add(article);
      }
      articleId.value = undefined;
    }
  };

  const articleOptions = computed((): Array<AgSearchSelectOption> => {
    return articles.value.map((a) => {
      const label: string[] = [a.code];

      const articleGroup = getTranslatedText(a.articleGroup?.title);
      const articleType = getTranslatedText(a.articleType?.title);
      if (articleGroup.length > 0 && articleType.length > 0) {
        label.push(`${articleGroup} (${articleType})`);
      } else if (articleGroup.length > 0) {
        label.push(articleGroup);
      } else if (articleType.length > 0) {
        label.push(articleType);
      }
      const title = getTranslatedText(a.title);
      if (title.length > 0) {
        label.push(title);
      }
      return {
        value: a.id,
        label: label.join(' - '),
        searchableString: a.code + (articleType ?? '') + title + a.searchName,
        disabled: !a.code,
      };
    });
  });

  const loadArticles = async function () {
    articles.value = [];
    if (order.value) {
      const customerId = order.value.customer.id;
      const priceListId = order.value.customer.priceListId;

      articles.value = await common.getArticlesForCustomer(customerId, priceListId);
    }
  };

  const removeArticle = function (article: ShoppingCartArticleDto, group: string) {
    if (selectedArticles.value[group]) {
      selectedArticles.value[group].delete(article);
      if (selectedArticles.value[group].size === 0) {
        delete selectedArticles.value[group];
      }
      for (const packaging of article.packagings) {
        articlesPackaging.value[article.id][packaging.id] = {
          requestedPartialDelivery: undefined,
          productionDescription: undefined,
          dimensions: undefined,
          quantity: null,
          freeQuantity: null,
        };
      }
    }
  };

  const resetArticles = function () {
    refreshContingentMap();
    Object.keys(selectedArticles.value).forEach((key) => {
      selectedArticles.value[key].forEach((article) => {
        for (const packaging of article.packagings) {
          articlesPackaging.value[article.id][packaging.id] = {
            requestedPartialDelivery: false,
            productionDescription: undefined,
            dimensions: undefined,
            quantity: null,
            freeQuantity: null,
          };
        }
      });
    });
  };

  const emptyAll = function () {
    selectedArticles.value = {};
    refreshContingentMap();
  };

  const isSaveButtonDisabled = computed(() => {
    let returnValue = false;
    if (Object.values(selectedArticles.value).length > 0) {
      for (const key in selectedArticles.value) {
        selectedArticles.value[key].forEach((article) => {
          let internalReturn = true;
          for (const packaging of article.packagings) {
            const formArticle = articlesPackaging.value[article.id][packaging.id];
            if (
              internalReturn &&
              ((formArticle.quantity && formArticle.quantity > 0) ||
                (formArticle.freeQuantity && formArticle.freeQuantity > 0))
            ) {
              internalReturn = false;
            }
          }
          returnValue = internalReturn || returnValue;
        });
      }
    } else {
      returnValue = true;
    }
    return returnValue;
  });

  const loadContingentItemInCart = function () {
    if (!order.value) {
      return;
    }
    order.value.items.forEach(async (item) => {
      if (!order.value || !item.article.dimensions) {
        return;
      }
      const dimCode = config.company.contingentDimensionCode;
      const dimId = item.article.dimensions.find((d) => d.code === dimCode)?.value.id ?? -1;
      if (dimId === -1) {
        console.warn('WARING: no contingent dimensions code found !!');
        return;
      }
      const contingent = await getContingentCustomerAndOrderArticle(
        dimId,
        order.value.customer.id,
        item.article,
        order.value.agentId
      );
      if (contingent) {
        const oldCont = contingentMap.value.get(contingent.id);
        const newEntry: ContingentEntry = {
          originalQuantity: 0,
          available: 0,
        };
        if (oldCont) {
          oldCont.available -= (item.quantity ?? 0) + (item.freeQuantity ?? 0);
        } else {
          newEntry.originalQuantity = contingent.availableQuantity;
          newEntry.available = contingent.availableQuantity - ((item.quantity ?? 0) + (item.freeQuantity ?? 0));
          contingentMap.value.set(contingent.id, newEntry);
        }
      }
    });
  };

  watch(
    isFullReloading,
    async (fullReload) => {
      if (!fullReload) {
        await loadArticles();
      }
    },
    { immediate: true }
  );

  watch(
    articleId,
    (newArticleId) => {
      if (newArticleId) {
        groupingArticles();
      }
    },
    { immediate: true }
  );

  watch(
    internalValue,
    (value) => {
      if (value && articles.value) {
        const artPackaging: ArticlePackaging = {};
        for (const article of articles.value) {
          artPackaging[article.id] = {};
          for (const packaging of article.packagings) {
            artPackaging[article.id][packaging.id] = {
              requestedPartialDelivery: undefined,
              productionDescription: undefined,
              dimensions: undefined,
              quantity: null,
              freeQuantity: null,
            };
          }
        }
        articlesPackaging.value = artPackaging;
        loadContingentItemInCart();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .row {
    @apply flex justify-between items-center;
  }

  .elementNumber {
    @apply flex items-center justify-center rounded-full p-4 w-fit shrink-0 grow-0 border bg-neutral-200 border-neutral-300 px-12 text-s mb-4;
  }
</style>
