import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import ByArticleIndexPage from './pages/ByArticleIndexPage.vue';
import ByCustomerIndexPage from './pages/ByCustomerIndexPage.vue';
import ByMonthIndexPage from './pages/ByMonthIndexPage.vue';
import OwnApplicationIndexPage from './pages/CustomIndexPage.vue';
import StatisticsIndexPage from './pages/StatisticsGlobalIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/global',
    component: StatisticsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-month',
    component: ByMonthIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-article',
    component: ByArticleIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-customer',
    component: ByCustomerIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/own-application',
    component: OwnApplicationIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
